import { Drawer, message, Table, DatePicker, Radio } from 'm-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'm-ui/lib/table';
import { history } from 'src/routes/config';
import { Line } from '@mchart/pc-react';

import {
    BadTag,
    HistoryCreateWrapper,
    FilterSearchWrapper,
    GoodTag,
    NiceTag,
    Operator,
    OperatorWrapper,
    PassTag,
    RightWrapper,
} from './style';
import moment, { Moment } from 'moment';

export interface HistoryState {
    pageType: SourceType;
    outPageId?: string;
    pageUrl?: string;
}

import { historyPageQualityListUsingPOST } from 'src/services-new/page-quality-check-controller';
import { IHistoryPageQualityCheckListView, IPageModuleView } from 'src/services-new/models';
import { SourceType } from './ListTable';

interface IProps {
    visible?: boolean;
    onClose?: (refresh?: boolean) => void;
    open?: () => void;
    historyState?: HistoryState;
}
function disabledDate(current) {
    // Can not select days before today and today
    return (
        current &&
        (current > moment().endOf('day') || current < moment().subtract(30, 'days').endOf('day'))
    );
}

/**
 *  根据分数获取标签展示组件
 * @param score
 * @returns
 */
export function getTag(score?: number) {
    if (!score) {
        return null;
    }
    if (score >= 80) {
        return <GoodTag>优秀</GoodTag>;
    }
    if (score >= 70) {
        return <NiceTag>良好</NiceTag>;
    }
    if (score >= 60) {
        return <PassTag>合格</PassTag>;
    }
    return <BadTag>较差</BadTag>;
}

export function HistoryDrawer(props: IProps) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IHistoryPageQualityCheckListView[]>([]);
    const [timeRange, setTimeRange] = useState<[Moment | undefined, Moment | undefined]>([
        // moment().subtract(1, 'year').startOf('day'),
        // moment().endOf('day'),
        undefined,
        undefined,
    ]);
    const [splitInfo, setSplitInfo] = useState({
        pageNum: 1,
        pageSize: 100,
        totalNum: 10,
    });
    useEffect(() => {
        if (props.visible && props.historyState?.pageType) {
            fetchData().then((list) => {
                if (!list) return;
                setData(list);
            });
        }
    }, [props.visible]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        if (!props.historyState?.pageType) {
            return;
        }
        try {
            const response = await historyPageQualityListUsingPOST({
                req: {
                    // pageSize: 10,
                    // pageNum: splitInfo.pageNum,
                    // select: searchValue,
                    startTime: timeRange[0]?.valueOf(),
                    endTime: timeRange[1]?.valueOf(),
                    outPageId:
                        props.historyState?.pageType === SourceType.MOLI
                            ? props.historyState?.outPageId
                            : undefined,
                    pageType: props.historyState?.pageType,
                    pageUrl:
                        props.historyState?.pageType === SourceType.OTHER
                            ? props.historyState?.pageUrl
                            : undefined,
                    splitInfo,
                },
            });
            setSplitInfo((splitInfo) => {
                splitInfo.totalNum = response?.data?.splitInfo?.totalNum;
                return splitInfo;
            });
            // console.log()
            setLoading(false);

            if (response.data && response.data.list) {
                return response.data.list;
            }
            return [];
        } catch (error) {
            message.error('请求出错');
            setLoading(false);
            return [];
        }
    }, [splitInfo.pageNum, timeRange, props.historyState]);
    useEffect(() => {
        fetchData().then((list) => {
            setData(list || []);
        });
    }, [fetchData]);
    const columns: ColumnsType<IPageModuleView> = [
        {
            title: '创建时间',
            dataIndex: 'submitTime',
            key: 'submitTime',
            render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>,
            width: 222,
            sorter: true,
        },
        {
            title: '得分',
            dataIndex: 'reportScore',
            key: 'reportScore',
            width: 158,
            render: (reportScore) => {
                return (
                    <div style={{ display: 'flex' }}>
                        {reportScore}
                        {getTag(+reportScore)}
                    </div>
                );
            },
        },

        {
            title: '营销吸引力',
            dataIndex: 'marketScoreRank',
            key: 'marketScoreRank',
            width: 188,
            sorter: true,
        },

        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            fixed: 'right',
            width: 114,
            render: (id) => {
                return (
                    <OperatorWrapper>
                        <Operator
                            onClick={() => {
                                // window.history.pushState(`/performance/detail?id=${id}`, '');
                                history.push({
                                    pathname: `/quality/detail`,
                                    state: { id },
                                    // query: { id },
                                });
                            }}
                        >
                            查看
                        </Operator>
                    </OperatorWrapper>
                );
            },
        },
    ];
    enum DateRadioValue {
        /**  最近七日*/
        SEVEN = 'SEVEN',
        /** 最近三十日 */
        THIRTY = 'THIRTY',
    }
    const [dateRadio, setDateRadio] = useState<DateRadioValue | undefined>();
    const lineData = useMemo(() => {
        return data
            ?.map((item) => {
                return [item.submitTime, item.reportScore];
            })
            .sort((a, b) => {
                return moment(a[0]).valueOf() - moment(b[0]).valueOf();
            });

        // .reverse();
    }, [data]);
    return (
        <>
            <Drawer
                title={`历史结果`}
                placement="right"
                size={'large'}
                className="quality-create-drawer"
                width={776}
                onClose={() => {
                    props?.onClose?.();
                }}
                visible={props.visible}
            >
                <HistoryCreateWrapper>
                    <FilterSearchWrapper
                        style={{
                            marginBottom: -16,
                        }}
                    >
                        <RightWrapper>
                            <Radio.Group
                                value={dateRadio}
                                onChange={(val) => {
                                    setDateRadio(val.target.value);
                                    if (val.target.value === DateRadioValue.SEVEN) {
                                        setTimeRange([
                                            moment().subtract(7, 'day').startOf('day'),
                                            moment().endOf('day'),
                                        ]);
                                    }
                                    if (val.target.value === DateRadioValue.THIRTY) {
                                        setTimeRange([
                                            moment().subtract(30, 'day').startOf('day'),
                                            moment().endOf('day'),
                                        ]);
                                    }
                                }}
                                style={{ marginBottom: 16 }}
                            >
                                <Radio.Button value={DateRadioValue.SEVEN}>最近七天</Radio.Button>
                                <Radio.Button value={DateRadioValue.THIRTY}>最近30天</Radio.Button>
                            </Radio.Group>
                            <DatePicker.RangePicker
                                allowClear={false}
                                disabledDate={disabledDate}
                                style={{
                                    height: 32,
                                    marginLeft: 16,
                                    marginBottom: 16,
                                }}
                                // @ts-expect-error 第三方UI组件库类型问题
                                value={timeRange}
                                // disabledDate={(current) => {
                                //     const today = moment().endOf('day');
                                //     return current.isAfter(today);
                                // }}
                                onChange={(value) => {
                                    // eslint-disable-next-line
                                    if (value?.[0]?.startOf('day') && value?.[1]?.endOf('day')) {
                                        setTimeRange([
                                            value?.[0]?.startOf('day'),
                                            value?.[1]?.endOf('day'),
                                        ]);
                                    }
                                    setDateRadio(undefined);

                                    setSplitInfo((splitInfo) => {
                                        splitInfo.pageNum = 1;
                                        return splitInfo;
                                    });
                                }}
                            />
                        </RightWrapper>
                    </FilterSearchWrapper>
                    <Line
                        name="总分"
                        height={330}
                        option={{
                            legend: {
                                orient: 'horizontal',
                                bottom: 0,
                                padding: [0, 0, 10, 0], // 在图例的下方增加 10px 的边距
                            },
                            grid: {
                                bottom: '15%', // 把图表向上移，以留出空间给图例
                            },
                            xAxis: {
                                type: 'time',
                                splitLine: {
                                    show: false,
                                },
                            },
                            yAxis: {
                                type: 'value',
                                // boundaryGap: [0, 100],
                                min: 0,
                                max: 100,
                                splitLine: {
                                    // show: false,
                                },
                            },
                        }}
                        data={lineData}
                    />
                    <Table
                        className="quality-history-list"
                        bordered
                        // scroll={{ x: 1328 } as any}
                        loading={loading}
                        dataSource={data}
                        // @ts-expect-error 第三方UI组件库类型问题
                        columns={columns}
                        // dataSource={[]}
                        style={{ background: 'rgba(255,255,255,0)', marginTop: 24 }}
                        rowKey="outPageIdStr"
                        scroll={{ y: 'calc(100vh - 566px)' }} // 注意这里的scroll属性
                        onChange={(pagination) => {
                            setSplitInfo((splitInfo) => ({
                                ...splitInfo,
                                pageNum: pagination.current || 1,
                            }));
                        }}
                        pagination={false}
                    ></Table>
                </HistoryCreateWrapper>
            </Drawer>
        </>
    );
}
