import styled from 'styled-components';

export const PropsEditorWrapper = styled.div`
    padding: 15px 24px;
`;

export const EditorPanel = styled.div``;

export const EditorTitle = styled.div`
    font-size: 16px;
    color: #1f1f1f;
    line-height: 24px;
`;

export const EditorBody = styled.div``;
export const EditorTitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 15px;
    margin-bottom: 24px;
    border-bottom: 1px solid #f0f0f0;
`;

export const EditorTitleRight = styled.div``;

export const PropsGroupWrapper = styled.div`
    margin-bottom: 24px;
    width: 360px;
`;
export const PropsGroupTitle = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #1f1f1f;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 12px;
`;

export const PropsGroupTips = styled.div`
    font-size: 12px;
    font-weight: 400;
    text-align: right;
    color: #8c8c8c;
    line-height: 22px;
`;
export const PropsGroupBody = styled.div``;
