import styled from 'styled-components';
import { Radio } from 'm-ui';

interface styledProps {
    marginLeft?: string;
}

export const RadioGroup = styled(Radio.Group)`
    margin-bottom: 12px;
    text-align: center;
    width: 100%;
    border-radius: 4px;
    .ant-radio-button-wrapper:first-child {
        border-radius: 4px 0 0 4px;
    }
    .ant-radio-button-wrapper:last-child {
        border-radius: 0 4px 4px 0;
    }
`;

export const ModalSetting = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Setting = styled.div`
    color: #0075ff;

    &:hover {
        cursor: pointer;
    }
`;

export const Error = styled.div`
    color: #f5222d;
    margin-left: ${(props: styledProps) => props?.marginLeft ?? '0'};
    /* margin-left: 24px; */
`;

export const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

export const Hint = styled.span`
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #8c8c8c;
    line-height: 22px;
    margin-left: 8px;
`;

export const RequiredHint = styled.div`
    width: 4px;
    height: 4px;
    background-color: #f93920;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 4px;
`;
