import { useEditorSelector, useSDK } from 'omega-editor';
import * as React from 'react';
import { useDebounce, usePrevious } from 'react-use';
import { verifyPageNameUsingPOST, updatePageTitleUsingPOST } from 'src/services/page-controller';

export const TitleValidate: React.FunctionComponent = () => {
    const { name, title, pageId } = useEditorSelector((state) => {
        const { titleState } = state;
        return {
            name: titleState.name,
            title: titleState.title,
            pageId: state.pageId,
        };
    });
    const { setModelState } = useSDK();
    const preName = usePrevious(name);
    const preTitle = usePrevious(title);
    // const actions = usePlatformActions(pageEditModel.actions);
    useDebounce(
        async () => {
            if (title === preTitle || preTitle === undefined) {
                return;
            }
            const msg = await validateTitle({ pageId, title });
            setModelState((s) => {
                s.titleState.errors.title = msg;
            });
        },
        1000,
        [title],
    );
    useDebounce(
        () => {
            (async () => {
                if (name === preName || preName === undefined) {
                    return;
                }
                const msg = await validateName({ pageId, name });
                setModelState((s) => {
                    s.titleState.errors.name = msg;
                });
            })();
        },
        1000,
        [name],
    );
    return null;
};

export async function validateTitle(req: { pageId?: string; title?: string }) {
    const { title, pageId } = req;
    if (!title) {
        return '落地页标题必填';
    }
    if (title.length > 30) {
        return '落地页标题最多30个字';
    }
    if (pageId) {
        try {
            await updatePageTitleUsingPOST({ req: { title: title || '', id: pageId ?? '' } });
        } catch (e: any) {
            const { result } = e;
            if (result === 1001) {
                const { msg } = e;
                return msg;
            }
        }
    }
    return undefined;
}
export const validateName = async (req: { pageId?: string; name?: string }) => {
    const { name, pageId } = req;
    if (!name) {
        return '请填写落地页名称';
    }
    if (name.length > 40) {
        return '落地页名称最多40个字';
    }
    try {
        await verifyPageNameUsingPOST({ req: { name: name || '', pageId: pageId ?? '' } });
    } catch (e: any) {
        const { result } = e;
        if (result === 1001) {
            const { msg } = e;
            return msg;
        }
    }
    return undefined;
};
