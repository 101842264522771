import { Tooltip } from 'm-ui';
import React from 'react';
import { PropsGroupBody, PropsGroupTitle, PropsGroupWrapper, PropsGroupTips } from './styles';

interface IProps {
    title: string;
    children?: React.ReactNode;
    icon?: React.ReactNode;
    tooltip?: React.ReactNode;
    tips?: string;
    style?: React.CSSProperties;
    wrapStyle?: React.CSSProperties;
}

export const PropsGroup = (props: IProps) => {
    const { title, children, icon, tooltip, tips, style, wrapStyle } = props;
    const iconDisplay = <span style={{ marginLeft: 4 }}>{icon}</span>;
    const titleIcon = icon ? (
        tooltip ? (
            <Tooltip title={tooltip} overlayClassName="wide-tooltip">
                {iconDisplay}
            </Tooltip>
        ) : (
            iconDisplay
        )
    ) : null;
    return (
        <PropsGroupWrapper style={wrapStyle}>
            <PropsGroupTitle style={style}>
                <div>
                    {title}
                    {titleIcon}
                </div>
                {tips && <PropsGroupTips>{tips}</PropsGroupTips>}
            </PropsGroupTitle>
            <PropsGroupBody>{children}</PropsGroupBody>
        </PropsGroupWrapper>
    );
};
