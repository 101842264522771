import { AnyObj, ComponentJson } from 'omega-render';
// import { WechatButtonType, WeiXinProps } from 'src/landingPage/components/WeiXinDrag/WeiXin';
// TODO 补充 Wechat和ShufflingImageProps
// import { ShufflingImageDragConfig } from 'src/pages/editor/componentEditor/s'
// import { WeiXinDragConfig } from '../componentEditor/WeiXinDragEditor';

// TODO 这部分逻辑迁移到formatJSON里面
const restRule: AnyObj = {
    // 微信
    // WEI_XIN_DRAG: (props: WeiXinProps) => {
    //     const { wechatButtonType: propsWechatButtonType, position } = props;
    //     const defaultProps = getDefaultConfig(WeiXinDragConfig);
    //     if (position !== 'rightBottom') {
    //         const {
    //             /** 外显和普通样式共享的 */
    //             text,
    //             index,
    //             opt,
    //             funcId,
    //             position,
    //             /** 外显专用  */
    //             explicitButtonStyles,
    //             explicitDescStyles,
    //             explicitWechatStyles,
    //             wechatButtonType,
    //             ...otherDefaultProps
    //         } = defaultProps;
    //         if (propsWechatButtonType === WechatButtonType.EXPLICIT) {
    //             // 只保留微信外显示相关信息
    //             return {
    //                 ...props,
    //                 ...otherDefaultProps,
    //             };
    //         } else {
    //             // 只恢复微信外显示相关信息
    //             return {
    //                 ...props,
    //                 explicitButtonStyles,
    //                 explicitDescStyles,
    //                 explicitWechatStyles,
    //             };
    //         }
    //     } else {
    //         // 如果全局悬浮，就重置微信外显,提取右侧配置
    //         const {
    //             /** 共享数据 */
    //             text, // 文案
    //             opt, // 微信号相关信息j
    //             funcId, // 微信号相关信息
    //             info, // 弹窗文案
    //             wechatType, // 微信类型
    //             dialogStyleType, // 弹框样式
    //             dialogType, // 弹框类型
    //             applets, // 小程序信息
    //             standbyMode, // 备选方式
    //             position, // 布局
    //             backgroundFillMode, // 图案
    //             index,
    //             /** 右下方专用的 */
    //             rightBottomButtonStyle, // 右下方按钮样式
    //             rightBottomTextStyle, // 右下方文字样式
    //             rightBottomIconColor, // 右下角icon 颜色
    //             iconStyle, // icon样式
    //             ...otherDefaultProps
    //         } = defaultProps;
    //         return {
    //             ...props,
    //             ...otherDefaultProps,
    //         };
    //     }
    // },
    // 轮播图
    // SHUFFLING_IMAGE_DRAG: (props: ShufflingImageProps) => {
    //     const defaultProps = getDefaultConfig(ShufflingImageDragConfig);
    //     const { shufflingType } = props;
    //     if (shufflingType === ShufflingType.GROUP) {
    //         return {
    //             ...props,
    //             imgs: defaultProps.imgs,
    //         } as ShufflingImageProps;
    //     } else {
    //         return {
    //             ...props,
    //             groupImageConfig: defaultProps.groupImageConfig,
    //             groupStyles: defaultProps.groupStyles,
    //             labelStyles: defaultProps.labelStyles,
    //             activeBorderStyles: defaultProps.activeBorderStyles,
    //             activeLabelStyles: defaultProps.activeLabelStyles,
    //         } as ShufflingImageProps;
    //     }
    // },
};

export function resetComponentProps(component: ComponentJson<AnyObj>) {
    const transformRule = restRule[component.type];
    if (transformRule && component?.props) {
        const newComponent = { ...component };
        newComponent.props = transformRule(newComponent.props);
        return newComponent;
    }
    return component;
}
