import React from 'react';
import { Tooltip } from 'm-ui';
import { AuditStatus, HoverTipsMap, ReAuditMap, TextContentMap } from './config';
import {
    Arrow,
    Dot,
    EditBtn,
    Reason,
    ReAuditLabel,
    StatusLabel,
    Wrapper,
    Ellipsis,
    TotalWrap,
} from './style';

export function Status({
    status,
    reason,
    onEdit,
    isWhiteList,
    disableEdit,
}: {
    isWhiteList?: boolean;
    disableEdit?: boolean;
    status: AuditStatus;
    reason?: string;
    onEdit?: any;
}) {
    return (
        <TotalWrap>
            <Wrapper status={status}>
                {status >= AuditStatus.ReAuditing && (
                    <>
                        <ReAuditLabel>{ReAuditMap[status]}</ReAuditLabel>
                        <Arrow />
                    </>
                )}
                <Dot status={status} />
                <Tooltip title={HoverTipsMap[status]}>
                    <StatusLabel status={status}>{TextContentMap(isWhiteList)[status]}</StatusLabel>
                </Tooltip>
                {status === AuditStatus.ReAuditRejected && (
                    <EditBtn disable={disableEdit} onClick={disableEdit ? null : onEdit}>
                        修改
                    </EditBtn>
                )}
            </Wrapper>
            {(status === AuditStatus.AuditRejected || status === AuditStatus.ReAuditRejected) && (
                <Tooltip
                    overlayClassName="wide-tooltip"
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    title={`拒绝原因：${reason}`}
                    mouseEnterDelay={0.8}
                >
                    <Reason>
                        <Ellipsis>
                            <span className="bolder">拒绝原因：</span>
                            {reason}
                        </Ellipsis>
                    </Reason>
                </Tooltip>
            )}
        </TotalWrap>
    );
}
