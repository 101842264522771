/* eslint-disable */
import React, { PureComponent } from 'react';
import { message } from 'm-ui';
import './index.less';

export default class CopyBox extends PureComponent<{
    className: string;
    url: string;
    text: string;
}> {
    public state = {
        countDown: 0,
    };

    ref = React.createRef<HTMLDivElement>();

    render() {
        return (
            <div
                ref={this.ref}
                className={`${this.props.className} copyt-box`}
                onClick={this.copyLink}
            >
                {this.props.children}
            </div>
        );
    }

    copyLink = () => {
        const dom = this.ref.current;
        if (!dom) {
            return;
        }
        const input = document.createElement('input');
        input.type = 'text';
        input.className = 'db__input_hidden';
        // eslint-disable-next-line react/destructuring-assignment
        input.value = this.props.url;
        dom.appendChild(input);
        input.focus();
        input.setSelectionRange(0, input.value.length);
        document.execCommand('copy');
        setTimeout(() => dom.removeChild(input), 0);
        message.success('复制成功');
    };
}
