import {
    // Radio,
    // RadioChangeEvent,
    Select,
    Input,
    DatePicker,
    Table,
    message,
    Popconfirm,
    Spin,
    Tooltip,
    Radio,
    Divider,
    // Modal,
} from 'm-ui';
import { ColumnsType } from 'm-ui/lib/table';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import {
    againQualityCheckUsingPOST,
    deleteQualityCheckUsingPOST,
    pageQualityCheckListUsingPOST,
    pageQualityReadUsingPOST,
    submitPageQualityUsingPOST,
} from 'src/services-new/page-quality-check-controller';
import { history } from 'src/routes/config';
import 'moment/locale/zh-cn'; // without this line it didn't work
import qs from 'qs';

import {
    BadTag,
    FilterSearchWrapper,
    GoodTag,
    ListWrapper,
    NiceTag,
    OperationManual,
    Operator,
    OperatorWrapper,
    PageCover,
    PageCoverWrapper,
    PageDetailRight,
    PageDetailWrapper,
    PageId,
    PageName,
    PassTag,
    RightWrapper,
    StatusFilterWrapper,
    StatusRadius,
    // TwoLineText,
} from './style';
import { debounce, throttle } from 'lodash';
import { Empty } from './List';
import { CreateNewDrawer } from './CreateNewDrawer';
import { IPageQualityCheckListView } from 'src/services-new/models';
import { HistoryDrawer, HistoryState } from './History';
import { SystemQuestionmarkCircleFill } from '@m-ui/icons';
import { webLogger } from 'src/utils/radar';
import { useLpUserInfo } from 'src/utils/useLpUserInfo';
import { getQuery } from 'src/utils/history';
import { useLocation } from 'react-router';

const { Option } = Select;
const { Search } = Input;

export enum SORT_NUM {
    SUBMIT_TIME_DESC = 0,
    SUBMIT_TIME_ASC = 1,
    MARKET_SCORE_RANK_DESC = 2,
    MARKET_SCORE_RANK_ASC = 3,
}

export enum ReadStatus {
    Unread = 1,
    Read = 2,
}

const scoreLevelOptions = ['优秀', '良好', '合格', '不合格'] as const;

// type ScoreLevel = '优秀' | '良好' | '合格' | '较差';
type ScoreLevel = (typeof scoreLevelOptions)[number];

export enum SourceType {
    'ALL' = '',
    'MOLI' = 'magicSite',
    'OTHER' = 'third',
}

export enum STATUS {
    ALL,
    PROCESSING,
    PENDING,
    SUCCESS,
    FAIL,
}

export const platformOptions = {
    ANDROID: 'Android',
    IOS: 'iOS',
};

export enum AuditStatus {
    UNAUDITED = 0,
    AUDITING = 1,
    AUDITED = 2,
    AUDIT_REJECT = 3,
    RE_AUDIT_UNAUDITED = 4,
    RE_AUDIT_AUDITING = 5,
    RE_AUDIT_AUDITED = 6,
    RE_AUDIT_AUDIT_REJECT = 7,
    COMPONENT_AUDIT = 8,
    COMPONENT_AUDIT_REJECT = 9,
    ALL = 100,
}

const cancelSort = '取消排序';
const triggerAsc = '点击升序';
const triggerDesc = '点击降序';
const ASCEND = 'ascend';
const DESCEND = 'descend';

function getSortTip(nextSortOrder) {
    let sortTip: string | undefined = cancelSort;
    if (nextSortOrder === DESCEND) {
        sortTip = triggerDesc;
    } else if (nextSortOrder === ASCEND) {
        sortTip = triggerAsc;
    }
    return sortTip;
}

export const auditStatusOptions = [
    {
        label: '全部',
        value: -1,
    },
    {
        label: '草稿态',
        value: 0,
    },
    {
        label: '审核中',
        value: 1,
    },
    {
        label: '已发布',
        value: 2,
    },
    {
        label: '审核未通过',
        value: 3,
    },
];

// 建站页面审核状态 0 未审核 1 审核中 2 审核通过 3 审核拒绝
// 4 重审-未审核 5  重审-审核中 6  重审-审核通过 7  重审-审核拒绝  8 组件审核 9 组件审核拒绝

const statusOptions = [
    {
        label: '全部',
        value: STATUS.ALL,
    },
    {
        label: '检测成功',
        value: STATUS.SUCCESS,
        icon: <StatusRadius color={'#27AD60'} />,
    },
    {
        label: '检测失败',
        value: STATUS.FAIL,
        icon: <StatusRadius color={'#F93920'} />,
    },
    {
        label: '检测中',
        value: STATUS.PROCESSING,
        icon: <StatusRadius color={'#0075FF'} />,
    },
    {
        label: '检测暂停',
        value: STATUS.PENDING,
        icon: <StatusRadius color={'#BFBFBF'} />,
    },
];

const sourceOptions = [
    {
        label: '全部',
        value: SourceType.ALL,
    },
    { label: '磁力建站', value: SourceType.MOLI },
    { label: '第三方', value: SourceType.OTHER },
];

export const ListTable = () => {
    /** 落地页类型 */
    // const sourceChange = (e: RadioChangeEvent) => {
    //     setSourceType(e.target.value);
    // };

    const [showDrawer, setShowDrawer] = useState(false);
    const [status, setStatus] = useState(+getQuery('status') || STATUS.ALL);
    const [data, setData] = useState([]);
    const [sourceType, setSourceType] = useState(SourceType.ALL);
    const [sortNum, setSortNum] = useState<SORT_NUM | undefined>();
    const [empty, setEmpty] = useState(false);
    const [url, setUrl] = useState('');
    const [urlTemp, setUrlTemp] = useState('');
    const [timeRange, setTimeRange] = useState<[Moment | undefined, Moment | undefined]>([
        // moment().subtract(1, 'year').startOf('day'),
        // moment().endOf('day'),
        getQuery('startTime') ? moment(+getQuery('startTime')) : undefined,
        getQuery('endTime') ? moment(+getQuery('endTime')) : undefined,
    ]);
    const [scoreLevel, setScoreLevel] = useState<ScoreLevel[]>(
        getQuery('scoreLevel') ? [getQuery('scoreLevel')] : [],
    );
    const [splitInfo, setSplitInfo] = useState({
        pageNum: 1,
        pageSize: 10,
        totalNum: 10,
    });
    const location = useLocation();

    useEffect(() => {
        if (location.search) {
            const params = qs.parse(location.search, { ignoreQueryPrefix: true });
            if (params?.scoreLevel) {
                setScoreLevel([params.scoreLevel as unknown as ScoreLevel]);
            }
        }
    }, [location.search]); // location 变化时重新运行
    const [loading, setLoading] = useState(false);
    /** 重新检测落地页 */
    const restart = async (id: string) => {
        try {
            await againQualityCheckUsingPOST({ id });
            message.success('重新检测开始');
            setTimeout(() => {
                getData();
            }, 500);
        } catch (e) {
            console.error(e);
            message.error(e?.msg ?? '重新检测失败');
        }
    };
    const deleteCheck = async (id: string) => {
        try {
            await deleteQualityCheckUsingPOST({ id });
            message.success('删除成功');
            setTimeout(() => {
                getData();
            }, 500);
        } catch (e) {
            console.error(e);
            message.error(e?.msg ?? '删除失败');
        }
    };

    const getData = useCallback(async () => {
        setLoading(true);
        let res;
        try {
            res = await pageQualityCheckListUsingPOST({
                req: {
                    startTime: timeRange[0]?.valueOf(),
                    endTime: timeRange[1]?.valueOf(),
                    splitInfo,
                    pageType: String(sourceType),
                    nameLike: url,
                    status: status === STATUS.ALL ? undefined : status,
                    scoreLevel,
                    readStatus: (+getQuery('readStatus') ?? undefined) as ReadStatus | undefined,
                    sortNum: sortNum as number,
                } as any,
            });
            setSplitInfo((splitInfo) => {
                splitInfo.totalNum = res?.data?.splitInfo?.totalNum;
                return splitInfo;
            });
        } catch (e) {
            console.error(e);
            message.error(e?.msg ?? e?.error_detail ?? '接口请求出错');
        } finally {
            setLoading(false);
            res?.data?.list?.length &&
                pageQualityReadUsingPOST({
                    req: {
                        pageCheckInfoIds:
                            res?.data?.list
                                ?.filter((item) => {
                                    return (
                                        item.readStatus === ReadStatus.Unread &&
                                        item.status === STATUS.SUCCESS
                                    );
                                })
                                ?.map((item) => item.id) || [],
                        readStatus: ReadStatus.Read,
                    },
                });
            // if (getQuery('readStatus')) {
            // 删除query中的readStatus
            const parsedUrl = new URL(window.location.href);
            parsedUrl.searchParams.delete('readStatus');
            parsedUrl.searchParams.delete('scoreLevel');
            parsedUrl.searchParams.delete('startTime');
            parsedUrl.searchParams.delete('endTime');
            parsedUrl.searchParams.delete('status');
            window.history.replaceState({}, '', parsedUrl.toString());
            // }
        }

        setData(res?.data?.list || []);
        if (
            !timeRange[0] &&
            !timeRange[1] &&
            !url &&
            !scoreLevel?.length &&
            status === STATUS.ALL &&
            sourceType === SourceType.ALL &&
            res?.data?.list.length === 0
        ) {
            setEmpty(true);
        }
    }, [
        timeRange,
        splitInfo.pageNum,
        splitInfo.pageSize,
        sourceType,
        status,
        url,
        sortNum,
        scoreLevel,
    ]);
    useEffect(() => {
        debounce(getData, 500)();
    }, [getData]);
    const [showHistory, setShowHistory] = useState(false);
    const [currentHistoryState, setCurrentHistoryState] = useState<HistoryState>();
    const [marketTooltipVisible, setMarketTooltipVisible] = useState(false);
    const handleMouseEnter = (e) => {
        e.stopPropagation();
        setMarketTooltipVisible(true);
    };

    const handleMouseLeave = (e) => {
        e.stopPropagation();
        setMarketTooltipVisible(false);
    };
    const submit = throttle((id) => {
        // setSubmitLoading(true);
        submitPageQualityUsingPOST({
            req: {
                // pageIds: waitToTest,
                pageList: [
                    {
                        outPageId: id,
                    },
                ],
                pageType: 'magicSite',
            },
        })
            .then((res) => {
                if (res.result === 1) {
                    message.success(`落地页提交检测成功`);
                    // setWaitToTest([]);
                } else {
                    message.error(res.msg || '提交失败, 请重试');
                }
            })
            .catch((err) => {
                message.error(err.msg || '提交失败, 请重试');
                console.error(err);
            });
    }, 1000);

    useEffect(() => {
        const parsedUrl = new URL(window.location.href);
        const parsedQuery = qs.parse(parsedUrl.search, { ignoreQueryPrefix: true });

        const parameterValue = parsedQuery['checkPageId']; // 获取参数的值

        if (parameterValue) {
            delete parsedQuery['checkPageId'];
            const newQueryString = qs.stringify(parsedQuery);
            submit(parameterValue);
            const newUrl =
                parsedUrl.origin +
                parsedUrl.pathname +
                (newQueryString ? '?' + newQueryString : '');
            window.history.replaceState({}, '', newUrl);
        }
    }, []);
    // const
    const accountId = useLpUserInfo('accountId');
    const userId = useLpUserInfo('ksUserId');
    const trackClick = (pageId: string, type) => {
        webLogger.sendImmediately('CLICK', {
            action: 'SITE_QUALITY_CHECK_LIST_OPERATE_MENU_BUTTON_CLICK',
            params: {
                account_id: accountId,
                user_id: userId,
                page_id: pageId,
                operate_button_click: type,
            },
            type: 'USER_OPERATION',
        });
    };
    const columns: ColumnsType<IPageQualityCheckListView> = [
        {
            title: '落地页信息',
            dataIndex: 'coverImgUrl',
            key: 'id',
            width: 314,
            render: (text, record) => {
                if (record.pageType === SourceType.MOLI) {
                    return (
                        <PageDetailWrapper>
                            <PageCoverWrapper>
                                <PageCover src={text}></PageCover>
                            </PageCoverWrapper>
                            <PageDetailRight>
                                <PageName>{record.pageName}</PageName>
                                <PageId>ID：{record.outPageIdStr}</PageId>
                            </PageDetailRight>
                        </PageDetailWrapper>
                    );
                } else if (text) {
                    return (
                        <PageDetailWrapper>
                            <PageCoverWrapper>
                                <PageCover src={text}></PageCover>
                            </PageCoverWrapper>
                            <PageDetailRight>
                                {record.pageName ? <PageName>{record.pageName}</PageName> : null}
                                {/* <PageId>ID：{record.url}</PageId> */}
                            </PageDetailRight>
                        </PageDetailWrapper>
                    );
                }
                return <PageDetailWrapper>--</PageDetailWrapper>;
            },
        },
        {
            title: '落地页类型',
            dataIndex: 'pageType',
            key: 'pageType',
            width: 126,
            render: (pageType) => {
                return <span>{sourceOptions.find((item) => item.value === pageType)?.label}</span>;
            },
        },
        {
            title: '审核状态',
            dataIndex: 'auditStatus',
            key: 'auditStatus',
            width: 126,
            render: (text) => {
                return (
                    <span>
                        {auditStatusOptions.find((item) => item.value === text)?.label || '-'}
                    </span>
                );
            },
        },
        {
            title: '检测状态',
            dataIndex: 'status',
            key: 'status',
            width: 126,
            render: (text) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {statusOptions.find((item) => item.value === text)?.icon}
                    {statusOptions.find((item) => item.value === text)?.label}
                </span>
            ),
        },
        {
            title: (
                <span>
                    得分
                    <Tooltip title="根据质检各项检测指标结果拟合总分，并根据检测结果，区分为优秀、良好、合格、较差4类评级。">
                        <SystemQuestionmarkCircleFill className="quality-check-question-mark" />
                    </Tooltip>
                </span>
            ),
            dataIndex: 'reportScore',
            key: 'reportScore',
            width: 133,
            render: (reportScore, record) => {
                const { status } = record;
                return status === STATUS.SUCCESS ? (
                    <div style={{ display: 'flex' }}>
                        {reportScore}
                        {+reportScore >= 80 ? (
                            <GoodTag>优秀</GoodTag>
                        ) : +reportScore >= 70 ? (
                            <NiceTag>良好</NiceTag>
                        ) : +reportScore >= 60 ? (
                            <PassTag>合格</PassTag>
                        ) : (
                            <BadTag>较差</BadTag>
                        )}
                    </div>
                ) : (
                    <span>--</span>
                );
            },
        },
        {
            title: (
                <span>
                    营销吸引力
                    <Tooltip
                        title="系统根据页面质检分数，预估该页面的营销吸引力。在相同广告投放条件下，营销吸引力越高，预估转化效果越好。"
                        visible={marketTooltipVisible}
                    >
                        <SystemQuestionmarkCircleFill
                            className="quality-check-question-mark"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    </Tooltip>
                    {/* <div className="test"></div> */}
                </span>
            ),
            dataIndex: 'marketScoreRank',
            key: 'marketScoreRank',
            width: 150,
            sorter: true,
            showSorterTooltip: {
                overlayClassName: 'aaa',
                trigger: 'hover',
                // visible: true,
                title: getSortTip(
                    sortNum === SORT_NUM.MARKET_SCORE_RANK_DESC
                        ? null
                        : sortNum === SORT_NUM.MARKET_SCORE_RANK_ASC
                          ? DESCEND
                          : ASCEND,
                ),

                overlayStyle: {
                    // display: marketTooltipVisible ? 'none' : 'block',
                    visibility: marketTooltipVisible ? 'hidden' : 'visible',
                },
            },
        },
        {
            title: '最新检测时间',
            dataIndex: 'submitTime',
            key: 'submitTime',
            render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>,
            width: 197,
            sorter: true,
        },

        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            fixed: 'right',
            width: 248,
            render: (id, record) => {
                const { status } = record;
                return (
                    <OperatorWrapper>
                        <Operator
                            disabled={status !== STATUS.SUCCESS}
                            onClick={() => {
                                if (status === STATUS.SUCCESS) {
                                    trackClick(record.outPageIdStr, 0);
                                    history.push({
                                        pathname: `/quality/detail`,
                                        state: { id },
                                        search: `?id=${id}`,
                                        // query: { id },
                                    });
                                }
                            }}
                        >
                            查看
                        </Operator>
                        <Operator
                            disabled={!record.isHasHistoryCheck}
                            onClick={() => {
                                if (!record.isHasHistoryCheck) return;
                                trackClick(record.outPageIdStr, 1);
                                setShowHistory(true);
                                setCurrentHistoryState({
                                    pageType: record.pageType as SourceType,
                                    outPageId: record.outPageIdStr,
                                    pageUrl: record.url,
                                });
                                // setShowHistoryId(id);
                            }}
                        >
                            历史结果
                        </Operator>
                        <Popconfirm
                            title="再次检测后，可在详情页下拉选择检测时间，查看每一次检测的分数和评价。"
                            onConfirm={() => {
                                restart(id);
                                // trackClick(record.outPageId, 2);
                            }}
                            overlayStyle={{
                                width: '236px',
                            }}
                            disabled={![STATUS.FAIL, STATUS.SUCCESS].includes(status)}
                        >
                            <Operator
                                disabled={![STATUS.FAIL, STATUS.SUCCESS].includes(status)}
                                onClick={() => {
                                    trackClick(record.outPageIdStr, 2);
                                }}
                            >
                                再次检测
                            </Operator>
                        </Popconfirm>
                        {/* {status === STATUS.PENDING && (
                            <Operator onClick={() => continueCheck(id)}>继续检测</Operator>
                        )} */}
                        {/* {status === STATUS.PROCESSING && (
                            <Popconfirm
                                title="您确定要暂停此检测内容吗？"
                                onConfirm={() => pause(id)}
                            >
                                <Operator> 暂停检测</Operator>
                            </Popconfirm>
                        )} */}
                        <Popconfirm
                            title="您确定要删除此检测内容吗？"
                            onConfirm={() => deleteCheck(id)}
                        >
                            <Operator
                                onClick={() => {
                                    trackClick(record.outPageIdStr, 3);
                                }}
                            >
                                删除
                            </Operator>
                        </Popconfirm>
                    </OperatorWrapper>
                );
            },
        },
    ];
    const onSortChange = (sorter) => {
        if (sorter.field === 'submitTime') {
            setSplitInfo((splitInfo) => ({
                ...splitInfo,
                pageNum: 1,
            }));
            if (sorter.order === 'ascend') {
                return setSortNum(SORT_NUM.SUBMIT_TIME_ASC);
            } else if (sorter.order === 'descend') {
                return setSortNum(SORT_NUM.SUBMIT_TIME_DESC);
            }
            return setSortNum(undefined);
        }
        if (sorter.field === 'marketScoreRank') {
            setSplitInfo((splitInfo) => ({
                ...splitInfo,
                pageNum: 1,
            }));
            if (sorter.order === 'ascend') {
                return setSortNum(SORT_NUM.MARKET_SCORE_RANK_ASC);
            } else if (sorter.order === 'descend') {
                return setSortNum(SORT_NUM.MARKET_SCORE_RANK_DESC);
            }
            return setSortNum(undefined);
        }
    };
    return (
        <Spin spinning={loading}>
            {empty ? (
                <Empty />
            ) : (
                <ListWrapper>
                    <HistoryDrawer
                        visible={showHistory}
                        historyState={currentHistoryState}
                        onClose={() => {
                            setShowHistory(false);
                        }}
                    />
                    <FilterSearchWrapper>
                        <RightWrapper>
                            <Radio.Group
                                value={sourceType}
                                onChange={(e) => {
                                    setSourceType(e.target.value);
                                }}
                                style={{ marginRight: 16, flex: 'none' }}
                            >
                                <Radio.Button value={SourceType.ALL}>全部</Radio.Button>
                                <Radio.Button value={SourceType.MOLI}>磁力建站</Radio.Button>
                                <Radio.Button value={SourceType.OTHER}>第三方</Radio.Button>
                            </Radio.Group>
                            <StatusFilterWrapper style={{ marginRight: '16px' }}>
                                <div style={{ marginRight: '12px', flex: 'none' }}> 检测状态</div>
                                <Select
                                    value={status}
                                    style={{ width: 120 }}
                                    onChange={(e) => {
                                        setStatus(e);
                                    }}
                                >
                                    {/* <Option value="jack">Jack</Option> */}
                                    {statusOptions.map((item) => {
                                        return (
                                            <Option value={item.value} key={item.value}>
                                                {item.label}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </StatusFilterWrapper>
                            <StatusFilterWrapper style={{ marginRight: '16px' }}>
                                <div style={{ marginRight: '12px', flex: 'none' }}> 得分评级</div>
                                <Select
                                    className="quality-score-level-select"
                                    // style={}
                                    mode="multiple"
                                    value={scoreLevel}
                                    style={{ width: 256 }}
                                    placeholder="请选择"
                                    allowClear
                                    showArrow
                                    maxTagCount={3}
                                    maxTagPlaceholder={(omittedValues) => {
                                        return `共 ${omittedValues.length + 3}`;
                                    }}
                                    onChange={(e) => {
                                        // console.log('select change', e);
                                        setScoreLevel(e);
                                        // setStatus(e);
                                    }}
                                >
                                    {/* <Option value="jack">Jack</Option> */}
                                    {scoreLevelOptions.map((item) => {
                                        return (
                                            <Option value={item} key={item}>
                                                {item === '不合格' ? '较差' : item}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </StatusFilterWrapper>
                            <Search
                                allowClear
                                style={{ marginRight: '16px', width: 256, flex: 'none' }}
                                placeholder="输入页面名称或ID"
                                value={urlTemp}
                                onChange={(e) => {
                                    setUrlTemp(e.target.value);
                                }}
                                onSearch={(value) => {
                                    setSplitInfo((splitInfo) => {
                                        splitInfo.pageNum = 1;
                                        return splitInfo;
                                    });
                                    // TODO 取消sort
                                    // setSortNum(undefined);
                                    setUrl(value);
                                }}
                                // onBlur={(e) => {
                                //     console.log('onBlur', e.target.value);
                                //     setUrl(e.target.value);
                                // }}
                            />
                            <DatePicker.RangePicker
                                allowClear={false}
                                style={{ flex: 'none' }}
                                // @ts-expect-error 历史代码
                                value={timeRange}
                                disabledDate={(current) => {
                                    const today = moment().endOf('day');
                                    return current.isAfter(today);
                                }}
                                onChange={(value) => {
                                    // eslint-disable-next-line
                                    if (value?.[0]?.startOf('day') && value?.[1]?.endOf('day')) {
                                        setTimeRange([
                                            value?.[0]?.startOf('day'),
                                            value?.[1]?.endOf('day'),
                                        ]);
                                    }

                                    setSplitInfo((splitInfo) => {
                                        splitInfo.pageNum = 1;
                                        return splitInfo;
                                    });
                                }}
                            />
                        </RightWrapper>
                        <Divider />
                        <div>
                            <CreateNewDrawer
                                style={{ marginRight: '0px' }}
                                visible={showDrawer}
                                open={() => {
                                    setShowDrawer(true);
                                }}
                                onClose={(refresh) => {
                                    if (refresh) {
                                        getData();
                                    }
                                    setShowDrawer(false);
                                }}
                            />
                            <OperationManual
                                onClick={() => {
                                    window.open(
                                        'https://docs.qingque.cn/d/home/eZQCaB5M_PQ0ux2hW9cFaEpNk?identityId=1oEFPxLx5W7',
                                    );
                                }}
                            >
                                用户操作手册
                            </OperationManual>
                        </div>
                    </FilterSearchWrapper>
                    <Table
                        className="quality-list-table"
                        bordered
                        scroll={{ x: 1328 } as any}
                        // loading={loading}
                        columns={columns}
                        dataSource={data}
                        style={{ background: 'rgba(255,255,255,0)' }}
                        rowKey="id"
                        onChange={(pagination, filter, sorter) => {
                            setSplitInfo((splitInfo) => ({
                                ...splitInfo,
                                pageNum: pagination.current || 1,
                                pageSize: Number(pagination?.pageSize) || 10,
                            }));
                            onSortChange(sorter);
                        }}
                        pagination={{
                            current: splitInfo.pageNum,
                            pageSize: splitInfo.pageSize,
                            total: splitInfo.totalNum,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50'],
                            // showQuickJumper: true,
                        }}
                    ></Table>
                </ListWrapper>
            )}
        </Spin>
    );
};
