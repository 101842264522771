import * as React from 'react';
import { Pagination } from 'm-ui';
import styles from './style.module.less';

interface FooterProps {
    current: number;
    total: number;
    pageSize: number;
    onChange: (page: number, pageSize?: number) => void;
}

const Footer: React.FC<FooterProps> = (props) => {
    const { current, total, pageSize, onChange } = props;

    return (
        <div className={styles.footer}>
            <Pagination
                className={styles.pagination}
                current={current}
                total={total}
                showSizeChanger
                pageSize={pageSize}
                onChange={onChange}
            />
        </div>
    );
};

export default Footer;
