import * as React from 'react';
import cs from 'classnames';
import { useScale } from 'src/hooks/useScale';
import styles from './styles.module.less';
import { Spin } from 'm-ui';
import { pageType } from 'src/pages/pageList/List/PageItem';

interface UrlPreviewProps {
    url: string;
    width: number;
    height: number;
    title?: string;
    className?: string;
}

const UrlPreview: React.FunctionComponent<UrlPreviewProps> = (props) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <AutoSizeIframe {...props} />;
};
export interface AutoSizeIframeProps {
    url?: string;
    width: number;
    height: number;
    title?: string;
    className?: string;
    clickable?: boolean;
    hoverScroll?: boolean;
    borderRadius?: number;
    noBorder?: boolean;
    pageType?: pageType;
}

const useHoverScroll = (
    hoverScroll: boolean,
    divRef: React.RefObject<HTMLDivElement>,
    isIframe: boolean,
) => {
    const getWin = React.useCallback(() => {
        const iframe = divRef.current?.querySelector(`.${styles.root} iframe`) as HTMLIFrameElement;
        const win = iframe?.contentWindow;
        if (!win) {
            throw new Error('can');
        }
        return win;
    }, [divRef]);
    const that = React.useRef({ enter: false, lastY: -1 });
    const scrollDown = React.useCallback(() => {
        if (!that.current.enter) {
            return;
        }
        if (isIframe) {
            const win = getWin();
            // 如果当前和上次的y位置一样，说明到底了
            that.current.lastY = win.scrollY;
            win.scrollTo({
                top: win.scrollY + 2,
            });
        } else {
            const node = divRef.current;
            if (!node) {
                return;
            }
            node.scrollTop += 2;
            that.current.lastY = node?.scrollTop;
        }
        requestAnimationFrame(scrollDown);
    }, [divRef, getWin, isIframe]);
    const onMouseOver = React.useCallback(() => {
        if (that.current.enter) {
            return;
        }
        that.current.enter = true;
        scrollDown();
    }, [scrollDown]);
    const onMouseLeave = React.useCallback(() => {
        if (isIframe) {
            const win = getWin();

            win.scrollTo(0, 0);
        } else if (divRef.current) {
            divRef.current.scrollTop = 0;
        }
        that.current.enter = false;
    }, [divRef, getWin, isIframe]);

    return {
        onMouseOver,
        onMouseLeave,
    };
};

export const AutoSizeIframe = React.forwardRef<HTMLIFrameElement, AutoSizeIframeProps>(
    (props, ref) => {
        const { url, title, className, width, height, clickable, noBorder } = props;
        const divRef = React.useRef<HTMLDivElement>(null);

        // const [hasLoad, setHasLoad] = React.useState(false);
        const [loading, setLoading] = React.useState(true);
        // React.useEffect(() => {
        //     divRef.current?.children[0] &&
        //         divRef.current?.children[0].addEventListener('load', () => {
        //             setLoading(false);
        //         });
        // }, [divRef]);
        const { style } = useScale({ width, height, rawWidth: 375 });
        let newUrl = url;
        // 替换chengzhongkj域名为moli
        if (newUrl?.includes('chenzhongkj')) {
            newUrl = newUrl.replace('chenzhongkj', 'moli.kuaishou');
        }
        return (
            <div
                className={cs(className, styles.root, { [styles.clickable]: clickable })}
                ref={divRef}
                onLoad={() => {
                    if (props.pageType === pageType.一跳咨询页) {
                        // 一跳咨询页，5s后隐藏才渲染，解决scrollIntoView穿透影响父页面的问题
                        setTimeout(() => {
                            setLoading(false);
                        }, 10000);
                    } else {
                        setLoading(false);
                    }
                }}
                style={{
                    width,
                    height,
                    border: noBorder ? '' : '1px solid #f0f0f0',
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <iframe
                    ref={ref}
                    title={title}
                    src={newUrl}
                    style={{
                        ...(style || {}),
                        display: loading ? 'none' : 'block',
                    }}
                    referrerPolicy="origin"
                    sandbox="allow-scripts allow-same-origin"
                />
                {loading && <Spin tip="加载中"></Spin>}
            </div>
        );
    },
);
export const AutoSizeImage: React.FC<AutoSizeIframeProps> = (props) => {
    const divRef = React.useRef<HTMLDivElement>(null);
    const { className, width, height, clickable, children } = props;
    const { onMouseOver, onMouseLeave } = useHoverScroll(true, divRef, false);
    return (
        <div
            ref={divRef}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            className={cs(className, styles.root, { [styles.clickable]: clickable })}
            style={{ width, height }}
        >
            {children}
        </div>
    );
};
export default UrlPreview;
