import { createModel } from 'rt-model';
import { listPopupUsingPOST, getPopupDetailUsingPOST } from 'src/services/popup-controller';
import { IGuideText } from 'src/services/models';
import { RootState } from 'src/store';
import * as model from 'src/services/models';
import { ICommonListResult, IPopupInfo, IPopupQueryParam } from 'landingPage/services/models';
// import { ComponentJson } from 'rolex-render';

export type IPopDetailInfo = IPopupInfo & {
    auditStatus: number;
    createTime?: number;
    effectTime: number;
    id: string;
    rejectReason: string;
    showTime: number;
    updateTime?: number;
    useStatus: number;
    canDelete?: boolean;
    canEdit?: boolean;
    canTerminate?: boolean;
};
export interface IPreview extends IGuideText {
    icon: string;
    animation: boolean;
}
export interface PopupState extends ICommonListResult<IPopDetailInfo> {
    searchValue?: Omit<IPopupQueryParam, 'pageSize' | 'pageNum'>;
    detailInfo?: IPopDetailInfo;
    editInfo?: IPopDetailInfo;
    preview?: IPreview;
}
const defaultState: PopupState = {
    searchValue: {
        keyWord: '',
        startTime: 0,
        endTime: 0,
    },
    list: [],
    splitInfo: {
        pageNum: 1,
        pageSize: 10,
        totalNum: 10,
    },
    preview: {
        guideMsg: '留言预约，立享优惠！',
        buttonText: '点击领取',
        icon: '',
        animation: false,
    },
    editInfo: {
        id: '', // 唯一ID
        name: '', // 弹窗名称
        type: 0, // 弹窗类型
        optimizationSwitch: 0, // 优选开关 0 关闭 1 打开
        conversionComponent: ['XIANSUO_FORM_DRAG'], // 转化组件类型
        showPosition: ['TOP_SUSPENSION'], // 展示位置
        guideTextList: [
            // 引导文案
            {
                guideMsg: '留言预约，立享优惠！', // 引导语
                buttonText: '点击领取', // 按钮文案
            },
        ],
        iconList: [], // icon列表
        appType: 'ALL', // 应用站点类型 ALL 全部 CUSTOM_PAGE 自定义
        siteType: 'ALL', // 站点类型 ALL 全部 COMMON 普通落地页 STORE 门店落地页
        rejectReason: '', // 审核拒绝原因
        auditStatus: 0, // 审核状态 0 待审核，1 审核中，2 审核通过，3，审核拒绝
        useStatus: 0, // 使用状态 0 草稿 1 审核中 2 审核通过 3.审核未通过 4 终止
        effectTime: 0, // 生效时间戳
        showTime: 0, // 展示时间戳  0是永久
    },
    detailInfo: {
        id: '', // 唯一ID
        name: '', // 弹窗名称
        type: 0, // 弹窗类型
        optimizationSwitch: 0, // 优选开关 0 关闭 1 打开
        conversionComponent: [], // 转化组件类型
        showPosition: [], // 展示位置
        guideTextList: [
            // 引导文案
            {
                guideMsg: '', // 引导语
                buttonText: '', // 按钮文案
            },
        ],
        iconList: [], // icon列表
        appType: 'ALL', // 应用站点类型 ALL 全部 CUSTOM_PAGE 自定义
        siteType: 'ALL', // 站点类型 ALL 全部 COMMON 普通落地页 STORE 门店落地页
        rejectReason: '不合规', // 审核拒绝原因
        auditStatus: 0, // 审核状态 0 待审核，1 审核中，2 审核通过，3，审核拒绝
        useStatus: 0, // 使用状态 0 草稿 1 审核中 2 审核通过 3.审核未通过 4 终止
        effectTime: 0, // 生效时间戳
        showTime: 0, // 展示时间戳  0是永久
    },
};
export const popupListModel = createModel({
    namespace: 'popupListModel',
    defaultState,
    reducers: {},
    effects: {
        fetchList() {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                const searchValue = state?.popupList?.searchValue;
                const req = {
                    ...searchValue,
                    ...state.popupList.splitInfo,
                } as IPopupQueryParam;
                const res: any = await listPopupUsingPOST({ req: req as model.IPopupListQueryReq });
                dispatch(
                    popupListModel.actions.setModelState({
                        list: res?.list,
                        splitInfo: res?.splitInfo,
                    }),
                );
            };
        },
        setSplitInfo(payload: { pageNum: number; pageSize: number }) {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                const { pageNum, pageSize } = payload;
                dispatch(
                    popupListModel.actions.setModelState({
                        splitInfo: {
                            ...state.popupList.splitInfo,
                            pageNum,
                            pageSize,
                        },
                    }),
                );

                dispatch(popupListModel.actions.fetchList());
            };
        },
        search(value: Omit<IPopupQueryParam, 'pageSize' | 'pageNum'>) {
            return async (dispatch) => {
                dispatch(
                    popupListModel.actions.setModelState({
                        searchValue: value,
                        splitInfo: defaultState.splitInfo,
                    }),
                );
                dispatch(popupListModel.actions.fetchList());
            };
        },
        fetchDetail(id: string) {
            return async (dispatch) => {
                const res: any = await getPopupDetailUsingPOST({ req: { id } });
                dispatch(
                    popupListModel.actions.setModelState({
                        detailInfo: res,
                    }),
                );
            };
        },
        fetchInfo(id: string) {
            return async (dispatch) => {
                const res: any = await getPopupDetailUsingPOST({ req: { id } });
                dispatch(
                    popupListModel.actions.setModelState({
                        editInfo: res,
                    }),
                );
            };
        },
    },
});
