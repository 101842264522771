/* eslint-disable */
import { upgradeComponents } from 'omega-shared/upgrade';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { ComponentJson } from 'omega-render';
import * as React from 'react';
import { useEffect } from 'react';
import { umdRenderEntry } from 'src/landingPage/common';

import { data } from './t';
import { init } from 'echarts';

export const setPageScale = async (node?: HTMLElement, preview?: boolean) => {
    if (node && node.ownerDocument?.defaultView) {
        const window = node.ownerDocument?.defaultView;
        // 落地页前置，某些机型开始读到的页面宽带是0.
        const width = await getWin(window);
        node.style.position = 'relative';
        node.style.width = '100%';
        node.style.minHeight = '604px';
        // node.style.overflowX = 'hidden';
        const meta = document.createElement('meta');
        meta.name = 'viewport';
        const pageRatio = width / 375;
        meta.content = `width=375, initial-scale=${pageRatio}, minimum-scale=${pageRatio}, maximum-scale=${pageRatio},user-scalable=no, viewport-fit=cover`;
        document.head.appendChild(meta);
        // scale布局方式，当非整数时，block之间会出现缝隙gap，暂时margin:-1px hack一下
        if (pageRatio !== 1) {
            document.body.classList.add('fix-gap-body');
        }
    } else {
        console.warn('这种模式下，渲染图层组件比例会有问题');
    }
};
async function getWin(win: Window) {
    return new Promise<number>((resolve) => {
        function getW(cb: (width: number) => void) {
            const width = win.innerWidth;
            if (width < 10) {
                setTimeout(() => {
                    getW(cb);
                }, 100);
            } else {
                cb(width);
            }
        }
        getW(resolve);
    });
}

interface TestProps {}
export const fonts = [
    { name: '喜鹊乐敦简体-Regular', cdn: '100.ttf' },
    { name: '喜鹊古字典体', cdn: '101.ttf' },
    { name: '喜鹊招牌体-Regular', cdn: '102.ttf' },
    { name: '喜鹊聚珍体', cdn: '103.ttf' },
    { name: '字魂206号-江汉手书', cdn: '104.ttf', expireTime: '2069.12.12' },
    { name: '字魂220号-鸿雁手书', cdn: '105.ttf', expireTime: '2069.12.12' },
    { name: '方正俊黑简体_准', cdn: '106.ttf', expireTime: '2021.6.25' },
    { name: '方正俊黑简体_粗', cdn: '107.ttf', expireTime: '2021.6.25' },
    { name: '方正俊黑简体_细', cdn: '108.ttf', expireTime: '2021.6.25' },
    { name: '方正品尚中黑简体', cdn: '109.ttf', expireTime: '2021.6.25' },
    { name: '方正尚酷简体', cdn: '110.ttf', expireTime: '2021.6.25' },
    { name: '方正悠黑简体_504L', cdn: '111.ttf', expireTime: '2021.6.25' },
    { name: '方正悠黑简体_509R', cdn: '112.ttf', expireTime: '2021.6.25' },
    { name: '方正悠黑简体_513B', cdn: '113.ttf', expireTime: '2021.6.25' },
    { name: '方正正中黑简', cdn: '114.ttf', expireTime: '2021.6.25' },
    { name: '方正正大黑简体', cdn: '115.ttf', expireTime: '2021.6.25' },
    { name: '方正水云简体_粗', cdn: '116.ttf', expireTime: '2021.6.25' },
    { name: '方正清刻本悦宋简体', cdn: '117.ttf', expireTime: '2021.6.25' },
    { name: '方正粗雅宋简体', cdn: '118.ttf', expireTime: '2021.6.25' },
    { name: '方正综艺简体', cdn: '119.ttf', expireTime: '2021.6.25' },
    { name: '方正超粗黑简体', cdn: '120.ttf', expireTime: '2021.6.25' },
    { name: '方正趣宋简体', cdn: '121.ttf', expireTime: '2021.6.25' },
    { name: '方正跃进简体', cdn: '122.ttf', expireTime: '2021.6.25' },
    { name: '方正静蕾简体', cdn: '123.ttf', expireTime: '2021.6.25' },
    { name: '方正颜宋简体_纤', cdn: '124.ttf', expireTime: '2021.6.25' },
    { name: '方正风雅宋简体', cdn: '125.ttf', expireTime: '2021.6.25' },
    { name: '方正黑变简体', cdn: '126.ttf', expireTime: '2021.6.25' },
];

function getLoadFont(components: ComponentJson[]) {
    if (components && components[0] && components[0].magic?.fontList) {
        const now = Date.now();
        const { fontList } = components[0].magic;
        const loadFontList: any[] = [];
        fontList.forEach((fontItem) => {
            const targetFont = fonts.find((font) => font.name === fontItem.name);
            if (targetFont && targetFont.expireTime) {
                // 小于0过期
                if (new Date(targetFont.expireTime).getTime() - now > 0) {
                    loadFontList.push(fontItem);
                }
            } else {
                loadFontList.push(fontItem);
            }
        });
        return loadFontList;
    }
    return [];
}
export const PageNoIFrame: React.FunctionComponent<TestProps> = () => {
    useEffect(() => {
        async function init() {
            window.pageJsonString = JSON.stringify(data);
            const raw = getUrlParam('raw');

            let components: ComponentJson[] = data.components as any;
            if (!raw) {
                const params = {
                    formInfoList: [
                        {
                            formId: '92628300136448000',
                            andromedaFormId: '701647034618',
                            formOpt: {
                                id: '701647034618',
                                name: '新建空白表单',
                                accountId: '92266925154435',
                                bizType: 'AD_LP',
                                userId: '2160212218',
                                publishStatus: 'PUBLISHED',
                                viewPropsDesc:
                                    '{"submitText":"提交","version": "2","submitTurnValue":{"type":"taobao","url":""},"afterSubmitText":"","advanceInfo":{"count":{"switch":false,"itemConfig":{"encryptedId":"","id":"305cf6ea-e3f7-4f56-b6da-6e0d8c0889c6","type":"USER_COUNT","version":"1.2.3","props":{"label":"计数统计","required":false,"placeholder":"","text":["截至目前已有","100","名用户参加"],"orderBy":"asc","iconStyle":1},"dataValidate":{"required":false}}},"apply":{"switch":false,"itemConfig":{"encryptedId":"","id":"035f4365-2256-4acf-84a6-816f340618bc","type":"USER_DISPLAY","version":"1.2.3","props":{"label":"报名展示","required":false,"elements":{"name":"","phone":""},"showType":1,"iconStyle":1},"dataValidate":{"required":false}}},"clueSet":{"switch":false},"submitTurn":{"switch":false,"visible":false},"afterSubmitText":{"visible":false},"shortMessage":{"visible":false,"validateType":0}},"filter":{"phone":{"checked":false,"range":15,"count":1,"title":"手机号去重"},"ip":{"checked":false,"range":15,"count":1,"title":"IP去重"},"deviceId":{"checked":false,"range":15,"count":1,"title":"设备去重"}}}',
                                clueCount: 0,
                                components: [
                                    {
                                        id: '701703272698',
                                        formId: '701647034618',
                                        componentType: 'NAME_TEXT',
                                        order: 0,
                                        label: '姓名',
                                        isRequire: true,
                                        desc: {
                                            label: '姓名',
                                            placeholder: '请输入您的姓名',
                                        },
                                        otherDesc:
                                            '{"label":"姓名","required":true,"placeholder":"请输入您的姓名","viewType":"NAME_TEXT","options":[]}',
                                        isHidden: false,
                                        validateConfs: [
                                            {
                                                selector: '',
                                                validateConfDetails: [
                                                    {
                                                        validatorName: 'format',
                                                        validatorConf: '0',
                                                        dataConverter: '',
                                                        customErrorCode: 0,
                                                        customErrorMessage: '',
                                                    },
                                                    {
                                                        validatorName: 'length',
                                                        validatorConf: '[1:50]',
                                                        dataConverter: '',
                                                        customErrorCode: 0,
                                                        customErrorMessage: '',
                                                    },
                                                ],
                                            },
                                        ],
                                        isDel: false,
                                        createTime: '1617851465033',
                                        modifyTime: '1617851465033',
                                    },
                                    {
                                        id: '701706746106',
                                        formId: '701647034618',
                                        componentType: 'PHONE_TEXT',
                                        order: 1,
                                        label: '电话',
                                        isRequire: true,
                                        desc: {
                                            label: '电话',
                                            placeholder: '请输入您的电话',
                                        },
                                        otherDesc:
                                            '{"label":"电话","required":true,"validatePhone":false,"placeholder":"请输入您的电话","viewType":"PHONE_TEXT","options":[]}',
                                        isHidden: false,
                                        validateConfs: [
                                            {
                                                selector: '',
                                                validateConfDetails: [
                                                    {
                                                        validatorName: 'verify2',
                                                        validatorConf: 'false',
                                                        dataConverter: '',
                                                        customErrorCode: 0,
                                                        customErrorMessage: '',
                                                    },
                                                ],
                                            },
                                        ],
                                        isDel: false,
                                        createTime: '1617851465033',
                                        modifyTime: '1617851465033',
                                    },
                                    {
                                        id: '701708966138',
                                        formId: '701647034618',
                                        componentType: 'GENDER_SINGLE_SELECT',
                                        order: 2,
                                        label: '性别',
                                        isRequire: true,
                                        desc: {
                                            label: '性别',
                                            options: [
                                                {
                                                    id: '1',
                                                    name: '男',
                                                },
                                                {
                                                    id: '2',
                                                    name: '女',
                                                },
                                            ],
                                        },
                                        otherDesc:
                                            '{"label":"性别","required":true,"viewType":"GENDER_RADIO","options":[{"id":"1","name":"男"},{"id":"2","name":"女"}]}',
                                        isHidden: false,
                                        validateConfs: [],
                                        isDel: false,
                                        createTime: '1617851465033',
                                        modifyTime: '1617851465033',
                                    },
                                    {
                                        id: '701712021754',
                                        formId: '701647034618',
                                        componentType: 'NORMAL_TEXT',
                                        order: 3,
                                        label: '单文本',
                                        isRequire: false,
                                        desc: {
                                            label: '单文本',
                                            placeholder: '请输入一行文本',
                                        },
                                        otherDesc:
                                            '{"label":"单文本","required":false,"placeholder":"请输入一行文本","viewType":"INLINE_TEXT","options":[]}',
                                        isHidden: false,
                                        validateConfs: [
                                            {
                                                selector: '',
                                                validateConfDetails: [
                                                    {
                                                        validatorName: 'length',
                                                        validatorConf: '[1:20]',
                                                        dataConverter: '',
                                                        customErrorCode: 0,
                                                        customErrorMessage: '',
                                                    },
                                                ],
                                            },
                                        ],
                                        isDel: false,
                                        createTime: '1617851465033',
                                        modifyTime: '1617851465033',
                                    },
                                ],
                                validateConfs: [
                                    {
                                        selector: '',
                                        validateConfDetails: [
                                            {
                                                validatorName: 'smartCheck',
                                                validatorConf: '{"type":0}',
                                                dataConverter: '',
                                                customErrorCode: 0,
                                                customErrorMessage: '',
                                            },
                                        ],
                                    },
                                ],
                                applyBizList: ['AD_GEMINI', 'AD_LP'],
                                isDel: false,
                                createTime: '1617851465033',
                                modifyTime: '1617851465033',
                                mainTitle: '新建空白表单',
                                tag: 'NONE',
                            },
                        },
                    ],
                };
                components = upgradeComponents(components as any, params);
                console.log('[after]', components);
            }
            const meta = document.createElement('meta');
            meta.name = 'viewport';
            meta.content = 'width=device-width, initial-scale=1, maximum-scale=1';
            document.body.appendChild(meta);
            setPageScale(document.body);
            // 模拟node渲染过程
            const needLoadFont = getLoadFont(components);
            const vconsole = document.createElement('style');
            vconsole.textContent = `
            #__vconsole{
                font-size: 14px !important;
            }`;
            document.head.appendChild(vconsole);
            needLoadFont
                .map((f) => {
                    const style = document.createElement('style');
                    style.textContent = `
                    @font-face{
                        font-family: ${f.name};
                        src: url(${f.cdn});
                        font-display: swap;
                    }`;
                    return style;
                })
                .forEach((fstyle) => document.head.appendChild(fstyle));
            const root = document.getElementById('app')!;

            window.pageJsonString = JSON.stringify(data);

            umdRenderEntry(components, { node: root }, data as any);
        }
        init();

        // (async () => {
        //     const root = document.getElementById('app')!;

        //     umdRenderEntry(components, { node: root }, data as any);
        // })();
    }, []);

    return <></>;
};

const customClass = 'tetCustom';
function setStyle(win: Window) {
    const oldStyles = Array.from(win.document.querySelectorAll(`.${customClass}`));

    const stylesCollection = document.getElementsByTagName('style');
    const styles = Array.from(stylesCollection);
    const fragment = win.document.createDocumentFragment();
    styles.forEach((style) => {
        const iStyle = style.cloneNode(true) as HTMLStyleElement;
        iStyle.classList.add(customClass);
        fragment.append(iStyle);
    });
    win.document.head.append(fragment);
    oldStyles.forEach((el) => {
        el.parentNode?.removeChild(el);
    });
    return () => {
        win.document.head.removeChild(fragment);
    };
}

export const useTest = (win?: any, inData?: any) => {
    // const {win = window} = props;
    useEffect(() => {
        console.log('useTeset', win, inData);
        if (!win || !inData) return;
        const components = inData?.components;
        const { document } = win.contentWindow;
        const observer = new MutationObserver(() => setStyle(win.contentWindow));
        // observer.observe(document.head, {
        //     childList: true,
        //     subtree: true,
        // });
        setStyle(win.contentWindow);

        const meta = document.createElement('meta');
        meta.name = 'viewport';
        meta.content = 'width=device-width, initial-scale=1, maximum-scale=1';
        document.head.appendChild(meta);
        setPageScale(document.body);
        // 模拟node渲染过程
        const needLoadFont = getLoadFont(components);
        const vconsole = document.createElement('style');
        vconsole.textContent = `
        #__vconsole{
            font-size: 14px !important;
        }`;
        document.head.appendChild(vconsole);
        needLoadFont
            .map((f) => {
                const style = document.createElement('style');
                style.textContent = `
                @font-face{
                    font-family: ${f.name};
                    src: url(${f.cdn});
                    font-display: swap;
                }`;
                return style;
            })
            .forEach((fstyle) => document.head.appendChild(fstyle));

        (async () => {
            if (win !== window) {
                const root = document.createElement('div');
                root.id = 'app';
                document.body.appendChild(root);
            }
            const root = document.getElementById('app')!;

            umdRenderEntry(components, { node: root }, inData);
        })();
    }, [win, inData]);

    return <></>;
};
