import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const getQuery = (key?: string) => {
    const query: string = window.location.search.slice(1);
    const result: any = {};
    for (const str of query.split('&')) {
        const [k, v] = str.split('=');
        result[k] = decodeURI(v);
    }
    return key ? result[key] : result;
};
