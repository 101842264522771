import React, { ReactNode, useEffect } from 'react';
import { Table, Modal, Switch, message, Tooltip } from 'm-ui';
import { usePlatformActions, usePlatformState } from 'src/store/hooks';
import { ExclamationCircleFilled } from '@m-ui/icons';
import { history } from 'src/routes/config';
import { RootState } from 'src/store';
import { PageMetaJson } from 'omega-render';
import { multiGroupModel } from '../programmaticMulti/redux/programmaticMultiModel';
import PreviewPage from '../../components/PreviewPage/index';
import { ReactComponent as Empty } from '../programmaticGroup/components/EmptyStatus/empty.svg';
import styles from './style.module.less';
import {
    groupListData,
    groupListModel,
    pageListData,
    ErrorType,
} from './redux/programmaticListModel';

import Header from './Header';
import Footer from './Footer';
import Content, { onTableScroll } from './Content';
import { tableColumns, subTableColumns, pageSource } from './util';

export enum Position {
    right = 'right',
    left = 'left',
}

export enum EnableStatus {
    close = 0,
    open = 1,
}

export enum AuditStatus {
    NotAudited,
    AuditInProcess,
    AuditPassed,
    AuditRejected,
}

export const auditText = {
    [AuditStatus.NotAudited]: {
        text: '草稿',
        color: '#434343',
        bkgColor: '#F5F7F9',
        borderColor: '#F0F0F0',
    },
    [AuditStatus.AuditInProcess]: {
        text: '审核中',
        color: '#0075FF',
        bkgColor: '#E6F6FF',
        borderColor: '#7AC6FF',
    },
    [AuditStatus.AuditPassed]: {
        text: '审核通过',
        color: '#27AD60',
        bkgColor: '#DFEDE3',
        borderColor: '#96D4AB',
    },
    [AuditStatus.AuditRejected]: {
        text: '审核拒绝',
        color: '#F93920',
        bkgColor: '#FFF2ED',
        borderColor: '#FFB19C',
    },
};

export interface DeleteDetail {
    groupId: string;
    groupName: string;
}

export interface columnsProps {
    title: ReactNode;
    width: number;
    dataIndex?: string;
    key: string;
    render?: (value: any, record?: any) => JSX.Element;
    align?: Position;
    fixed?: Position;
    className?: string;
}

export interface ShowPreviewData {
    show: boolean;
    url: string;
    title: string;
    id: string;
    pageMeta?: PageMetaJson;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProgrammaticListProps {}
const ProgrammaticList: React.FC<ProgrammaticListProps> = () => {
    const actions = usePlatformActions(groupListModel.actions);

    const multiActions = usePlatformActions(multiGroupModel.actions);

    const { curGroupList, loading, pageInfo, hasError, groupPageUpdate } = usePlatformState(
        (state: RootState) => {
            return {
                curGroupList: state.groupList.curGroupList,
                groupPageUpdate: state.adUser.lpUser?.groupPageUpdate,
                loading: state.groupList.loading,
                pageInfo: state.groupList.pageInfo,
                hasError: state.groupList.hasError,
            };
        },
    );

    const [showModal, setShowModal] = React.useState(false);
    const [deleteDetail, setDeleteDetail] = React.useState({} as DeleteDetail);
    const [columns, setColumns] = React.useState([] as columnsProps[]);
    const [pageColumns, setPageColumns] = React.useState([] as columnsProps[]);
    const [showPreview, setShowPreview] = React.useState<ShowPreviewData>({
        show: false,
        url: '',
        title: '',
        id: '',
    });
    const [disabledList, setDisabledList] = React.useState({} as { [key: string]: boolean });

    const [searchResult, setSearchResult] = React.useState(false);

    useEffect(() => {
        // 初始页面时获取列表信息，获取列表信息，获取主、子表格column信息
        actions.fetchGroupList();
        const tableColumn = tableColumns(setDeleteDetail, false, setShowModal, groupPageUpdate);
        const pageTableColumn = subTableColumns(setShowPreview, false, actions, groupPageUpdate);

        setColumns(tableColumn);
        setPageColumns(pageTableColumn);
        multiActions.init();
    }, [groupPageUpdate, actions]);

    React.useEffect(() => {
        const disableList = {} as { [key: string]: boolean };
        curGroupList.forEach((item) => {
            disableList[item.groupId] =
                item.pageList.filter((subItem) => subItem.enable === EnableStatus.open)?.length ===
                1;
        });
        setDisabledList(disableList);
    }, [curGroupList]);

    // 删除操作二次确认弹窗
    React.useEffect(() => {
        if (showModal) {
            Modal.confirm({
                title: `确认删除落地页组“${deleteDetail.groupName}”`,
                icon: <ExclamationCircleFilled style={{ color: '#FBBD1B' }} />,
                content: <p>已删除的落地页组，可以从组回收站恢复。</p>,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    actions.deleteGroup({ groupId: deleteDetail.groupId });
                },
                onCancel: () => {
                    setShowModal(false);
                },
            });
        }
    }, [actions, showModal, deleteDetail.groupId, deleteDetail.groupName]);

    React.useEffect(() => {
        if (hasError === ErrorType.deleteGroupErr) {
            message.error('删除失败，请稍后重试');
        }
    }, [hasError]);

    /** 父表格ref */
    const tableRef = React.useRef<HTMLTableElement>(null);

    /** 展开的子表格数据 */
    const expandedRowRender = (groupRecord: groupListData) => {
        // 这个放在这里，未抽出来的原因是因为开关需要实时监听列表中的数据状态
        const statusColumn = {
            title: '状态',
            width: 108,
            dataIndex: 'auditStatus',
            key: 'auditStatus',
            render: (value: AuditStatus, record: pageListData) => {
                let auditTextNode: any = auditText[value].text;
                if (record.pageSource === pageSource.自建站 && value === AuditStatus.NotAudited) {
                    auditTextNode = '- -';
                }
                if (value === AuditStatus.AuditRejected) {
                    auditTextNode = (
                        <Tooltip title={record.rejectReason}>{auditText[value].text}</Tooltip>
                    );
                }
                return (
                    <>
                        <div
                            style={{
                                color: auditText[value].color,
                                background: auditText[value].bkgColor,
                                borderColor: auditText[value].borderColor,
                            }}
                            className={styles.pageStatus}
                        >
                            {auditTextNode}
                        </div>
                        {!(
                            disabledList[groupRecord.groupId] && record.enable === EnableStatus.open
                        ) ? (
                            <Switch
                                disabled={
                                    disabledList[groupRecord.groupId] &&
                                    record.enable === EnableStatus.open
                                }
                                checked={record.enable === EnableStatus.open}
                                onChange={() => {
                                    actions.changeSwitchStatus({
                                        groupId: groupRecord.groupId,
                                        outPageId: record.outPageId,
                                        switchAction:
                                            record.enable === EnableStatus.open
                                                ? EnableStatus.close
                                                : EnableStatus.open,
                                    });
                                }}
                            />
                        ) : (
                            <Tooltip title="组内必须保留一个开启的落地页">
                                <Switch
                                    disabled={
                                        disabledList[groupRecord.groupId] &&
                                        record.enable === EnableStatus.open
                                    }
                                    checked={record.enable === EnableStatus.open}
                                    onChange={() => {
                                        actions.changeSwitchStatus({
                                            groupId: groupRecord.groupId,
                                            outPageId: record.outPageId,
                                            switchAction:
                                                record.enable === EnableStatus.open
                                                    ? EnableStatus.close
                                                    : EnableStatus.open,
                                        });
                                    }}
                                />
                            </Tooltip>
                        )}
                    </>
                );
            },
        };

        const newPageColumns = pageColumns.concat([]);
        newPageColumns.splice(2, 0, statusColumn);
        const disableDel = groupRecord.pageList.length < 3;
        return (
            <div
                onScroll={() => {
                    onTableScroll({ groupId: groupRecord.groupId, tableRef });
                }}
                id={groupRecord.groupId}
                className="subTable"
            >
                <Table
                    showHeader={false}
                    columns={newPageColumns}
                    pagination={false}
                    dataSource={groupRecord.pageList.map((e) => ({ ...e, disableDel }))}
                    scroll={{ x: true }}
                />
            </div>
        );
    };

    return (
        <div className={styles.root}>
            <Header setSearchResult={setSearchResult} />
            {Boolean(curGroupList.length) && (
                <Content
                    columns={columns}
                    loading={loading}
                    dataSource={curGroupList}
                    expandedRowRender={expandedRowRender}
                    parentTabRef={tableRef}
                />
            )}
            {!curGroupList.length && (
                <div className={styles.errorSence}>
                    <Empty className={styles.errorIcon} />
                    <div>
                        {hasError === ErrorType.groupListErr ? (
                            <div className={styles.errorText}>
                                数据加载失败
                                <span
                                    onClick={() => {
                                        actions.fetchGroupList();
                                    }}
                                >
                                    请重试
                                </span>
                            </div>
                        ) : (
                            <div className={styles.errorText}>
                                {searchResult ? (
                                    '暂无数据'
                                ) : (
                                    <div className={styles.errorText}>
                                        暂无落地页组
                                        <span
                                            onClick={() => {
                                                history.push('/programmaticGroup');
                                            }}
                                        >
                                            去新建
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showPreview.show && (
                <PreviewPage
                    title={showPreview.title}
                    url={showPreview.id.length ? '' : showPreview.url}
                    id={showPreview.id}
                    pageMeta={showPreview.pageMeta}
                    onClosePreview={() => setShowPreview({ ...showPreview, show: false })}
                    hideUseBtn={true}
                />
            )}
            <Footer
                current={pageInfo.pageNum}
                total={pageInfo.totalNum}
                pageSize={pageInfo.pageSize}
                onChange={(page, pageSize) => {
                    actions.setPageInfo({ pageNum: page, pageSize });
                }}
            />
        </div>
    );
};

export default ProgrammaticList;
