import { message, Modal } from 'm-ui';
import { createModel } from 'rt-model';
import { disableConfirm } from 'src/hooks/useCloseConfirm';
import { pageType } from 'src/pages/pageList/List/PageItem';
import { AuditStatus } from 'src/pages/pageList/List/SubComponents/config';
import { IPageView } from 'src/services-new/models';
import {
    createAndPublishPageUsingPOST,
    createPageUsingPOST,
    publishPageUsingPOST,
    savePageUsingPOST,
} from 'src/services-new/page-controller';
import { RootState } from 'src/store';
import {
    trackCreatePageNotPublish,
    trackCreatePageWithPublish,
    trackPageSubmitFrontErrorDetail,
    trackPageSubmitRawJson,
    trackPageSubmitServerErrorDetail,
    trackPageSubmitSuccess,
} from 'src/utils/adMonitor';
import { v4 as uuidv4 } from 'uuid';
import { whiteListHistory } from 'src/utils/whiteListHistory';
import { validateName, validateTitle } from 'src/utils/TtitleValidate';
import { AnyObj } from 'omega-render';
import { privateLetterDetailUsingPOST } from 'src/services-new/customer-controller';
import { IM_TYPE } from 'landingPage/components/CustomerServiceDrag/CustomerService';
import { openPrivateLetterUserInfo } from '@/store/pageInfo/model';

// 生成落地页name
export const generateCSPageName = () => {
    // 生成5位UUID
    const fiveDigitUUID = uuidv4().slice(0, 5);
    // 获取当前日期并格式化为MMdd
    const currentDate = new Date();
    const formattedDate =
        ('0' + (currentDate.getMonth() + 1)).slice(-2) + ('0' + currentDate.getDate()).slice(-2);

    // 拼接字符串
    const customTitle = `咨询页-${formattedDate}-${fiveDigitUUID}`;
    return customTitle;
};

export interface IImState {
    customerServiceType: IM_TYPE;
    url?: string;
    funcId?: string;
    uid?: number;
    publishing?: boolean;
    appId?: number;
    pageInfo: {
        canSave?: any;
        name: string;
        type: pageType;
        preVersionPageId?: string;
        preVersion?: string;
        outPageId?: string;
        pageId?: string;
        title?: string;
        copyId?: string;
    };
    editorState?: {
        error: AnyObj;
    };
}
export const getDefaultIMState: () => IImState = () => ({
    customerServiceType: IM_TYPE.PRIVATE_LETTER,
    url: '',
    funcId: '',
    uid: undefined,
    publishing: false,
    deepLink: '',
    pageInfo: {
        // name: '落地页',
        title: '咨询落地页',
        name: generateCSPageName(),
        type: pageType.一跳咨询页,
        auditStatus: AuditStatus.NotAudited,
        canSave: true,
    },
    editorState: {
        error: {},
    },
});
export const imPageModel = createModel({
    namespace: 'imPageModel',
    defaultState: getDefaultIMState(),
    reducers: {
        setEditorState(
            state: IImState,
            payload: (state: IImState['editorState']) => IImState['editorState'],
        ) {
            const res = payload(state.editorState);
            const newState = {
                ...state,
                editorState: res,
            };
            console.log('[setEditorState]', newState, res);
            return newState;
        },
    },
    effects: {},
});
let trackAccountId: number;

let inPublish = false;

export const validateIMConfig = async (
    state: IImState,
    options?: {
        nativePrivateLetterUser?: boolean;
        lpPrivateLetterOpt?: boolean;
    },
) => {
    const nativePrivateLetterUser = options?.nativePrivateLetterUser;
    const lpPrivateLetterOpt = options?.lpPrivateLetterOpt;
    if (nativePrivateLetterUser === false && state.customerServiceType === IM_TYPE.PRIVATE_LETTER) {
        Modal.warning({
            title: '当前账号暂不可使用原生私信进行投放',
            content: '请修改咨询类型或联系您的运营同学处理',
            okText: '去设置',
        });
        return true;
    }
    if (state.customerServiceType === IM_TYPE.KUAI && !state.url) {
        return '请选择客服咨询页';
    }
    if (state.customerServiceType === IM_TYPE.OTHER && !state.funcId) {
        return '请选择咨询页';
    }
    if (state.customerServiceType === IM_TYPE.PRIVATE_LETTER && !state.uid) {
        return '请选择快手号';
    }
    if (state.customerServiceType === IM_TYPE.PRIVATE_LETTER && !lpPrivateLetterOpt) {
        const res = await privateLetterDetailUsingPOST({
            uid: state.uid,
        });
        if (res.data?.authStatus !== 1) {
            Modal.warning({
                title: '当前已选咨询工具无法正常使用，请修改',
                content: `已选快手号${
                    res.data.authStatus === 2
                        ? '未开启「私信工作台」，请设置开启，或'
                        : '授权失效，请'
                }更换其他快手号`,
                okText: '去修改',
            });
            return true;
        }
    }
};

export function createIMPageComponent(props) {
    const { customerServiceType, funcId, url, uid } = props;
    return {
        id: uuidv4(),
        type: 'CUSTOMER_SERVICE_PAGE',
        version: '1.0.0',
        props: {
            // 快聊: 1
            // 第三方2
            // 原生私信:3
            type: customerServiceType,
            //  快聊或者第三方的咨询页id，原生私信这个字段为空
            funcId,
            //  快聊或者第三方的咨询页url，原生私信这个字段为空
            url,
            //  原生私信的uid
            uid,
        },
        components: [],
    };
}

export const saveOrSubmitThunk =
    (
        save: boolean,
        cb: () => void,
        auditDirectPublish?: boolean,
        onSuccess?: (...args: any) => Promise<void>,
    ) =>
    async (dispatch: any, getState: () => RootState) => {
        async function saveOrSubmit() {
            const state = getState();
            const nativePrivateLetterUser = state.adUser.lpUser?.nativePrivateLetterUser;

            const privateLetterUserInfo = state.pageInfo?.privateLetterUserInfo || [];

            const lpPrivateLetterOpt = state.adUser.lpUser?.lpPrivateLetterOpt || false;

            try {
                //先调用快聊账户去开通 用户在建站中配置的私信能力
                await openPrivateLetterUserInfo({ privateLetterUserInfo, lpPrivateLetterOpt });
            } catch (e) {
                const msg = e.msg || '保存失败';
                onSuccess?.(false);

                trackPageSubmitServerErrorDetail(save ? 'save' : 'publish', trackAccountId, msg);
                message.error(msg);
                return;
            }

            const validateRes = await validateIMConfig(state.imPageModel, {
                nativePrivateLetterUser,
                lpPrivateLetterOpt,
            });
            if (validateRes) {
                if (typeof validateRes === 'string') {
                    message.error(validateRes);
                }
                return;
            }
            const pageInfo = state.imPageModel.pageInfo;
            const pageJson = {
                components: [
                    createIMPageComponent({
                        customerServiceType: state.imPageModel.customerServiceType,
                        funcId: state.imPageModel.funcId,
                        url: state.imPageModel.url,
                        uid: state.imPageModel.uid,
                    }),
                ],
                name: pageInfo.name,
                title: pageInfo.title,
                type: pageType.一跳咨询页,
                preVersionPageId: pageInfo.preVersionPageId,
                preVersion: pageInfo.preVersion,
                outPageId: pageInfo.outPageId,
                pageId: pageInfo.pageId,
            };
            (pageJson as any).name = pageInfo.name || generateCSPageName();
            console.log('prePageInfo', pageJson, state.imPageModel);
            const accountId = state.adUser?.lpUser?.accountId;
            trackAccountId = accountId as number;
            trackPageSubmitRawJson({
                source: save ? 'save' : 'publish',
                pageJson: pageJson,
                accountId: trackAccountId,
            });
            const nameErr = await validateName({ pageId: pageInfo.pageId, name: pageJson.name });
            const titleErr = await validateTitle({
                pageId: pageInfo.pageId,
                title: pageJson.title,
            });
            if (nameErr) {
                message.error(nameErr);
                dispatch(
                    imPageModel.actions.setModelState({
                        editorState: {
                            error: {
                                title: titleErr,
                            },
                        },
                    }),
                );
            }
            const hasErr = nameErr;
            if (hasErr) {
                trackPageSubmitFrontErrorDetail(trackAccountId, hasErr);
                return;
            }
            if (auditDirectPublish) {
                delete pageJson.pageId;
            }
            const creatOrSavePage = async (
                p: typeof pageJson,
                id?: string,
                auditDirectPublish?: boolean,
            ) => {
                const req: any = { ...p };
                // 有PageId, 走保存落地页接口
                if (id) {
                    // await uploadBindMaterial(id, pageJson.components);
                    delete req.outPageId;
                    delete req.preVersion;
                    delete req.preVersionPageId;
                    // 保存落地页
                    return (
                        await savePageUsingPOST({
                            req: {
                                ...req,
                                id,
                            },
                        })
                    ).data;
                }
                // const { templateId } = json;
                // if (templateId) {
                //     req.templateId = templateId;
                // }
                if (auditDirectPublish) {
                    // 保存并发布落地页
                    const data = (await createAndPublishPageUsingPOST({ req })).data;
                    onSuccess?.(true, data);

                    return data;
                }
                delete req.outPageId;
                delete req.preVersion;
                delete req.preVersionPageId;

                // 没有PageId,走创建落地页接口
                const data = (await createPageUsingPOST({ req })).data;

                // await uploadBindMaterial(data?.id, pageJson.components);

                return data;
            };

            // 保存落地页
            if (save) {
                try {
                    trackCreatePageNotPublish();
                    // console.log(document.getElementById('preview-app'));
                    // h(document.getElementById('preview-app')!, {
                    //     allowTaint:true,
                    //     useCORS:true,
                    //     onrendered: function(canvas: HTMLCanvasElement) {
                    //         const image = canvas.toDataURL('image/png');
                    //         console.log(image);
                    //     },
                    // } as any);

                    const res = await creatOrSavePage(pageJson, pageJson.pageId);
                    // trackPageCreateWithWeblogger(
                    //     trackAccountId,
                    //     (res as IPageView).id,
                    //     !!state.pageInfo.containPsdModule,
                    //     'save',
                    // );

                    if ((res as IPageView)?.id) {
                        // setModelState({
                        //     pageId: String((res as IPageView).id),
                        //     postedComponents: rawComponents,
                        // });
                        dispatch(
                            imPageModel.actions.setModelState({
                                pageInfo: {
                                    ...pageInfo,
                                    pageId: String((res as IPageView).id),
                                },
                                // postedComponents: rawComponents,
                            }),
                        );
                    }

                    message.success('保存成功');
                    onSuccess?.(true, res);
                    trackPageSubmitSuccess('save');
                } catch (e) {
                    const msg = e.msg || '保存失败';
                    onSuccess?.(false);

                    trackPageSubmitServerErrorDetail(
                        save ? 'save' : 'publish',
                        trackAccountId,
                        msg,
                    );
                    message.error(msg);
                    // e.result === 1001 && msg && setNameErr(msg);
                }
            } else {
                // let publishRes: IPageView;
                if (!inPublish) {
                    inPublish = true;
                    trackCreatePageWithPublish();
                    // const pageId = state.imPageModel.pageInfo.pageId;
                    try {
                        const res = await creatOrSavePage(
                            pageJson,
                            pageJson.pageId,
                            auditDirectPublish,
                        );
                        // publishRes = res;
                        // trackPageCreateWithWeblogger(
                        //     trackAccountId,
                        //     (res as IPageView).id,
                        //     !!state.pageInfo.containPsdModule,
                        //     'publish',
                        // );
                        inPublish = false;
                        if (!auditDirectPublish) {
                            await publishPageUsingPOST({ id: (res as IPageView).id });
                            message.success('发布成功');
                        }
                    } catch (e: any) {
                        inPublish = false;
                        // todo log error
                        const msg = e.msg || '发布失败';
                        trackPageSubmitServerErrorDetail(
                            save ? 'save' : 'publish',
                            trackAccountId,
                            msg,
                        );
                        message.error(msg);
                        // e.result === 1001 && msg && setNameErr(msg);
                        return;
                    }
                }
                // ReactDOM.unmountComponentAtNode(window.previewEditorRect);
                trackPageSubmitSuccess('publish');
                setTimeout(async () => {
                    disableConfirm();
                    // const outPageId =
                    // await onSuccess?.(true, res);
                    whiteListHistory.push(`/page/list`);
                }, 500);
                // history.push('/page/list');
            }
        }
        // dispatch(pageInfoSlice.actions.setPublishStatus(true));
        dispatch(
            imPageModel.actions.setModelState({
                publishing: true,
                // postedComponents: rawComponents,
            }),
        );
        try {
            await saveOrSubmit();
        } catch (e) {
            console.error('[publish error]', e);
        }
        // dispatch(pageInfoSlice.actions.setPublishStatus(false));
        dispatch(
            imPageModel.actions.setModelState({
                publishing: false,
            }),
        );
    };
