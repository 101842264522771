import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select, Tabs } from 'm-ui';
import axios from 'axios';

const Wrapper = styled.section`
    display: flex;
    justify-content: center;
`;

const IframeWrapper = styled.div``;

const FormWrapper = styled.div`
    width: 800px;
    margin: 0 auto;
`;

const CenterH1 = styled.h1`
    text-align: center;
`;

export function UpgradeURL() {
    const [transform, setTransform] = useState('');
    const ref = useRef();
    const ref2 = useRef();
    const [target, setTarget] = useState('');
    const onFinish = (val: any) => {
        console.log('val', val);
        const u = new URL(val.url);
        const params = new URLSearchParams(u.search);
        const [k, v] = val.query.split('=');
        params.delete(k);
        params.append(k, v);
        setTarget(val.url);
        setTransform(`${u.origin}${u.pathname}?${params.toString()}`);
    };
    return (
        <div>
            <CenterH1>升级JSON对比</CenterH1>
            {/* <h1>升级测试123</h1> */}
            <FormWrapper>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ url: 'http://localhost:3000/m', query: 'tmp=2' }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item label="网址" name="url" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="参数" name="query">
                        <Select>
                            <Select.Option value="tmp=2">tmp=2</Select.Option>
                            <Select.Option value="tmp=1">tmp=1</Select.Option>
                            <Select.Option value="raw=1">raw=1</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
            <Wrapper>
                <IframeWrapper>
                    <TestIframe src={target} ref={ref} />
                    <TestIframe ref={ref2} src={transform} />
                </IframeWrapper>
            </Wrapper>
        </div>
    );
}

const StyledIframe = styled.iframe`
    width: 375px;
    height: 667px;
    border: 1px solid gray;
    margin-right: 30px;
    box-sizing: content-box;
`;

export const TestIframe = React.forwardRef((props: { src: string }, ref) => {
    return <StyledIframe ref={ref as any} src={props.src} />;
});

const ComparedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ImageWrapper = styled.div`
    display: flex;
    /* align-items: center; */
`;

const ImageItem = styled.img`
    display: block;
    width: 375px;
    min-height: 500px;
    border: 1px solid gray;
    box-sizing: content-box;
`;

const ImageItemWrapper = styled.div`
    width: 375px;
    margin-right: 50px;
    box-sizing: content-box;
`;

const CenterH2 = styled.h2`
    text-align: center;
`;

export const ImageCompare = () => {
    const [pageId, setPageId] = useState('');
    const [imgs, setImgs] = useState({
        old: '',
        new: '',
    });
    const onFinish = (val: any) => {
        setPageId(val.pageId);
    };
    useEffect(() => {
        async function update() {
            if (!pageId) return;
            const result = await axios.post('/rest/lp/component/upgrade/image', {
                pageId,
            });
            const imgs = result.data.data;
            if (imgs) {
                setImgs({
                    old: imgs.oldCoverImg,
                    new: imgs.newCoverImg,
                });
            }
        }
        update();
    }, [pageId]);
    return (
        <ComparedWrapper>
            <FormWrapper>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ pageId: '', query: 'tmp=2' }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item label="pageId" name="pageId" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
            <ImageWrapper>
                <ImageItemWrapper>
                    <CenterH2>老组件</CenterH2>
                    <ImageItem src={imgs.old} />
                </ImageItemWrapper>
                <ImageItemWrapper>
                    <CenterH2>新组件</CenterH2>

                    <ImageItem src={imgs.new} />
                </ImageItemWrapper>
            </ImageWrapper>
        </ComparedWrapper>
    );
};
const { TabPane } = Tabs;

export function Upgrade() {
    return (
        <Tabs defaultActiveKey="2" centered>
            <TabPane tab="URL渲染" key="1">
                <UpgradeURL />
            </TabPane>
            <TabPane tab="图片渲染" key="2">
                <ImageCompare />
            </TabPane>
        </Tabs>
    );
}
