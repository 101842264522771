import * as model from './models';

import axios from '../request';

/** 私信B端相关接口  私信快手号详情 */
export async function privateLetterDetailUsingPOST(payload: { uid?: number }) {
    const data = {
        uid: payload.uid,
    };

    const result = await axios.request<model.IResponsePrivateLetterUserView>({
        url: `/rest/lp/customer/private/letter/info`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 私信B端相关接口  查询私信列表 */
export async function queryPrivateLetterListUsingPOST(payload: {
    req: model.IPrivateLetterListQueryReq;
}) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPrivateLetterUserView>({
        url: `/rest/lp/customer/private/letter/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 私信B端相关接口  私信预览接口 */
export async function privateLetterPreviewUsingPOST(payload: {
    req: model.IPrivateLetterPreviewReq;
}) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePrivateLetterPreviewView>({
        url: `/rest/lp/customer/private/letter/preview`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 私信B端相关接口  最近使用的私信快手号 */
export async function privateLetterRecommendUsingPOST() {
    const result = await axios.request<model.IResponsePrivateLetterUserView>({
        url: `/rest/lp/customer/private/letter/recommend`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 私信B端相关接口  用户快手号是否有资格去开通私信 */
export async function privateUserAuditUsingPOST(userId: number | undefined) {
    const result = await axios.request<model.IResponsePrivateLetterUserView>({
        url: `/rest/lp/customer/private/im/jump`,
        method: 'post',
        data: { uid: userId },
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 私信B端相关接口  获取该快手号是否支持私信的所有功能 */
export async function privateContentUsingPOST(userId: number | undefined) {
    const result = await axios.request<model.IResponsePrivateContentView>({
        url: `/rest/lp/customer/private/letter/content/get`,
        method: 'post',
        data: { uid: userId },
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 私信B端相关接口  用户快手号去开通私信的相关功能 */
export async function privateUserOpenUsingPOST(param: model.IRequestPrivateUserOpenView[]) {
    const result = await axios.request<model.IResponsePrivateUserOpenView>({
        url: `/rest/lp/customer/private/letter/open`,
        method: 'post',
        data: { params: [...param] },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return result.data;
}
