import { Image, Table, Tooltip } from 'm-ui';
import React, { useEffect, useState } from 'react';
import { pageReportUsingPOST } from 'src/services/report-controller';
import { IDynamicElementReportView } from 'src/services/models';
import styled from 'styled-components';
import styles from './DynamicReport.module.less';
// import { DynamicElementReportEntity, dynamicReport } from '../../../../services/apis';

interface Props {
    outPageId: string;
}

const Wrap = styled.div``;

export function DynamicReport({ outPageId }: Props) {
    const [data, setData] = useState<TableProps[]>([]);
    useEffect(() => {
        pageReportUsingPOST({ pageReportReq: { outPageId } }).then((data) => {
            setData(
                orderDynamicData(data?.data?.dynamicElementReport)?.map(
                    (e: IDynamicElementReportView) => {
                        return {
                            type: e.dynamicElementType,
                            values: e.elementValueMap.map((a) => a.elementValue),
                            perfData: e.elementValueMap.map((b) => b.exposureRate),
                            showTitle: e.dynamicElementType !== 'FORM_BUTTON',
                        } as TableProps;
                    },
                ),
            );
        });
    }, [outPageId]);
    return (
        <Wrap style={{ marginTop: '-24px' }}>
            {data.map((d) => {
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <TableRender {...d} />;
            })}
        </Wrap>
    );
}
const comTypeOrder = {
    DRAG_TEXT: 1,
    DRAG_IMAGE: 2,
    XIANSUO_FORM_DRAG: 3,
};

const orderDynamicData = (data: IDynamicElementReportView[]) => {
    const dataTemp = data?.map((e) => {
        return {
            ...e,
            order: comTypeOrder[e.compType as keyof typeof comTypeOrder],
        };
    });
    return dataTemp.sort((firstEl, secondEl) => firstEl.order - secondEl.order);
};
export interface TableProps {
    type: 'TEXT_CONTENT' | 'IMAGE_CONTENT' | 'FORM_TITLE' | 'FORM_BUTTON';
    values: string[];
    perfData: number[];
    showTitle: boolean;
}

const TitleMap = {
    TEXT_CONTENT: '文本组件',
    IMAGE_CONTENT: '图片组件',
    FORM_TITLE: '表单组件',
    FORM_BUTTON: '表单组件',
};

function TableRender({ type, values, perfData, showTitle }: TableProps) {
    const data = values.map((value, i) => {
        let category = '';
        switch (type) {
            case 'TEXT_CONTENT':
                category = '文本';
                break;
            case 'IMAGE_CONTENT':
                category = '图片';
                break;
            case 'FORM_TITLE':
                category = '标题';
                break;
            case 'FORM_BUTTON':
                category = '按钮文案';
                break;
            default:
                return undefined;
        }
        return { category: `${category}${toChinese(i)}`, value, perfData: perfData[i] };
    });
    return (
        <>
            {showTitle && <div className={styles.title}>{TitleMap[type]}</div>}
            <Table
                className={styles.tableWrapper}
                columns={columns(type)}
                dataSource={data as any}
                pagination={false}
            />
        </>
    );
}

const columns = (type: string) => {
    return [
        {
            title: '类型',
            dataIndex: 'category',
            key: 'category',
            width: 104,
        },
        type === 'IMAGE_CONTENT'
            ? {
                  title: '内容',
                  dataIndex: 'value',
                  key: 'value',
                  width: 304,
                  render: (src: string) => {
                      return <Image className={styles.imgWrapper} src={src} width={48} />;
                  },
              }
            : {
                  title: '内容',
                  dataIndex: 'value',
                  key: 'value',
                  width: 304,
                  ellipsis: {
                      showTitle: false,
                  },
                  render: (value: string) => (
                      <Tooltip placement="topLeft" title={value}>
                          {value}
                      </Tooltip>
                  ),
              },
        {
            title: '曝光率',
            dataIndex: 'perfData',
            key: 'perfData',
            render: (perfData: number) => {
                return `${perfData}%`;
            },
        },
    ];
};

function toChinese(i: number) {
    switch (i) {
        case 0:
            return '一';
        case 1:
            return '二';
        case 2:
            return '三';
        default:
    }
}
