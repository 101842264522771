import styled, { createGlobalStyle } from 'styled-components';
import { Modal } from 'm-ui';

export const GlobalStyle = createGlobalStyle`
    > .ant-modal-header {
        /* padding: 17px !important; */
        padding-top: 17px !important;
        padding-bottom: 17px !important;
        border-bottom: none !important;
    }
`;

export const ModalWrapper = styled(Modal)`
    > .ant-modal-header {
        /* padding: 17px !important; */
        padding-top: 17px !important;
        padding-bottom: 17px !important;
        border-bottom: none !important;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    padding: 8px 0 24px;
    width: 100%;
    /* height: 698px; */
`;

export const PageContainer = styled.div`
    /* width: 375px;
    height: 666px; */
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-right: 20px;
    overflow: hidden;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const QrCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
`;

export const QrCodeWrapper = styled.div`
    width: 180px;
    height: 180px;
    /* background: #f5f7f9; */
    border-radius: 4px;
    /* padding: 8px; */
    border: 8px #f5f7f9 solid;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;
export const QrCodeContent = styled.div`
    background: #fff;
    padding: 12px;
`;

export const QrTitle = styled.div`
    /* text-align: center; */
    color: #1f1f1f;
    line-height: 22px;
    margin-bottom: 4px;
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`;
export const QrTips = styled.div`
    width: 204px;
    height: 22px;
    font-size: 12px;
    font-weight: 400;
    /* text-align: center; */
    color: #8c8c8c;
    line-height: 22px;
    margin-bottom: 12px;
`;
export const QrTipsWarning = styled.div`
    /* width: 277px; */
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #f93920;
    line-height: 17px;
    margin-top: 8px;
`;

export const HalfScreenPreview = styled.div`
    width: 375px;
    height: 667px;
    background: url('https://h2.static.yximgs.com/kos/nlav10749/lp-manual/half-screen-bg-com.png')
        no-repeat 0 0 / cover;
    overflow: hidden;
`;

export const HalfScreenWrapper = styled.div`
    margin-top: 133px;
    overflow: scroll;
    border-radius: 16px 16px 0px 0px;
    &::-webkit-scrollbar {
        width: 0px;
    }
`;

export const PageTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #1f1f1f;
    margin-bottom: 12px;
`;

export const ReloadQrCode = styled.div`
    position: absolute;
    width: 164px;
    height: 164px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.85);
`;

export const ReloadIcon = styled.img`
    width: 24px;
    height: 24px;
    &:hover {
        cursor: pointer;
    }
`;
