import { getUrlParamsWithoutReferer } from 'landingPage/common/adTrack/util/env';
import { getSDK } from 'omega-editor';
import { isDevelopment } from 'omega-shared/utils';
import * as React from 'react';
import { getUserId } from './useUserId';

let disable = false;
export function disableConfirm() {
    disable = true;
}

export const useCloseConfirm = () => {
    const info = React.useCallback(() => {
        // 获取userId、pageId
        let pageId = getUrlParamsWithoutReferer('pageId');
        let userId = getUserId();
        const { getJSON } = getSDK();
        window.localStorage.setItem((pageId || userId) + '_save_data', JSON.stringify(getJSON()));
        return disable ? null : '确定要离开吗？';
    }, []);

    React.useEffect(() => {
        if (isDevelopment) {
            return;
        }
        window.onbeforeunload = disable ? null : info;
        return () => {
            window.onbeforeunload = null;
        };
    }, [info]);
};
