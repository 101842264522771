import { listPageUsingPOST } from 'src/services-new/page-controller';
import { history } from 'src/routes/config';
import { AuditStatus } from 'src/pages/pageList/List/SubComponents/config';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IPageQualityCheckListResult, IPageView } from 'src/services-new/models';

export async function goToEditPage(outPageId: string) {
    const data = await listPageUsingPOST({
        req: {
            select: outPageId,
            pageNum: 1,
            pageSize: 1,
        },
    });
    const pageJson = data.data.list?.[0];
    if (!data.data.list?.[0]) {
        return;
    }
    // 代码来自 => packages/site-editor/src/pages/pageList/List/PageItem.tsx

    const ReAuditRejected = pageJson.auditStatus === AuditStatus.ReAuditRejected;

    history.push({
        pathname: '/page/edit',
        search: `pageId=${pageJson.id}`,
        state: {
            // 除去草稿状态之外，都不可保存，只能直接编辑发布 https://wiki.corp.kuaishou.com/pages/viewpage.action?pageId=714366149
            canSave: pageJson.auditStatus === AuditStatus.NotAudited,
            auditPassed:
                pageJson.auditStatus === AuditStatus.AuditPassed ||
                pageJson.auditStatus === AuditStatus.ReAuditPassed,
            pageId:
                ReAuditRejected && pageJson.latestAuditPassedPage
                    ? pageJson.latestAuditPassedPage.id
                    : pageJson.id,
            ...(ReAuditRejected && pageJson.latestAuditPassedPage
                ? pageJson.latestAuditPassedPage
                : pageJson),
        },
    });
    // if (list.data.list)
}

export function usePollingQualityDetail(
    outPageID?: string,
    skipFirst?: boolean,
    onSuccess?: () => void,
) {
    const [currentTaskId, setCurrentTaskId] = useState<string | undefined>(outPageID);
    const currentTaskIdRef = useRef<string | undefined>(currentTaskId);
    const preStatusRef = useRef<IPageQualityCheckListResult['status'] | undefined>();
    const [qualityDetail, setQualityDetail] = useState<IPageQualityCheckListResult | null>(null);

    useEffect(() => {
        currentTaskIdRef.current = currentTaskId;
    }, [currentTaskId]);
    useEffect(() => {
        preStatusRef.current = qualityDetail?.status;
    }, [qualityDetail?.status]);
    const [pageDetail, setPageDetail] = useState<IPageView>();
    useEffect(() => {
        setCurrentTaskId(outPageID);
        if (!outPageID) {
            setQualityDetail(null);
            clearPolling();
        }
    }, [outPageID]);

    const intervalId = useRef<number | null>(null);
    const getQualityDetail = useCallback(async (id?: string) => {
        if (!id) return;
        const res = await listPageUsingPOST({
            req: {
                pageNum: 1,
                pageSize: 1,
                select: id,
                // @ts-expect-error 历史代码，涉及swagger的改动，需推进后端改动
                auditStatus: -1,
                componentIdQuery: {
                    funcId: '',
                    whetherPublish: false,
                },
            },
        });
        // if (res.data?.list[0]?.auditStatus !== undefined) {
        //     setAuditStatus(res.data.list[0].auditStatus);
        // }
        setPageDetail(res.data?.list[0]);
        if (res.data?.list[0]?.pageQualityCheckResult) {
            return res.data?.list[0].pageQualityCheckResult;
        }
    }, []);

    // 清除轮询
    const clearPolling = () => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
            // setIntervalId(null);
            intervalId.current = null;
            // setCurrentTaskId('');
            // setCurrentTask()
        }
    };
    // useEffect

    // 开始轮询
    const startPolling = (outPageID?: string) => {
        if (outPageID) {
            setCurrentTaskId(outPageID);
        }
        // setGenerating(true);
        const task = async () => {
            try {
                // setGenerating(true);
                // const preId = qualityDetail?.id;

                const res = await getQualityDetail(outPageID || currentTaskId);
                if (preStatusRef.current === 'CHECKING' && res?.status === 'SUCCESS') {
                    onSuccess?.();
                }
                if (currentTaskIdRef.current && res) {
                    setQualityDetail(res);
                }

                // setQualityDetail(res);
                // if (res?.status === 'SUCCESS') {
                //     // setGenerating(false);
                //     clearPolling();
                //     if (res.id !== preId) {
                //         setShowSuccessTip(true);
                //         setTimeout(() => {
                //             setShowSuccessTip(false);
                //         }, 3000);
                //     }
                // } else
                if (!isProcessingQuality(res?.status)) {
                    clearPolling();
                }
            } catch (err) {
                console.error(err);
                clearPolling();
                // setGenerating(false);
            }
        };
        if (intervalId.current) {
            clearPolling();
        }
        task();
        const id = setInterval(async () => {
            task();
            //     });
        }, 10000);
        // setIntervalId(id);
        intervalId.current = id as unknown as number;
    };
    const [first, setFirst] = useState(true);
    useEffect(() => {
        if (currentTaskId && !(first && skipFirst)) {
            startPolling();

            setFirst(false);
        }
        return () => {
            clearPolling();
        };
    }, [currentTaskId]);
    return { qualityDetail, startPolling, pageDetail };
}

export const isProcessingQuality = (status?: IPageQualityCheckListResult['status']) => {
    if (!status) return false;
    return ['CHECKING', 'CREAT'].includes(status);
};
