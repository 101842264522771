import dayjs from 'dayjs';
import { Upload, message } from 'm-ui';
import { RcFile } from 'm-ui/es/upload/interface';
import { ComponentJson, componentsForEach } from 'omega-render';
import { MaterialItemView } from 'src/pages/editor/setting/components/MaterialDrawer';
import { imageURLUploadUsingPOST } from 'src/services-new/material-controller';
import { IPageMaterialDetailDto } from 'src/services-new/models';
import { pageBindMaterialUsingPOST } from 'src/services-new/page-controller';
import { Limit, ImgFile, ImgUrl, LimitType } from './index';

export const getImgDetail: (
    param: ImgFile | ImgUrl,
) => Promise<{ width: number; height: number }> = async (param: ImgFile | ImgUrl) => {
    return new Promise((resolve) => {
        const img = new Image();

        img.onload = () => {
            const { width, height } = img;
            resolve({ width, height });
        };
        if ((param as ImgFile).file) {
            img.src = URL.createObjectURL((param as ImgFile).file);
        }
        if ((param as ImgUrl).imgUrl) {
            img.src = (param as ImgUrl).imgUrl;
        }
    });
};

export const beforeUpload: (param: {
    file: RcFile;
    limit: Limit;
    limitType: LimitType[];
    imgDetail: any;
    setImgDetail: (param: any) => void;
}) => string | Promise<undefined | boolean | string | Blob | File> = async (param: {
    file: RcFile;
    limit: Limit;
    limitType: LimitType[];
    imgDetail: any;
    setImgDetail: (param: any) => void;
}) => {
    const { file, limit, limitType, imgDetail, setImgDetail } = param;
    const { minSize, maxSize, fixedRatio, fixedwh, maxWh } = limit;

    console.log('limit', limit);

    console.log('file', file);

    const typeAllowed = limitType?.some((item) => {
        return file.type.includes(item);
    });

    if (!typeAllowed) {
        message.error('您上传的文件类型有误，请重新上传');
        return Upload.LIST_IGNORE;
    }

    let ratioAllowed = true;

    const res = await getImgDetail({ file });

    if (fixedRatio) {
        const { width, height } = res;
        const ratioList = fixedRatio.split(':');
        const ratio = (Number(ratioList[0]) / Number(ratioList[1])).toFixed(3);

        ratioAllowed = (width / height).toFixed(3) === ratio;

        if (!ratioAllowed) {
            message.error(`图片上传比例不符合${fixedRatio},请重新上传`);
            return Upload.LIST_IGNORE;
        }
    }

    let maxSizeAllowed = true;

    if (maxSize) {
        if (maxSize.match(/[k,K]/g)?.length) {
            maxSizeAllowed = file.size / 1024 < Number(maxSize?.toUpperCase().split('K')?.[0]);
        } else {
            maxSizeAllowed =
                file.size / 1024 / 1024 < Number(maxSize?.toUpperCase().split('M')?.[0]);
        }
    }

    let minSizeAllowed = true;

    if (minSize) {
        if (minSize.match(/[k,K]/g)?.length) {
            minSizeAllowed = file.size / 1024 > Number(minSize?.toUpperCase().split('K')?.[0]);
        } else {
            minSizeAllowed =
                file.size / 1024 / 1024 > Number(minSize?.toUpperCase().split('M')?.[0]);
        }
    }
    if (!minSizeAllowed || !maxSizeAllowed) {
        message.error('图片大小不符合限制，请重新上传');
        return Upload.LIST_IGNORE;
    }

    let whAllowed = true;

    if (fixedwh) {
        const { width, height } = res;

        if (width !== fixedwh.width || height !== fixedwh.height) {
            whAllowed = false;
            message.error(`图片上传比例不符合${fixedwh.width}*${fixedwh.height},请重新上传`);
            return Upload.LIST_IGNORE;
        }
    }
    if (maxWh) {
        const { width, height } = res;
        if (width > maxWh.maxWidth || height > maxWh.maxHeight) {
            whAllowed = false;
            message.error(
                `图片上传比例不符合宽高最大限制${maxWh.maxWidth}*${maxWh.maxHeight},请重新上传`,
            );
            return Upload.LIST_IGNORE;
        }
    }
    return new Promise((resolve, reject) => {
        if (minSizeAllowed && maxSizeAllowed && typeAllowed && ratioAllowed && whAllowed) {
            console.log('resolve');
            const { width, height } = res;
            const newImgDetail = { ...imgDetail };
            newImgDetail[file.uid] = {
                width,
                height,
            };
            setImgDetail({
                ...newImgDetail,
            });
            return resolve(file);
        }
        console.log('reject');
        return reject();
    });
};

export const materialSource = [] as Pick<MaterialItemView, 'url' | 'name' | 'width' | 'height'>[];
/** 收集裁剪后的图片信息，保存发布落地页的时候上传 */
export const collectMaterial = (
    source: Pick<MaterialItemView, 'url' | 'name' | 'width' | 'height'>[],
) => {
    materialSource.push(...source);
    console.log('[collectMaterial]', materialSource);
};

export const nameRecords = {} as Record<string, string>;
/** 收集名称 */
export const recordName = (url: string, name: string) => {
    if (!nameRecords[url]) {
        nameRecords[url] = name;
    }
};

export const uploadCutMaterialToLibrary = async (json: ComponentJson[]) => {
    const jsonString = JSON.stringify(json);
    const res = materialSource
        .filter((item) => {
            return jsonString.includes(item.url);
        })
        .map((item) => ({
            ...item,
            name: item.name.includes('_jianqie_')
                ? item.name.replace(/_jianqie_.+$/, `_jianqie_${dayjs().format('YYYYMMDDHHmmss')}`)
                : `${item.name}_jianqie_${dayjs().format('YYYYMMDDHHmmss')}`,
        }));
    try {
        if (!res.length) return;
        await imageURLUploadUsingPOST({
            req: {
                imageInfoList: res,
            },
        });
    } catch (e) {
        console.error(e);
    }
};

/** 收集素材使用情况，仅非编辑器状态使用 */
export const materialBind: { [key: string]: IPageMaterialDetailDto[] } = {
    default: [],
    weChatHead: [],
    appIcon: [],
    appCover: [],
};

export const clearMaterialBind = () => {
    Object.keys(materialBind).forEach((key) => {
        materialBind[key] = [];
    });
};

export const uploadBindMaterial = async (pageId: string, json: ComponentJson[]) => {
    const materialDetailList: IPageMaterialDetailDto[] = [];
    if (json.length > 0) {
        componentsForEach(json, (comp) => {
            if (comp.props?.materialInfo) {
                Object.values(comp.props.materialInfo).forEach((item) => {
                    materialDetailList.push(...(item as IPageMaterialDetailDto[]));
                });
            }
        });
    } else {
        Object.values(materialBind).forEach((item) => {
            materialDetailList.push(...(item as IPageMaterialDetailDto[]));
        });
    }
    try {
        await pageBindMaterialUsingPOST({
            req: {
                pageId,
                materialDetailList: materialDetailList.filter((v) => v),
            },
        });
    } catch (e) {
        console.error(e);
    }
};

function formatSize(size: string | number) {
    if (typeof size === 'number') {
        return size;
    }
    if (size.match(/[k,K]/g)?.length) {
        return Number(size?.toUpperCase().split('K')?.[0]) * 1024;
    } else {
        return Number(size?.toUpperCase().split('M')?.[0]) * 1024 * 1024;
    }
}

export const compareSize = (first: string | number, second: string | number) => {
    const firstSize = formatSize(first);
    const secondSize = formatSize(second);
    return firstSize - secondSize;
};

export const getImgNameFromUrl = (url: string) => {
    return /.+\/(.+)\.(jpg|gif|png)/.exec(url)?.[1];
};
