import * as React from 'react';
import { Modal, Button } from 'm-ui';

import { usePlatformState, usePlatformActions } from 'src/store/hooks';
import { userModel } from 'src/store/userModel';
import { enableUserProtoUsingPOST } from 'src/services/user-controller';
import file from './file.pdf';

export const Agreement: React.FunctionComponent = () => {
    const { show, agreed } = usePlatformState((state) => {
        return {
            show: state.adUser.agreement?.show,
            agreed: state.adUser.lpUser?.enableUserProto,
        };
    });
    const actions = usePlatformActions(userModel.actions);
    const hide = React.useCallback(() => {
        actions.setModelState({
            agreement: {
                show: false,
            },
        });
    }, [actions]);
    const onOk = React.useCallback(() => {
        (async () => {
            await enableUserProtoUsingPOST();
            actions.agreed();
            hide();
        })();
    }, [actions, hide]);

    return (
        <Modal
            visible={show}
            title="磁力建站使用协议"
            okText="开始使用"
            width={900}
            onOk={onOk}
            onCancel={hide}
            footer={agreed ? <Button onClick={hide}>关闭</Button> : undefined}
        >
            {/* <Content /> */}
            <embed src={file} width="100%" height={window.innerHeight - 300} />
        </Modal>
    );
};
