const key = 'MOLI_ROUTER_LP_STATE';

export function setData(data: any) {
    window.localStorage.setItem(
        key,
        JSON.stringify({
            data,
            timeStamp: Date.now(),
        }),
    );
}

export function getData() {
    const stringData = window.localStorage.getItem(key);
    if (stringData) {
        try {
            const res = JSON.parse(stringData);
            if (Object.keys(res?.data ?? {})?.length === 0) return null;
            const { data, timeStamp } = res;
            if (Date.now() - timeStamp > 30 * 1000) {
                console.log('[MOLI_ROUTER_LP_STATE]: out of Date');
                return null;
            }
            return data;
        } catch (e) {
            console.error(e);
        }
    }
    return null;
}
