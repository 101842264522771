import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useTitle } from 'react-use';
import { recordFmp } from 'src/utils/radar';
import { trackPageImpression } from 'src/utils/adMonitor';
import styled from 'styled-components';
import { Spin } from 'm-ui';

export interface PageLoaderChildrenProps {
    pageId: number;
    pageLoaded: () => void;
}

const Modal = styled.div`
    height: 100vh;
    width: 100vw;
`;
const Loading = () => {
    return (
        <Spin tip="页面加载中" size="large" delay={300}>
            <Modal />
        </Spin>
    );
};

const pageLoader = (loadFun: () => any, opt?: { key?: string; title?: string }) => {
    opt = {
        key: 'default',
        ...opt,
    };
    const key = opt.key as string;
    return (props: RouteComponentProps) => {
        const [C, setC] = React.useState<React.ComponentType<any> | null>(null);
        const that = React.useRef<null | typeof loadFun>(null);
        const { location } = props;
        React.useEffect(() => {
            // tslint:disable-next-line:no-console
            if (that.current !== loadFun) {
                if (process.env.NODE_ENV !== 'development') {
                    if (that.current) {
                        return;
                    }
                }
                setTimeout(() => {
                    loadFun().then((res: any) => {
                        const Com = res[key];
                        setC(() => Com);
                        recordFmp();
                    });
                }, 50);
            }

            that.current = loadFun;

            trackPageImpression(location.pathname);
        });
        React.useEffect(() => {
            return () => {
                // tslint:disable-next-line:no-console
            };
        }, [location.pathname]);

        useTitle(opt?.title ?? '磁力建站');

        const pageLoaded = React.useCallback(() => {}, []);
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <>{C != null ? <C {...props} pageLoaded={pageLoaded} /> : <Loading />}</>;
    };
};
export default pageLoader;
