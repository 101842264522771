import styled from 'styled-components';

export const Alert = styled.div`
    opacity: 1;
    padding: 0 8px;
    margin-right: 8px;
    display: inline-flex;
    opacity: 1;
    background: #f5f7f9;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 22px;
    background: #f5f7f9;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    color: #434343;
`;
