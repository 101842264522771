export const whiteListHistory = {
    replace(path: string) {
        const url = new URL(window.location.href);
        url.pathname = path;
        window.location.replace(url.toString());
    },
    push(path: string, query?: Record<string, string>) {
        const url = new URL(window.location.href);
        url.pathname = path;
        if (query) {
            url.search = new URLSearchParams(query).toString();
        }
        window.location.href = url.toString();
    },
};
