/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Dropdown, Layout } from 'm-ui';
import * as React from 'react';
import { usePlatformState } from 'src/store/hooks';
import { history } from 'src/routes/config';

import { RootState } from 'src/store';
import { getQuery } from 'src/utils/history';
import { breadcrumbConfig, IBreadcrumbItem } from './breadcrumbConfig';
import styles from './styles.module.less';
import UserInfoMenu from './userInfoMenu';

const Header: React.FunctionComponent = () => {
    const adUser = usePlatformState((state: RootState) => {
        return state.adUser;
    });
    const { user } = adUser;

    // 面包屑包含多级标题，title表示标题组list，每一级均可根据对应路由进行跳转，text表示当前页面
    const [breadcrumb, setBreadcrumb] = React.useState<IBreadcrumbItem[]>([]);

    // 面包屑主、子标题的设定
    React.useEffect(() => {
        setBreadcrumb([]);
        const { pathname } = history.location;
        if (breadcrumbConfig[pathname]) {
            return setBreadcrumb(breadcrumbConfig[pathname]);
        }
        Object.keys(breadcrumbConfig).forEach((key) => {
            if (new RegExp(key).test(pathname)) {
                setBreadcrumb(breadcrumbConfig[key]);
            }
        });
    }, [history.location.pathname]);

    return (
        <section className={styles.wrapper}>
            <Layout.Header className={styles.header}>
                <div className={styles.breadcrumb}>
                    {breadcrumb.map((bc, index) => {
                        return (
                            <>
                                <p
                                    className={bc.link ? styles.guideToMain : ''}
                                    onClick={() => {
                                        if (bc.link) {
                                            history.push({ pathname: bc.link });
                                        }
                                    }}
                                >
                                    {bc.textOpt?.[getQuery('mode')] ?? bc.text}
                                </p>
                                {index !== breadcrumb.length - 1 && (
                                    <p style={{ margin: '0 4px', color: '#8c8c8c' }}>/</p>
                                )}
                            </>
                        );
                    })}
                </div>

                <div className={styles.userPart}>
                    {user && (
                        <Dropdown overlay={<UserInfoMenu />} placement="bottomLeft">
                            <div className={styles.user}>
                                <div
                                    className={styles.avatar}
                                    style={{ backgroundImage: `url(${user.headUrl})` }}
                                />
                                <div>{user.userName}</div>
                                <span className={styles['arrow-down']} />
                            </div>
                        </Dropdown>
                    )}
                </div>
            </Layout.Header>
        </section>
    );
};

export default Header;
