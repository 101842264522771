import * as model from './models';

import axios from '../request';

/** 落地页质检接口  再次检测 */
export async function againQualityCheckUsingPOST(payload: { id?: string }) {
    const data = {
        id: payload.id,
    };

    const result = await axios.request<model.IResponseboolean>({
        url: `/rest/lp/page/quality/check/again`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页质检接口  删除落地页质检结果 */
export async function deleteQualityCheckUsingPOST(payload: { id?: string }) {
    const data = {
        id: payload.id,
    };

    const result = await axios.request<model.IResponseboolean>({
        url: `/rest/lp/page/quality/check/delete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页质检接口  质量检测列表 */
export async function pageQualityCheckListUsingPOST(payload: { req: model.IPageQualityListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageQualityCheckListView>({
        url: `/rest/lp/page/quality/check/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页质检接口  质量检测列表已读状态 */
export async function pageQualityReadUsingPOST(payload: { req: model.IPageQualityReadReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageQualityCheckListView>({
        url: `/rest/lp/page/quality/read/status`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页质检接口  质量详情 */
export async function pageQualityDetailUsingPOST(payload: { req: model.IPageQualityDetailReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePageQualityCheckDetailView>({
        url: `/rest/lp/page/quality/detail`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页质检接口  历史质量检测列表 */
export async function historyPageQualityListUsingPOST(payload: {
    req: model.IHistoryPageQualityCheckListReq;
}) {
    const data = {
        ...payload.req,
    };

    const result =
        await axios.request<model.IResponseMultiResponseWrapHistoryPageQualityCheckListView>({
            url: `/rest/lp/page/quality/histroy/list`,
            method: 'post',
            data,
            headers: {
                'Content-Type': 'application/json',
            },
        });

    return result.data;
}

/** 落地页质检接口  质检结果行业列表 */
export async function industryViewListUsingGET() {
    const result =
        await axios.request<model.IResponseMultiResponseWrapPageQualityCheckIndustryView>({
            url: `/rest/lp/page/quality/industry/list`,
            method: 'get',
        });

    return result.data;
}

/** 落地页质检接口  质检列表(最新10000条质检成功记录) */
export async function pageListUsingPOST(payload: { req: model.IPageQualityListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageQualityCheckView>({
        url: `/rest/lp/page/quality/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页质检接口  质检结果评分等级列表 */
export async function scoreLevelViewListUsingGET() {
    const result =
        await axios.request<model.IResponseMultiResponseWrapPageQualityCheckScoreLevelView>({
            url: `/rest/lp/page/quality/scoreLevel/list`,
            method: 'get',
        });

    return result.data;
}

/** 落地页质检接口  提交检测 */
export async function submitPageQualityUsingPOST(payload: { req: model.ISubmitPageQualityReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseListPageQualitySubmitResult>({
        url: `/rest/lp/page/quality/submit`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页质检接口  批量上传三方页面地址 */
export async function submitThirdPageQualityUsingPOST(payload: { file?: File }) {
    const formData = new FormData();
    for (const key in payload) {
        formData.append(key, (payload as any)[key]);
    }
    const data = formData;

    const result = await axios.request<model.IResponseListPageThirdQualitySubmitResult>({
        url: `/rest/lp/page/quality/third/page/upload`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return result.data;
}
