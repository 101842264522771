import axios from '../request';
import * as model from './models';

/** 落地页模板接口  获取模板列表 */
export async function listTemplatesUsingPOST(payload: { req: model.ITemplateListRequest }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapTemplateView>({
        url: `/rest/lp/template/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页模板接口  上传模板 */
export async function pushTemplateUsingPOST(payload: { pageId?: string }) {
    const data = {
        pageId: payload.pageId,
    };

    const result = await axios.request<model.IResponsestring>({
        url: `/rest/lp/template/pushTemplate`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页模板接口  模板列表分类 */
export async function showCategoriesUsingGET() {
    const result = await axios.request<model.IResponseListcasCategory>({
        url: `/rest/lp/template/showCategories`,
        method: 'get',
    });

    return result.data;
}

/** 落地页模板接口  获取模板库列表 */
export async function listTemplatesV2UsingPOST(payload: { req: model.ITempListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapTemplateV2View>({
        url: `/rest/lp/template/v2/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页模板接口  行业信息 */
export async function industryListUsingPOST(payload: { req: model.IIndustryListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseListIndustryView>({
        url: `/rest/lp/template/industry/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页模板接口  运营单位是否存在 */
export async function operateNameExistUsingPOST(payload: { operateName?: string }) {
    const data = {
        operateName: payload.operateName,
    };

    const result = await axios.request<model.IResponseint>({
        url: `/rest/lp/template/operate/name/exist`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页模板接口  创建预览 */
export async function createPreviewUsingPOST(payload: { req: model.IPagePreviewReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsestring>({
        url: `/rest/lp/template/createPreview`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页模板接口  查看预览页面 */
export async function getPreviewUsingGET(payload: { key: string }) {
    const params = {
        key: payload.key,
    };

    const result = await axios.request<string>({
        url: `/rest/lp/template/getPreview`,
        method: 'get',
        params,
    });

    return result.data;
}

/** 落地页模板接口  查询搜索记录 */
export async function listRecordUsingPOST(payload: { req: model.ITempRecordReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseTemplateSearchRecordView>({
        url: `/rest/lp/template/search/record`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页模板接口  清洗数据 */
export async function cleanUsingPOST(payload: { req: any }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<any>({
        url: `/rest/lp/template/clean`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}
