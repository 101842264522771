import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    .ant-modal-header {
        /* padding: 17px !important; */
        padding-top: 17px !important;
        padding-bottom: 17px !important;
        border-bottom: none !important;
    }
`;
