import { createModel } from 'rt-model';
import {
    listBatchCreatePageUsingPOST,
    batchCreateGroupUsingPOST,
    exportResultUsingPOST,
    postBatchModifyPageMeta,
} from 'src/services/page-group-controller';
import { PageMetaJson } from 'omega-render';
import { IResultInfo, IGroupBatchCreateReq, modifyPageMetaReq } from 'src/services/models';
import { RootState } from 'src/store';

interface PageInfo {
    pageNum: number;
    pageSize: number;
    totalNum: number;
}
export enum GroupSource {
    moli = 0,
    self = 1,
}

export interface pageItem {
    id: string;
    name: string;
    pageMeta?: PageMetaJson;
}

export interface MultiPageGroupState {
    allPageInfo: PageInfo;
    selectPageInfo: PageInfo;
    loading: boolean;
    tags: string[];
    groupSource: GroupSource;
    ids?: pageItem[];
    prefix: string;
    thirdPages?: string[];
    containNum?: number;
    allPageList: pageItem[];
    selectPageList: pageItem[];
    keyword: string;
    totalNum: number;
    failedNum: number;
    resultId: string;
    createList: IResultInfo[];
    excelLink?: string;
    errorMessage?: string;
    inputIdsError?: boolean;
}

export const initPageInfo = {
    pageNum: 1,
    pageSize: 10,
    totalNum: 1,
};

const defaultState: MultiPageGroupState = {
    allPageInfo: { ...initPageInfo },
    selectPageInfo: { ...initPageInfo },
    tags: [],
    loading: false,
    groupSource: GroupSource.moli,
    keyword: '',
    ids: [],
    prefix: '',
    thirdPages: [],
    // containNum: 2,
    allPageList: [],
    selectPageList: [],
    totalNum: 0,
    failedNum: 0,
    createList: [],
    resultId: '',
};

export const multiGroupModel = createModel({
    namespace: 'multiGroupModel',
    defaultState,
    reducers: {
        init() {
            return defaultState;
        },
    },
    effects: {
        fetchPageList(payload?: { pageNum?: number }) {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const allPageList = [...state.multiGroup.allPageList];

                try {
                    const res = await listBatchCreatePageUsingPOST({
                        req: {
                            ...state.multiGroup.allPageInfo,
                            ...payload,
                            tag: state.multiGroup.tags,
                        },
                    });

                    dispatch(
                        multiGroupModel.actions.setModelState({
                            allPageInfo: {
                                ...state.multiGroup.allPageInfo,
                                totalNum: res?.data?.splitInfo?.totalNum,
                            },
                            allPageList:
                                state.multiGroup.allPageInfo.pageNum === 1
                                    ? [...res.data.list]
                                    : [...allPageList, ...res.data.list],
                        }),
                    );
                } catch (e) {
                    console.log('error', e);
                }
            };
        },
        fetchSelectPageList(payload?: { keyword?: string }) {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const selectPageList = [...state.multiGroup.selectPageList];
                const selectPageInfo = { ...state.multiGroup.selectPageInfo };

                try {
                    const res = await listBatchCreatePageUsingPOST({
                        req: {
                            ...selectPageInfo,
                            ...payload,
                            tag: state.multiGroup.tags,
                        },
                    });

                    dispatch(
                        multiGroupModel.actions.setModelState({
                            selectPageInfo: {
                                ...selectPageInfo,
                                totalNum: res?.data?.splitInfo?.totalNum,
                                pageNum: selectPageInfo.pageNum,
                            },
                            selectPageList:
                                selectPageInfo.pageNum === 1
                                    ? [...res.data.list]
                                    : [...selectPageList, ...res.data.list],
                        }),
                    );
                } catch (e) {
                    console.log('error', e);
                }
            };
        },
        fetchPageListByInput(payload?: { ids: string[] }) {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const ids = state.multiGroup.ids?.concat([]);

                try {
                    const res = await listBatchCreatePageUsingPOST({
                        req: {
                            ...payload,
                            tag: state.multiGroup.tags,
                            ...initPageInfo,
                        },
                    });

                    const newIds = ids?.concat([]);

                    res.data.list.forEach((item) => {
                        if (ids?.every((m) => m.id !== item.id)) {
                            newIds?.push(item);
                        }
                    });

                    const inputIdsError = res?.data?.list?.length !== payload?.ids?.length;
                    if (inputIdsError) {
                        dispatch(
                            multiGroupModel.actions.setModelState({
                                inputIdsError,
                            }),
                        );
                    } else {
                        dispatch(
                            multiGroupModel.actions.setModelState({
                                ids: newIds?.concat([]),
                                inputIdsError,
                            }),
                        );
                    }
                } catch (e) {
                    console.log('error', e);
                    dispatch(
                        multiGroupModel.actions.setModelState({
                            errorMessage: e.msg,
                        }),
                    );
                }
            };
        },
        createMultiGroup() {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const {
                    tags,
                    ids,
                    prefix,
                    groupSource,
                    thirdPages,
                    containNum = 2,
                } = state.multiGroup;

                const requestParam: IGroupBatchCreateReq = {
                    prefix,
                    groupSource,
                    containNum,
                };
                if (groupSource === GroupSource.moli) {
                    requestParam.ids = ids?.map((item) => {
                        return item.id;
                    });
                    requestParam.tags = tags;
                }
                if (groupSource === GroupSource.self) {
                    requestParam.thirdPages = thirdPages;
                }

                try {
                    const res = await batchCreateGroupUsingPOST({ req: requestParam });

                    dispatch(
                        multiGroupModel.actions.setModelState({
                            createList: [...res.data.resultInfo],
                            totalNum: res.data.totalNum,
                            failedNum: res.data.failedNum,
                            resultId: String(res.data.resultId),
                        }),
                    );
                } catch (e) {
                    console.log('error', e);
                }
            };
        },
        downloadPageExcel() {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const { resultId } = state.multiGroup;

                try {
                    const res = await exportResultUsingPOST({ resultId: resultId ?? '' });
                    dispatch(
                        multiGroupModel.actions.setModelState({
                            excelLink: res.data,
                        }),
                    );
                } catch (e) {
                    console.log('error', e);
                }
            };
        },
        batchUpdatePageMeta() {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const { ids } = state.multiGroup;

                const update = ids
                    ?.filter((item) => {
                        return item.pageMeta?.anchorForm?.checked;
                    })
                    ?.map((m) => {
                        return {
                            outPageId: m.id,
                            pageMeta: {
                                ...m.pageMeta,
                                anchorForm: {
                                    checked: false,
                                },
                            },
                        };
                    });

                try {
                    await postBatchModifyPageMeta((update || []) as modifyPageMetaReq[]);
                } catch (e) {
                    console.log('error', e);
                }
            };
        },
    },
});
