import { Layout } from 'm-ui';
import cs from 'classnames';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import IconFont from 'src/components/IconFont';
import { history } from 'src/routes/config';

import { usePlatformState } from 'src/store/hooks';
import { RootState } from 'src/store';
import { openXianSuoUrl, useOpenIm } from 'src/utils/open';
import { useMemo } from 'react';
import { IconType } from '../IconFont/type.gen';
import { ReactComponent as ProgrammaticIcon } from './img/programmatic.svg';
import styles from './styles.module.less';
import { useLpUserInfo } from 'src/utils/useLpUserInfo';
import { webLogger } from 'src/utils/radar';
import { QualityPop } from './QualityPop';

interface MenuConfig {
    title: string;
    path: string;
    iconClass?: IconType;
    icon?: React.ReactNode;
    mathPath?: string;
    onClick?: (params?: any) => void;
}

interface MenuProps extends RouteComponentProps {}

const Menu: React.FunctionComponent<MenuProps> = (props) => {
    const openIm = useOpenIm();
    const { location } = props;
    const qualityUser = useLpUserInfo('qualityUser');
    const accountId = useLpUserInfo('accountId');
    const userId = useLpUserInfo('ksUserId');
    const { tabs, imUser, jumpAdImBiz } = usePlatformState((state: RootState) => {
        return {
            tabs: state.adUser.lpUser?.tabs || [],
            imUser: state.adUser.imUser,
            jumpAdImBiz: state.adUser.lpUser?.jumpAdImBiz || false,
        };
    });
    // const []
    const configMap: {
        [key: string]: MenuConfig;
    } = useMemo(
        () => ({
            首页: {
                title: '首页',
                path: '/mainGuide',
                iconClass: 'icon-icon-fill--shou-ye',
                mathPath: '/mainGuide',
            },
            我的落地页: {
                title: '我的落地页',
                path: '/page/list',
                iconClass: 'icon-wo-de-la-di-ye',
                mathPath: '/page',
            },
            模板: {
                title: '模板',
                path: '/template',
                iconClass: 'icon-icon-fill--mo-ban',
                mathPath: '/template',
            },
            程序化落地页: {
                title: '程序化落地页',
                path: '/programmaticList',
                icon: <ProgrammaticIcon className={styles.programmaticIcon} />,
                mathPath: '/programmatic',
            },
            营销组件: {
                title: '营销组件',
                path: '/market',
                iconClass: 'icon-icon-fill--ying-xiao',
                mathPath: '/market',
            },
            快聊客服: {
                title: '快聊客服',
                path: '/report/manage',
                iconClass: 'icon-kuailiao',
                mathPath: '/im',
                onClick: () => {
                    if (imUser) {
                        openIm(jumpAdImBiz ? 31 : 103);
                    } else {
                        history.push('/im/create');
                    }
                },
            },
            表单: { title: '表单', path: '/market/form' },
            咨询页: {
                title: '咨询页',
                path: '/market/consult',
            },
            线索管理: {
                title: '线索管理',
                path: '/clue',
                iconClass: 'icon-icon-fill--xian-suo',
                mathPath: '/clue',
            },
            数据报表: {
                title: '数据报表',
                path: '/report/manage',
                iconClass: 'icon-icon-fill-report',
                mathPath: '/report/manage',
            },
            数据分析: {
                title: '数据分析',
                path: '/report',
                iconClass: 'icon-icon-fill-report',
                mathPath: '/report',
            },
            热力图: {
                title: '热力图',
                path: '/report/heatmap',
                iconClass: 'icon-icon-fill-report',
                mathPath: '/report/heatmap',
            },
            漏斗分析: {
                title: '漏斗分析',
                path: '/report/funnelChart',
                iconClass: 'icon-icon-fill-report',
                mathPath: '/report/funnelChart',
            },
            老铁线索通: {
                title: '磁力追踪',
                path: '/report/manage',
                iconClass: 'icon-icon-fill--xian-suo',
                mathPath: '/xiansuo',
                onClick: () => {
                    openXianSuoUrl('/market/form');
                },
            },
            互动弹窗: {
                title: '互动弹窗',
                path: '/popup',
                iconClass: 'icon-icon-fill--xian-suo',
                mathPath: '/popup',
            },
            落地页质量评分: {
                title: '落地页质量评分',
                icon: <QualityPop />,
                path: '/quality/list',
                mathPath: '/quality',
                onClick: () => {
                    webLogger.sendImmediately('CLICK', {
                        action: 'SITE_QUALITY_CHECK_SIDEBAR_MENU_CLICK',
                        params: {
                            user_id: userId,
                            account_id: accountId,
                        },
                        type: 'USER_OPERATION',
                    });
                    history.push('/quality/list');
                },
            },
        }),
        [imUser, openIm, jumpAdImBiz],
    );
    React.useEffect(() => {
        if (tabs.find((tab) => tab.name === '落地页质量评分')) {
            webLogger.sendImmediately('SHOW', {
                action: 'SITE_QUALITY_CHECK_SIDEBAR_MENU',
                params: {
                    user_id: userId,
                    account_id: accountId,
                },
            });
        }
    }, [qualityUser, userId, accountId]);
    return (
        <section className={styles.wrapper}>
            <Layout.Sider collapsed={false} width={208} className={styles.menu}>
                <div className={styles.logo} />

                <div className={styles.pages}>
                    {tabs.map((tab) => {
                        const conf = configMap[tab.name];

                        if (!conf) {
                            return null;
                        }

                        const { children } = tab;
                        if (children && children.length === 0) {
                            return null;
                        }
                        const matched = location.pathname.startsWith(conf.mathPath ?? conf.path);
                        const itermCollapsed = !matched;

                        return (
                            <React.Fragment key={conf.title}>
                                <div
                                    onClick={() => {
                                        if (typeof conf.onClick === 'function') {
                                            return conf.onClick();
                                        }
                                        if (children?.length) {
                                            const { name } = children[0];
                                            const item = configMap[name];
                                            if (item) {
                                                history.push(item.path);
                                                return;
                                            }
                                        }
                                        history.push(conf.path);
                                    }}
                                    className={cs(styles.item, {
                                        [styles.active]: matched && !children,
                                    })}
                                    key={conf.title}
                                >
                                    {conf.iconClass ? (
                                        <IconFont type={conf.iconClass} />
                                    ) : (
                                        conf.icon
                                    )}
                                    <span className={styles.title}>{conf.title}</span>
                                    {children && (
                                        <IconFont
                                            className={cs(styles.arrow, {
                                                [styles.collapseIcon]: itermCollapsed,
                                            })}
                                            type="icon-filled-arrow-up-16"
                                        />
                                    )}
                                </div>
                                {children && !itermCollapsed && (
                                    <div className={styles.children}>
                                        {children.map((childTab) => {
                                            const item = configMap[childTab.name];
                                            if (!item) {
                                                return null;
                                            }
                                            const active = location.pathname.startsWith(
                                                item.mathPath ?? item.path,
                                            );
                                            return (
                                                <div
                                                    key={item.title}
                                                    className={cs(styles.item, {
                                                        [styles.active]: active,
                                                    })}
                                                    onClick={() => {
                                                        history.push(item.path);
                                                    }}
                                                >
                                                    {item.title}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            </Layout.Sider>
        </section>
    );
};

export default withRouter(Menu);
