import { Weblog } from '@ks/weblogger';
import { getUrlParam } from 'omega-shared/utils';
import Radar from '@ks-radar/radar';
import { isProduction } from './env';

const state = {
    fmp: undefined as undefined | number,
};
export const webLogger = new Weblog(
    {
        fps: false,
        autoPV: true,
        timing: false,
        env: isProduction ? 'production' : 'development',
        // radar: {
        //     projectId: 'e92728ecff', // 雷达平台上的唯一标识
        //     sampling: 1.0, // 上报采样率，1 为全部上报
        // },
    },
    { product_name: 'moli' },
);

export const radar = new Radar({
    weblog: webLogger,
    projectId: 'e92728ecff', // 雷达项目ID
    customDimensions: {
        release_tag: window.kfxEnv && window.kfxEnv.channelId,
        product_version: window.kfxEnv && window.kfxEnv.version,
    },
    enableKtrace: true,
    ktraceServiceName: 'ad-clue', // 服务树根节点，方便天问计费
    ktraceBizName: 'ad',
    ktraceWhitelist: [location.host + '/'],
    // 其他参数详见：https://ksurl.cn/8EUcHTfF
});

/**
 * 记录fmp
 */
export const recordFmp = () => {
    if (state.fmp == null) {
        state.fmp = new Date().getTime();
        radar?.fmp(state.fmp);
    }
};

/** dsp外化跳转埋点 */
export function handleDSPTrack() {
    const listener = () => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const [account_id, location, information_id] = getUrlParam([
            'account_id',
            'location',
            'information_id',
        ]);
        if (account_id && location && information_id) {
            if (!sessionStorage.getItem('sessionTracked')) {
                sessionStorage.setItem('sessionTracked', 'true');
                if (webLogger) {
                    webLogger.sendImmediately('PV', {
                        type: 'enter',
                        page: 'OPEN_SUCC',
                        params: {
                            account_id,
                            location,
                            information_id,
                        },
                    });
                }
            }
        }
    };
    listener();
    window.addEventListener('unload', () => {
        if (sessionStorage.getItem('sessionTracked')) {
            sessionStorage.removeItem('sessionTracked');
        }
    });
}

export function dataTrackPageUseAnchorForm(pageId: string) {
    const [accountId] = getUrlParam(['accountId']);
    if (accountId && webLogger) {
        webLogger.sendImmediately('CLICK', {
            action: 'CREATE_PAGE_USE_ANCHOR_FORM',
            params: {
                accountId,
                page_id: pageId,
            },
            type: 'USER_OPERATION',
        });
    }
}

export function dataTrackPageNotUseAnchorForm(pageId: string) {
    const [accountId] = getUrlParam(['accountId']);
    if (accountId && webLogger) {
        webLogger.sendImmediately('CLICK', {
            action: 'CREATE_PAGE_NOT_USE_ANCHOR_FORM',
            params: {
                accountId,
                page_id: pageId,
            },
            type: 'USER_OPERATION',
        });
    }
}

/** 质检页面编辑按钮点击 */
export function dataTrackQualityEdit(accountId?: number, pageId?: string) {
    // const [accountId] = getUrlParam(['accountId']);
    if (webLogger) {
        webLogger.sendImmediately('CLICK', {
            action: 'QUALITY_EDIT_BUTTON',
            params: {
                account_id: accountId?.toString() || accountId,
                page_id: pageId,
            },
            type: 'USER_OPERATION',
        });
    }
}

/** 性能检测页面新建按钮点击 */
export function dataTrackPerformanceEdit(accountId?: number, pageId?: string) {
    if (webLogger) {
        webLogger.sendImmediately('CLICK', {
            action: 'PERFORMA_EDIT_BUTTON',
            params: {
                account_id: accountId?.toString() || accountId,
                page_id: pageId,
            },
            type: 'USER_OPERATION',
        });
    }
}
