import { IAdLpPageView } from 'landingPage/services/models';
import { createModel } from 'rt-model';
import { materialBind, uploadBindMaterial } from 'src/components/UploadImage/utils';
import {
    batchCreateFictionPageUsingPOST,
    batchSaveFictionPageUsingPOST,
    fictionListUsingPOST,
} from 'src/services/fiction-controller';

import { IFictionQueryRequest, IFictionView, IPageBatchGenerateReq } from 'src/services/models';
import { batchPublishUsingPOST } from 'src/services/page-controller';

import { RootState } from 'src/store';
// import produce from 'immer';
export interface DefaultState {
    pageInfo: PageInfo;
    novelList: IFictionView[];
    novelPageDetail: NovelPageDetail;
    loading: boolean;
    landingPageList: LandingPageList[];
    hasMore: boolean;
    fictionPageIds: string[];
    hasSaved: boolean;
    hasPublished: boolean;
    jumpFromDSP?: boolean;
    hasEdited: boolean;
    pageIds: string[];
    errMsg?: string;
    hasError?: boolean;
    saveLoading?: boolean;
    libraryId?: number;
}

interface LandingPageList {
    createTime: number;
    fictionId: number;
    fictionPageId: string;
    pageJson: IAdLpPageView;
    checked?: boolean;
}
export interface SectionContent {
    title: string;
    content: string;
}
interface PageInfo {
    pageNum: number;
    pageSize: number;
    name?: string;
    fictionIds?: string[];
    totalNum: number;
}
export interface NovelPageDetail extends NovelDeliveryDetail {
    type: 0 | 1; // 1.应用下载；2.关注公众号
    fictionId: number; // 小说id
    createCount: number; // 落地页生成个数
    synopsis: string;
    fictionSections: SectionContent[];
    fictionTags: string[];
    name: string; // 小说名
    sessionId: string; // uuid
    appType?: string; // 应用中心迁移新增字段
}
interface NovelDeliveryDetail {
    linkType: 2 | 3 | 4;
    appInfo?: {
        appId: number;
        accountId: number;
        appVersion: string;
        appName: string;
        packageName: string;
        sysPackageName: null;
        packageSize: number;
        url: string;
        appIconUrl: string;
        h5App: boolean;
        platform: string;
        virusScore: number;
        updateTime: number;
        date: number;
        appScore: number;
        iosAppId: null;
        useSdk: boolean;
        downloadCount: number;
    };
    quickAppLink?: string;
    quickAppDirectCall?: 0 | 1;
    appId: string;
    iOSLink?: string;
    fictionBanners: string[]; // 小说头图，应用下载和关注公众号都有
    weChatName: string; // 公众号名称
    weChatId: string; // 微信id
    weChatHeadUrl?: string; // 公众号头像
}
const defaultState: DefaultState = {
    hasError: false,
    landingPageList: [],
    pageIds: [],
    errMsg: '',
    jumpFromDSP: false,
    novelList: [],
    hasMore: true,
    hasSaved: false,
    hasPublished: false,
    fictionPageIds: [],
    novelPageDetail: {
        type: 0, // 1.应用下载；2.关注公众号
        fictionId: 0, // 小说id
        linkType: 2, // 用户下载类型，快应用、安卓、和ios
        quickAppLink: '', // 快应用链接
        fictionBanners: [], // 小说头图，应用下载和关注公众号都有
        weChatName: '', // 公众号名称
        weChatId: '', // 微信id
        createCount: 4, // 落地页生成个数
        iOSLink: '', // ios链接
        synopsis: '',
        fictionSections: [],
        name: '', // 小说名
        fictionTags: [],
        sessionId: '',
        appId: '',
        quickAppDirectCall: 0,
        appType: '',
    },
    pageInfo: {
        pageNum: 1,
        pageSize: 10,
        totalNum: 10,
    },
    loading: false,
    hasEdited: false,
    saveLoading: false,
};
export const novelModel = createModel({
    namespace: 'novelModel',
    defaultState,
    reducers: {
        init() {
            return defaultState;
        },
        // setPageName(state, payload: { name?: string; id: string; title?: string }) {
        //     const { name, title, id } = payload;
        //     return produce(state, draftState => {
        //         const page = draftState.list.find(p => p.id === id);
        //         if (page) {
        //             if ('title' in payload) {
        //                 page.title = title || '';
        //             } else {
        //                 page.name = name || '';
        //             }
        //         }
        //     });
        // },
        // setNovelList(state, payload: NovelList[]) {
        //
        //     return produce(state, draftState => {
        //         const page = draftState.novelList.find(p => p.id === id);
        //         if (page) {
        //             if ('title' in payload) {
        //                 page.title = title || '';
        //             } else {
        //                 page.name = name || '';
        //             }
        //         }
        //     });
        // },
    },
    effects: {
        fetchNovelList() {
            return async (dispatch, getState) => {
                // pageListModel.reduce
                const state: RootState = getState();
                // const req = {
                //     ...state.novel.pageInfo,
                // };

                const { loading } = state.novel;
                if (!loading) {
                    dispatch(
                        novelModel.actions.setModelState({
                            loading: true,
                        }),
                    );
                    try {
                        const fictionProductLibraryUser =
                            state.adUser.lpUser?.fictionProductLibraryUser;
                        const { libraryId } = state.novel;
                        const params = fictionProductLibraryUser
                            ? {
                                  ...state.novel.pageInfo,
                                  libraryId,
                              }
                            : {
                                  ...state.novel.pageInfo,
                              };
                        const res = await fictionListUsingPOST({
                            req: params as IFictionQueryRequest,
                        });
                        dispatch(
                            novelModel.actions.setModelState({
                                novelList: res.data,
                                pageInfo: {
                                    ...state.novel.pageInfo,
                                    totalNum: res.data?.[0]?.totalNum,
                                },
                                loading: false,
                                hasError: false,
                            }),
                        );
                        if (state.novel.novelPageDetail.fictionId) {
                            const novelContent = res.data[0];

                            dispatch(
                                novelModel.actions.editNovelDetail({
                                    fictionSections: [...novelContent.sections],
                                    fictionTags: [...novelContent.tags],
                                    synopsis: novelContent.synopsis,
                                    name: novelContent.name,
                                }),
                            );
                        }
                    } catch (e) {
                        dispatch(
                            novelModel.actions.setModelState({
                                loading: false,
                                hasError: true,
                            }),
                        );
                    }
                }
            };
        },
        cleanLandingPageList() {
            return async (dispatch) => {
                // const state: RootState = getState();
                dispatch(
                    novelModel.actions.setModelState({
                        hasSaved: false,
                        hasMore: true,
                        hasPublished: false,
                        landingPageList: [],
                    }),
                );
            };
        },
        fetchLandingPageList() {
            return async (dispatch, getState) => {
                // pageListModel.reduce
                const state: RootState = getState();
                const req = {
                    ...state.novel.novelPageDetail,
                };
                const landingPageList = [...state.novel.landingPageList];

                const { loading } = state.novel;
                if (!loading) {
                    dispatch(
                        novelModel.actions.setModelState({
                            loading: true,
                        }),
                    );
                    try {
                        const fictionProductLibraryUser =
                            state.adUser.lpUser?.fictionProductLibraryUser;
                        const { libraryId } = state.novel;
                        const param = {
                            ...req,
                            ...(fictionProductLibraryUser ? { libraryId } : {}),
                            appId: req.appInfo?.appId,
                            materialDetailList: [
                                ...materialBind.default,
                                ...materialBind.weChatHead,
                            ],
                        };
                        const { data: res } = await batchCreateFictionPageUsingPOST({
                            req: param as IPageBatchGenerateReq,
                        });
                        dispatch(
                            novelModel.actions.setModelState({
                                landingPageList: landingPageList.concat(res as any),
                                loading: false,
                                hasMore: res && res.length > 0,
                                fictionPageIds:
                                    landingPageList.length > 0 ? state.novel.fictionPageIds : [],
                                hasError: false,
                            }),
                        );
                    } catch (e) {
                        dispatch(
                            novelModel.actions.setModelState({
                                loading: false,
                                hasError: true,
                            }),
                        );
                    }
                }
            };
        },
        setSplitInfo(payload: { pageInfo: PageInfo; reset?: boolean }) {
            return async (dispatch) => {
                // const state: RootState = getState();
                dispatch(
                    novelModel.actions.setModelState({
                        pageInfo: {
                            ...payload.pageInfo,
                        },
                    }),
                );

                if (!payload.reset) {
                    dispatch(novelModel.actions.fetchNovelList());
                }
            };
        },
        editNovelInfo(payload) {
            return async (dispatch) => {
                // const state: RootState = getState();
                dispatch(
                    novelModel.actions.setModelState({
                        hasEdited: payload,
                    }),
                );
            };
        },
        setDSPTag(payload) {
            return async (dispatch) => {
                // const state: RootState = getState();
                dispatch(
                    novelModel.actions.setModelState({
                        jumpFromDSP: payload,
                    }),
                );
            };
        },
        editNovelDetail(payload) {
            return async (dispatch, getState) => {
                // pageListModel.reduce
                const state: RootState = getState();

                dispatch(
                    novelModel.actions.setModelState({
                        novelPageDetail: {
                            ...state.novel.novelPageDetail,
                            ...payload,
                        },
                    }),
                );
            };
        },
        editSelectedNovelList(payload: {
            fictionPageIds: string[];
            landingPageList: LandingPageList[];
        }) {
            return async (dispatch) => {
                const { fictionPageIds, landingPageList } = payload;
                // pageListModel.reduce
                // const state: RootState = getState();
                // const { section, synopsis } = payload;
                dispatch(
                    novelModel.actions.setModelState({
                        fictionPageIds,
                        landingPageList,
                        hasSaved: false,
                    }),
                );
            };
        },
        saveLandingPage() {
            return async (dispatch, getState) => {
                // pageListModel.reduce
                const state: RootState = getState();
                const { fictionPageIds, saveLoading, landingPageList } = state.novel;

                if (fictionPageIds.length) {
                    if (!saveLoading) {
                        dispatch(
                            novelModel.actions.setModelState({
                                saveLoading: true,
                            }),
                        );

                        try {
                            const fictionProductLibraryUser =
                                state.adUser.lpUser?.fictionProductLibraryUser;
                            const { libraryId } = state.novel;
                            const libraryInfo = fictionProductLibraryUser ? { libraryId } : {};
                            const param = {
                                fictionPageIds,
                                ...libraryInfo,
                            };
                            const res = await batchSaveFictionPageUsingPOST({
                                req: param,
                            });

                            if (res.result === 1) {
                                dispatch(
                                    novelModel.actions.setModelState({
                                        hasSaved: true,
                                        pageIds: res.data,
                                        errMsg: '',
                                        saveLoading: false,
                                        // fictionPageIds: [],
                                    }),
                                );
                                // 上报使用的素材
                                const pageJsonObj: any = {};
                                landingPageList.forEach((item) => {
                                    pageJsonObj[item.fictionPageId] =
                                        item.pageJson?.components ?? [];
                                });
                                console.log('pageJsonObj----', pageJsonObj);
                                res.data?.forEach((id, index) => {
                                    uploadBindMaterial(id, pageJsonObj[fictionPageIds[index]]);
                                });
                            } else {
                                dispatch(
                                    novelModel.actions.setModelState({
                                        hasSaved: false,
                                        errMsg: '保存失败，请稍后再试',
                                        saveLoading: false,
                                        // fictionPageIds: [],
                                    }),
                                );
                            }
                        } catch (e) {
                            dispatch(
                                novelModel.actions.setModelState({
                                    hasSaved: false,
                                    errMsg: '保存失败，请稍后再试',
                                    saveLoading: false,
                                }),
                            );
                        }
                    }
                }
            };
        },
        publishLandingPage() {
            return async (dispatch, getState) => {
                // pageListModel.reduce
                const state: RootState = getState();

                const { pageIds, saveLoading } = state.novel;

                if (!saveLoading) {
                    dispatch(
                        novelModel.actions.setModelState({
                            saveLoading: true,
                        }),
                    );

                    try {
                        const res = await batchPublishUsingPOST({
                            pageIds: [...pageIds],
                        });

                        if (res.result === 1) {
                            dispatch(
                                novelModel.actions.setModelState({
                                    hasPublished: true,
                                    hasSaved: false,
                                    fictionPageIds: [],
                                    pageIds: [],
                                    errMsg: '',
                                    saveLoading: false,
                                }),
                            );
                        } else {
                            dispatch(
                                novelModel.actions.setModelState({
                                    hasPublished: false,
                                    errMsg: '发布失败，请稍后再试',
                                    saveLoading: false,
                                }),
                            );
                        }
                    } catch (e) {
                        dispatch(
                            novelModel.actions.setModelState({
                                hasPublished: false,
                                errMsg: '发布失败，请稍后再试',
                                saveLoading: false,
                            }),
                        );
                    }
                }
            };
        },
        clearAll() {
            return async (dispatch) => {
                dispatch(
                    novelModel.actions.setModelState({
                        ...defaultState,
                    }),
                );
            };
        },
        clearErrMsg() {
            return async (dispatch) => {
                dispatch(
                    novelModel.actions.setModelState({
                        errMsg: '',
                    }),
                );
            };
        },
        setLibraryId(payload) {
            return async (dispatch) => {
                // const state: RootState = getState();
                dispatch(
                    novelModel.actions.setModelState({
                        libraryId: payload,
                    }),
                );
            };
        },
    },
});
