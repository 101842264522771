import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';

import styles from './index.module.less';

export default class E404 extends PureComponent<RouteComponentProps<{ msg?: string }>> {
    public render() {
        return (
            <div className={styles.root}>
                <div className={styles.show} />
                {/* eslint-disable-next-line react/destructuring-assignment */}
                <p>{this.props.match.params.msg ?? '你访问的界面不存在或发生错误'}</p>
            </div>
        );
    }
}
