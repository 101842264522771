import * as Mui from 'm-ui';
import * as React from 'react';
import { RouteComponentProps, Router, withRouter } from 'react-router';
import { matchRoutes, renderRoutes } from 'react-router-config';
// import store from 'src/redux';
import { ExtendRouteConfig, history, routes, uc } from 'src/routes/config';
import zhCN from 'm-ui/es/locale/zh_CN';
import { refreshPassToken, useUser } from 'src/hooks/user';
import Header from 'src/components/Header';
import Menu from 'src/components/Menu';
import Info from 'src/components/Info';
import { Agreement } from 'src/components/Agreement';
// import * as Sentry from '@sentry/browser';
import { useMount } from 'react-use';
// import { isProduction } from 'omega-shared/utils';
import { handleDSPTrack } from 'src/utils/radar';
import moment from 'moment';
import ImLoader, { addGlobalShare } from '@ad/ad-im-loader';
import { batchLoadFonts, fonts } from './utils/fontFamily';
import { usePlatformState } from './store/hooks';
import { RootState } from './store';
import { isProduction } from './utils/env';

const { Layout, ConfigProvider } = Mui;

enum AppIdEnum {
    JuXing = 2,
}

// 展示页面骨架，包括侧边栏、header，同时解析routes
const Skeleton: React.FunctionComponent<RouteComponentProps> = (props) => {
    const { location } = props;
    const [init, setInit] = React.useState(false);
    const hideSkeleton = matchRoutes(routes, location.pathname).find((matchedRoute) => {
        const route = matchedRoute.route as ExtendRouteConfig;
        return route.hideSkeleton;
    });
    const disableUserInfo = !!matchRoutes(routes, location.pathname).find((matchedRoute) => {
        const route = matchedRoute.route as ExtendRouteConfig;
        return route.disableUserInfo;
    });

    useUser({ ...props, disable: disableUserInfo });
    const { accountId, appId } = usePlatformState((state: RootState) => {
        return {
            accountId: state.adUser?.lpUser?.accountId,
            appId: state.adUser?.userDspAccount?.appId,
        };
    });
    React.useEffect(() => {
        (async () => {
            try {
                await refreshPassToken();
            } catch (e) {
                console.error(e);
            } finally {
                setInit(true);
            }
        })();
    }, []);
    const robot = React.useCallback(() => {
        addGlobalShare({
            moment,
            React,
            mUi: Mui,
        });
        if (!accountId || appId === AppIdEnum.JuXing) {
            return;
        }

        return (
            <div className="robot">
                <ImLoader
                    toolList={[]}
                    userConfig={{
                        appId: 2,
                        uid: accountId as number,

                        environment: (isProduction ? 'production' : 'staging') as any,
                        bizType: 2,
                    }}
                    initPageSize={10}
                    dollyId={isProduction ? 4752 : 2799}
                />
            </div>
        );
    }, [accountId]);
    if (!init) {
        return <></>;
    }
    if (hideSkeleton) {
        return (
            <>
                {renderRoutes(routes)}
                {location.pathname !== '/page/edit' && robot()}
            </>
        );
    }
    return (
        <Layout>
            <Menu />
            <Layout.Content
                style={{ minHeight: window.innerHeight, minWidth: 1078, marginLeft: 208 }}
            >
                <Header />
                {renderRoutes(routes)}
                {location.pathname !== '/page/edit' && robot()}
            </Layout.Content>
        </Layout>
    );
};
const RouterSkeleton = withRouter(Skeleton);

const App: React.SFC = () => {
    useMount(() => {
        batchLoadFonts(fonts, false);
        handleDSPTrack();
        // if (isProduction) {
        //     Sentry.init({
        //         dsn: 'https://04349c21dba341c5b313cac59ec4b805@sentry.kuaishou.com/286',
        //         release: process.env.GIAO_SENTRY_RELEASE,
        //         sampleRate: 1,
        //         environment: process.env.NODE_ENV,
        //         // allowUrls: ['chenzhongkj.com', 'moli.kuaishou.com'],
        //         ignoreErrors: ['Refused to evaluate a string as JavaScript because'],
        //     });
        // }
    });

    return (
        <div onClick={uc.onRootClick}>
            <ConfigProvider locale={zhCN}>
                {/* <Provider store={store}> */}
                <Router history={history}>
                    <RouterSkeleton />
                    <Agreement />
                </Router>
                {/* </Provider> */}
                <Info />
            </ConfigProvider>
        </div>
    );
};
export default App;
