import * as model from './models';

import axios from '../request';

/** Material Controller  账户今日使用一键制图次数 */
export async function aigcImageGenTodayCountUsingPOST() {
    const result = await axios.request<model.IResponseint>({
        url: `/rest/lp/material/aigc/image/aigcImageGenTodayCount`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库AI制图任务删除 */
export async function delAigcImageUsingPOST(payload: { req: model.IAigcTaskQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/material/aigc/image/delete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库AI制图任务制作 */
export async function imageGenerateUsingPOST(payload: { req: model.IImageGenerateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseImageGenerateView>({
        url: `/rest/lp/material/aigc/image/generate`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库AI制图,通过任务id轮询查询，间隔10s */
export async function viewImageUsingPOST(payload: { taskId?: string }) {
    const params = {
        taskId: payload.taskId,
    };

    const result = await axios.request<model.IResponseImageGenerateView>({
        url: `/rest/lp/material/aigc/image/get`,
        method: 'post',
        params,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  账户是否第一次使用一键制图 */
export async function isFirstUseImageGenUsingPOST() {
    const result = await axios.request<model.IResponseboolean>({
        url: `/rest/lp/material/aigc/image/isFirstUseImageGen`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库AI制图关键词查询 */
export async function imageKeywordUsingPOST() {
    const result = await axios.request<model.IResponseImageKeywordView>({
        url: `/rest/lp/material/aigc/image/keyword`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库AI制图任务列表查询 */
export async function viewImageListUsingPOST() {
    const result = await axios.request<model.IResponseListImageGenerateView>({
        url: `/rest/lp/material/aigc/image/list`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库AI制图任务图片保存 */
export async function saveAigcImageUsingPOST(payload: { req: model.IAigcTaskQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapMaterialImageView>({
        url: `/rest/lp/material/aigc/image/save`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库图片收藏 */
export async function imageCollectUsingPOST(payload: { id?: string }) {
    const data = {
        id: payload.id,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/material/image/collect`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库图片取消收藏 */
export async function imageCollectCancelUsingPOST(payload: { id?: string }) {
    const data = {
        id: payload.id,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/material/image/collect/cancel`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库图片删除 */
export async function imageDeleteUsingPOST(payload: { req: model.IMaterialImageDelReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/material/image/delete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库图片列表 */
export async function imageListUsingPOST(payload: { req: model.IMaterialListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapMaterialImageView>({
        url: `/rest/lp/material/image/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库图片上传 */
export async function imageUploadUsingPOST(payload: { file: File }) {
    const formData = new FormData();
    for (const key in payload) {
        formData.append(key, (payload as any)[key]);
    }
    const data = formData;

    const result = await axios.request<model.IResponseMaterialImageView>({
        url: `/rest/lp/material/image/upload`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return result.data;
}

/** Material Controller  素材库图片URL上传 */
export async function imageURLUploadUsingPOST(payload: { req: model.IMaterialImageUploadReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/material/image/url/upload`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** Material Controller  素材库图片上传 */
export async function securityImageUploadUsingPOST(payload: { fileToken?: string }) {
    const data = {
        fileToken: payload.fileToken,
    };

    const result = await axios.request<model.IResponseMaterialImageView>({
        url: `/rest/lp/material/security/image/upload`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}
