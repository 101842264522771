import axios from '../request';
import * as model from './models';

/** 报表接口  查询图表 */
export async function chartUsingPOST(payload: { reportParam: model.IReportQueryReq }) {
    const data = {
        ...payload.reportParam,
    };

    const result = await axios.request<model.IResponseReportChartView>({
        url: `/rest/lp/report/chart`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 报表接口  转化漏斗图表 */
export async function funnelReportUsingPOST(payload: { req: model.IFunnelReportQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseFunnelReportView>({
        url: `/rest/lp/report/funnel/chart`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 报表接口  转化漏斗落地页下拉列表 */
export async function funnelPageDropdownListUsingPOST(payload: {
    req: model.IFunnelDropdownQueryReq;
}) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapFunnelPageDropdownView>({
        url: `/rest/lp/report/funnel/page/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 报表接口  落地页动态组件图表 */
export async function pageReportUsingPOST(payload: { pageReportReq: model.IPageReportReq }) {
    const data = {
        ...payload.pageReportReq,
    };

    const result = await axios.request<model.IResponsePageReportView>({
        url: `/rest/lp/report/pageReport`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 报表接口  查询报表信息 */
export async function summaryUsingPOST(payload: { reportParam: model.IReportQueryReq }) {
    const data = {
        ...payload.reportParam,
    };

    const result = await axios.request<model.IResponseReportView>({
        url: `/rest/lp/report/summary`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}
