import Cookies from 'js-cookie';
import { useCallback } from 'react';
// import { isProduction, isBeta, isStaging } from 'src/landingPage/common/utils/env';
import { getXianSuoDomain } from 'src/landingPage/common/utils/getXianSuoDomain';
import { usePlatformState } from 'src/store/hooks';
import { RootState, store } from 'src/store';
import { uc } from 'src/routes/config';
import { getAgentDspToken } from 'src/request';
import { isBeta, isProduction, isStaging } from './env';
import { privateUserAuditUsingPOST } from '@/services-new/customer-controller';
import { message } from 'm-ui';
/** 跳转线索地址,需要 / 开头 */

export const openXianSuoUrl = async (url: string) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const u = new URL(`${getXianSuoDomain(true)}${url}`);
    const authCheckStrategyAgent =
        store.getState().adUser?.userDspAccount?.authCheckStrategyAgent || false;
    try {
        if (authCheckStrategyAgent) {
            const { data } = await getAgentDspToken();
            u.searchParams.append('agent', data);
        }
    } catch (e) {
        console.error('Get agent dsp token error', e);
        // 没有升级后的multi_agency_token就用agent_dsp_token
        const agent = Cookies.get('agent_dsp_token');
        if (agent) {
            u.searchParams.append('agent', agent);
        }
    }

    u.searchParams.append('from', 'cilijianzhan');
    uc.jump(u.href);
};

export const useOpenIm = () => {
    const { accountId, ksUserId } = usePlatformState((state: RootState) => {
        return {
            accountId: state.adUser?.lpUser?.accountId,
            ksUserId: state.adUser?.lpUser?.ksUserId,
        };
    });
    let imUrl = 'https://ad-im-pc.test.gifshow.com/admin/#/home';

    if (isProduction) {
        imUrl = 'https://adim.kuaishou.com/workbench/#/home';
    }
    if (isBeta) {
        imUrl = 'https://adim-beta.corp.kuaishou.com/workbench/#/home';
    }
    if (isStaging) {
        imUrl = 'https://adim.staging.kuaishou.com/workbench/#/home';
    }
    const openIm = useCallback(
        async (bid = 103) => {
            let enableJumpToIM = true;
            try {
                await privateUserAuditUsingPOST(ksUserId);
            } catch (e) {
                enableJumpToIM = false;
            }
            if (enableJumpToIM) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                window.open(
                    `${imUrl}?aid=${accountId}&bid=${bid}&uid=${ksUserId}&from=cilijianzhan`,
                );
            } else {
                message.warn('该用户无权限');
            }
        },
        [accountId, imUrl, ksUserId],
    );
    return openIm;
};

// 打开私信工作台设置页面
export const useOpenImSetting = () => {
    const { accountId, ksUserId } = usePlatformState((state: RootState) => {
        return {
            accountId: state.adUser?.lpUser?.accountId,
            ksUserId: state.adUser?.lpUser?.ksUserId,
        };
    });
    let imUrl = 'https://ad-im-pc.test.gifshow.com/admin/#/home';

    if (isProduction) {
        imUrl = 'https://adim.kuaishou.com/workbench#/setting/private-im';
    }
    if (isBeta) {
        imUrl = 'https://adim-beta.corp.kuaishou.com/workbench#/setting/private-im';
    }
    if (isStaging) {
        imUrl = 'https://adim.staging.kuaishou.com/workbench#/setting/private-im';
    }
    /**
     * @param {boolean} needCheckPrivateAuth - 判断跳转快聊是否需要拉起开通私信工作台的弹窗
     */
    const openIm = useCallback(
        async (needCheckPrivateAuth = false, bid = 103) => {
            let enableJumpToIM = true;
            try {
                await privateUserAuditUsingPOST(ksUserId);
            } catch (e) {
                enableJumpToIM = false;
            }
            if (enableJumpToIM) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                window.open(
                    `${imUrl}?aid=${accountId}&bid=${bid}&uid=${ksUserId}${
                        needCheckPrivateAuth ? '&checkPrivateAuth=1' : ''
                    }&from=cilijianzhan`,
                );
            } else {
                message.warn('该用户无权限');
            }
        },
        [accountId, imUrl, ksUserId],
    );
    return openIm;
};

// 打开私信工作台三方设置页面
export const useOpenImThirdPartySetting = () => {
    const { accountId, ksUserId } = usePlatformState((state: RootState) => {
        return {
            accountId: state.adUser?.lpUser?.accountId,
            ksUserId: state.adUser?.lpUser?.ksUserId,
        };
    });
    let imUrl = 'https://adim.kuaishou.com/workbench#/setting/externalCustomer';

    if (isProduction) {
        imUrl = 'https://adim.kuaishou.com/workbench#/setting/externalCustomer';
    }
    if (isBeta) {
        imUrl = 'https://adim-beta.corp.kuaishou.com/workbench#/setting/externalCustomer';
    }
    if (isStaging) {
        imUrl = 'https://adim.staging.kuaishou.com/workbench#/setting/externalCustomer';
    }
    /**
     * @param {boolean} needCheckPrivateAuth - 判断跳转快聊是否需要拉起开通私信工作台的弹窗
     */
    const openIm = useCallback(
        (bid = 31) => {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            window.open(`${imUrl}?aid=${accountId}&bid=${bid}&uid=${ksUserId}&from=cilijianzhan`);
        },
        [accountId, imUrl, ksUserId],
    );
    return openIm;
};

//线上：https://ad.e.kuaishou.com/capital/TalentEmpower?openAddUserIdModal=true&__accountId__=7869843
//beta: https://beta-ad-e.corp.kuaishou.com/capital/TalentEmpower?openAddUserIdModal=true&__accountId__=7869843
// staging:https://master--kuaishou-frontend-dsp.jinx.staging.kuaishou.com/capital/TalentEmpower?openAddUserIdModal=true&__accountId__=7869843
export const useOpenDSP = () => {
    const { accountId } = usePlatformState((state: RootState) => {
        return {
            accountId: state.adUser?.lpUser?.accountId,
            ksUserId: state.adUser?.lpUser?.ksUserId,
        };
    });
    let dspUrl = 'https://ad.e.kuaishou.com/capital/TalentEmpower?openAddUserIdModal=true';

    if (isProduction) {
        dspUrl = 'https://ad.e.kuaishou.com/capital/TalentEmpower?openAddUserIdModal=true';
    }
    if (isBeta) {
        dspUrl =
            'https://beta-ad-e.corp.kuaishou.com/capital/TalentEmpower?openAddUserIdModal=true';
    }
    if (isStaging) {
        dspUrl =
            'https://master--kuaishou-frontend-dsp.jinx.staging.kuaishou.com/capital/TalentEmpower?openAddUserIdModal=true';
    }
    const openDSP = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.open(`${dspUrl}&__accountId__=accountId`);
    }, [accountId]);
    return openDSP;
};

export const downloadFile = (url: string, fileName: string) => {
    const x = new XMLHttpRequest();
    x.open('GET', url, true);
    x.responseType = 'blob';
    x.onload = () => {
        const dnUrl = window.URL.createObjectURL(x.response);
        const a = document.createElement('a');
        a.href = dnUrl;
        a.download = fileName;
        a.click();
    };
    x.send();
};
