import axios, { AxiosResponse } from 'axios';
import { infoUsingPOST } from 'src/services/user-controller';
import { AD_E_HOST as adEHost } from 'src/utils/envUrl';
import { history } from 'src/utils/history';
import { Modal } from 'm-ui';
import * as sso from '@ks/sso';
import { AdFrontTrace } from '@ad/ad-front-trace';

export const perfLog = new AdFrontTrace({
    env: process.env.NODE_ENV as any,
    bizName: 'adFrontendOmega',
});
export async function login({ authToken, sid }: { authToken: string; sid: string }) {
    const res = await axios.post<any, any>(`/rest/infra/sts?authToken=${authToken}&sid=${sid}`);
    return res;
}

export async function refreshPassToken() {
    // 更新凭证
    const { authToken } = await sso.passToken();
    if (authToken) {
        await login({
            authToken: encodeURIComponent(authToken),
            sid: encodeURIComponent('kuaishou.ad.dsp'),
        });
    }
}

export const checkLogin = async (data: AxiosResponse['data']) => {
    const { result, url, error_msg: errMsg } = data;
    if (data && +result === 109) {
        try {
            await refreshPassToken();
            const res = await infoUsingPOST();
            if (res.result === 1) {
                window.location.reload();
            }
        } catch (e) {
            window.location.href = `${adEHost as string}/welcome?redirectUrl=${
                window.location.href
            }`;
        }
    } else if (result === 300001) {
        history.replace('/404/资质审核未通过，无法使用磁力建站');
    } // 如果是多账户 则跳到账户中心
    else if (result === 209 && url) {
        window.location.href = `${url as string}`;
    } else if (result === 52) {
        window.location.href = `${adEHost as string}/welcome?redirectUrl=${window.location.href}`;
        Modal.error({
            title: '请先开通权限',
            content: `错误原因： ${errMsg as string}`,
        });
    } else if (result === 302) {
        window.location.href = data;
    }
};

export const getAgentDspToken = async (): Promise<{ data: string; result: number }> => {
    const url = '/rest/ad/multi-agency/convert';
    return new Promise((res, rej) => {
        fetch(url)
            .then((data) => {
                return data.json();
            })
            .then((data) => {
                if (data.result !== 200) {
                    rej(data);
                    perfLog.perf({ subtag: 'API_ERROR', extra1: url, extra2: data.result });
                }
                res(data);
            })
            .catch((e) => {
                perfLog.perf({ subtag: 'API_ERROR', extra1: url, extra2: '-' });
                rej(e);
            });
    });
};

axios.interceptors.request.use(
    (req) => {
        (req as any).metadata = { startTime: Date.now() };
        req.withCredentials = true;
        req.timeout = 15000;
        return req;
    },
    async (err) => {
        return Promise.reject(err);
    },
);

axios.interceptors.response.use(
    (res: AxiosResponse) => {
        const { data, config } = res;
        const { result, msg } = data;
        const url = (config?.url ?? '').split('?')[0];
        let time: number = 0;
        if ((config as any)?.metadata?.startTime) {
            time = Date.now() - (config as any).metadata.startTime;
            perfLog.perf({ subtag: 'API_TIME', extra1: url, millis: time });
        }
        if (data && +result === 1) {
            return res;
        }
        perfLog.perf({ subtag: 'API_ERROR', extra1: url, extra2: result });
        perfLog.trace({
            api: config.url,
            apiCode: result,
            apiTime: Date.now(),
            message: msg,
        });
        // checkLogin(axios);
        return Promise.reject(data);
    },
    async (err) => {
        let time;
        if (err.metadata?.startTime) {
            time = Date.now() - err.metadata.startTime;
        }
        const url = (err?.config?.url || '').split('?')[0];
        perfLog.perf({ subtag: 'API_ERROR', extra1: url, extra2: '-', millis: time });
        perfLog.trace({ api: url, message: JSON.stringify(err) });
        return Promise.reject(err);
    },
);
export default axios;
