import { componentsForEach } from 'omega-render';
import { LINK_TYPE } from 'omega-shared/middlePageTrans';

/**
 * 处理包含预约下载的表单组件
 * 逻辑： 含有预约下载的表单，会增加一个下载的子组件，该组件不渲染，只增加信息存储（为了跟应用中心同步），且c端也是用改组件的内容进行预约下载
 * @param component
 */
export const handleFormAppointmentDownload = (component) => {
    componentsForEach(component, (com) => {
        if (com.type === 'XIANSUO_FORM_DRAG' && com.props?.isAppointment) {
            const appointmentInfo =
                com?.props?.formSetting?.submitTurn?.itemConfig?.appointmentInfo;
            com.components = com?.components ? com.components : [];
            com.components.push({
                id: '488137732813788473',
                version: '1.0.0',
                type: 'APP_DOWNLOAD_DRAG',
                props: {
                    hideCom: true,
                    appInfo: appointmentInfo?.appInfo,
                    deeplink: appointmentInfo?.deeplink,
                    appType: appointmentInfo?.appType,
                    linkType: LINK_TYPE.ANDROID,
                },
            });
        }
        return com;
    });
};
