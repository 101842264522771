/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as sso from '@ks/sso';
import Cookies from 'js-cookie';
// import * as Sentry from '@sentry/browser';
import { Modal, message } from 'm-ui';
import { useCallback, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

import { imLogin, imUserInfo, passTokenUsingGET } from 'src/landingPage/services/apis';
// import { isBeta, isProduction, isStaging } from 'omega-shared/utils';
import { RootState } from 'src/store';
import { history } from '../routes/config';
import { usePlatformActions, usePlatformState } from '../store/hooks';
import { login, UserInfo, userModel } from '../store/userModel';
import { getAgentDspToken } from 'src/request';
import { isBeta, isProduction, isStaging } from '@/utils/env';

export const AD_E_HOST = isProduction
    ? '//ad.e.kuaishou.com'
    : isBeta
      ? '//beta-ad-e.corp.kuaishou.com'
      : isStaging
        ? '//staging--kuaishou-frontend-dsp.jinx.staging.kuaishou.com'
        : '//master--kuaishou-frontend-dsp.jinx.corp.kuaishou.com';

export function initSSO() {
    sso.init({
        env: isProduction || isBeta ? 'production' : 'staging',
        sid: 'kuaishou.ad.dsp',
    });
}

// 请求IM中台接口，需要提前换取token
async function refreshImToken() {
    const sid = 'kuaishou.customer.service.im';
    if (isProduction || isBeta || isStaging) {
        const { authToken } = await sso.passToken(sid);

        // await axios.post(`/rest/infra/sts?authToken=${encodeURIComponent(authToken)}&sid=${encodeURIComponent(sid)}`);
        if (authToken) {
            await imLogin({
                authToken: encodeURIComponent(authToken),
                sid: encodeURIComponent(sid),
            });
        }
    }
}
export async function refreshPassToken() {
    // 更新凭证
    const { authToken } = await sso.passToken();
    if (authToken) {
        await login({
            authToken: encodeURIComponent(authToken),
            sid: encodeURIComponent('kuaishou.ad.dsp'),
        });
    } else {
        throw new Error('get authToken error');
    }
}
async function checkUserInfo(fetchUserInfo: () => Promise<UserInfo | undefined>) {
    try {
        let data = await fetchUserInfo();
        if (!data) {
            return;
        }
        const { userDspAccount, lpUser } = data;
        if (lpUser.dspCheck && userDspAccount.reviewStatus !== 2) {
            history.replace('/404/资质审核未通过，无法使用磁力建站');
        }
        // const userId = String(user.userId);

        // Sentry.configureScope((scope) => {
        //     scope.setUser({ id: userId });
        // });
    } catch (e) {
        // 如果没有登陆并且不是白名单路由就推向欢迎页
        if (e.result === 109) {
            try {
                await refreshPassToken();
                await checkUserInfo(fetchUserInfo);
            } catch (e) {
                window.location.href = `${AD_E_HOST}/welcome?redirectUrl=${window.location.href}`;
            }
            // } else if (e.result === 211) {
            //     // 代理商登陆态失效
            //     window.location.href = `${AD_E_HOST}/welcome?redirectUrl=${window.location.href}`;
        } else if (e.result === 300001) {
            history.replace('/404/资质审核未通过，无法使用磁力建站');
        } else if (e.result === 209) {
            // 如果是多账户 则跳到账户中心
            // window.location.href = `${e.url}`;
        } else if (e.result === 52) {
            window.location.href = `${AD_E_HOST}/welcome?redirectUrl=${window.location.href}`;
            Modal.error({
                title: '请先开通权限',
                content: `错误原因： ${e.error_msg}`,
            });
        } else if (e.result === 302) {
            window.location.href = e.data;
        } else {
            // tslint:disable-next-line:no-console
            //
            message.error('登录失败');
            // window.location.href = `${AD_E_HOST}/welcome?redirectUrl=${window.location.href}`;
        }
    }
}

interface UserProps extends RouteComponentProps {
    disable?: boolean;
}

export function useUser(props: UserProps) {
    const { disable } = props;
    const that = useRef({
        initializing: false,
    });

    const actions = usePlatformActions(userModel.actions);
    const { accountId, ksUserId, authCheckStrategyAgent } = usePlatformState((state: RootState) => {
        return {
            accountId: state.adUser?.lpUser?.accountId,
            ksUserId: state.adUser?.lpUser?.ksUserId,
            authCheckStrategyAgent: state.adUser?.userDspAccount?.authCheckStrategyAgent,
            // bizId: state.adUser?.lpUser?.bizId,
        };
    });
    const imChekcTimes = useRef(0);
    const checkImUser = useCallback(async () => {
        // let ifIMUser = false;
        try {
            // await getIMUser();
            if (accountId) {
                // const agent = Cookies.get('multi_agency_token');
                // const agent = Cookies.get('agent_dsp_token');
                // if (agent) {
                // const res = await getAgentDspToken();
                try {
                    if (authCheckStrategyAgent) {
                        const { data } = await getAgentDspToken();
                        await passTokenUsingGET({ agentTk: data });
                    }
                } catch (e) {
                    console.error('Get agent dsp token error', e);
                    // 没有升级后的multi_agency_token就用agent_dsp_token
                    const agent = Cookies.get('agent_dsp_token');
                    if (agent) {
                        await passTokenUsingGET({ agentTk: agent });
                    }
                }

                // await passTokenUsingGET({ agentTk: agent });
                // }
                await imUserInfo({
                    accountId,
                    ksUserId,
                });
                actions.setModelState({ imUser: true });
            }
            // ifIMUser = true;
        } catch (e) {
            //
            if (e.result === 404) {
                return;
            }
            // 暂时不判断109，全部重新登录
            try {
                if (imChekcTimes.current > 5) {
                    console.error('im refresh token fail');
                } else {
                    // setImCheckTimes(i => ++i);
                    imChekcTimes.current = imChekcTimes.current + 1;
                    await refreshImToken();
                    //
                    await checkImUser();
                }
            } catch (e) {
                // window.location.href = e.loginUrl;
            }
        }
        // actions.setModelState({ imUser: ifIMUser });
    }, [accountId, actions, imChekcTimes, ksUserId]);
    // const checkImUserRef = useRef()
    useEffect(() => {
        (async () => {
            if (disable) return;
            try {
                that.current.initializing = true;
                initSSO();
                await checkUserInfo(actions.initialize);
            } catch (e) {
                // tslint:disable-next-line:no-console
            } finally {
                that.current.initializing = false;
            }
            try {
                await checkImUser();
            } catch (err) {
                console.error(err);
            }
        })();
    }, [actions, checkImUser, disable]);
    useEffect(() => {
        if (disable) return;
        if (that.current.initializing) {
            return;
        }
        if (props.location.pathname === '/welcome') {
            return;
        }

        checkUserInfo(actions.initialize);
    }, [actions.initialize, disable, props.location.pathname]);
}
