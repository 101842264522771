import axios from '../request';
import * as model from './models';

/** 用户信息接口  记录新手引导状态 */
export async function saveUserGuidStatusUsingPOST(payload: { status?: string }) {
    const data = {
        status: payload.status,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/user/guid/status`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 用户信息接口  获取用户基本信息 */
export async function infoUsingPOST() {
    const result = await axios.request<model.IResponseobject>({
        url: `/rest/lp/user/info`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 用户信息接口  用户协议 */
export async function enableUserProtoUsingPOST() {
    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/user/proto/enable`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 用户信息接口  记录用户关闭模板活动弹窗的状态 */
export async function saveTemplateActivityStatusUsingPOST(payload: { req: model.IUserStatusReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/user/status/update`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}
