import { configureStore, Reducer } from '@reduxjs/toolkit';
import { editorSlice } from 'omega-editor';
import { pageListModel, PageListState } from 'src/pages/pageList/redux/pageListModel';
import { popupListModel, PopupState } from 'src/pages/popup/redux/popupListModel';
import { novelModel, DefaultState } from 'src/pages/novel/redux/novelModel';
import {
    groupListModel,
    GroupListState,
} from 'src/pages/programmaticList/redux/programmaticListModel';
import {
    multiGroupModel,
    MultiPageGroupState,
} from 'src/pages/programmaticMulti/redux/programmaticMultiModel';
import { userModel, UserState } from './userModel';
import { pageInfoSlice } from './pageInfo/model';
import { IImState, imPageModel } from 'src/pages/ImPage/redux';
import { telEditModal, TelEditState } from '@/pages/editor/componentEditor/TelDragEditor/redux';

export const store = configureStore({
    reducer: {
        editor: editorSlice.reducer,
        pageInfo: pageInfoSlice.reducer,
        // formPage: formPageModel.reducer,
        adUser: userModel.reducer as Reducer<UserState>,
        // pageEdit: pageEditReducer,
        pageList: pageListModel.reducer as Reducer<PageListState>,
        novel: novelModel.reducer as Reducer<DefaultState>,
        groupList: groupListModel.reducer as Reducer<GroupListState>,
        popupList: popupListModel.reducer as Reducer<PopupState>,
        multiGroup: multiGroupModel.reducer as Reducer<MultiPageGroupState>,
        imPageModel: imPageModel.reducer as Reducer<IImState>,
        telEditModal: telEditModal.reducer as Reducer<TelEditState>,
        // pageInfo:
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
