/* eslint-disable */
// @ts-ignore
import { init } from '@ad/uc';
import { createBrowserHistory } from 'history';
import { RouteConfig } from 'react-router-config';
import { Redirect } from 'react-router-dom';

import Page404 from 'src/pages/Page404';
import pageLoader from 'src/utils/pageLoader';
import { basePath } from 'omega-shared/utils';
import { AD_E_HOST } from 'src/utils/envUrl';
import { isProduction } from '@/utils/env';

export const history = createBrowserHistory({
    basename: basePath,
});
// 向外跳转要使用uc.jump。此外，这个库有副作用，劫持了xhr，fetch等方法
export const uc = init({
    env: isProduction ? 'production' : 'staging',
    appKey: 'ad.adUkmConfig.adLp',
    // appKey: 'ad.adUkmConfig.adDsp',
    sid: 'kuaishou.ad.dsp',
    // followUrl: `https:${AD_E_HOST}/welcome?redirectUrl=${window.location.href}`,
    interceptorUnLogged: {
        loginUrl: `https:${AD_E_HOST}/welcome?redirectUrl=${window.location.href}`,
        intercept109: false,
        intercept211: true,
    },
    history,
    interceptorAccount: {
        loginUrl: `https:${AD_E_HOST}/welcome?redirectUrl=${window.location.href}`,
        // 设置快聊域名黑名单
        blackList: [
            /https:\/\/ad-im-pc\.test\.gifshow\.com/,
            /https:\/\/adim\.kuaishou\.com\/pc/,
            /https:\/\/adim-beta\.corp\.kuaishou\.com/,
            /https:\/\/s2-11175\.kwimgs\.com/,
            /https:\/\/cdnfile\.corp\.kuaishou\.com/,
        ],
    },
});

export interface ExtendRouteConfig extends RouteConfig {
    // 是否隐藏页面骨架
    hideSkeleton?: boolean;
    // routes?: ExtendRouteConfig[];
}
export const routes: ExtendRouteConfig[] = [
    {
        path: '/pc-preview',
        exact: true,
        strict: true,
        hideSkeleton: true,
        disableUserInfo: true,
        component: pageLoader(async () => import('src/pages/PCPreview'), { title: '磁力建站' }),
    },
    // {
    //     path: '/market/form',
    //     exact: true,
    //     strict: true,
    //     component: pageLoader(() => import('./page/formPage'), { title: '表单' }),
    // },
    {
        path: '/mainGuide',
        exact: true,
        strict: true,
        component: pageLoader(async () => import('src/pages/mainGuide'), { title: '首页' }),
    },
    {
        path: '/mainGuide/messageCenter',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/mainGuide/msgList'), {
            title: '消息中心',
        }),
    },
    {
        path: '/page/list/createNewPage/novel',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/novel'), { title: '批量生成落地页' }),
    },
    {
        path: '/page/list/createNewPage/novel/stepOne',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/novel/components/stepOne'), {
            title: '批量生成落地页',
        }),
    },
    {
        path: '/mainGuide/novel/stepOne',
        exact: true,
        strict: true,
        component: () => (
            <Redirect to={`/page/list/createNewPage/novel/stepOne${window.location.search}`} />
        ),
    },
    {
        path: '/page/list/createNewPage/novel/stepTwo_select',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/novel/components/stepTwoSelect'), {
            title: '批量生成落地页',
        }),
    },
    {
        path: '/page/list/createNewPage/novel/stepTwo_editor',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/novel/components/stepTwoEditor'), {
            title: '批量生成落地页',
        }),
    },
    {
        path: '/page/list/createNewPage/novel/stepThree',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/novel/components/stepThree'), {
            title: '批量生成落地页',
        }),
    },
    {
        path: '/page/list/createNewPage/novel/stepFour',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/novel/components/stepFour'), {
            title: '批量生成落地页',
        }),
    },
    {
        path: '/page/list/createNewPage',
        exact: true,
        strict: true,
        component: pageLoader(async () => import('src/pages/pageList/SearchPage/createNewPage'), {
            title: '选择落地页类型',
        }),
        // component: pageList,
    },
    // {
    //     path: '/market/consult',
    //     exact: true,
    //     strict: true,
    //     component: () => <Redirect to="/market/consult/manage" />,
    // },
    // {
    //     path: '/market/consult',
    //     component: pageLoader(() => import('./page/consult'), { title: '咨询页' }),
    // },
    // {
    //     path: '/market/form/edit/:id',
    //     strict: true,
    //     component: pageLoader(() => import('./page/formPage/edit')),
    // },
    {
        path: '/page/list',
        exact: true,
        strict: true,
        component: pageLoader(async () => import('src/pages/pageList'), { title: '我的落地页' }),
        // component: pageList,
    },
    {
        path: '/page/list/trash',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/trash'), { title: '回收站' }),
    },
    {
        path: '/template',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/pageTemplate'), { title: '我的模板' }),
    },
    {
        path: '/page/list/createNewPage/halfPage',
        exact: true,
        strict: true,
        component: pageLoader(() => import('src/pages/halfPage'), {
            title: '半屏投放落地页-应用下载场景',
        }),
    },
    {
        path: '/page/list/createNewPage/storePage',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/storeTemplate'), { title: '门店落地页' }),
    },
    {
        path: '/page/imPage',
        exact: true,
        strict: true,
        hideSkeleton: true,
        component: pageLoader(() => import('../pages/ImPage'), { title: '咨询落地页' }),
    },
    {
        path: '/page/list/createNewPage/keywordPage',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/keywordTemplate'), { title: '关键词创建' }),
    },
    {
        path: '/page/aiCreatePage',
        exact: true,
        strict: true,
        hideSkeleton: true,
        component: pageLoader(() => import('../pages/aiCreatePage'), { title: 'AI落地页' }),
    },
    {
        path: '/im/create',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/imCreate'), { title: '快聊客服' }),
    },
    {
        path: '/page/edit',
        hideSkeleton: true,
        component: pageLoader(async () => import('../pages/editor'), { title: '编辑落地页' }),
    },
    {
        path: '/v3/page/edit',
        hideSkeleton: true,
        component: pageLoader(async () => import('../pages/editor'), { title: '编辑落地页' }),
    },
    {
        path: '/programmaticList',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/programmaticList'), { title: '程序化落地页' }),
    },
    {
        path: '/programmaticGroup',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/programmaticGroup'), {
            title: '编辑程序化落地页组',
        }),
    },
    {
        path: '/programmaticGroupEdit',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/programmaticGroup'), {
            title: '编辑程序化落地页组',
        }),
    },
    {
        path: '/programmaticTrash',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/programmaticTrash'), { title: '组回收站' }),
    },
    {
        path: '/programmaticMulti/stepOne',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/programmaticMulti/components/stepOne'), {
            title: '批量新建',
        }),
    },
    {
        path: '/programmaticMulti/stepTwo',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/programmaticMulti/components/stepTwo'), {
            title: '批量新建',
        }),
    },
    {
        path: '/programmaticMulti/stepThree',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/programmaticMulti/components/stepThree'), {
            title: '批量新建',
        }),
    },

    {
        path: '/popup',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/popup'), { title: '互动弹窗' }),
    },
    {
        path: '/popup/editPopup',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/popup/PopupInfo'), { title: '修改互动弹窗' }),
    },
    {
        path: '/popup/createPopup',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/popup/PopupInfo'), { title: '新建互动弹窗' }),
    },
    {
        path: '/',
        exact: true,
        strict: true,
        component: () => <Redirect to="/mainGuide" />,
    },
    {
        path: '/no-auth',
        hideSkeleton: true,
        component: pageLoader(() => import('../pages/NoAuth'), { title: '无权限页' }),
    },
    {
        path: '/404/:msg',
        component: Page404,
    },
    {
        path: '/coupon/consume',
        exact: true,
        strict: true,
        hideSkeleton: true,
        disableUserInfo: true,
        component: pageLoader(() => import('../pages/CouponConsume'), { title: '卡券核销' }),
    },
    {
        path: '/coupon/consumeResult',
        exact: true,
        strict: true,
        hideSkeleton: true,
        disableUserInfo: true,
        component: pageLoader(() => import('../pages/CouponConsume/ConsumeResult'), {
            title: '核销结果',
        }),
    },
    {
        path: '/report/manage',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/reportPage'), { title: '数据报表' }),
    },
    {
        path: '/report/funnelChart',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/funnelChart'), { title: '漏斗分析' }),
    },
    {
        path: '/report/heatmap',
        exact: true,
        strict: true,
        component: pageLoader(() => import('../pages/heatmap'), { title: '热力图' }),
    },
    {
        path: '/gameAudit',
        exact: true,
        strict: true,
        hideSkeleton: true,
        disableUserInfo: true,
        component: pageLoader(() => import('../pages/gameAudit'), { title: '跳转小游戏' }),
    },
    {
        path: '/performance/list',
        exact: true,
        strict: true,
        hideSkeleton: true,

        component: pageLoader(() => import('../pages/performance/List'), {
            title: '落地页性能检测',
        }),
    },
    {
        path: '/performance/detail',
        exact: true,
        strict: true,
        hideSkeleton: true,

        component: pageLoader(() => import('../pages/performance/Detail'), {
            title: '落地页性能检测详情',
        }),
    },
    {
        path: '/quality/list',
        exact: true,
        strict: true,
        // hideSkeleton: true,

        component: pageLoader(() => import('../pages/quality/List'), {
            title: '落地页质量检测',
        }),
    },
    {
        path: '/quality/detail',
        exact: true,
        strict: true,
        // hideSkeleton: true,

        component: pageLoader(() => import('../pages/quality/Detail'), {
            title: '落地页质量检测详情',
        }),
    },
    {
        path: '/qywx/page',
        exact: true,
        strict: true,
        hideSkeleton: true,
        disableUserInfo: true,
        component: pageLoader(async () => import('src/pages/LongPressQW'), { title: '企微中间页' }),
    },
    // {
    //     path: '/m',
    //     exact: true,
    //     strict: true,
    //     component: pageLoader(() => import('../pages/test/Test'), { title: '漏斗分析' }),
    // },
];

//暂时给测试环境用的预览
if (!isProduction) {
    // eslint-disable-next-line global-require
    const { PageNoIFrame } = require('../pages/test/Test');
    // eslint-disable-next-line global-require
    const { Upgrade } = require('../pages/test/Upgrade');
    routes.push({
        path: '/m',
        component: PageNoIFrame,
        hideSkeleton: true,
        exact: true,
    });
    routes.push({
        path: '/m',
        component: PageNoIFrame,
        hideSkeleton: true,
        exact: true,
    });
    routes.push({
        path: '/test/upgrade',
        component: Upgrade,
        hideSkeleton: true,
        exact: true,
        disableUserInfo: true,
    });
}
