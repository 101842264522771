import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Popover } from 'm-ui';
// import './UserGuide.css';
import styles from './UserGuide.module.less';
import { SvgMask } from 'src/components/UserGuide/styles';
import dayjs from 'dayjs';

interface GuidePortalProps {
    target: HTMLElement | any;
    onClose: () => void;
    borderRadius?: number;
    dataKey?: string;
    opacity?: number;
    useCoolDown?: boolean;
    showLater?: boolean;
}
interface Position {
    top: number;
    left: number;
    width: number;
    height: number;
}

// const guideTimeKey = 'guideTimeKey';
export const ONLINE_TIME = '2023-09-25';
export const lastGuideTimeKey = 'lastGuideTimeKey';
export const showGuideKey = 'showGuideKey';

const GuidePortal: React.FC<GuidePortalProps> = (props) => {
    const {
        dataKey = 'list',
        opacity = 0.45,
        target,
        onClose,
        useCoolDown = true,
        showLater = true,
    } = props;
    const [position, setPosition] = useState<Position | null>(null);
    useEffect(() => {
        if (target) {
            const rect = target.getBoundingClientRect();
            // console.log('rect', rect, targetRef.current);
            setPosition({
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height,
            });
        }
    }, [target]);
    const generatePath = (position?: Position | null, borderRadius?: number): string => {
        if (!position || !borderRadius) return '';
        const { left, top, width, height } = position;

        return `
            M${window.innerWidth},0 
            L${window.innerWidth},${window.innerHeight} 
            L0,${window.innerHeight} 
            L0,0 
            L${window.innerWidth},0 
            Z
            M${left + width},${top} 
            L${left + borderRadius},${top}
            C${left + borderRadius},${top} ${left},${top} ${left},${top + borderRadius}
            L${left},${top + height - borderRadius} 
            C${left},${top + height - borderRadius} ${left},${top + height} ${
                left + borderRadius
            },${top + height}
            L${left + width - borderRadius},${top + height} 
            C${left + width - borderRadius},${top + height} ${left + width},${top + height} ${
                left + width
            },${top + height - borderRadius}
            L${left + width},${top + borderRadius} 
            C${left + width},${top + borderRadius} ${left + width},${top} ${
                left + width - borderRadius
            },${top}
            Z
        `;
    };

    // 使用方法：
    const path = generatePath(position, 4);
    // const borderRadius = 4; // Assuming 4px border radius
    const iKnow = () => {
        if (useCoolDown) {
            localStorage.setItem(`${lastGuideTimeKey}-${dataKey}`, dayjs().format('YYYY-MM-DD'));
            localStorage.setItem(`${showGuideKey}-${dataKey}`, 'false');
        }

        onClose();
    };
    const nextTime = () => {
        if (useCoolDown) {
            localStorage.setItem(`${lastGuideTimeKey}-${dataKey}`, dayjs().format('YYYY-MM-DD'));
            localStorage.setItem(`${showGuideKey}-${dataKey}`, 'true');
        }
        onClose();
    };
    if (!position) return null;

    return (
        <div className={styles.guideContainer}>
            <SvgMask
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    zIndex: 10,
                }}
            >
                <g
                    id="帮助"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    opacity={opacity}
                >
                    <g id="编组-9" fill="#000000">
                        <path d={path} />
                    </g>
                </g>
            </SvgMask>
            <Popover
                // title="新功能介绍"
                visible={true}
                content={
                    <div className={styles.popoverContent}>
                        <div className={styles.popoverTitle}>质量检测新增入口啦</div>
                        <div>使用质量检测查看落地页评分，帮您提升转化率！</div>
                        <div className={styles.btnGroup}>
                            {showLater && (
                                <span
                                    className={styles.later}
                                    onClick={nextTime}
                                    // onClick=
                                >
                                    稍后了解
                                </span>
                            )}

                            <Button type="primary" style={{ marginLeft: '12px' }} onClick={iKnow}>
                                知道啦
                            </Button>
                        </div>
                    </div>
                }
                placement="leftTop"
                getPopupContainer={() => {
                    return document.querySelector('.' + styles.guideContainer) as HTMLElement;
                }}
            >
                <div
                    style={{ ...position, position: 'absolute', backgroundColor: 'transparent' }}
                ></div>
            </Popover>
        </div>
    );
};

// 禁止滚动
function disableScroll() {
    document.body.style.overflow = 'hidden';
}

// 允许滚动
function enableScroll() {
    document.body.style.overflow = '';
}

export const Guide = {
    show: (props: Omit<GuidePortalProps, 'onClose'>) => {
        const pre = document.querySelector('.' + styles.wrapper);
        if (pre) {
            ReactDOM.unmountComponentAtNode(pre);
            document.body.removeChild(pre);
        }
        const div = document.createElement('div');
        div.className = styles.wrapper;
        document.body.append(div);
        if (props.target) {
            props.target.scrollIntoView({
                block: 'center',
                inline: 'center',
            });
        }
        disableScroll();

        const onClose = () => {
            enableScroll();
            ReactDOM.unmountComponentAtNode(div);
            document.body.removeChild(div);
        };

        ReactDOM.render(<GuidePortal {...props} onClose={onClose} />, div);
    },
};

export default Guide;
