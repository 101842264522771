import axios from 'axios';

// import { infoUsingPOST } from 'src/services/apis';
import { createModel } from 'rt-model';
import { RootState } from 'src/store';
import produce from 'immer';
// import { usePlatformState } from 'src/store/hooks';
// import { BizId } from 'src/landingPage/common/enums';
import { infoUsingPOST } from 'src/services/user-controller';
import { imUserInfo } from 'landingPage/services/apis';
// import { imUserInfo } from 'src/services/apis';

export async function login({ authToken, sid }: { authToken: string; sid: string }) {
    // 这个不加 basePath
    const res = await axios
        .create()
        .post<any, any>(`/rest/infra/sts?authToken=${authToken}&sid=${sid}`);
    console.log('login res', res);
    return res;
}

export interface User {
    userId: number;
    userName: string;
    headUrl: string;
}

type Tabs = { name: string; children?: Tabs; subMenu?: Tabs }[];

export interface UserInfo {
    userDspAccount: {
        accountType: number;
        accountDetailType: number;
        accountUcId: {
            accountId: number;
            userId: number;
            accountType: number;
            accountDetailType: number;
            reviewStatus: number;
        };
        reviewStatus: number;
        /** 是否是游戏表单用户，1代表是 */
        primaryIndustry: number;
        /** 是否展示过新手引导 */
        pageGuideStatus: boolean;
        /** 营业执照 */
        accountLicenseNo: string;
        /** 是否展示过模板大赛 */
        templateActivityStatus?: number;
        corporationName?: string;
        productName?: string;
        /** 是否附身登陆 */
        authCheckStrategyAgent?: boolean;
        /**  是否显示私信气泡 0 显示 1 关闭 */
        customerPageGuideStatus?: 0 | 1;
        /** 私信新标识， 0 显示 1 关闭 */
        privateLetterNewFlag?: 0 | 1;
        /** 二级行业id */
        secondIndustry?: number;
        /** 账户类型 */
        appId?: number;
    };
    lpUser: {
        accountId: number;
        clueAccountId: number;
        ksUserId: number;
        /* 是否可以重新审核编辑 */
        enableReEdit?: number;
        /** 是否展示模板tab */
        templateTabShowUser: boolean;
        /** 落地页组编辑白名单 */
        groupPageUpdate?: boolean;
        halfPageUser: boolean;
        /** 是否展示发布按钮 */
        noPublishPages: boolean;
        /** 是否是模板制作用户，不是广告主 */
        templateUser: boolean;
        phone: string;
        status: number;
        tabs?: Tabs;
        /** 是否同意过用户协议 */
        enableUserProto?: boolean;
        // bizId?: BizId;
        /** 是否是两个标题的用户 */
        twoTitlesUser?: boolean;
        /** 是否添加应用的时候增加隐私链接 */
        appPrivacyUrlUser?: boolean;
        /** 是否展示批量创建入口 */
        fictionBatchCreatePage?: boolean;
        /* 是否校验用户通过了DSP的资质审核 */
        dspCheck: boolean;
        enableWechatApplets: boolean; // 能否进入小程序
        enableWechatScanCode: boolean; // 能否扫码
        optimizationPageUser: boolean; // 优选组件白名单用户
        /** 是否新门店用户 */
        newStoreUser: boolean;
        popupUser: boolean; // 互动弹窗白名单用户
        /** 是否是微信小游戏跳转开白用户 */
        miniGameJumpButtonUser: boolean;
        /** 是否是新编辑器白名单用户 */
        editorUser: boolean;
        /** 是否PSD导入白名单用户 */
        psdParseUser: boolean;
        /** 是否为商品库用户 */
        fictionProductLibraryUser: boolean;
        /** 是否是小游戏直调客户 */
        wechatGameDirectCallUser?: boolean;
        /** 是否是H5半屏落地页白名单 */
        pageHalfScreen?: boolean;
        /** 是否是快应用黑名单 */
        quickAppBlackList?: boolean;
        /** OPPO小游戏白名单 */
        oppoMiniGameButtonUser?: boolean;
        /** QQ小游戏白名单 */
        qqMiniGameButtonUser?: boolean;
        /** cid电商白名单 */
        cidUser?: boolean;
        /** 快应用直调白名单 */
        quickAppDirectCallUser?: boolean;
        /** 瑞虎需求定制——展示底部悬浮表单 & 图片弹窗 */
        picturePopupAndFormStyleUser?: boolean;
        /** 关键词创建白名单 */
        keyWordCreatePageStatus?: boolean;
        /** 商户信息组件白名单 */
        corporationElementUser?: boolean;
        /** 小游戏下载组件样式优化白名单 */
        weChatGameAndDownloadUpgradeUser?: boolean;
        /** 表单跳转白名单 */
        formPopupLinkUser?: boolean;
        /** 组件新样式白名单 */
        elementNewStyle?: boolean;
        /** 企微加粉白名单 */
        qywxWechatSalePkgUser?: boolean;
        /**是否展示应用中心 */
        showNewApplicationCenter?: boolean;
        /**是否隐藏旧版应用列表 */
        hideOldApplicaitonCenter?: boolean;
        /**预约下载用户 */
        appointmentDownLoad?: boolean;
        /** 性能检测平台白名单 */
        pagePerformanceAuth?: boolean;
        /** 企微获客助手 */
        qywxCustomerAcquisitionUser?: boolean;
        /** 质量检测平台白名单 */
        qualityUser?: boolean;
        /** 咨询组件样式优化白名单 */
        consultCompStyleOptUser?: boolean;
        /** 一键生图白名单 */
        aigcImageUser?: boolean;
        /** 应用直调白名单 */
        appMarketDirect?: boolean;
        /**获客助手组 */
        qywxCustomerAcquisitionGroupUser?: boolean;
        /** 企微黑名单 */
        qywxBlackList?: boolean;
        bannedUseUser: boolean;
        /** cdn中台上传白名单 */
        securityFileUpload?: boolean;
        /** 对话表单嵌入式样式能力白名单 */
        chatFormStyleCheck?: boolean;
        /** 咨询页白名单 */
        privateLetterUser?: boolean;
        /**企微直跳白名单 */
        qywxDirectCall?: boolean;
        /** 开启微信小游戏挽留弹窗 */
        enableWechatStayComponent?: boolean;
        /** 开启微信小游戏热区样式 */
        enableHotZone?: boolean;
        /** 批量转赠白名单 */
        batchGiveUser?: boolean;
        /** 原生私信咨询白名单 */
        nativePrivateLetterUser?: boolean;
        /** ai智能创建落地页 */
        aiGenerateLp?: boolean;
        /** 咨询链路切换原生链路-增量 白名单 */
        changeImPath?: boolean;
        /** 判断点击侧边栏的快聊客服跳转身份，为true就是通用工作台身份，bid传31 */
        jumpAdImBiz?: boolean;
        /** 三方迁移私信 白名单 */
        thirdAppMoveSixin?: boolean;
        /** 表单支持一键留资白名单 */
        autoFillFormUser?: boolean;
        /** 按钮组件小游戏下线 白名单 */
        miniGameJumpButtonOfflineUser?: boolean;
        /** 是否展示推荐模块 */
        modelCommond?: boolean;
        /** 是否展示获客链接直跳开关 */
        customerAcqDirectJumpBlackList?: boolean;
        /** button cid 1500长度链接白名单， */
        releaseCidLength?: boolean;
        /** 是否开启鸿蒙能力 */
        harmonyOpen?: boolean;
        /** 是否使用私信用户选择新方案（都可以选，自动开通私信权限） */
        lpPrivateLetterOpt?: boolean;
    };
    user: {
        userId: number;
        userName: string;
        headUrl: string;
    };
}

export type UserState = {
    agreement: {
        show?: boolean;
    };
    imUser?: boolean;
    userGuideVisible: boolean;
} & Partial<UserInfo>;
export const userModel = createModel({
    namespace: 'adUser',
    defaultState: {
        agreement: {},
    } as UserState,
    reducers: {
        agreed(state) {
            return produce(state, (draftState) => {
                if (draftState.lpUser) {
                    draftState.lpUser.enableUserProto = true;
                }
            });
        },
    },
    effects: {
        initialize() {
            return async (dispatch, getState) => {
                const adUser = (await infoUsingPOST()).data as UserState;
                // 如果是高危建站用户，则展示无权限页面
                if (adUser.lpUser?.bannedUseUser && !location.pathname.includes('no-auth')) {
                    location.href = '/no-auth';
                    return;
                }
                // 是否免资质校验白名单
                const dspCheck = adUser.lpUser?.dspCheck;
                const { show } = (getState() as RootState).adUser.agreement;
                // 资质审核通过与否
                const approved = adUser?.userDspAccount?.reviewStatus === 2;
                // show是undefined，就赋一个初始值
                if (show == null) {
                    adUser.agreement = {
                        show: !adUser.lpUser?.enableUserProto && (dspCheck ? approved : true),
                    };
                }
                window.MOLI_USER = adUser;
                dispatch(userModel.actions.setModelState(adUser));

                return adUser as UserInfo;
            };
        },
        getIMUser() {
            return async (dispatch, getState) => {
                let ifIMUser = false;
                const accountId = (getState() as RootState).adUser?.lpUser?.accountId;
                const ksUserId = (getState() as RootState).adUser?.lpUser?.ksUserId;
                try {
                    await imUserInfo({
                        accountId,
                        ksUserId,
                    });
                    ifIMUser = true;
                } catch (err) {
                    console.warn(err);
                }
                dispatch(userModel.actions.setModelState({ imUser: ifIMUser }));
            };
        },
    },
});
