/**
 * 从url中获取userId
 */
let userId = '';
export function getUserId() {
    if (!userId.length) {
        const search = new URLSearchParams(window.location.search);
        userId = search.get('__accountId__') ?? '';
    }
    return userId;
}
