export const data = {
    type: 9,
    pageTitle: '',
    components: [
        {
            id: '687457588158179955',
            encryptedId: '',
            type: 'BLANK',
            version: '1.0.0',
            props: {
                style: {
                    height: 132,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    backgroundImage: '',
                },
                activeOption: 'color',
            },
            magic: {
                editHeight: 132,
                fontList: [],
            },
            components: [
                {
                    id: '687457588162472563',
                    encryptedId: '',
                    type: 'DRAG_IMAGE',
                    version: '1.0.0',
                    props: {
                        linkType: 1,
                        className: 'select',
                        type: 'single',
                        imgUrl: 'appIconUrl',
                        id: 'a05f3c1d-acd4-47a2-bc0d-e71c61b2f8b0',
                        style: {
                            borderRadius: 16,
                            borderWidth: 1,
                            borderColor: '#EAEAEA',
                            borderStyle: 'solid',
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 19,
                            y: 24,
                            width: 88,
                            height: 88,
                            excludeAnchor: ['left', 'right', 'top', 'bottom'],
                            holdRatio: true,
                            ratio: 1,
                            moving: false,
                        },
                    },
                    components: [],
                },
                {
                    id: '687457588174916211',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: 'appName',
                        contentType: 'normal',
                        style: {
                            textAlign: 'left',
                            fontSize: 20,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(34,34,34,1)',
                            letterSpacing: 0,
                            lineHeight: 1.4,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontWeight: 500,
                        },
                        singleText: 'appName',
                    },
                    magic: {
                        dragFree: {
                            x: 123,
                            y: 28,
                            width: 233,
                            autoHeight: true,
                            moving: false,
                            height: 28,
                        },
                    },
                    components: [],
                },
                {
                    id: '687457588179266163',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: 'appFlexLabel',
                        contentType: 'normal',
                        displayType: 'flex',
                        style: {
                            textAlign: 'center',
                            fontSize: 11,
                            backgroundColor: '#EFEFEF',
                            color: 'rgba(102,102,102,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                            borderRadius: 4,
                            paddingTop: 2,
                            paddingBottom: 2,
                            paddingLeft: 4,
                            paddingRight: 4,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            marginRight: 4,
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 123,
                            y: 88,
                            width: 236,
                            autoHeight: true,
                            moving: false,
                            height: 18,
                        },
                    },
                    components: [],
                },
                {
                    id: '687457588196035187',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: 'description',
                        contentType: 'normal',
                        style: {
                            textAlign: 'left',
                            fontSize: 12,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(102,102,102,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            lineClamp: 1,
                        },
                        themeConfig: {
                            'style.color': {
                                status: false,
                            },
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 123,
                            y: 60,
                            width: 218,
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
            ],
        },
        {
            id: '687489150882966131',
            encryptedId: '',
            type: 'BLANK',
            version: '1.0.0',
            props: {
                style: {
                    height: 60,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    backgroundImage: '',
                },
                activeOption: 'color',
                themeConfig: {
                    'style.backgroundColor': {
                        status: false,
                    },
                },
            },
            magic: {
                editHeight: 78,
            },
            components: [
                {
                    id: '687489150792338355',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: '',
                        singleText: '',
                        contentType: 'normal',
                        style: {
                            background: '#E0E0E0',
                            height: 40,
                        },
                        themeConfig: {},
                    },
                    magic: {
                        dragFree: {
                            x: 134,
                            y: 10,
                            width: 0.5,
                            height: 40,
                            background: 'red',
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
                {
                    id: '687498150792338355',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: '',
                        singleText: '',
                        contentType: 'normal',
                        style: {
                            background: '#E0E0E0',
                            height: 40,
                        },
                        themeConfig: {},
                    },
                    magic: {
                        dragFree: {
                            x: 244,
                            y: 10,
                            width: 0.5,
                            height: 40,
                            background: 'red',
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
                {
                    id: '687489150891338355',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: 'appUserInterest',
                        singleText: 'appUserInterest',
                        contentType: 'normal',
                        style: {
                            textAlign: 'center',
                            fontSize: 17,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(34,34,34,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                            fontWeight: 500,
                        },
                        themeConfig: {
                            'style.color': {
                                status: false,
                            },
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 35,
                            y: 3,
                            width: 88,
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
                {
                    id: '687489150899849843',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: 'appUserAttention',
                        singleText: 'appUserAttention',
                        contentType: 'normal',
                        style: {
                            textAlign: 'center',
                            fontSize: 17,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(34,34,34,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                            fontWeight: 500,
                        },
                        themeConfig: {
                            'style.color': {
                                status: false,
                            },
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 146,
                            y: 3,
                            width: 88,
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
                {
                    id: '687489150903921267',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: 'packageSize',
                        contentType: 'normal',
                        style: {
                            textAlign: 'center',
                            fontSize: 17,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(34,34,34,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                            fontWeight: 500,
                        },
                        themeConfig: {
                            'style.color': {
                                status: false,
                            },
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 256,
                            y: 3,
                            width: 88,
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
                {
                    id: '687489150908115571',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: '用户感兴趣',
                        singleText: '用户感兴趣',
                        contentType: 'normal',
                        style: {
                            textAlign: 'center',
                            fontSize: 12,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(102,102,102,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                        },
                        themeConfig: {
                            'style.color': {
                                status: false,
                            },
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 35,
                            y: 29,
                            width: 88,
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
                {
                    id: '687489150916659827',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: '用户已关注',
                        singleText: '用户已关注',
                        contentType: 'normal',
                        style: {
                            textAlign: 'center',
                            fontSize: 12,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(102,102,102,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                        },
                        themeConfig: {
                            'style.color': {
                                status: false,
                            },
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 146,
                            y: 29,
                            width: 88,
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
                {
                    id: '687489150920731251',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: '大小',
                        singleText: '大小',
                        contentType: 'normal',
                        style: {
                            textAlign: 'center',
                            fontSize: 12,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(102,102,102,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                        },
                        themeConfig: {
                            'style.color': {
                                status: false,
                            },
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 256,
                            y: 29,
                            width: 88,
                            autoHeight: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
            ],
        },
        {
            id: '687457588254640654',
            encryptedId: '',
            type: 'BLANK',
            version: '1.0.0',
            props: {
                style: {
                    height: 62,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    backgroundImage: '',
                },
                activeOption: 'color',
            },
            magic: {
                editHeight: 62,
            },
            components: [
                {
                    id: '687457588258974321',
                    encryptedId: '',
                    type: 'DRAG_MARQUEE',
                    version: '1.0.0',
                    props: {
                        dataList: 'appUserRecord',
                        wrapperStyle: {
                            height: 24,
                            boxSizing: 'border-box',
                        },
                    },
                    components: [],
                },
            ],
        },
        {
            id: '687457588254640755',
            encryptedId: '',
            type: 'BLANK',
            version: '1.0.0',
            props: {
                style: {
                    height: 295,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    backgroundImage: '',
                    marginTop: 2,
                },
                activeOption: 'color',
            },
            magic: {
                editHeight: 297,
            },
            components: [
                {
                    id: '687457588258974323',
                    encryptedId: '',
                    type: 'DRAG_TEXT',
                    version: '1.0.0',
                    props: {
                        text: '产品介绍',
                        singleText: '产品介绍',
                        contentType: 'normal',
                        style: {
                            textAlign: 'left',
                            fontSize: 17,
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: 'rgba(34,34,34,1)',
                            letterSpacing: 0,
                            lineHeight: 1.5,
                            fontWeight: 500,
                        },
                    },
                    magic: {
                        dragFree: {
                            x: 19,
                            y: 0,
                            width: 88,
                            autoHeight: true,
                            moving: false,
                            height: 26,
                        },
                    },
                    components: [],
                },
                {
                    id: '687457588267207283',
                    encryptedId: '',
                    type: 'DRAG_IMAGE',
                    version: '1.0.0',
                    props: {
                        linkType: 1,
                        className: 'select',
                        type: 'multi',
                        mutiInfo: [
                            {
                                linkType: 1,
                                imgUrl: 'appDetailImage',
                                urlType: 'NORMAL',
                                targetUrl: '',
                                style: {
                                    borderRadius: 8,
                                },
                            },
                            {
                                linkType: 1,
                                imgUrl: 'appDetailImage',
                                urlType: 'NORMAL',
                                targetUrl: '',
                                style: {
                                    borderRadius: 8,
                                },
                            },
                            {
                                linkType: 1,
                                imgUrl: 'appDetailImage',
                                urlType: 'NORMAL',
                                targetUrl: '',
                                style: {
                                    borderRadius: 8,
                                },
                            },
                        ],
                        imgRatio: '9:16',
                    },
                    magic: {
                        dragFree: {
                            x: 0,
                            y: 36,
                            width: 375,
                            height: 259,
                            excludeAnchor: ['left', 'right', 'top', 'bottom'],
                            holdRatio: true,
                            moving: false,
                        },
                    },
                    components: [],
                },
            ],
        },
        {
            id: '687457188279904883',
            encryptedId: '',
            type: 'COLLAPSE_TEXT',
            version: '0.0.1',
            props: {
                text: 'appDescription',
                contentType: 'normal',
                style: {
                    textAlign: 'left',
                    fontSize: 15,
                    backgroundColor: 'rgba(0,0,0,0)',
                    color: '#666666',
                    letterSpacing: 0,
                    lineHeight: 1.5,
                    paddingTop: 12,
                    paddingLeft: 19,
                },
                collapseTextColor: '#385080',
            },
            magic: {},
            components: [],
        },
        {
            id: '687457588313459315',
            encryptedId: '',
            type: 'BLANK',
            version: '1.0.0',
            props: {
                style: {
                    height: 56,
                    background: '#ffffff',
                    borderTop: 1,
                    borderTopColor: 'red',
                    borderTopStyle: 'solid',
                },
                activeOption: 'color',
            },
            magic: {
                bottomBlank: true,
                editHeight: 56,
            },
            components: [
                {
                    id: '687457588321864307',
                    encryptedId: '',
                    type: 'APP_DOWNLOAD_DRAG',
                    version: '1.0.0',
                    props: {
                        index: 0,
                        text: '立即下载',
                        buttonStyle: {
                            fontSize: 15,
                            color: '#fff',
                            backgroundColor: '#FE3666',
                            borderRadius: 8,
                            background: '#FE3666',
                            iconColor: '#fff',
                            wrapBackground: '#ccc',
                            fontWeight: 500,
                        },
                        targetType: 'deepLink',
                        dynamicStatus: 2,
                        jumpType: 0,
                        formProps: {
                            showLabel: 'show',
                            theme: '#3089FF',
                            titleStyle: {
                                color: '#595C69',
                            },
                            formStyle: {
                                backgroundColor: '#fff',
                            },
                            formType: 0,
                            inputStyle: {
                                backgroundColor: '#fff',
                                borderColor: '#E0E0E0',
                            },
                            placeholderColor: '#C6C6C6',
                        },
                        linkType: 3,
                        buttonInfo: '立即下载',
                        appName: '应用名称',
                        appDesc: '填写描述文案，大小、版本等',
                        appNameStyle: {
                            fontSize: 16,
                            color: '#222',
                            fontWeight: 500,
                        },
                        appDescStyle: {
                            fontSize: 14,
                            color: '#666',
                            fontWeight: 400,
                        },
                        imgLink: '',
                        position: 'base',
                        btnSetting: {
                            fontMax: 16,
                            fontMin: 12,
                        },
                        themeConfig: {
                            'buttonStyle.backgroundColor': {
                                status: true,
                            },
                        },
                        isAppointmentUser: true,
                        appType: 'app',
                        appInfo: {
                            appId: 1314280550,
                            accountId: 900015071,
                            appVersion: '3.3.3',
                            appName: 'Test-Channel',
                            packageName: 'com.ym.test.quickchannel',
                            packageSize: '0',
                            url: 'https://mkt.4399sy.com/api/download/do?app_id=MuTBYh2Z&pid=1374740944131310&cid=270&aid=159457&oid=99992.apk',
                            appIconUrl:
                                'http://blobstore-nginx.staging.kuaishou.com/udata/pkg/09030b444e0d41b4875732d18ab18832.jpg',
                            h5App: false,
                            platform: 'android',
                            virusScore: 0,
                            updateTime: 1659930813263,
                            date: 1659888000000,
                            appScore: 0,
                            useSdk: false,
                            downloadCount: 0,
                            appPrivacyUrl: 'https://666.com',
                            scanStatus: 5,
                        },
                        iOSLink: '',
                    },
                    magic: {
                        dragFree: {
                            x: 19,
                            y: 10,
                            width: 336,
                            height: 40,
                            holdRatio: false,
                        },
                        globalDragFree: {
                            right: 11,
                            top: 400,
                            width: 64,
                            height: 70,
                            includeAnchor: [],
                            holdRatio: true,
                        },
                        minWidth: 80,
                        maxWidth: 375,
                        minHeight: 32,
                        maxHeight: 72,
                    },
                    components: [],
                },
            ],
        },
    ],
    bizId: 0,
    pageMeta: {},
    pageConversionType: null,
};
