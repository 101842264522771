import axios from '../request';
import * as model from './models';

/** 小说相关接口  批量创建小说模板 */
export async function batchCreateFictionPageUsingPOST(payload: {
    req: model.IPageBatchGenerateReq;
}) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseListFictionPageView>({
        url: `/rest/lp/fiction/create/batch`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 小说相关接口  小说列表 */
export async function fictionListUsingPOST(payload: { req: model.IFictionQueryRequest }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseListFictionView>({
        url: `/rest/lp/fiction/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 小说相关接口  批量保存小说模板 */
export async function batchSaveFictionPageUsingPOST(payload: { req: model.IBatchSaveFictionReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseListstring>({
        url: `/rest/lp/fiction/save/batch`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 小说相关接口  获取商品库列表 */
export async function productLibraryListPOST(payload: { req }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseLibraryList>({
        url: `/rest/lp/product/library/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}
