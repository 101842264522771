import React, { ReactNode } from 'react';
import { Table } from 'm-ui';
import cs from 'classnames';
import styles from './style.module.less';
import { deletedGroupListData, groupListData } from './redux/programmaticListModel';
import expand from './img/willExpand.png';
import { columnsProps } from './index';

interface ContentProps {
    columns: columnsProps[];
    loading: boolean;
    dataSource: groupListData[] | deletedGroupListData[];
    expandedRowRender: (groupRecord: any) => ReactNode;
    parentTabRef: React.RefObject<HTMLTableElement>;
}

const Content: React.FC<ContentProps> = (props) => {
    const { columns, loading, dataSource, expandedRowRender, parentTabRef } = props;

    return (
        <div
            className={styles.content}
            onScroll={() => {
                onTableScroll({ tableRef: parentTabRef });
            }}
            ref={parentTabRef}
        >
            <Table
                bordered={false}
                showHeader
                columns={columns}
                pagination={false}
                loading={loading}
                dataSource={dataSource}
                scroll={{ x: true }}
                expandable={{
                    expandIcon: ({ expanded, onExpand, record }) => {
                        return (
                            <img
                                src={expand}
                                className={cs(styles.foldIcon, {
                                    [styles.expandIcon]: expanded,
                                })}
                                alt=""
                                onClick={(e) => onExpand(record, e)}
                            />
                        );
                    },
                    expandedRowRender,
                }}
            />
        </div>
    );
};

/**  控制父子表格同步滚动 */
export const onTableScroll = (param: {
    groupId?: string;
    tableRef: React.RefObject<HTMLTableElement>;
}) => {
    const { groupId, tableRef } = param;
    /** 子表格list */
    const subTableAll = document.querySelectorAll('.subTable');
    const parentTab = tableRef?.current?.querySelector('.ant-table-content');
    if (groupId) {
        const curScrollTab = document.getElementById(`${groupId}`);
        const scrollLeft = curScrollTab?.querySelector('.ant-table-content')?.scrollLeft;
        parentTab?.scrollTo(scrollLeft ?? 0, 0);
        subTableAll.forEach((item) => {
            item.querySelector('.ant-table-content')?.scrollTo(scrollLeft ?? 0, 0);
        });
    } else {
        const scrollLeft = parentTab?.scrollLeft;

        subTableAll.forEach((item) => {
            item.querySelector('.ant-table-content')?.scrollTo(scrollLeft ?? 0, 0);
        });
    }
};

export default Content;
