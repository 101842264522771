import { createModel } from 'rt-model';
import {
    listGroupUsingPOST,
    getReportUsingPOST,
    deleteGroupUsingPOST,
    listDeletedGroupUsingPOST,
    switchPageUsingPOST,
    restoreGroupUsingPOST,
} from 'src/services/page-group-controller';
import { IAdLpGroupReportView, IGroupPageInfo } from 'src/services/models';
import { RootState } from 'src/store';
import { PageMetaJson } from 'omega-render';
import { timeFormat } from '../util';
import { EnableStatus } from '../index';

interface PageInfo {
    pageNum: number;
    pageSize: number;
    totalNum: number;
}

export interface pageListData extends itemReportData {
    outPageId: string;
    groupId?: string;
    pageName: string;
    auditStatus: number;
    enable: number; // 投放开关状态 0关1开
    pageSource: number; // 0建站1自建站
    url: string;
    updateTime: string;
    componentsType?: string[];
    disableDel?: boolean;
    key: string;
    pageNum: string;
    pageId: string;
    hasDeleted: boolean;
    rejectReason: string;
    pageMeta?: PageMetaJson;
}

interface itemReportData {
    avgConversionNum: number;
    avgPv: number;
    avgEventNum: number;
    avgConversionRate: number;
    diffAvgConversionNum: number;
    diffAvgPv: number;
    diffAvgEventNum: number;
    diffAvgConversionRate: number;
}

export interface groupListData extends itemReportData {
    usable: number;
    groupName: string;
    groupNum: number;
    updateTime: string;
    componentsType?: string[];
    groupSource: number;
    groupId: string;
    key: string;
    pageList: pageListData[];
}

export interface deletedGroupListData {
    usable: number;
    groupName: string;
    groupNum: number;
    updateTime: string;
    groupSource: number;
    groupId: string;
    key: string;
    pageList: deletedPageListData[];
}

export interface deletedPageListData {
    outPageId: string;
    pageName: string;
    auditStatus: number;
    enable: number; // 投放开关状态 0关1开
    pageSource: number; // 0建站1自建站
    url: string;
    updateTime: string;
    key: string;
    pageNum: string;
    pageId: string;
    hasDeleted: boolean;
    rejectReason: string;
}

export enum ErrorType {
    none = 0,
    groupListErr = 1,
    deleteGroupErr = 2,
    fetchDeletedGroupListErr = 3,
    restoreGroupErr = 4,
}

export enum SuccessType {
    none = 0,
    deleteGroupSuccess = 1,
    restoreGroupSuccess = 2,
}

export interface GroupListState {
    pageInfo: PageInfo;
    select?: string;
    curGroupList: groupListData[];
    loading: boolean;
    hasError: ErrorType;
    deletedPageInfo: PageInfo;
    deletedGroupList: deletedGroupListData[];
    operateSuccess: SuccessType;
}

const initReportData = {
    avgConversionNum: 0,
    avgPv: 0,
    avgEventNum: 0,
    avgConversionRate: 0,
    diffAvgConversionNum: 0,
    diffAvgPv: 0,
    diffAvgEventNum: 0,
    diffAvgConversionRate: 0,
};

const defaultState: GroupListState = {
    pageInfo: {
        pageNum: 1,
        pageSize: 10,
        totalNum: 1,
    },
    curGroupList: [],
    deletedGroupList: [],
    deletedPageInfo: {
        pageNum: 1,
        pageSize: 10,
        totalNum: 1,
    },
    loading: false,
    hasError: ErrorType.none,
    operateSuccess: SuccessType.none,
};

export const groupListModel = createModel({
    namespace: 'groupListModel',
    defaultState,
    reducers: {
        init() {
            return defaultState;
        },
    },
    effects: {
        fetchGroupList(payload?: { select: string; pageNum?: number }) {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const { loading } = state.groupList;
                if (!loading) {
                    dispatch(
                        groupListModel.actions.setModelState({
                            loading: true,
                        }),
                    );
                    try {
                        const res = await listGroupUsingPOST({
                            req: {
                                ...state.groupList.pageInfo,
                                select: state.groupList?.select as string,
                                ...payload,
                            },
                        });

                        const groupList = res?.data?.list?.map((item) => {
                            const pageList = item.pageList?.map((m: IGroupPageInfo) => {
                                return {
                                    ...m,
                                    ...initReportData,
                                    key: m.outPageId,
                                    pageNum: '-',
                                    updateTime: timeFormat(m.updateTime),
                                };
                            });
                            return {
                                ...item,
                                groupNum: item.pageList?.length,
                                pageList,
                                ...initReportData,
                                key: item.groupId,
                                updateTime: timeFormat(item.updateTime),
                            };
                        });

                        dispatch(
                            groupListModel.actions.setModelState({
                                pageInfo: {
                                    ...state.groupList.pageInfo,
                                    totalNum: res?.data?.splitInfo?.totalNum,
                                    pageNum: payload?.pageNum ?? state.groupList.pageInfo.pageNum,
                                },
                                curGroupList: groupList,
                                loading: false,
                                hasError: ErrorType.none,
                                select: payload?.select ?? state.groupList.select,
                            }),
                        );
                        dispatch(groupListModel.actions.fetchReportData());
                    } catch (e) {
                        dispatch(
                            groupListModel.actions.setModelState({
                                loading: false,
                                hasError: ErrorType.groupListErr,
                            }),
                        );
                    }
                }
            };
        },

        fetchReportData() {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                const curGroupList = state.groupList.curGroupList.concat([]);

                const newCurGroupObj = {} as { [key: string]: groupListData };

                const reportList = [] as any[];

                curGroupList.forEach((item) => {
                    const outPageIds = item.pageList.map((m) => m.outPageId);
                    reportList.push(
                        new Promise((resolve, reject) => {
                            getReportUsingPOST({ req: { groupId: item.groupId, outPageIds } })
                                .then((res) => {
                                    if (res) {
                                        resolve({
                                            groupId: item.groupId,
                                            data: res.data,
                                        });
                                    } else {
                                        // eslint-disable-next-line prefer-promise-reject-errors
                                        reject('error');
                                    }
                                })
                                .catch((e) => {
                                    reject(e);
                                });
                        }),
                    );
                    newCurGroupObj[item.groupId] = item;
                });

                Promise.allSettled(reportList)
                    .then((res) => {
                        res.forEach((item) => {
                            if (item.status === 'fulfilled') {
                                const value = item.value as {
                                    groupId: string;
                                    data: IAdLpGroupReportView;
                                };

                                const newPageList = newCurGroupObj[value.groupId].pageList.map(
                                    (pageItem) => {
                                        return {
                                            ...pageItem,
                                            ...value.data.pageReportInfo[pageItem.outPageId],
                                        };
                                    },
                                );

                                newCurGroupObj[value.groupId] = {
                                    ...newCurGroupObj[value.groupId],
                                    ...value.data.groupReportInfo,
                                    pageList: newPageList,
                                };
                            }
                        });

                        const newCurGroupList = curGroupList.map((item) => {
                            return newCurGroupObj[item.groupId];
                        });

                        dispatch(
                            groupListModel.actions.setModelState({
                                loading: false,
                                curGroupList: newCurGroupList,
                            }),
                        );
                    })
                    .catch(() => {
                        dispatch(
                            groupListModel.actions.setModelState({
                                loading: false,
                            }),
                        );
                    });
            };
        },

        deleteGroup(payload) {
            return async (dispatch) => {
                try {
                    const res = await deleteGroupUsingPOST(payload);
                    if (res.result === 1) {
                        dispatch(
                            groupListModel.actions.setModelState({
                                pageInfo: {
                                    pageNum: 1,
                                    pageSize: 10,
                                    totalNum: 10,
                                },
                            }),
                        );
                        dispatch(groupListModel.actions.fetchGroupList());
                    }
                } catch (e) {
                    dispatch(
                        groupListModel.actions.setModelState({
                            hasError: ErrorType.deleteGroupErr,
                        }),
                    );
                }
            };
        },
        changeSwitchStatus(payload: {
            groupId: string;
            outPageId: string;
            switchAction: EnableStatus;
        }) {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                const { curGroupList } = state.groupList;
                const { groupId, outPageId, switchAction } = payload;
                try {
                    const res = await switchPageUsingPOST({ pageSwitchReq: payload });

                    const newCurGroupList = curGroupList.map((item) => {
                        if (item.groupId === groupId) {
                            const pageList = item.pageList.map((pageItem) => {
                                if (pageItem.outPageId === outPageId) {
                                    return {
                                        ...pageItem,
                                        enable: switchAction,
                                    };
                                }
                                return pageItem;
                            });
                            return {
                                ...item,
                                pageList,
                                usable: res.data.usable,
                            };
                        }
                        return item;
                    });

                    dispatch(
                        groupListModel.actions.setModelState({
                            curGroupList: newCurGroupList,
                        }),
                    );
                } catch (e) {
                    console.log('error', e);
                }
            };
        },

        setPageInfo(payload) {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                const { pageInfo } = state.groupList;

                dispatch(
                    groupListModel.actions.setModelState({
                        pageInfo: {
                            ...pageInfo,
                            ...payload,
                        },
                    }),
                );
                dispatch(groupListModel.actions.fetchGroupList());
            };
        },
        setDeletedPageInfo(payload) {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                const { deletedPageInfo } = state.groupList;
                dispatch(
                    groupListModel.actions.setModelState({
                        deletedPageInfo: {
                            ...deletedPageInfo,
                            ...payload,
                        },
                    }),
                );
                dispatch(groupListModel.actions.fetchDeletedGroupList());
            };
        },

        fetchDeletedGroupList() {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const { loading } = state.groupList;
                if (!loading) {
                    dispatch(
                        groupListModel.actions.setModelState({
                            loading: true,
                        }),
                    );
                    try {
                        const res = await listDeletedGroupUsingPOST({
                            splitReq: {
                                ...state.groupList.deletedPageInfo,
                            },
                        });

                        const groupList = res?.data?.list?.map((item) => {
                            const pageList = item.pageList?.map((m: IGroupPageInfo) => {
                                return {
                                    ...m,
                                    ...initReportData,
                                    key: m.outPageId,
                                    pageNum: '-',
                                    updateTime: timeFormat(m.updateTime),
                                };
                            });
                            return {
                                ...item,
                                groupNum: item.pageList?.length,
                                pageList,
                                ...initReportData,
                                key: item.groupId,
                                updateTime: timeFormat(item.updateTime),
                            };
                        });

                        console.log('groupList', groupList);

                        dispatch(
                            groupListModel.actions.setModelState({
                                deletedPageInfo: {
                                    ...state.groupList.deletedPageInfo,
                                    totalNum: res?.data?.splitInfo?.totalNum,
                                },
                                deletedGroupList: groupList,
                                loading: false,
                                operateSuccess: SuccessType.none,
                            }),
                        );
                    } catch (e) {
                        dispatch(
                            groupListModel.actions.setModelState({
                                loading: false,
                                hasError: ErrorType.fetchDeletedGroupListErr,
                                operateSuccess: SuccessType.none,
                            }),
                        );
                    }
                }
            };
        },

        restoreDeletedGroup(payload) {
            return async (dispatch) => {
                try {
                    const res = await restoreGroupUsingPOST(payload);
                    if (res.result === 1) {
                        dispatch(
                            groupListModel.actions.setModelState({
                                deletedPageInfo: {
                                    pageNum: 1,
                                    pageSize: 10,
                                    totalNum: 10,
                                },
                                operateSuccess: SuccessType.restoreGroupSuccess,
                            }),
                        );
                        dispatch(groupListModel.actions.fetchDeletedGroupList());
                    }
                } catch (e) {
                    dispatch(
                        groupListModel.actions.setModelState({
                            hasError: ErrorType.restoreGroupErr,
                        }),
                    );
                }
            };
        },
    },
});
