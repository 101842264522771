import { createModel } from 'rt-model';
// import { listPageUsingPOST, updateNameUsingPOST } from 'src/services/apis';
// import { ICommonListResultAdLpPageView } from '../../../services/models';
import { RootState } from 'src/store';
import produce from 'immer';
import { message } from 'm-ui';
// import { IGivePageView, ILpViewCompView } from 'src/services/models';
import { updateNameUsingPOST } from 'src/services/page-controller';
import { listPageUsingPOST } from 'src/services-new/page-controller';

import { IPageCreateReq, IViewCompParam } from 'src/services/models';
import { IMultiResponseWrapPageView } from 'src/services-new/models';

import { cleanComponent, cleanPageMeta } from 'omega-shared/upgrade';
import { ProductSearch } from '../SearchPage/SearchPage';

export interface IGivePageView extends IPageCreateReq {
    id: string;
    isHalfPage?: boolean;
}

export interface PageListState extends Pick<IMultiResponseWrapPageView, 'list' | 'splitInfo'> {
    searchValue?: string;
    auditStatus?: number;
    batchOperationList?: string[];
    batchDeletePageNameList?: string[];
    batchOperatePageComp?: IGivePageView[];
    productSearch?: ProductSearch;
    showEditState: boolean;
}
const defaultState: PageListState = {
    list: [],
    splitInfo: {
        pageNum: 1,
        pageSize: 10,
        totalNum: 10,
    },
    auditStatus: -1,
    showEditState: false,
    batchOperationList: [],
};
export const pageListModel = createModel({
    namespace: 'pageListModel',
    defaultState,
    reducers: {
        setPageName(state, payload: { name?: string; id: string; title?: string }) {
            const { name, title, id } = payload;
            return produce(state, (draftState) => {
                const page = draftState.list.find((p) => p.id === id);
                if (page) {
                    if ('title' in payload) {
                        page.title = title ?? '';
                    } else {
                        page.name = name ?? '';
                    }
                }
            });
        },
    },
    effects: {
        fetchList() {
            return async (dispatch, getState) => {
                // pageListModel.reduce
                const state: RootState = getState();
                const { searchValue, productSearch, auditStatus } = state.pageList;
                const req = {
                    ...state.pageList.splitInfo,
                } as Parameters<typeof listPageUsingPOST>[0]['req'];
                if (searchValue) {
                    req.select = searchValue;
                }
                (req as any).auditStatus = auditStatus;
                if (productSearch) {
                    req.componentIdQuery = { ...productSearch };
                }

                const res = await listPageUsingPOST({ req });
                dispatch(
                    pageListModel.actions.setModelState({
                        list: res.data.list,
                        splitInfo: res.data.splitInfo,
                        // batchOperationList: [],
                        // batchDeletePageName: undefined,
                        // batchOperatePageComp: [],
                    }),
                );
            };
        },
        setSplitInfo(payload: { pageNum: number; pageSize: number }) {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                const { pageNum, pageSize } = payload;
                dispatch(
                    pageListModel.actions.setModelState({
                        splitInfo: {
                            ...state.pageList.splitInfo,
                            pageNum,
                            pageSize,
                        },
                    }),
                );

                dispatch(pageListModel.actions.fetchList());
            };
        },
        search(value: string, auditStatus?: number) {
            return async (dispatch) => {
                dispatch(
                    pageListModel.actions.setModelState({
                        searchValue: value,
                        auditStatus,
                        splitInfo: defaultState.splitInfo,
                    }),
                );
                dispatch(pageListModel.actions.fetchList());
            };
        },
        productSearch(productSearch: ProductSearch) {
            return async (dispatch) => {
                dispatch(
                    pageListModel.actions.setModelState({
                        productSearch,
                        splitInfo: defaultState.splitInfo,
                    }),
                );
                dispatch(pageListModel.actions.fetchList());
            };
        },
        editShowState(showState: boolean) {
            return async (dispatch) => {
                dispatch(
                    pageListModel.actions.setModelState({
                        showEditState: showState,
                    }),
                );
            };
        },
        editBatchOperateList(params: {
            operateAdd?: boolean;
            pageId?: string;
            pageComp?: IGivePageView;
            pageIdList?: string[];
            pageName?: string;
            pageCompList?: IGivePageView[];
            pageNameList?: string[];
            wechatGameDirectCallUser?: boolean;
        }) {
            return async (dispatch, getState) => {
                const state: RootState = getState();

                const {
                    operateAdd,
                    pageId,
                    pageIdList,
                    pageName,
                    pageComp,
                    pageCompList,
                    pageNameList,
                    wechatGameDirectCallUser,
                } = params;
                const batchOperationList = state.pageList?.batchOperationList || [];
                const batchOperatePageComp = state.pageList?.batchOperatePageComp || [];
                let newPageNameList = state.pageList?.batchDeletePageNameList?.concat([]) || [];
                let newBatchOperationList = batchOperationList.concat([]);
                let newBatchOperatePageComp = batchOperatePageComp.concat([]);

                if (pageId && pageComp) {
                    if (operateAdd) {
                        newBatchOperationList.push(pageId || '');

                        const {
                            // bizId,
                            name,
                            title,
                            type,
                            coverImgUrl,
                            components,
                            id,
                            componentDynamicElements,
                            isHalfPage,
                            pageMeta,
                        } = pageComp;
                        // const { bizId, name, title, type, coverImgUrl, components, id, isHalfPage } = pageComp;
                        const newComponents = cleanComponent(components);
                        const newpageMeta = cleanPageMeta({ pageMeta, wechatGameDirectCallUser });
                        newBatchOperatePageComp.push({
                            // bizId,
                            name,
                            title,
                            type,
                            coverImgUrl,
                            id,
                            components: newComponents as IViewCompParam[],
                            componentDynamicElements,
                            isHalfPage,
                            pageMeta: newpageMeta,
                        } as any);
                        newPageNameList.push(pageName ?? '');
                    } else {
                        newBatchOperationList = batchOperationList.filter(
                            (item) => item !== pageId,
                        );
                        newPageNameList = newPageNameList.filter((item) => item !== pageName);
                        newBatchOperatePageComp = batchOperatePageComp.filter(
                            (item) => item.id !== pageComp.id,
                        );
                    }
                }

                dispatch(
                    pageListModel.actions.setModelState({
                        batchOperationList: pageIdList ?? newBatchOperationList,
                        batchDeletePageNameList: pageNameList ?? newPageNameList,
                        batchOperatePageComp: pageCompList ?? newBatchOperatePageComp,
                    }),
                );
            };
        },
        editName(name: string, id: string) {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                const twoTitlesUser = state.adUser.lpUser?.twoTitlesUser;
                const page = state.pageList.list.find((com) => com.id === id);
                if (!page) {
                    throw new Error('找不到页面');
                }
                if (twoTitlesUser) {
                    const oldName = page.name;
                    dispatch(pageListModel.actions.setPageName({ id, name }));
                    try {
                        await updateNameUsingPOST({
                            id,
                            name,
                        });
                    } catch (e) {
                        message.error(e.msg || '修改失败');
                        dispatch(pageListModel.actions.setPageName({ id, name: oldName }));
                    }
                } else {
                    const oldName = page.name;
                    dispatch(pageListModel.actions.setPageName({ id, name }));
                    try {
                        await updateNameUsingPOST({
                            id,
                            name,
                        });
                    } catch (e) {
                        message.error(e.msg || '修改失败');
                        dispatch(pageListModel.actions.setPageName({ id, name: oldName }));
                    }
                }
            };
        },
    },
});
