import { Button, Input, Select } from 'm-ui';
import styled, { createGlobalStyle } from 'styled-components';

export const Page = styled.div`
    padding: 24px;
    background-color: #f5f7f9;
`;

export const Wrapper = styled.div`
    background-color: white;
    padding: 32px 32px 16px;
`;

export const EmptyWrapper = styled.div`
    height: calc(100vh - 148px);
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    margin-bottom: 24px;
`;
export const Title = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #1f1f1f;
`;

export const OperationManual = styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #0075ff;
    margin-left: 12px;
`;

export const Tips = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    /* margin-top: 28px; */
    /* gap: 6px; */

    /* width: 1328px; */
    /* height: 90px; */

    background: #f5f7f9;
    border-radius: 4px;
`;

export const TipsIconWrapper = styled.div`
    margin-top: 2px;
    margin-right: 8px;
`;
export const TipsContent = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #434343;
    & li {
        list-style: disc;
    }
    & ul {
        padding-inline-start: 20px;
    }
`;

export const emptyWrapper = styled.div`
    display: flex;
`;
export const FlowchartWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
`;

export const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
`;

export const StepImage = styled.img`
    height: 100px;
    width: 100px;
    /* background-color: #ccc; */
    margin-bottom: 4px;
`;

export const StepTitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1f1f1f;
    margin-bottom: 4px;
`;

export const StepDesc = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8c8c8c;
`;

export const GapIcon = styled.img`
    height: 50px;
    width: 34px;
    object-fit: cover;
    object-position: center top;
`;

export const NewButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
`;
export const AddTitle = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #434343;
    margin-bottom: 12px;
`;
export const ListWrapper = styled.div`
    tbody .ant-table-cell {
        padding-top: 0;
        padding-bottom: 0;
        height: 80px;
    }
`;
export const FilterSearchWrapper = styled.div`
    display: flex;
    margin-bottom: 20px;
    /* min-width: 1270px; */
    flex-direction: column;
    /* justify-content: space-between; */
`;

export const RightWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 12px;
`;
export const StatusFilterWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const PageListCover = styled.img`
    width: 46px;
    height: 64px;
`;

export const PageListName = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #434343;
`;

export const PageListDesc = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    width: 284px;
    word-wrap: no-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #8c8c8c;
`;

export const DetailWrapper = styled.div`
    padding: 24px;
    background-color: #f5f7f9;
`;

export const SummaryWrapper = styled.div`
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 32px;
`;

export const SummaryTitle = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #1f1f1f;
`;

export const SummaryTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SummarySubTitle = styled.div``;

export const SummaryContent = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
`;
export const TestRule = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 22px;
    margin-left: 12px;
    color: #0075ff;
    cursor: pointer;
`;

export const SummaryIcon = styled.img`
    width: 100px;
    height: 100px;
    margin-right: 16px;
`;
export const SummaryRight = styled.div``;
export const SummaryMark = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #1f1f1f;
`;
export const SummaryMarkText = styled.div``;
export const SummaryTestNum = styled.div`
    font-size: 14px;
    line-height: 22px;

    color: #8c8c8c;
`;
export const SummaryAdvice = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
    color: #434343;
`;

export const CoreIndicatorWrapper = styled.div`
    padding: 32px;
    background-color: white;
    width: 100%;
    margin-top: 16px;
    /* width: 1044px; */
`;

export const CoreIndicatorContent = styled.div`
    display: flex;
    margin-top: 16px;
`;
export const IndicatorWrapper = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 16px 16px 16px 16px;
    width: 316px;
    height: 124px;
    border: 1px solid #f5f7f9;
    border-radius: 4px;
    margin-right: 16px;
    &:last-of-type {
        margin-right: 0;
    }
`;

export const IndicatorTitle = styled.div`
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1f1f1f;
`;

export const BottomWrapper = styled.div`
    display: flex;
`;
export const LeftWrapper = styled.div`
    flex-basis: 1044px;
    flex-grow: 1;
    flex-shrink: 0;
`;

export const BaseInfoWrapper = styled.div`
    width: 332px;
    flex-basis: 332px;
    padding: 24px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: white;
    margin-left: 16px;
    margin-top: 16px;
`;
export const BaseTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* margin-bottom: 16px; */
    color: #1f1f1f;
`;
export const BaseItem = styled.div`
    display: flex;
    margin-bottom: 16px;
`;
export const BaseLabel = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    width: 84px;
    margin-right: 12px;
    color: #8c8c8c;
    flex: none;
    text-align: right;
`;
export const BaseContent = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #434343;
`;

export const BaseContentA = styled.a`
    word-break: break-all;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
`;

export const IndicatorDetailWrapper = styled.div`
    background-color: white;
    padding: 32px;
    margin-top: 16px;
`;

export const IndicatorContent = styled.div`
    margin-top: 22px;
`;

export const IndicatorHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SegmentHeader = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #1f1f1f;
`;

export const PreviewCover = styled.div`
    /* border: none; */
    width: 284px;
    height: 505px;
    border: 1px solid #f0f0f0;
    overflow: scroll;
    border-radius: 4px;
    /* transform: scale(0.75);
    
    transform-origin: 0 0; */
`;

export const Tag = styled.div`
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffb19c;
    border-radius: 4px;
    font-size: 12px;
`;
export const GoodTag = styled(Tag)`
    background-color: #dfede3;
    border-color: #27ad60;
    color: #27ad60;
    margin-left: 8px;
    line-height: 20px;
`;
export const BadTag = styled(Tag)`
    border-color: #f93920;
    background-color: #fff2ed;
    color: #f93920;
    margin-left: 8px;
    line-height: 20px;
`;

export const PassTag = styled(Tag)`
    border-color: #fbbd1b;
    background-color: #fffce8;
    color: #fbbd1b;
    margin-left: 8px;
    line-height: 20px;
`;

export const NiceTag = styled(Tag)`
    color: #0075ff;
    border-color: #0075ff;
    background-color: #e6f0ff;
    margin-left: 8px;
    line-height: 20px;
`;

export const StatusRadius = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    border-radius: 6px;
    width: 6px;
    height: 6px;
    margin-right: 8px;
`;

export const Operator = styled.div<{ disabled?: boolean }>`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-right: 16px;
    color: ${(props) => (props.disabled ? '#A3DAFF' : ' #0075FF')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const OperatorWrapper = styled.div`
    display: flex;
    white-space: nowrap;
`;

export const TwoLineText = styled.a`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const OneLineText = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
`;

export const InputSelect = styled(Select)`
    .ant-select-selection-overflow-item-suffix:not(:only-child) {
        display: none;
    }
`;

export const IndicatorItem = styled.div`
    /* padding: 16px; */
    /* margin: 0 16px; */
    padding: 16px 0;
    border-bottom: 1px solid #f0f0f0;
    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }
`;

export const IndicatorItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const IndicatorItemName = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #1f1f1f;
`;
export const IndicatorItemValueWrapper = styled.div`
    display: flex;
    align-items: center;
`;
export const IndicatorItemBase = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8c8c8c;
`;
export const IndicatorItemValue = styled.div<{ error?: boolean }>`
    color: ${(props) => (props.error ? '#ff4d4f' : '#434343')};
`;

export const SeparatorLine = styled.div`
    /* width: 2px; */
    height: 12px;
    /* background-color: #f0f0f0; */
    border-left: 1px solid #f0f0f0;
    margin: 0 8px;
`;

export const IndicatorDesc = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* align-items: center; */
    color: #8c8c8c;
    margin-top: 16px;
    padding: 0 8px;
`;
export const IndicatorDescTitle = styled.div<{ color?: string }>`
    flex: none;
    margin-left: 6px;
    margin-right: 8px;
    color: ${(props) => props.color};
`;
export const IndicatorDescContent = styled.div`
    color: #434343;
`;

export const GlobalStyle = createGlobalStyle`
.quality-score-level-select {
    .ant-select-selection-overflow {
        flex-wrap: nowrap;
    }
}
.create-third-drawer-modal {
    .ant-modal-confirm-content  {
        margin-left: 0px !important;
        max-height: 212px;
        overflow: scroll;
    }
    .ant-modal-confirm-body > .anticon {
        margin-right: 10px !important;
    }
    .ant-modal-confirm-title {
        text-wrap: nowrap;
    }
}
  /* 全局CSS样式 */
  *:not(input, textarea) {
    -webkit-user-select: auto !important;
}
    .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
        color: #9c9c9c;
    }
.quality-create-drawer  {
    .ant-drawer-body {
        padding: 16px;
        background-color: #f5f7f9;
        overflow-x: hidden;
    }
}
.quality-check-question-mark {
    color: #BFBFBF;
    margin-left: 4px;
    cursor: pointer;
    &:hover {
        color: #595959;
    }
}
.quality-history-list{
.ant-table-body::-webkit-scrollbar {
    /* height: 0; */
    display: none;
}
.ant-table-column-sorter {
    margin-left: 0px;
}
}
.quality-list-table {
    .ant-table-column-sorter {
    margin-left: 0px;
} 
& *::-webkit-scrollbar {
    display: block !important;
    height: 8px;
}

}

body {
    background-color: #f5f7f9;
}
.error-detail-table .ant-table thead th {
  background-color:white;
  border-top: 1px solid #f0f0f0;
}
.input-select {
    .ant-select-item {
        padding: 0;
    }
   
}

`;
export const PassPageItemWrapper = styled.div<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    padding: 6px 12px;
    background-color: ${(props) => (props.selected ? '#E6F6FF' : 'inherit')} !important;
`;

export const PassPageRightWrapper = styled.div`
    margin-left: 16px;
`;

export const PanelHeaderDesc = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 16px;
    color: #8c8c8c;
`;
export const PanelHeaderName = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #1f1f1f;
`;

export const PanelHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;
export const PanelHeaderNum = styled.div<{ error?: boolean }>`
    margin-left: 6px;
    color: ${(props) => (props.error ? '#F93920' : '#1F1F1F')};
`;

export const IndicatorSuggestion = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    background-color: rgba(245, 247, 249, 0.7);
    padding: 8px;
    margin-top: 8px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    /* color: #27ad60; */
`;

export const IndicatorError = styled(IndicatorSuggestion)`
    color: #f93920;
`;
export const PageDetailWrapper = styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
`;

export const PageCoverWrapper = styled.div`
    width: 44px;
    height: 44px;
    background: #f0f0f0;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PageCover = styled.img`
    width: 32px;
    height: 44px;
`;

export const PageDetailRight = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
`;

export const PageName = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #434343;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PageId = styled.div`
    color: #8c8c8c;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-top: 4px;
`;

export const ExpandIconWrapper = styled.div`
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* padding: 4px 8px; */
    padding: 0 2px;
`;

export const ExpandIconText = styled.div`
    margin-right: 8px;
`;

export const TextTip = styled.div`
    width: 476px;
    height: 76px;
    background: #f5f7f9;
    border-radius: 4px;
    padding: 16px 129px 16px 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #434343;
    margin-top: 24px;
`;

export const CreateWrapper = styled.div`
    background-color: #fff;
    padding: 24px;
    height: calc(100vh - 140px);
`;
export const HistoryCreateWrapper = styled.div`
    background-color: #fff;
    padding: 24px;
    height: calc(100vh - 90px);
`;

export const CreateContent = styled.div``;
export const QualityFooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: '16px 24px';
`;

export const BaseInfoHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;
export const ImageItem = styled.div`
    margin-right: 8px;
    background: #f5f7f9;
    border-radius: 4px;
    overflow: hidden;
    width: 100px;
    height: 100px;
`;

export const ImageWrapper = styled.div`
    display: flex;
    margin-left: 8px;
    margin-top: 8px;
    flex-wrap: wrap;
`;

export const FooterLeftDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const TotalDiv = styled.div``;

export const FooterRightDiv = styled.div``;

export const SubmitButton = styled(Button)`
    margin-left: 8px;
`;

export const LeftDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const RightDiv = styled.div``;

export const AddUrlButton = styled(Button)`
    margin-right: 16px;
`;

export const BatchAddButton = styled(Button)`
    margin-right: 8px;
`;

export const DescDiv = styled.div`
    font-size: 12px;
    color: #8c8c8c;
    margin-right: 8px;
`;

export const LineDiv = styled.div`
    width: 1px;
    background-color: #f0f0f0;
    height: 16px;
    margin-right: 8px;
`;

export const DownloadLink = styled.a`
    color: 0075FF;
    font-size: 12px;
`;

export const ClearButton = styled(Button)`
    padding-right: 0px;
`;

export const MainDiv = styled.div``;

export const MainListWrapper = styled.div`
    margin-top: 16px;
`;

export const ItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 48px;
`;

export const UrlInput = styled(Input)`
    width: 508px;
`;

export const EmptyDiv = styled.div`
    margin: 100px auto;
    width: 120px;
`;

export const EmptyImage = styled.img`
    width: 120px;
    height: 120px;
`;

export const EmptyDescDiv = styled.div`
    color: #bfbfbf;
    text-align: center;
`;

export const ThirdRemoveButton = styled.div`
    cursor: pointer;
    color: #8c8c8c;
    line-height: 32px;
    height: 32px;
    &:hover {
        color: #0075ff;
    }
`;

export const ModalConfirmTitle = styled.div`
    color: #434343;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
`;

export const ModalConfirmUl = styled.ul`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #8c8c8c;
    padding-left: 22px;
`;

export const ModalConfirmLi = styled.li`
    list-style: inherit;

    /* list-style: inherit !important; */
`;

export const ModalConfirmOneLine = styled.span`
    display: block;
    width: 306px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ItemLeftDiv = styled.div`
    align-self: flex-start;
    display: flex;
    align-items: center;
`;

export const SuggestionSpan = styled.span`
    color: #0075ff;
    margin-right: 8px;
`;

export const MiddleDiv = styled.div`
    flex: 1;
`;

export const ItemRightDiv = styled.div`
    flex: none;
    color: #0075ff;
    margin-left: 24px;
    cursor: pointer;
`;
