import styled from 'styled-components';

export const GuideMask = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
`;

export const GuideBlock = styled.div`
    z-index: 10000;
    position: absolute;
`;

export const StyledButton = styled.div`
    width: 52px;
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 20px;
`;

export const Mask = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: 0.5;
    z-index: 9;
    left: 0;
    top: 0;
`;

const PointerPosition = {
    right: {
        right: '-5px',
        left: '',
        top: '32px',
        transform: 'rotate(-135deg)',
    },
    left: {
        left: '-5px',
        right: '',
        top: '32px',
        transform: 'rotate(45deg)',
    },
    top: {
        right: '27px',
        top: '-5px',
        left: '',
        transform: 'rotate(135deg)',
    },
};
export type PointerType = keyof typeof PointerPosition;
export const TipContainer = styled.div<{ pointerType?: PointerType }>`
    position: fixed;
    background-color: white;
    padding: 24px;
    z-index: 9999;
    width: 390px;
    color: #434343;
    border-radius: 4px;
    &:after {
        content: '';
        position: absolute;
        left: ${(props) => PointerPosition[props.pointerType as PointerType]?.left};
        right: ${(props) => PointerPosition[props.pointerType as PointerType]?.right};
        top: ${(props) => PointerPosition[props.pointerType as PointerType]?.top};
        width: 0;
        height: 0;
        border-bottom: 10px solid white;
        border-right: 10px solid transparent;
        transform: rotate(45deg);
        transform: ${(props) => PointerPosition[props.pointerType as PointerType]?.transform};
    }
`;
export const TipTitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg:hover {
        cursor: pointer;
    }
`;

export const TipTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: #1f1f1f;
    line-height: 24px;
`;
// export const CloseIcon = styled.div``;
export const TipContent = styled.div`
    margin-top: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #434343;
    line-height: 22px;
`;
export const TipSteps = styled.div`
    display: flex;
    align-items: center;
`;
export const TipStepNode = styled.div`
    width: 4px;
    height: 4px;
    margin-right: 4px;
    background: #d9d9d9;
`;
export const CurrentTipStepNode = styled.div`
    background: #3e7cf7;
`;

export const SvgMask = styled.svg`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9998;
    /* pointer-events: none; */
    /* opacity: 0.5; */
    transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
    overflow: hidden;
`;

export const GuideBottom = styled.div`
    text-align: right;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    align-items: center;
`;

export const CurStepInfo = styled.div`
    font-size: 14px;
    color: #8c8c8c;
    line-height: 22px;
`;

export const StepBtnBar = styled.div``;
