/** key为全路径,如何最后含有* 则为路径下的所有匹配 */
export interface IBreadcrumbItem {
    text: string;
    link: string;
    textOpt?: {
        [key: string]: string;
    };
}
export const breadcrumbConfig: { [key: string]: IBreadcrumbItem[] } = {
    '/mainGuide/messageCenter': [
        {
            text: '首页',
            link: '/mainGuide',
        },
        {
            text: '消息中心',
            link: '',
        },
    ],
    '/programmaticTrash': [
        {
            text: '程序化落地页',
            link: '/programmaticList',
        },
        {
            text: '组回收站',
            link: '',
        },
    ],
    '/programmaticGroup': [
        {
            text: '程序化落地页',
            link: '/programmaticList',
        },
        {
            text: '新建程序化落地页组',
            link: '',
        },
    ],
    '/programmaticGroupEdit': [
        {
            text: '程序化落地页',
            link: '/programmaticList',
        },
        {
            text: '编辑程序化落地页组',
            link: '',
        },
    ],
    '/page/list/trash': [
        {
            text: '我的落地页',
            link: '/page/list',
        },
        {
            text: '回收站',
            link: '',
        },
    ],
    '/page/list/createNewPage': [
        {
            text: '我的落地页',
            link: '/page/list',
        },
        {
            text: '选择类型',
            link: '',
        },
    ],
    '/page/list/createNewPage/halfPage': [
        {
            text: '我的落地页',
            link: '/page/list',
        },
        {
            text: '选择类型',
            link: '/page/list/createNewPage',
        },
        {
            text: '选择落地页类型',
            link: '',
        },
    ],
    '/page/list/createNewPage/storePage': [
        {
            text: '我的落地页',
            link: '/page/list',
        },
        {
            text: '选择类型',
            link: '/page/list/createNewPage',
        },
        {
            text: '门店落地页',
            link: '',
        },
    ],
    '/page/list/createNewPage/novel/*': [
        {
            text: '我的落地页',
            link: '/page/list',
        },
        {
            text: '选择类型',
            link: '/page/list/createNewPage',
        },
        {
            text: '批量生成落地页',
            link: '',
        },
    ],
    '/popup/editPopup': [
        {
            text: '互动弹窗',
            link: '/popup',
        },
        {
            text: '编辑引导弹窗',
            link: '',
        },
    ],
    '/popup/createPopup': [
        {
            text: '互动弹窗',
            link: '/popup',
        },
        {
            text: '新建引导弹窗',
            link: '',
        },
    ],
    '/template/megagame': [
        {
            text: '模板',
            link: '/template',
        },
        {
            text: '模板悬赏任务',
            link: '',
        },
    ],
    '/template/megagame/introduction': [
        {
            text: '模板',
            link: '/template',
        },
        {
            text: '模板悬赏任务',
            link: '/template/megagame',
        },
        {
            text: '活动详情',
            link: '',
        },
    ],
    '/template/megagame/commit': [
        {
            text: '模板',
            link: '/template',
        },
        {
            text: '模板悬赏任务',
            link: '/template/megagame',
        },
        {
            text: '提交模板',
            textOpt: {
                create: '提交模板',
                update: '编辑模板信息',
            },
            link: '',
        },
    ],
    '/page/list/createNewPage/keywordPage': [
        {
            text: '我的落地页',
            link: '/page/list',
        },
        {
            text: '新建落地页',
            link: '/page/list/createNewPage',
        },
        {
            text: '关键词创建',
            link: '',
        },
    ],
};
