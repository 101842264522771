import { createSlice } from '@reduxjs/toolkit';
import { message } from 'm-ui';
import {
    getSDK,
    PageEditState,
    componentValidate,
    componentsConfig,
    isGlobalDrag,
} from 'omega-editor';
import {
    ComponentJson,
    WeChatType,
    componentsAsyncForEach,
    componentsForEach,
    componentsMap,
    findComponent,
    DIALOG_STYLE,
    AnyObj,
} from 'omega-render';
import { cloneDeep } from 'lodash';
import { validateName, validateTitle } from 'src/utils/TtitleValidate';
import { setFontToComponents } from 'src/utils/fontFamily';
import { resetComponentProps } from 'src/utils/resetComponentProps';
import {
    createAndPublishPageUsingPOST,
    createPageUsingPOST,
    publishPageUsingPOST,
    savePageUsingPOST,
} from 'src/services/page-controller';
import { IPageModuleView, IPageView } from 'src/services/models';
import ReactDOM from 'react-dom';
import { whiteListHistory } from 'src/utils/whiteListHistory';
import { disableConfirm } from 'src/hooks/useCloseConfirm';
import {
    trackPageSubmitFrontErrorDetail,
    trackPageSubmitRawJson,
    trackPageSubmitServerErrorDetail,
    trackPageSubmitSuccess,
    trackCreatePageNotPublish,
    trackCreatePageWithPublish,
    trackCreatePageUsingAnchorForm,
    trackCreatePageNotUseAnchorForm,
    trackPageSubmitFinalJson,
} from 'src/utils/adMonitor';
import {
    dataTrackPageUseAnchorForm,
    dataTrackPageNotUseAnchorForm,
    webLogger,
} from 'src/utils/radar';
// import { BizId } from 'landingPage/common/enums';

import { validateHalfScreen } from 'src/pages/editor/utils/halfScreenSetting';
import { RootState, store } from '..';
import {
    validatePageMeta,
    validateDynamicsElements,
    validateOnlyOnePlanId,
    validateOnlyOneAssistant,
    validatOnlyOneApp,
} from './validate';
import { uploadBindMaterial, uploadCutMaterialToLibrary } from 'src/components/UploadImage/utils';
import { validateDirectCall } from 'src/pages/editor/utils/directCall';
import { handleFormAppointmentDownload } from './utils';
import { getFormComponentType } from 'omega-shared/utils';
import { privateUserOpenUsingPOST } from '@/services-new/customer-controller';
import { ConsultType } from '@/landingPage/components/MultiConsultDrag/MultiConsult';

// 为方便打点设置的变量
let trackAccountId: number;

export type ComponentListType = {
    /** 组件类型 */
    type: string;
    /** 组件编辑器url */
    editorUrl?: string;
    /** 组件icon的class */
    iconUrl?: string;
    /** 组件名称 */
    name?: string;
    /** 组件的url */
    componentUrl?: string;
    /** 是否为新组件 */
    newFlag?: boolean;
}[];

export interface PrivateLetterState {
    compId: string[];
    uid: number;
    selectedValue: number;
    showOpenType: string;
    showPaiType: string;
    showOpenContent: string;
}

export interface PageInfoState {
    componentsList: ComponentListType;
    collectModules: IPageModuleView;
    collectModuleList: IPageModuleView[];
    publishing: boolean;
    marketComponentsId: string[];
    // psd导入弹窗
    psdModalVisible?: boolean;
    psdUploadingId?: string;
    psdTargetId?: string;
    /** 是否包含PSD导入的模块，用于统计消耗数据 */
    containPsdModule?: boolean;
    /** 是否展示调整内容 */
    isShowPromptContent?: boolean;
    /** 是否展示调整内容弹窗 */
    isShowPromptNotification?: boolean;
    /** 页面内容是否占满一屏 */
    pageFull?: boolean;
    /** 是否有按钮动效 */
    hasButtonAnimation?: boolean;
    privateLetterUserInfo?: PrivateLetterState[];
}
const initialState: PageInfoState = {
    componentsList: [],
    collectModules: {} as any,
    collectModuleList: [],
    publishing: false,
    /** 页面原有的营销组件的ids,用来判断编辑时，删除营销组件是否提示 */
    marketComponentsId: [],
    psdModalVisible: false,
    psdUploadingId: '',
    psdTargetId: '',
    containPsdModule: false,
    isShowPromptContent: false,
    isShowPromptNotification: false,
    privateLetterUserInfo: [],
};

export const pageInfoSlice = createSlice({
    name: 'pageInfo',
    initialState,
    reducers: {
        setModelState(state, action: { payload: Partial<PageInfoState> }) {
            Object.assign(state, action.payload);
        },
        setCollectModule(state, action: { payload: IPageModuleView }) {
            state.collectModules = action.payload || ({} as any);
        },
        setCollectModuleList(state, action: { payload: Partial<PageInfoState> }) {
            state.collectModuleList = action.payload.collectModuleList ?? [];
        },
        setPublishStatus(state, action: { payload: boolean }) {
            state.publishing = action.payload;
        },
        setPrivateLetterUserInfo(state, action: { payload: PrivateLetterState[] }) {
            state.privateLetterUserInfo = [...action.payload];
        },
        /**
         * 从私信切换到三方咨询页时，清除页面中统计的原来选择的私信信息。
         *
         * @param {object} state - 当前的状态对象。
         * @param {object} action -  id - 组件ID，isImPage - 是否是咨询落地页
         *
         * 如果是咨询落地页，直接返回空数组即可（因为咨询落地页只能配置一个私信或三方客服）
         * 如果是普通落地页，则把原来组件选择的私信账户信息数组中清除掉现在的组件id
         */
        updatePrivateLetterUserInfoInThirdPage(
            state,
            action: {
                payload: {
                    id?: string;
                    isImPage?: boolean;
                };
            },
        ) {
            const prePrivateLetterInfo = [...(state.privateLetterUserInfo || [])];
            const { id: compId, isImPage } = action.payload;

            const newPrivateLetterInfo = [] as PrivateLetterState[];

            if (isImPage) {
                state.privateLetterUserInfo = [...newPrivateLetterInfo];
                return;
            }

            for (let item of prePrivateLetterInfo) {
                if (item.compId.includes(compId || '')) {
                    const newCompIdList = item.compId.filter((comp) => comp !== compId);
                    if (newCompIdList?.length) {
                        newPrivateLetterInfo.push({
                            ...item,
                            compId: newCompIdList,
                        });
                    }
                } else {
                    newPrivateLetterInfo.push(item);
                }
            }

            state.privateLetterUserInfo = [...newPrivateLetterInfo];
        },
    },
});

/**
 * 上报埋点到app分析平台
 * */
const trackPageCreateWithWeblogger = (
    accountId: number,
    pageId: string,
    containPsdModule: boolean,
    operation: 'save' | 'publish',
) => {
    webLogger.sendImmediately('CLICK', {
        action: 'LANDING_PAGE_CREATED',
        params: {
            account_id: accountId,
            page_id: pageId,
            contain_psd_module: containPsdModule,
            operation,
        },
        type: 'BACKGROUND_TASK_EVENT',
    });
};

// 发布中状态
let inPublish = false;

// 删除多余的优选参数（删除父空白区后的问题）
const deleteInvalidDyEl = (json, deleteComponentDynamicElementById) => {
    const allComp: string[] = [];
    componentsForEach(json.components, (comp) => {
        allComp.push(comp.id);
    });
    json.componentDynamicElements?.forEach((item) => {
        if (!allComp.includes(item.compId)) {
            deleteComponentDynamicElementById({ id: item.compId });
        }
    });
};

/**
 * 开通用户所选的私信用户配置
 *
 * 如果lpPrivateLetterOpt=true（支持自动开通能力）且页面所选的私信用户列表不为空，
 * 则调用快聊接口，自动开通广告主在建站配置的私信用户的配置能力
 * 如果该用户没有开通私信，则会自动开通
 *
 * @param {PrivateLetterState[]} privateLetterUserInfo - 当前页面选择的所有私信用户信息数组。
 * @param {boolean} lpPrivateLetterOpt - 是否使用新的私信开通方案。
 * @throws {Error} 如果调用 `privateUserOpenUsingPOST` 方法时发生错误，将抛出异常。
 */
export const openPrivateLetterUserInfo = async (params: {
    privateLetterUserInfo: PrivateLetterState[];
    lpPrivateLetterOpt: boolean;
    components?: ComponentJson<AnyObj>[];
}) => {
    const { privateLetterUserInfo, lpPrivateLetterOpt, components } = params;
    if (!privateLetterUserInfo?.length || !lpPrivateLetterOpt) return;

    let componentsIdList: string[] = [];
    if (components?.length) {
        componentsForEach(components, (comp) => {
            if (['CUSTOMER_SERVICE', 'CUSTOMER_SERVICE_DRAG'].includes(comp.type)) {
                componentsIdList.push(comp.id);
            } else if (
                comp.type === 'MULTI_CONSULT_DRAG' &&
                comp.props?.tabs?.some((tab) => tab.type === ConsultType.onlineConsult)
            ) {
                componentsIdList.push(comp.id);
            }
        });
    }
    try {
        let params: any[] = [];
        for (let item of privateLetterUserInfo) {
            if (item.compId.some((id) => componentsIdList?.includes(id)) || !components?.length) {
                const { compId, showOpenContent, ...otherParams } = item;
                if (otherParams?.uid) {
                    params.push({
                        ...otherParams,
                    });
                }
            }
        }

        if (params?.length) {
            await privateUserOpenUsingPOST(params);
        }
    } catch (e) {
        throw e;
    }
};

export const saveOrSubmitThunk =
    (
        save: boolean,
        cb: () => void,
        auditDirectPublish?: boolean,
        onSuccess?: (...args: any) => Promise<void>,
    ) =>
    async (dispatch: any, getState: () => RootState) => {
        async function saveOrSubmit() {
            const {
                selectComponent,
                setModelState,
                setPageMeta,
                getJSON,
                deleteComponentDynamicElementById,
            } = getSDK();
            const json = cloneDeep(getJSON());

            deleteInvalidDyEl(json, deleteComponentDynamicElementById);
            const state = getState();
            const accountId = state.adUser?.lpUser?.accountId;
            trackAccountId = accountId as number;
            trackPageSubmitRawJson({
                source: save ? 'save' : 'publish',
                pageJson: json,
                accountId: trackAccountId,
            });
            if (state.adUser.lpUser?.templateUser) {
                const com = state.editor.components.find(
                    (com) => !!com.magic?.dragFree && !isGlobalDrag(com),
                );
                if (com) {
                    message.error('有未转换的悬浮组件');
                    trackPageSubmitFrontErrorDetail(trackAccountId, 'template');
                    selectComponent({ id: com.id });
                    return;
                }
            }
            const validateHalfScreenRes = validateHalfScreen(json, save ? '保存' : '发布');
            if (!validateHalfScreenRes) return;
            const validateDirectCallRes = validateDirectCall(json);
            if (!validateDirectCallRes) return;
            const validateOnlyOnePlanIdRes = validateOnlyOnePlanId(json);
            if (!validateOnlyOnePlanIdRes) return message.error('一个页面只能包含一种加粉方案');
            const validateOnlyOneAssistantRes = validateOnlyOneAssistant(json);
            if (!validateOnlyOneAssistantRes) return message.error('一个页面只能包含一种获客链接');
            const validateOnlyOneAssistantGroupRes = validateOnlyOneAssistant(
                json,
                WeChatType.ASSISTANT_GROUP,
            );
            if (!validateOnlyOneAssistantGroupRes)
                return message.error('一个页面只能包含一种获客链接组');
            const retValidatOnlyOneApp = validatOnlyOneApp(json);
            if (!retValidatOnlyOneApp) return message.error('落地页仅支持唯一应用配置');
            const res = await submit(cb);
            console.log('submit error', res);
            if (!res || res.error) {
                console.error(res);
                return;
            }
            const setNameErr = (msg: string) => {
                setModelState((s) => {
                    s.titleState.titleSelected = true;
                    s.titleState.errors.name = msg;
                });
            };
            console.log('json.components====', json.components);
            //表单样式埋点优化：https://team.corp.kuaishou.com/task/T4476446
            const { formComponentType, subFormComponentType, rawWorkWechatLink } =
                getFormComponentType(json);
            setPageMeta({
                subFormComponentType,
                formComponentType,
                rawWorkWechatLink,
            });
            json.pageMeta = {
                ...json.pageMeta,
                subFormComponentType,
                formComponentType,
                rawWorkWechatLink,
            };
            // validateTitle 两个标题的用户，提交校验
            const twoTitlesUser = state.adUser?.lpUser?.twoTitlesUser;

            let pageMetaError;
            if (json?.pageMeta) {
                pageMetaError = validatePageMeta(json);
                if (pageMetaError) {
                    message.error(
                        pageMetaError.formIndex ??
                            pageMetaError.wechatStay ??
                            pageMetaError.pageModal ??
                            pageMetaError.appletsModal,
                    );
                    setModelState((t) => {
                        t.titleState.errors = {
                            ...t.titleState.errors,
                            ...pageMetaError,
                        };
                    });
                }
            }
            if (twoTitlesUser) {
                const { title, name } = json.titleState;
                const nameErr = await validateName({ pageId: state.editor.pageId, name });
                const titleErr = await validateTitle({ pageId: state.editor.pageId, title });
                const hasErr = nameErr || titleErr || !!pageMetaError;
                console.log('nameErr titleErr', titleErr, nameErr, pageMetaError);
                if (hasErr) {
                    trackPageSubmitFrontErrorDetail(trackAccountId, hasErr);
                }
                setModelState((s) => {
                    s.titleState.errors.name = nameErr;
                    s.titleState.errors.title = titleErr;
                    if (hasErr) {
                        s.titleState.titleSelected = true;
                        s.selectedId = undefined;
                    }
                });
                if (hasErr) {
                    return;
                }
            }
            if (auditDirectPublish) {
                delete res.pageId;
            }
            // 这里的components不是原来的components
            const {
                components,
                componentDynamicElements,
                name,
                pageId,
                title,
                preVersionPageId,
                outPageId,
                preVersion,
            } = res;
            // 处理表单的预约下载组件
            handleFormAppointmentDownload(components);
            const rawComponents = json.components;
            // 当在动态组件白名单内，且含有动态组件时为4 (0:落地页, 1:模板 2:半屏, 3:门店, 4：优选)
            const pageType =
                state.adUser.lpUser?.optimizationPageUser &&
                componentDynamicElements &&
                componentDynamicElements.length > 0
                    ? 4
                    : 0;

            let acquisitionGroupDistributeType: number[] = [];

            /** 收集微信组件 分布表单里的获客链接组 distributeType */
            const collectDistributeTypeForWxDrag = (comp) => {
                if (!['WEI_XIN_DRAG', 'XIANSUO_FORM', 'XIANSUO_FORM_DRAG'].includes(comp?.type))
                    return;
                const { props, type } = comp || {};
                if (
                    type === 'WEI_XIN_DRAG' &&
                    props?.dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP &&
                    props?.wechatType === WeChatType.ASSISTANT_GROUP &&
                    props?.opt
                ) {
                    if (props?.opt?.distributeType || props?.opt?.distributeType === 0) {
                        acquisitionGroupDistributeType.push(props?.opt?.distributeType as number);
                    }
                }

                if (
                    type === 'XIANSUO_FORM' &&
                    props?.wechatOpt &&
                    (props?.wechatOpt?.distributeType || props?.wechatOpt?.distributeType === 0)
                ) {
                    acquisitionGroupDistributeType.push(props?.wechatOpt?.distributeType as number);
                }

                if (
                    type === 'XIANSUO_FORM_DRAG' &&
                    props?.formSetting?.submitTurn?.itemConfig?.weChatOpt
                ) {
                    const { opt, dialogStyleType, wechatType } =
                        props?.formSetting?.submitTurn?.itemConfig?.weChatOpt ?? {};
                    if (
                        dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP &&
                        wechatType === WeChatType.ASSISTANT_GROUP &&
                        opt &&
                        (opt?.distributeType || opt?.distributeType === 0)
                    ) {
                        acquisitionGroupDistributeType.push(opt?.distributeType as number);
                    }
                }
            };

            // 提交
            console.log('json.pageMeta=====', json.pageMeta);
            const pageJson = {
                components: componentsMap(components, (com) => {
                    const newCom = resetComponentProps(com);
                    const { type, version, props, id, magic, components } = newCom;
                    collectDistributeTypeForWxDrag(newCom);
                    const res: ComponentJson = {
                        id,
                        type,
                        version,
                        props,
                        magic,
                        components,
                    };
                    return res;
                }),
                componentDynamicElements,
                name,
                type: pageType,
                preVersionPageId,
                preVersion,
                outPageId,
                pageMeta: json.pageMeta,
            };

            if (title) {
                (pageJson as any).title = title;
            }

            // 收集微信挽留里的获客链接组distributeType
            if (pageJson.pageMeta) {
                const { wechatStay } = pageJson.pageMeta || {};
                if (
                    wechatStay &&
                    wechatStay?.dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP &&
                    wechatStay?.wechatType === WeChatType.ASSISTANT_GROUP &&
                    wechatStay?.opt &&
                    (wechatStay?.opt?.distributeType || wechatStay?.opt?.distributeType === 0)
                ) {
                    !acquisitionGroupDistributeType.includes(
                        wechatStay?.opt?.distributeType as number,
                    ) &&
                        acquisitionGroupDistributeType.push(
                            wechatStay?.opt?.distributeType as number,
                        );
                }
            }
            await setFontToComponents(pageJson.components, false, String(accountId));
            await uploadCutMaterialToLibrary(pageJson.components);
            const creatOrSavePage = async (
                p: typeof pageJson,
                id?: string,
                auditDirectPublish?: boolean,
            ) => {
                // 复制页面的场景会把老的acquisitionGroupDistributeType也带上，需要提前排除掉，用新的
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                const { acquisitionGroupDistributeType: oldAcq, ...rest } = p?.pageMeta || {};
                const req: any = {
                    ...p,
                    pageMeta: Object.assign(
                        JSON.parse(JSON.stringify(rest || {})) || {},
                        {
                            subFormComponentType: subFormComponentType,
                            formComponentType: formComponentType,
                        },
                        acquisitionGroupDistributeType.length > 0
                            ? {
                                  acquisitionGroupDistributeType: [
                                      ...acquisitionGroupDistributeType,
                                  ],
                              }
                            : {},
                    ),
                };
                console.log('req====', req);
                trackPageSubmitFinalJson({
                    source: save ? 'save' : 'publish',
                    pageJson: req,
                    accountId: trackAccountId,
                });
                // 有PageId, 走保存落地页接口
                if (id) {
                    await uploadBindMaterial(id, pageJson.components);
                    delete req.outPageId;
                    delete req.preVersion;
                    delete req.preVersionPageId;
                    // 保存落地页
                    return (
                        await savePageUsingPOST({
                            req: {
                                ...req,
                                id,
                            },
                        })
                    ).data;
                }
                const { templateId } = json;
                if (templateId) {
                    req.templateId = templateId;
                }
                if (auditDirectPublish) {
                    // 保存并发布落地页
                    const data = (await createAndPublishPageUsingPOST({ req })).data;
                    onSuccess?.(true, data);

                    await uploadBindMaterial(data?.id, pageJson.components);

                    return data;
                }
                delete req.outPageId;
                delete req.preVersion;
                delete req.preVersionPageId;

                // 没有PageId,走创建落地页接口
                const data = (await createPageUsingPOST({ req })).data;

                await uploadBindMaterial(data?.id, pageJson.components);

                return data;
            };

            const privateLetterUserInfo = state.pageInfo?.privateLetterUserInfo || [];

            const lpPrivateLetterOpt = state.adUser.lpUser?.lpPrivateLetterOpt || false;

            // 保存落地页
            if (save) {
                try {
                    trackCreatePageNotPublish();
                    // console.log(document.getElementById('preview-app'));
                    // h(document.getElementById('preview-app')!, {
                    //     allowTaint:true,
                    //     useCORS:true,
                    //     onrendered: function(canvas: HTMLCanvasElement) {
                    //         const image = canvas.toDataURL('image/png');
                    //         console.log(image);
                    //     },
                    // } as any);

                    await openPrivateLetterUserInfo({
                        privateLetterUserInfo,
                        lpPrivateLetterOpt,
                        components: pageJson?.components,
                    });

                    const res = await creatOrSavePage(pageJson, pageId);
                    trackPageCreateWithWeblogger(
                        trackAccountId,
                        (res as IPageView).id,
                        !!state.pageInfo.containPsdModule,
                        'save',
                    );

                    if ((res as IPageView)?.id) {
                        setModelState({
                            pageId: String((res as IPageView).id),
                            postedComponents: rawComponents,
                        });
                    }

                    message.success('保存成功');
                    onSuccess?.(true, res);
                    trackPageSubmitSuccess('save');
                } catch (e) {
                    const msg = e.msg || '保存失败';
                    console.error('失败===', e);
                    onSuccess?.(false);

                    trackPageSubmitServerErrorDetail(
                        save ? 'save' : 'publish',
                        trackAccountId,
                        msg,
                    );
                    message.error(msg);
                    e.result === 1001 && msg && setNameErr(msg);
                }
            } else {
                let publishRes: IPageView;
                if (!inPublish) {
                    inPublish = true;
                    trackCreatePageWithPublish();
                    try {
                        await openPrivateLetterUserInfo({
                            privateLetterUserInfo,
                            lpPrivateLetterOpt,
                            components: pageJson?.components,
                        });

                        const res = await creatOrSavePage(pageJson, pageId, auditDirectPublish);
                        publishRes = res;
                        trackPageCreateWithWeblogger(
                            trackAccountId,
                            (res as IPageView).id,
                            !!state.pageInfo.containPsdModule,
                            'publish',
                        );
                        inPublish = false;
                        if (!auditDirectPublish) {
                            await publishPageUsingPOST({ id: (res as IPageView).id });
                            message.success('发布成功');
                        }

                        if (pageJson?.pageMeta?.anchorForm) {
                            if (pageJson.pageMeta.anchorForm.checked) {
                                trackCreatePageUsingAnchorForm();
                                dataTrackPageUseAnchorForm(pageId ?? (res as IPageView).id);
                            } else {
                                trackCreatePageNotUseAnchorForm();
                                dataTrackPageNotUseAnchorForm(pageId ?? (res as IPageView).id);
                            }
                        }
                    } catch (e: any) {
                        inPublish = false;
                        console.error('失败===', e);
                        // todo log error
                        const msg = e.msg || '发布失败';
                        trackPageSubmitServerErrorDetail(
                            save ? 'save' : 'publish',
                            trackAccountId,
                            msg,
                        );
                        message.error(msg);
                        e.result === 1001 && msg && setNameErr(msg);
                        return;
                    }
                }
                ReactDOM.unmountComponentAtNode(window.previewEditorRect);
                trackPageSubmitSuccess('publish');
                setTimeout(async () => {
                    disableConfirm();
                    await onSuccess?.(true, res);
                    whiteListHistory.push(`/page/list`, {
                        fromPageId: outPageId || pageId || publishRes.id,
                    });
                }, 500);
                // history.push('/page/list');
            }
        }
        dispatch(pageInfoSlice.actions.setPublishStatus(true));
        try {
            await saveOrSubmit();
        } catch (e) {
            console.error('[publish error]', e);
        }
        dispatch(pageInfoSlice.actions.setPublishStatus(false));
    };

export const submit = async (cb?: () => void) => {
    const { setModelState, selectComponent, getJSON } = getSDK();
    const state = cloneDeep(store.getState());
    const pageEditState: PageEditState = cloneDeep(getJSON());
    if (!pageEditState.components.length) {
        trackPageSubmitFrontErrorDetail(trackAccountId, 'empty');
        message.error('请选择组件');
        return;
    }
    await componentsAsyncForEach(pageEditState.components, async (com) => {
        const errorRes = await componentValidate(com, pageEditState.components, state);

        if (com.type === 'BUTTON_DRAG') {
            com.editor.error = {
                ...errorRes,
                ...com.editor.error,
            };
        } else {
            com.editor.error = errorRes;
        }
    });
    setModelState(pageEditState);
    const errorCom = findComponent(
        pageEditState.components,
        (com) => Object.keys(com.editor.error).filter((key) => com.editor.error[key]).length !== 0,
    );

    console.log('----errorCom', errorCom);
    // trackPageSubmitFrontError(errorCom?.type as string);

    if (errorCom) {
        // 显示错误
        const firstErrorKey = Object.keys(errorCom?.editor?.error || {})[0];
        const firstError = errorCom.editor.error[firstErrorKey];
        selectComponent({ id: errorCom.id });
        const { type } = errorCom;
        if (typeof firstError.error === 'string' || typeof firstError === 'string') {
            const errorMessage = `${componentsConfig[type].name}组件保存失败：${
                firstError.error ?? firstError ?? ''
            }`;
            message.error(errorMessage);
            trackPageSubmitFrontErrorDetail(trackAccountId, errorMessage);
        }
        if (firstError.error?.message) {
            if (typeof firstError.error?.message === 'string') {
                const errorMessage = `${componentsConfig[type].name}组件保存失败：${firstError.error?.message}`;
                message.error(errorMessage);
                trackPageSubmitFrontErrorDetail(trackAccountId, errorMessage);
            } else {
                message.error(firstError.error?.message);
                trackPageSubmitFrontErrorDetail(
                    trackAccountId,
                    `${componentsConfig[type].name}组件保存失败,提示信息为node节点`,
                );
            }
        }

        return {
            error: errorCom,
        };
    }
    // 没有错误
    const { componentDynamicElements = [] } = pageEditState;
    const validatRes = validateDynamicsElements(componentDynamicElements);
    if (!validatRes.flag) {
        trackPageSubmitFrontErrorDetail(trackAccountId, validatRes.msg);
        message.error(validatRes.msg);
        validatRes?.errorId && selectComponent({ id: validatRes?.errorId });

        return;
    }
    console.log('[validatRes]', validatRes);
    cb?.();

    const { pageId: id, titleState, preVersionPageId, outPageId, preVersion } = pageEditState;
    const { name, title } = titleState;
    const processedComponent = cloneDeep(pageEditState.components);
    console.log('[processedComponent]', processedComponent);
    if (!processedComponent) return;

    componentsForEach(processedComponent, (com) => {
        const { formatJson } = componentsConfig[com.type];
        if (formatJson) {
            formatJson(com);
        }
    });
    return {
        components: processedComponent,
        componentDynamicElements,
        name,
        pageId: id,
        title,
        preVersionPageId,
        preVersion,
        outPageId,
    };
};

export const showPreview =
    () =>
    async (
        dispatch: any,
        /** 编辑页预览，预览前进行校验 */
        getState: () => RootState,
    ) => {
        const state = getState();
        const res = await submit();
        const { setModelState } = getSDK();
        if (!res || res.error) {
            return;
        }
        const validateHalfScreenRes = validateHalfScreen(state.editor, '预览');
        if (!validateHalfScreenRes) return;
        const twoTitlesUser = state.adUser?.lpUser?.twoTitlesUser;
        if (twoTitlesUser) {
            const { title, name } = state.editor.titleState;
            const nameErr = await validateName({ pageId: state.editor.pageId, name });
            const titleErr = await validateTitle({ pageId: state.editor.pageId, title });
            const hasErr = nameErr || titleErr;
            setModelState((s) => {
                s.titleState.errors.name = nameErr;
                s.titleState.errors.title = titleErr;
                if (hasErr) {
                    s.titleState.titleSelected = true;
                    s.selectedId = undefined;
                }
            });
            if (hasErr) {
                return;
            }
        }
        setModelState((s) => {
            s.isShowPreview = true;
        });
    };
