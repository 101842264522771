import React from 'react';

export enum AuditStatus {
    NotAudited,
    AuditInProcess,
    AuditPassed,
    AuditRejected,
    ReAuditNeeded,
    ReAuditing,
    ReAuditPassed,
    ReAuditRejected,
    /* 组件审核汇总 */
    ComponentAuditing,
    /* 组件审核拒绝 */
    componentRejected,
}
export const HoverTipsMap: { [key in AuditStatus]: string } = {
    [AuditStatus.NotAudited]: '草稿状态，不可用于投放',
    [AuditStatus.AuditInProcess]: '审核中，不可投放和编辑',
    [AuditStatus.AuditPassed]: '审核通过，可用于投放',
    [AuditStatus.AuditRejected]:
        '审核拒绝，不可用于投放，您可修改内容重新审核，审核通过后可用于投放',
    [AuditStatus.ReAuditNeeded]:
        '审核通过的落地页再次编辑时会重新审核，审核过程中此落地页所关联的广告计划依然使用旧版内容投放',
    [AuditStatus.ReAuditing]:
        '审核通过的落地页再次编辑时会重新审核，审核过程中此落地页所关联的广告计划依然使用旧版内容投放',

    [AuditStatus.ReAuditPassed]:
        '审核通过的落地页再次编辑时会重新审核，重审通过后，此落地页所关联的广告计划即会替换为新版内容',
    [AuditStatus.ReAuditRejected]:
        '审核通过的落地页再次编辑时会重新审核，重审拒绝后，此落地页所关联的广告计划依然使用旧版内容投放。您可继续编辑旧版内容或点击修改按钮在被拒内容上进行修改',
    [AuditStatus.ComponentAuditing]: '审核中，不可投放和编辑',
    [AuditStatus.componentRejected]: '组件审核未通过，请到线索通修改组件信息并手动送审',
};
export const TextContentMap: (isWhiteList?: boolean) => {
    [key in AuditStatus]: React.ReactNode;
} = (isWhiteList?: boolean) => ({
    [AuditStatus.NotAudited]: isWhiteList ? '草稿已保存' : '已保存',
    [AuditStatus.AuditInProcess]: '页面审核中',
    [AuditStatus.AuditPassed]: isWhiteList ? '审核通过，已发布' : '已发布',
    [AuditStatus.AuditRejected]: '审核拒绝',
    [AuditStatus.ReAuditNeeded]: '重审中',
    [AuditStatus.ReAuditing]: '重审中',
    [AuditStatus.ReAuditPassed]: '重审通过，已发布',
    [AuditStatus.ReAuditRejected]: '重审拒绝',
    [AuditStatus.ComponentAuditing]: '组件审核中',
    [AuditStatus.componentRejected]: '组件审核拒绝',
});

export const ColorsMap: { [key in AuditStatus]: string } = {
    [AuditStatus.NotAudited]: 'rgba(0, 5, 25,.45)',
    [AuditStatus.AuditInProcess]: '#3E7CF7',
    [AuditStatus.AuditPassed]: '#3CCC3B',
    [AuditStatus.AuditRejected]: '#FF4A4A',
    [AuditStatus.ReAuditNeeded]: '#3E7CF7',
    [AuditStatus.ReAuditing]: '#3E7CF7',
    [AuditStatus.ReAuditPassed]: '#3CCC3B',
    [AuditStatus.ReAuditRejected]: '#FF4A4A',
    [AuditStatus.ComponentAuditing]: '#3E7CF7',
    [AuditStatus.componentRejected]: '#FF4A4A',
};

export const ReAuditMap: Partial<{ [key in AuditStatus]: string }> = {
    [AuditStatus.ReAuditNeeded]: '审核通过',
    [AuditStatus.ReAuditing]: '审核通过',
    [AuditStatus.ReAuditPassed]: '已保存',
    [AuditStatus.ReAuditRejected]: '已保存',
};
