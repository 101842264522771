import axios from '../request';
import * as model from './models';

/** 程序化落地页  批量创建落地页组 */
export async function batchCreateGroupUsingPOST(payload: { req: model.IGroupBatchCreateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseBatchCreateGroupView>({
        url: `/rest/lp/group/batch/create`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  创建结果导出 */
export async function exportResultUsingPOST(payload: { resultId?: string }) {
    const data = {
        resultId: payload.resultId,
    };

    const result = await axios.request<model.IResponsestring>({
        url: `/rest/lp/group/batch/export`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  获取落地页组转化类型 */
export async function getComponentsUsingPOST() {
    const result = await axios.request<model.IResponseListstring>({
        url: `/rest/lp/group/batch/listComponent`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  落地页列表 */
export async function listBatchCreatePageUsingPOST(payload: {
    req: model.IGroupCreatePageListReq;
}) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapGroupCreatePageView>({
        url: `/rest/lp/group/batch/listPage`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  前缀重复校验 */
export async function verifyPrefixUsingPOST(payload: { prefix?: string }) {
    const data = {
        prefix: payload.prefix,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/group/batch/verifyPrefix`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  创建落地页组 */
export async function createGroupUsingPOST(payload: { req: model.IGroupCreateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/group/create`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  删除落地页组 */
export async function deleteGroupUsingPOST(payload: { groupId?: string }) {
    const data = {
        groupId: payload.groupId,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/group/delete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  回收站列表 */
export async function listDeletedGroupUsingPOST(payload: { splitReq: model.ISplitReq }) {
    const data = {
        ...payload.splitReq,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageGroupView>({
        url: `/rest/lp/group/deleted/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  获取落地页组列表 */
export async function listGroupUsingPOST(payload: { req: model.IPageGroupListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageGroupView>({
        url: `/rest/lp/group/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  删除组内落地页 */
export async function deletePageOfGroupUsingPOST(payload: { req: model.IGroupPageDeleteReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePageSwitchView>({
        url: `/rest/lp/group/page/delete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  建站落地页编辑详情 */
export async function getPageUsingPOST(payload: { outPageId?: string }) {
    const data = {
        outPageId: payload.outPageId,
    };

    const result = await axios.request<model.IResponsePageView>({
        url: `/rest/lp/group/page/get`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  建站落地页列表 */
export async function listPageUsingPOST(payload: { req: model.IPageListQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapGroupPageView>({
        url: `/rest/lp/group/page/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  落地页投放开关 */
export async function switchPageUsingPOST(payload: { pageSwitchReq: model.IPageSwitchReq }) {
    const data = {
        ...payload.pageSwitchReq,
    };

    const result = await axios.request<model.IResponsePageSwitchView>({
        url: `/rest/lp/group/page/switch`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  自建站落地页改名 */
export async function updateThirdNameUsingPOST(payload: { req: model.IUpdateThirdPageNameReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/group/page/updateName`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  获取落地页组报表 */
export async function getReportUsingPOST(payload: { req: model.IGroupReportReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseAdLpGroupReportView>({
        url: `/rest/lp/group/report`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  恢复落地页组 */
export async function restoreGroupUsingPOST(payload: { groupId?: string }) {
    const data = {
        groupId: payload.groupId,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/group/restore`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  更新落地页组 */
export async function updateGroupUsingPOST(payload: { req: model.IGroupUpdateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/group/update`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 程序化落地页  落地页组名校验 */
export async function verifyGroupNameUsingPOST(payload: { name?: string; groupId?: string }) {
    const data = {
        name: payload.name,
        groupId: payload.groupId,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/group/verifyName`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

export async function postBatchModifyPageMeta(params: model.modifyPageMetaReq[]) {
    const result = await axios.post('/rest/lp/page/batchModifyPageMeta', {
        modifyPageMetaReq: params,
    });
    return result.data.data;
}

export interface IPageGroupSubmit {
    groupId?: string;
    groupName: string;
    groupSource: number;
    outPageIdList: string[];
    thirdPageList: { pageName: string; url: string }[];
}

export async function submitOrUpdateGroup(params: IPageGroupSubmit, groupId?: string) {
    const url = `/rest/lp/group/${groupId ? 'update' : 'create'}`;
    if (groupId) {
        params.groupId = groupId;
    }
    return (await axios.post(url, params)).data;
}
