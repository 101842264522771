import axios from '../request';
import * as model from './models';

/** 弹窗接口  创建弹窗 */
export async function createPopupUsingPOST(payload: { req: model.IPopupCreateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePopupView>({
        url: `/rest/lp/popup/create`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 弹窗接口  删除弹窗 */
export async function deletePopupUsingPOST(payload: { req: model.IPopupSimpleReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/popup/delete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return result.data;
}

/** 弹窗接口  查询弹窗详情 */
export async function getPopupDetailUsingPOST(payload: { req: model.IPopupSimpleReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePopupView>({
        url: `/rest/lp/popup/detail/info`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 弹窗接口  分页查询弹窗列表 */
export async function listPopupUsingPOST(payload: { req: model.IPopupListQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPopupView>({
        url: `/rest/lp/popup/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return result.data.data;
}

/** 弹窗接口  建站落地页列表 */
export async function popupPageListUsingPOST(payload: { req: model.IPopupPageListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPopupPageInfoView>({
        url: `/rest/lp/popup/page/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 弹窗接口  更新弹窗 */
export async function savePopupUsingPOST(payload: { req: model.IPopupUpdateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePopupView>({
        url: `/rest/lp/popup/save`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 弹窗接口  提交弹窗 */
export async function submitPopupUsingPOST(payload: { req: model.IPopupSubmitReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/popup/submit`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 弹窗接口  终止弹窗 */
export async function terminatePopupUsingPOST(payload: { req: model.IPopupSimpleReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/popup/termination`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}
