import * as React from 'react';
import { Modal } from 'm-ui';
import styles from './style.module.less';

const flag518 = 'moli_info_518';
const flag625 = 'moli_info_625';
/**
 * 默认是否展示。 625前展示一次，625到630展示一次
 */
function defaultVisible() {
    const date = new Date().getTime() / 1000;

    // 6月30号之后
    if (date >= 1593532800) {
        return false;
    }
    // 630 到625
    if (date < 1593532800 && date >= 1593014400) {
        const showed = window.localStorage.getItem(flag625);
        if (showed) {
            return false;
        }
        window.localStorage.setItem(flag625, '1');
        return true;
    }
    if (date < 1593014400) {
        const showed = window.localStorage.getItem(flag518);
        if (showed) {
            return false;
        }
        window.localStorage.setItem(flag518, '1');
        return true;
    }
    return false;
}

export const Info: React.FunctionComponent = () => {
    const [visible, setState] = React.useState(defaultVisible());
    return (
        <Modal
            title="建站升级"
            visible={visible}
            onOk={() => setState(false)}
            onCancel={() => setState(false)}
            destroyOnClose
            cancelButtonProps={{
                className: styles.root,
            }}
        >
            <p>
                旧建站将在6月30日下线，请您在此之前完成落地页和线索数据迁移。欢迎使用磁力建站及线索回传功能，如遇到问题可及时反馈给我们~
            </p>
        </Modal>
    );
};

export default Info;
