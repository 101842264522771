/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment';
import { Tooltip, message, Modal } from 'm-ui';
import { history } from 'src/routes/config';
import { copyToClipboard } from 'src/utils/copyText';
import { deletePageOfGroupUsingPOST, getPageUsingPOST } from 'src/services/page-group-controller';
import { AuditStatus } from 'src/pages/pageList/List/SubComponents/config';
import cs from 'classnames';
import { ExclamationCircleFilled } from '@m-ui/icons';
import { setData } from 'src/utils/stateLocation';
import deleteStyles from '../programmaticTrash/style.module.less';
import question from './img/question.png';
import { ReactComponent as CopyIcon } from './img/copyIcon.svg';
import { groupListData, pageListData } from './redux/programmaticListModel';
import styles from './style.module.less';
import { Position, columnsProps, DeleteDetail, ShowPreviewData } from './index';

export enum Status {
    可用 = 1,
    不可用 = 0,
}

export enum pageSource {
    磁力建站 = 0,
    自建站 = 1,
}

export const timeFormat = (time: number) => {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
};

const commonColumn = [
    // {
    //     title: '近7日平均转化数',
    //     width: 152,
    //     dataIndex: 'avgConversionNum',
    //     key: 'avgConversionNum',
    //     align: Position.right,
    //     render: (value: number, record: pageListData) => {
    //         return (
    //             <>
    //                 <div className={styles.reportAvg}>{value}</div>
    //                 <div className={styles.reportDiff}>
    //                     环比
    //                     <span
    //                         style={{
    //                             color:
    //                                 record.diffAvgConversionNum > 0
    //                                     ? '#F93920'
    //                                     : record.diffAvgConversionNum < 0
    //                                     ? '#27AD60'
    //                                     : '#8C8C8C',
    //                         }}
    //                     >
    //                         {record.diffAvgConversionNum < 0
    //                             ? record.diffAvgConversionNum
    //                             : '+' + record.diffAvgConversionNum}
    //                         %
    //                     </span>
    //                 </div>
    //             </>
    //         );
    //     },
    // },
    {
        title: () => (
            <Tooltip title="率值为周环比">
                近7日平均转化率
                <img src={question} className={styles.questionIcon} alt="" />
            </Tooltip>
        ),
        width: 168,
        dataIndex: 'avgConversionRate',
        key: 'avgConversionRate',
        align: Position.right,
        render: (value: number, record: pageListData) => {
            return (
                <>
                    <div className={styles.reportAvg}>{value}%</div>
                    <div className={styles.reportDiff}>
                        <span
                            style={{
                                color:
                                    record.diffAvgConversionRate > 0
                                        ? '#F93920'
                                        : record.diffAvgConversionRate < 0
                                        ? '#27AD60'
                                        : '#8C8C8C',
                            }}
                        >
                            {record.diffAvgConversionRate < 0
                                ? record.diffAvgConversionRate
                                : `+${record.diffAvgConversionRate}`}
                            %
                        </span>
                    </div>
                </>
            );
        },
    },
    {
        title: () => (
            <Tooltip title="率值为周环比；客户自有落地页无法统计pv，想看pv数据可尝试用磁力建站搭建落地页">
                近7日平均PV
                <img src={question} className={styles.questionIcon} alt="" />
            </Tooltip>
        ),
        width: 135,
        dataIndex: 'avgPv',
        key: 'avgPv',
        align: Position.right,
        render: (value: number, record: pageListData) => {
            return (
                <>
                    <div className={styles.reportAvg}>
                        {record.pageSource === pageSource.自建站 ? '-' : value}
                    </div>
                    <div className={styles.reportDiff}>
                        <span
                            style={{
                                color:
                                    record.diffAvgPv > 0
                                        ? '#F93920'
                                        : record.diffAvgPv < 0
                                        ? '#27AD60'
                                        : '#8C8C8C',
                            }}
                        >
                            {record.diffAvgPv < 0 ? record.diffAvgPv : `+${record.diffAvgPv}`}%
                        </span>
                    </div>
                </>
            );
        },
    },
    {
        title: () => (
            <Tooltip title="率值为周环比">
                近7日行为数
                <img src={question} className={styles.questionIcon} alt="" />
            </Tooltip>
        ),
        width: 152,
        dataIndex: 'avgEventNum',
        key: 'avgEventNum',
        align: Position.right,
        render: (value: number, record: pageListData) => {
            return (
                <>
                    <div className={styles.reportAvg}>{value}</div>
                    <div className={styles.reportDiff}>
                        <span
                            style={{
                                color:
                                    record.diffAvgEventNum > 0
                                        ? '#F93920'
                                        : record.diffAvgEventNum < 0
                                        ? '#27AD60'
                                        : '#8C8C8C',
                            }}
                        >
                            {record.diffAvgEventNum < 0
                                ? record.diffAvgEventNum
                                : `+${record.diffAvgEventNum}`}
                            %
                        </span>
                    </div>
                </>
            );
        },
    },
    { title: '更新时间', width: 184, dataIndex: 'updateTime', key: 'updateTime' },
] as columnsProps[];

const deleteColumn = [
    {
        title: '删除时间',
        width: 184,
        dataIndex: 'updateTime',
        key: 'updateTime',
    },
] as columnsProps[];

export const tableColumns = (
    setDeleteDetail: (param: DeleteDetail) => void,
    isDeleted: boolean,
    showModal?: (param: boolean) => void,
    isWhiteList = false,
) => {
    const baseColumn = [
        {
            title: '落地页组信息',
            width: 232,
            dataIndex: 'groupName',
            key: 'groupName',
            render: (value: string, record: groupListData) => {
                return (
                    <div className={styles.groupDetail}>
                        <Tooltip title={value} placement="topLeft">
                            <p className={styles.groupName}>{value}</p>
                        </Tooltip>
                        <p className={styles.groupId}>
                            组ID：
                            {record.groupId}
                            <CopyIcon
                                className={styles.copyIcon}
                                onClick={() => {
                                    const result = copyToClipboard(record.groupId);
                                    result
                                        ? message.success('组ID已复制')
                                        : message.error('复制失败，请重试！');
                                }}
                            />
                        </p>
                    </div>
                );
            },
        },
        {
            title: '状态',
            width: 108,
            dataIndex: 'usable',
            key: 'usable',
            render: (value: number, record: groupListData) => {
                return (
                    <>
                        {isDeleted ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={deleteStyles.usable} />
                                <span>已删除</span>
                            </div>
                        ) : (
                            <Tooltip
                                title={
                                    record.groupSource === pageSource.磁力建站
                                        ? '当含有审核通过且开关打开的落地页时，落地页组为【可用】状态，可在广告平台投放'
                                        : '当含有开关打开的落地页时，落地页组为【可用】状态，可在广告平台投放'
                                }
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        className={styles.status}
                                        style={{
                                            background:
                                                value === Status.可用 ? '#27AD60' : '#F93920',
                                        }}
                                    />
                                    <span className={styles.statusValue}>
                                        {value === Status.可用 ? '可用' : '不可用'}
                                    </span>
                                </div>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            title: '落地页数',
            width: 88,
            dataIndex: 'groupNum',
            key: 'groupNum',
            align: Position.right,
        },
    ] as columnsProps[];

    const extraColumn = [
        {
            title: '来源',
            width: 96,
            dataIndex: 'groupSource',
            key: 'groupSource',
            render: (value: number) => (
                <div>{value === pageSource.磁力建站 ? '磁力建站' : '自建站'}</div>
            ),
        },
        {
            title: '操作',
            key: 'operation',
            fixed: Position.right,
            width: 148,
            render: (record: groupListData) => {
                return (
                    <>
                        {!isDeleted && isWhiteList && (
                            <span
                                className={styles.operate}
                                onClick={() => {
                                    history.push({
                                        pathname: '/programmaticGroupEdit',
                                        state: record,
                                    });
                                }}
                            >
                                编辑
                            </span>
                        )}
                        <span
                            className={cs(styles.operate, { [styles.marginLeft]: isWhiteList })}
                            onClick={() => {
                                if (isDeleted) {
                                    setDeleteDetail({
                                        groupId: record.groupId,
                                        groupName: record.groupName,
                                    });
                                } else {
                                    showModal?.(true);
                                    setDeleteDetail({
                                        groupId: record.groupId,
                                        groupName: record.groupName,
                                    });
                                }
                            }}
                        >
                            {isDeleted ? '恢复' : '删除'}
                        </span>
                    </>
                );
            },
        },
    ] as columnsProps[];
    if (isDeleted) {
        return baseColumn.concat(deleteColumn, extraColumn);
    }

    return baseColumn.concat(commonColumn, extraColumn);
};

export const subTableColumns = (
    showPreview: (param: ShowPreviewData) => void,
    isDeleted: boolean,
    actions?: any,
    isWhiteList = false,
) => {
    const baseColumn = [
        {
            title: '落地页组信息',
            width: 232,
            dataIndex: 'pageName',
            key: 'pageName',
            render: (value: string, record: pageListData) => {
                return (
                    <div className={styles.groupDetail}>
                        <Tooltip title={value} placement="topLeft">
                            <p className={styles.groupName}>{value}</p>
                        </Tooltip>
                        <p className={styles.groupId}>
                            ID：
                            {record.outPageId}
                            <CopyIcon
                                className={styles.copyIcon}
                                onClick={() => {
                                    const result = copyToClipboard(record.outPageId);
                                    result
                                        ? message.success('落地页ID已复制')
                                        : message.error('复制失败，请重试！');
                                }}
                            />
                        </p>
                    </div>
                );
            },
        },
        {
            title: '落地页数',
            width: 88,
            dataIndex: 'pageNum',
            key: 'pageNum',
            align: Position.right,
        },
    ] as columnsProps[];

    const extraColumn = [
        {
            title: '来源',
            width: 96,
            dataIndex: 'pageSource',
            key: 'pageSource',
            render: (value: number) => (
                <div>{value === pageSource.磁力建站 ? '磁力建站' : '自建站'}</div>
            ),
        },
        {
            title: '操作',
            key: 'operation',
            fixed: Position.right,
            width: 148,
            render: (record: pageListData) => {
                return (
                    <>
                        <span
                            className={styles.operate}
                            onClick={() => {
                                showPreview({
                                    show: true,
                                    url: record.url,
                                    title: record.pageName,
                                    pageMeta: record.pageMeta,
                                    id:
                                        record.pageSource === pageSource.自建站
                                            ? ''
                                            : record.pageId,
                                });
                            }}
                        >
                            预览
                        </span>
                        {record.pageSource === pageSource.磁力建站 && (
                            <Tooltip
                                title={
                                    record.hasDeleted
                                        ? '该落地页不存在'
                                        : '请保证编辑前后落地页转化目标不变'
                                }
                                placement="topRight"
                            >
                                <span
                                    className={cs(styles.operate, styles.marginLeft, {
                                        [styles.disabled]: record.hasDeleted,
                                    })}
                                    onClick={async () => {
                                        if (!record.hasDeleted) {
                                            const res = await getPageUsingPOST({
                                                outPageId: record.outPageId,
                                            });

                                            const {
                                                components,
                                                id,
                                                name,
                                                bizId,
                                                title,
                                                auditStatus,
                                                ...state
                                            } = res.data;
                                            setData({
                                                canSave: auditStatus === AuditStatus.NotAudited,
                                                auditPassed:
                                                    auditStatus === AuditStatus.AuditPassed ||
                                                    auditStatus === AuditStatus.ReAuditPassed,
                                                components,
                                                pageId: id,
                                                id,
                                                name,
                                                bizId,
                                                title,
                                                ...state,
                                            });
                                            history.push({
                                                pathname: '/page/edit',
                                                search: `pageId=${record.outPageId}`,
                                                state: {
                                                    canSave: auditStatus === AuditStatus.NotAudited,
                                                    auditPassed:
                                                        auditStatus === AuditStatus.AuditPassed ||
                                                        auditStatus === AuditStatus.ReAuditPassed,
                                                    components,
                                                    pageId: id,
                                                    id,
                                                    name,
                                                    bizId,
                                                    title,
                                                    ...state,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    编辑
                                </span>
                            </Tooltip>
                        )}
                        {isWhiteList && (
                            <Tooltip
                                title={record.disableDel ? '落地页组内至少包含两个落地页' : ''}
                                placement="topRight"
                            >
                                <span
                                    className={cs(styles.operate, styles.marginLeft, {
                                        [styles.disabled]: record.disableDel,
                                    })}
                                    onClick={async () => {
                                        if (record.disableDel) {
                                            return;
                                        }
                                        Modal.confirm({
                                            title: `确认将落地页“${record.pageName}”从落地页组中删除？`,
                                            icon: (
                                                <ExclamationCircleFilled
                                                    style={{ color: '#FBBD1B' }}
                                                />
                                            ),
                                            content: (
                                                <p>删除后可能影响组的可用状态，并影响在投的广告</p>
                                            ),
                                            okText: '确认',
                                            cancelText: '取消',
                                            onOk: () => {
                                                deletePageOfGroupUsingPOST({
                                                    req: {
                                                        outPageId: record.outPageId,
                                                        groupId: record.groupId ?? '',
                                                    },
                                                })
                                                    .then(() => {
                                                        message.success('删除成功');
                                                        actions.fetchGroupList();
                                                    })
                                                    .catch(() => message.error('删除失败'));
                                            },
                                        });
                                    }}
                                >
                                    删除
                                </span>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ] as columnsProps[];

    const blankColumn = [
        {
            title: '占位',
            width: 48,
            dataIndex: 'blank',
            key: 'blank',
            className: `${styles.blankChildren}`,
            render: () => <div className={styles.blankItem} />,
        },
    ] as columnsProps[];

    if (isDeleted) {
        return blankColumn.concat(baseColumn, deleteColumn, extraColumn);
        // return baseColumn.concat(deleteColumn, extraColumn);
    }

    return blankColumn.concat(baseColumn, commonColumn, extraColumn);
};
