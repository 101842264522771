import axios from '../request';
import * as model from './models';

/** 落地页接口  批量删除页面 */
export async function batchDeleteUsingPOST(payload: { req: model.IBatchDeletePageReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/page/batchDelete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  批量赠送落地页 */
export async function batchGiveAwayUsingPOST(payload: { req: model.IBatchGivePageReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/page/batchGive`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  view组件列表 */
export async function queryCompsUsingGET() {
    const params = {
        // bizId: payload.bizId,
    };

    const result = await axios.request<model.IResponseListViewComponentView>({
        url: `/rest/lp/page/component`,
        method: 'get',
        params,
    });

    return result.data;
}

/** 落地页接口  创建落地页 */
export async function createPageUsingPOST(payload: { req: model.IPageCreateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePageView>({
        url: `/rest/lp/page/create`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  创建并发布落地页 */
export async function createAndPublishPageUsingPOST(payload: { req: model.IPageCreateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePageView>({
        url: `/rest/lp/page/createAndPublish`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  创建预览 */
export async function createPreviewUsingPOST(payload: { req: model.IPagePreviewReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsestring>({
        url: `/rest/lp/page/createPreview`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  拉取已删除的落地页 */
export async function listDeletedPageUsingPOST(payload: { req: model.IPageListQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageView>({
        url: `/rest/lp/page/deleted/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 落地页接口  查看预览页面 */
export async function getPreviewUsingGET(payload: { key: string }) {
    const params = {
        key: payload.key,
    };

    const result = await axios.request<string>({
        url: `/rest/lp/page/getPreview`,
        method: 'get',
        params,
    });

    return result.data;
}

/** 落地页接口  获取同一主体下其他广告主Id */
export async function getSameCorpAccountIdsUsingPOST() {
    const result = await axios.request<model.IResponseListlong>({
        url: `/rest/lp/page/getSameCorpAccountIds`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  获取同一主体下其他广告主信息 */
export async function getSameCorpAccountInfosUsingPOST() {
    const result = await axios.request<model.IResponseAccountInfo>({
        url: `/rest/lp/page/sameCorpAccountInfo`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  白名单用户,复制落地页时,获得去重后的标题和名称 */
export async function nextUniquePageNameUsingPOST(payload: { req: model.IPageUniqueTitleReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePageUniqueTitleView>({
        url: `/rest/lp/page/getUniqueName`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  落地页html */
export async function getHtmlUsingGET(payload: { pageId: number; pageType: number }) {
    const params = {
        pageId: payload.pageId,
        pageType: payload.pageType,
    };

    const result = await axios.request<string>({
        url: `/rest/lp/page/html`,
        method: 'get',
        params,
    });

    return result.data;
}

/** 落地页接口  获取我的落地页列表 */
export async function listPageUsingPOST(payload: { req: model.IPageListQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageView>({
        url: `/rest/lp/page/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  获取审核通过的落地页列表 */
export async function queryPassedListUsingPOST(payload: { req: model.IPageListQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageSimpleView>({
        url: `/rest/lp/page/pass/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  发布页面 */
export async function publishPageUsingPOST(payload: { id?: string }) {
    const data = {
        id: payload.id,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/page/publish`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  批量发布落地页 */
export async function batchPublishUsingPOST(payload: { pageIds?: string[] }) {
    const data = {
        pageIds: payload.pageIds,
    };

    const result = await axios.request<model.IResponseListlong>({
        url: `/rest/lp/page/publish/batch`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  恢复被删除的页面 */
export async function restorePageUsingPOST(payload: { id?: string }) {
    const data = {
        id: payload.id,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/page/restore`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  保存页面 */
export async function savePageUsingPOST(payload: { req: model.IPageUpdateReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePageView>({
        url: `/rest/lp/page/save`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  获取落地页摘要列表 */
export async function listPageSummaryUsingPOST(payload: { req: model.IPageListQueryReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapPageView>({
        url: `/rest/lp/page/summary/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  修改页面名 */
export async function updateNameUsingPOST(payload: { name?: string; id?: string }) {
    const data = {
        name: payload.name,
        id: payload.id,
    };

    const result = await axios.request<model.IResponsePageView>({
        url: `/rest/lp/page/update-name`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  验证落地页标题是否唯一 */
export async function verifyPageNameUsingPOST(payload: { req: model.IVerifyUniqueNameReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/page/verifyName`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  更新页面标题 */
export async function updatePageTitleUsingPOST(payload: { req: model.IUpdateTitleReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseVoid>({
        url: `/rest/lp/page/update-title`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  验证落地页标题是否唯一 */
export async function getPageStatusUsingPOST(payload: { req: { id: string } }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePageStatus>({
        url: `/rest/lp/page/status`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 落地页接口  获取落地页状态 */
export async function pageInfoOfLastUsingPOST(payload: { id?: string }) {
    const data = {
        outPageId: payload.id,
    };

    const result = await axios.request<model.IResponsePageView>({
        url: `/rest/lp/page/info/last`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}
