import { SystemDeleteLine, SystemUploadCloudLine } from '@m-ui/icons';
import { throttle } from 'lodash';
import { Button, Drawer, Form, message, Popconfirm, Upload, Modal, Tooltip } from 'm-ui';
import { UploadChangeParam } from 'm-ui/lib/upload';
import { useState } from 'react';
import { submitPageQualityUsingPOST } from 'src/services-new/page-quality-check-controller';
import styled from 'styled-components';
import {
    AddUrlButton,
    BatchAddButton,
    ClearButton,
    DescDiv,
    DownloadLink,
    EmptyDescDiv,
    EmptyDiv,
    EmptyImage,
    FooterLeftDiv,
    FooterRightDiv,
    ItemWrapper,
    LeftDiv,
    LineDiv,
    MainDiv,
    MainListWrapper,
    ModalConfirmLi,
    ModalConfirmOneLine,
    ModalConfirmTitle,
    ModalConfirmUl,
    QualityFooterWrapper,
    SubmitButton,
    ThirdRemoveButton,
    TotalDiv,
    UrlInput,
} from './style';

interface IProps {
    visible?: boolean;
    onClose: (refresh?: boolean) => void;
}

enum UPLOAD_STATUS {
    BEFORE_UPLOAD = 'before', //未上传
    UPLOADING = 'uploading', //上传中/解析中
    DONE = 'done',
    SUCCESS = 'success',
    ERROR = 'error',
    REMOVED = 'removed',
    HALF_SUCCESS = 'half_success', //部分成功
    EXCEPTION = 'exception', //异常
}

enum UploadStatus {
    SUCCESS,
    ERROR_REPEAT,
    ERROR_FORMAT,
}
interface IUploadResult {
    status: UploadStatus;
    pageUrl: string;
}

interface UrlItem {
    value: string;
    error: string | null;
}

const Wrapper = styled.div`
    min-height: 100%;
    padding: 24px 24px 8px;
    background: #fff;
    border-radius: 4px;
    & .ant-form-item {
        margin-bottom: 0;
    }
`;

const MAX_SELECTION = 20;
const FILE_LIMIT_SIZE = 5;

export function CreateThirdDrawer(props: IProps) {
    const [waitToTest, setWaitToTest] = useState<UrlItem[]>([]);
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false);

    const addUrl = () => {
        setWaitToTest([...waitToTest, { value: '', error: null }]);
    };

    const updateUrl = (index: number, newUrl: string) => {
        const newUrls = [...waitToTest];
        newUrls[index] = { value: newUrl, error: null };
        setWaitToTest(newUrls);
    };

    const validateUrl = (url: UrlItem) => {
        if (!url.value) {
            return `URL不能为空`;
        } else if (!/^(https?:\/\/)[^\s$.?#].[^\s]*$/.test(url.value)) {
            return `请输入正确的落地页地址`;
        }

        return null;
    };

    const validateUrls = async () => {
        let hasError = false;
        const newUrls = waitToTest.map((url, index) => {
            let error = validateUrl(url);
            if (!error && waitToTest.slice(0, index).find((item) => item.value === url.value)) {
                error = '请勿重复添加重复的URL';
            }
            if (error) {
                hasError = true;
            }
            return { ...url, error };
        });

        setWaitToTest(newUrls);

        if (!hasError) {
            // message.success('提交成功！');
            return true;
        }
        return false;
    };
    const submit = throttle(async () => {
        const validateRes = await validateUrls();
        if (!validateRes) return;

        setSubmitLoading(true);
        submitPageQualityUsingPOST({
            req: {
                // pageIds: waitToTest,
                pageList: waitToTest.map((item) => {
                    return {
                        url: item.value,
                    };
                }),
                pageType: 'third',
            },
        })
            .then((res) => {
                if (res.result === 1) {
                    message.success(`${waitToTest.length}个落地页提交检测成功`);
                    setWaitToTest([]);
                } else {
                    message.error(res.msg || '提交失败');
                }
            })
            .catch((err) => {
                message.error(err.msg || '提交失败');
                console.error(err);
            })
            .finally(() => {
                setSubmitLoading(false);
                props?.onClose?.(true);
                setWaitToTest([]);
            });
    }, 1000);
    const closeDrawer = () => {
        const close = () => {
            props?.onClose?.();
            setWaitToTest([]);
        };
        if (waitToTest.length > 0) {
            Modal.confirm({
                title: '当前已选内容还未提交检测, 确认退出吗？',
                onOk: close,
                okText: '确认',
            });
        } else {
            close();
        }
    };
    const handleUpload = (info: UploadChangeParam) => {
        const { status = UPLOAD_STATUS.EXCEPTION, response } = info.file;
        if (status === 'removed') {
            // info.file = [];
            // setUploadStatus(UPLOAD_STATUS.BEFORE_UPLOAD);
        } else if (status === 'done') {
            if (response.result === 1 && (response.data as IUploadResult[])) {
                const newUrls = getDedupeUrls(
                    response.data,
                    waitToTest.map((item) => item.value),
                );
                const newWaitToTest = newUrls.map(
                    (item) =>
                        ({
                            value: item.pageUrl,
                            error: null,
                        } as UrlItem),
                );
                setWaitToTest([...waitToTest, ...newWaitToTest].slice(0, MAX_SELECTION));
                return;
            } else {
                let msg = (response && response.message) || '';
                if (response.result === 208) {
                    msg = `Excel文件过大（最大限制为${FILE_LIMIT_SIZE}M），请修改后重新上传`;
                }
                message.error(msg || '上传失败，请稍后重试');
                return;
            }
        }
    };
    return (
        <Drawer
            title={`添加第三方落地页检测页面`}
            placement="right"
            size={'large'}
            className="quality-create-drawer"
            width={620}
            onClose={closeDrawer}
            visible={props.visible}
            footer={
                <QualityFooterWrapper>
                    <FooterLeftDiv>
                        <TotalDiv>
                            已选择{waitToTest.length}/{MAX_SELECTION}
                        </TotalDiv>
                    </FooterLeftDiv>
                    <FooterRightDiv>
                        <Popconfirm
                            title="当前已选内容还未提交检测, 确认退出吗？"
                            disabled={waitToTest.length === 0}
                            okText="确认"
                            onConfirm={() => {
                                props.onClose();
                                setWaitToTest([]);
                            }}
                        >
                            <Button
                                onClick={() => {
                                    if (waitToTest.length) return;
                                    props.onClose();
                                    setWaitToTest([]);
                                }}
                            >
                                取消
                            </Button>
                        </Popconfirm>
                        <SubmitButton
                            type="primary"
                            disabled={waitToTest.length === 0}
                            loading={submitLoading}
                            onClick={submit}
                        >
                            提交检测
                        </SubmitButton>
                    </FooterRightDiv>
                </QualityFooterWrapper>
            }
        >
            <Wrapper>
                <div
                    className="header"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <LeftDiv>
                        <Tooltip
                            title={
                                waitToTest.length >= MAX_SELECTION
                                    ? `最多添加${MAX_SELECTION}条`
                                    : ''
                            }
                        >
                            <AddUrlButton
                                type="primary"
                                disabled={waitToTest.length >= MAX_SELECTION}
                                onClick={addUrl}
                            >
                                添加url
                            </AddUrlButton>
                        </Tooltip>

                        <Upload
                            name="file"
                            action="/rest/lp/page/quality/third/page/upload"
                            disabled={waitToTest.length >= MAX_SELECTION}
                            showUploadList={false}
                            beforeUpload={(file) => {
                                const isLt50M = file.size / 1024 / 1024 < FILE_LIMIT_SIZE;
                                if (!isLt50M) {
                                    message.error(
                                        `Excel文件过大（最大限制为${FILE_LIMIT_SIZE}M），请修改后重新上传`,
                                    );
                                }
                                return isLt50M;
                            }}
                            multiple={false}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={handleUpload}
                        >
                            <Tooltip
                                title={
                                    waitToTest.length >= MAX_SELECTION
                                        ? `最多添加${MAX_SELECTION}条`
                                        : ''
                                }
                            >
                                <BatchAddButton disabled={waitToTest.length >= MAX_SELECTION}>
                                    <SystemUploadCloudLine />
                                    批量添加
                                </BatchAddButton>
                            </Tooltip>
                        </Upload>
                        <DescDiv>支持xlsx格式，文件大小不超过5M</DescDiv>
                        <LineDiv />
                        <DownloadLink href="https://p1-yx.adkwai.com/kos/nlav10749/site/quality-demo.xlsx">
                            下载模板
                        </DownloadLink>
                    </LeftDiv>
                    <div className="right">
                        <Popconfirm
                            title="您确认要清空所有已添加URL地址吗？"
                            onConfirm={() => setWaitToTest([])}
                            overlayStyle={{
                                width: '236px',
                            }}
                            disabled={waitToTest.length === 0}
                        >
                            <ClearButton type="link" disabled={waitToTest.length === 0}>
                                清空
                            </ClearButton>
                        </Popconfirm>
                    </div>
                </div>
                <MainDiv>
                    <Form form={form} layout="vertical">
                        <MainListWrapper>
                            {waitToTest.length
                                ? waitToTest.map((url, index) => {
                                      return (
                                          <ItemWrapper key={index}>
                                              <Form.Item
                                                  validateStatus={url.error ? 'error' : 'success'}
                                                  help={url.error || ''}
                                              >
                                                  <UrlInput
                                                      value={url.value}
                                                      placeholder="请输入链接地址，仅以https://开头"
                                                      onChange={(e) =>
                                                          updateUrl(index, e.target.value)
                                                      }
                                                  />
                                              </Form.Item>
                                              <ThirdRemoveButton
                                                  onClick={() => {
                                                      const newUrls = [...waitToTest];
                                                      newUrls.splice(index, 1);
                                                      setWaitToTest(newUrls);
                                                  }}
                                              >
                                                  <SystemDeleteLine />
                                              </ThirdRemoveButton>
                                          </ItemWrapper>
                                      );
                                  })
                                : null}
                            {waitToTest.length === 0 && (
                                <EmptyDiv>
                                    <EmptyImage
                                        src="https://h2.static.yximgs.com/kos/nlav10749/empty-third.48c6304f54be0e4c.png"
                                        alt=""
                                    />
                                    <EmptyDescDiv>请添加URL地址</EmptyDescDiv>
                                </EmptyDiv>
                            )}
                        </MainListWrapper>
                    </Form>
                </MainDiv>
            </Wrapper>
        </Drawer>
    );
}

/**
 * 获取去重后的URL地址列表。
 *
 * @param result - 上传结果列表。
 * @param addedUrls - 已添加的URL地址列表。
 * @returns 去重后的URL地址列表。
 */
export function getDedupeUrls(result: IUploadResult[], addedUrls: string[] = []) {
    if (!result.length) {
        message.error('文件为空，URL地址上传失败');
        return [];
    }
    /** 后端检测的合法url */
    const successUrls = result.filter((item) => item.status === UploadStatus.SUCCESS);
    /**  后端检测的重复url */
    const repeatFileUrls = result
        .filter((item) => item.status === UploadStatus.ERROR_REPEAT)
        .map((item) => item.pageUrl);
    /**  前端检测的重复url，和用户已上传的对比 */
    const repeatExistUrls = addedUrls.filter((item) => {
        return successUrls.find((res) => res.pageUrl === item);
    });
    const allRepeatUrls = [...repeatFileUrls, ...repeatExistUrls];
    /** 经过前后端去重的urls */
    const dedupeUrls = successUrls.filter((item) => {
        return !addedUrls?.includes(item.pageUrl);
    });
    const formatErrorUrls = result
        .filter((item) => item.status === UploadStatus.ERROR_FORMAT)
        .map((item) => item.pageUrl);

    const modalContent = (
        <div
            style={{
                padding: '16px',
                borderRadius: '4px',
                background: '#F5F7F9',
            }}
        >
            {allRepeatUrls.length ? (
                <div>
                    <ModalConfirmTitle>
                        {allRepeatUrls.length}个URL地址重复或已添加
                    </ModalConfirmTitle>
                    <ModalConfirmUl>
                        {allRepeatUrls.map((item, index) => {
                            return (
                                <ModalConfirmLi key={index}>
                                    <ModalConfirmOneLine>{item}</ModalConfirmOneLine>
                                </ModalConfirmLi>
                            );
                        })}
                    </ModalConfirmUl>
                </div>
            ) : null}
            {formatErrorUrls.length ? (
                <div>
                    <ModalConfirmTitle>
                        {formatErrorUrls.length}个URL地址不符合要求
                    </ModalConfirmTitle>
                    <ModalConfirmUl>
                        {formatErrorUrls.map((item, index) => {
                            return (
                                <ModalConfirmLi key={index}>
                                    <ModalConfirmOneLine>{item}</ModalConfirmOneLine>
                                </ModalConfirmLi>
                            );
                        })}
                    </ModalConfirmUl>
                </div>
            ) : null}
        </div>
    );
    if (dedupeUrls.length && !allRepeatUrls.length && !formatErrorUrls.length) {
        message.success(`${dedupeUrls.length}个URL地址上传成功`);
    } else if (dedupeUrls.length) {
        Modal.info({
            title: `${dedupeUrls.length}个URL地址上传成功，${
                result.length - dedupeUrls.length
            }个URL地址上传失败`,
            content: modalContent,
            className: 'create-third-drawer-modal',
        });
    } else {
        Modal.error({
            title: `${result.length}个URL地址上传失败`,
            content: modalContent,
            className: 'create-third-drawer-modal',
        });
    }
    return dedupeUrls;
}
