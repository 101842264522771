import { v4 as uuidv4 } from 'uuid';
import { isOnline } from 'omega-shared/utils';
import { ComponentJson, componentsForEach, EditorComponentJson } from 'omega-render';

interface CdnFont {
    name: string;
    cdn?: string;
    expireTime?: string;
}
const now = Date.now();
// 字体信息列表
export const fonts: CdnFont[] = [
    { name: '系统默认字体', cdn: '' },
    { name: '喜鹊乐敦简体-Regular', cdn: '100.ttf' },
    { name: '喜鹊古字典体', cdn: '101.ttf' },
    { name: '喜鹊招牌体-Regular', cdn: '102.ttf' },
    { name: '喜鹊聚珍体', cdn: '103.ttf' },
    { name: '字魂206号-江汉手书', cdn: '104.ttf', expireTime: '2069.12.12' },
    { name: '字魂220号-鸿雁手书', cdn: '105.ttf', expireTime: '2069.12.12' },
    { name: '思源黑体纤细', cdn: 'SourceHanSansCN-ExtraLight.ttf' },
    { name: '思源黑体极细', cdn: 'SourceHanSansCN-Light.ttf' },
    { name: '思源黑体细体', cdn: 'SourceHanSansCN-Normal.ttf' },
    { name: '思源黑体常规', cdn: 'SourceHanSansCN-Regular.ttf' },
    { name: '思源黑体中黑', cdn: 'SourceHanSansCN-Medium.ttf' },
    { name: '思源黑体黑体', cdn: 'SourceHanSansCN-Bold.ttf' },
    { name: '思源黑体粗体', cdn: 'SourceHanSansCN-Heavy.ttf' },
    { name: '文泉驿正黑', cdn: 'wqyzh.ttc' },
    { name: '文泉驿微米黑常规', cdn: 'wqywmh.ttc' },
    { name: '庞门正道标题体', cdn: 'pangmenzhengdaobiaotiti.ttf' },
    { name: '站酷高端黑', cdn: 'zkgdh.ttf' },
    { name: '站酷酷黑', cdn: 'zkkht.ttf' },
    // { name: '站酷快乐体',cdn: 'wqywmh.ttc',},
    { name: '站酷文艺体', cdn: 'zhankuwenyiti.ttf' },
    { name: '站酷小薇logo体', cdn: 'zkxwlogo.otf' },
    { name: '站酷庆科黄油体', cdn: 'zkqkhyt.ttf' },
    { name: '装甲明朝体', cdn: 'zhuangjiamingchaoti.ttf' },
    { name: '濑户字体', cdn: 'laihuziti.ttf' },
    { name: '杨任东竹石体', cdn: 'yrdzst.ttf' },
    { name: '普惠体极细', cdn: 'puhui-Light.otf' },
    { name: '普惠体常规', cdn: 'puhui-Regular.otf' },
    { name: '普惠体中黑', cdn: 'puhui-Medium.otf' },
    { name: '普惠体黑体', cdn: 'puhui-Bold.otf' },
    { name: '普惠体粗体', cdn: 'puhui-Heavy.otf' },
].filter((fontItem) => {
    if (fontItem.expireTime) {
        // 小于0过期
        if (new Date(fontItem.expireTime).getTime() - now < 0) {
            return false;
        }
    }
    return true;
});

const cdnBase = 'https://ali2.a.kwimgs.com/kos/nlav10749/upload_font_package/';
const cheapCDNBase = 'https://ali2.a.kwimgs.com/kos/nlav10749/default/';
const zipCdnBase = 'https://ali2.a.kwimgs.com/kos/nlav10749/zip_fonts/';
function joinFontCDNUrl(cdnName: string) {
    return cdnBase + cdnName;
}

function joinFontCheapCDNUrl(cdnName: string) {
    return `${cheapCDNBase}cheap_${cdnName}`;
}
/**
 * base64到File 类型
 * @param dataurl
 * @param filename
 */
// function dataURLtoFile(dataurl: string, filename: string) {
//     const arr = dataurl.split(',');
//     //@ts-ignore
//     const mime = arr[0].match(/:(.*?);/)[1];
//     const bstr = atob(arr[1]);
//     let n = bstr.length;
//     const u8arr = new Uint8Array(n);
//     while (n--) {
//         u8arr[n] = bstr.charCodeAt(n);
//     }
//     return new File([u8arr], filename, { type: mime });
// }

/**
 * 预览批量加载字体
 * @param fontList
 * @param isEitor
 */
export function batchLoadFonts(fontList: CdnFont[], isEitor: boolean) {
    fontList.forEach((fontItem) => {
        if (isEitor) {
            const fontInfo = fonts.find((font) => font.name === fontItem?.name);
            loadFont(fontInfo);
        } else {
            loadFont(fontItem);
        }
    });
}
/**
 * 预加载字体
 */
export function preLoadAllFont() {
    fonts.forEach((fontItem) => {
        loadFont(fontItem, true);
        preLoadFont(fontItem);
    });
}
/**
 * 预加载编辑器字体选择字体
 * @param fontInfo
 */
export function preLoadFont(fontInfo?: CdnFont) {
    if (!fontInfo) {
        return;
    }

    if (fontInfo.cdn) {
        const url = joinFontCheapCDNUrl(fontInfo.cdn);
        const preloadId = `preload_link_${fontInfo.name}`;
        const preLoadLink = document.getElementById(preloadId);
        if (!preLoadLink) {
            const newLink = document.createElement('link');
            newLink.href = url;
            newLink.as = 'font';
            newLink.id = preloadId;
            newLink.rel = 'preload';
            document.head.appendChild(newLink);
        }
    } else {
        console.debug(`预加载 字体${fontInfo.name}没找到`);
    }
}
/**
 * 加载字体
 * 编辑器加载会加载cheap_ 的字体用作字体选择预览
 * @param fontInfo
 * @param isEitor
 */
export function loadFont(fontInfo?: CdnFont, isEitor?: boolean) {
    if (!fontInfo) {
        return;
    }
    if (fontInfo.cdn) {
        let url = joinFontCDNUrl(fontInfo.cdn);
        let id = fontInfo.name;
        let fontFace = `
                @font-face{
                    font-family: ${fontInfo.name};
                    src: url(${url});
                    font-display: swap;
                }
                `;
        if (isEitor) {
            url = joinFontCheapCDNUrl(fontInfo.cdn);
            fontFace = `
                @font-face{
                    font-family: cheap_${fontInfo.name};
                    src: url(${url});
                    font-display: swap;
                }
                `;
            id = `preload_style_${fontInfo.name}`;
        }

        let fontStyle = document.getElementById(id);
        if (!fontStyle) {
            fontStyle = document.createElement('style');
            fontStyle.id = id;
            fontStyle.innerHTML = fontFace;
            document.head.appendChild(fontStyle);
        } else {
            fontStyle.innerHTML = fontFace;
        }
    } else {
        console.error(`fontface 字体${fontInfo.name}没找到`);
    }
}
/**
 * 字体工具，编辑器使用
 */
const FontFamilyUtil = {
    fonts,
    /**
     * 加载单个字体
     * @param selectFont
     */
    loadFont: (selectFont: CdnFont) => {
        const fontInfo = fonts.find((font) => font.name === selectFont.name);
        loadFont(fontInfo);
    },
    /**
     * 优化字体并上传到cdn
     * @param fontInfo
     * @param text
     */
    fontToBase64(fontInfo: CdnFont, preview: boolean, accountId?: string) {
        if (fontInfo.name === '系统默认字体') {
            console.debug('系统默认字体不不作处理跳过');
        }
        const font = fonts.find((item) => item.name === fontInfo.name);
        if (!font) {
            console.debug('字体没找到', fontInfo.name);
            return;
        }
        if (preview) {
            return {
                ...font,
                cdn: font.cdn ? joinFontCDNUrl(font.cdn) : '',
            };
        }
        const fileName = `${accountId ?? ''}_${uuidv4()}.ttf`;
        return {
            name: font.name,
            cdn: zipCdnBase + fileName,
            fontFileName: fileName,
            //  线上环境 测试环境 cdn 域名有区别
            env: isOnline ? 'online' : 'test',
            expireTime: font.expireTime,
        };
    },
    hasFont(fontFamilyName: string) {
        return !!fonts.find((font) => font.name === fontFamilyName);
    },
};
// 注意，更改这里也需更改csr-node中的字体
const fontText = [
    'text',
    'Text',
    'couponTitle',
    'buttonInfo',
    'appName',
    'appDesc',
    'appDescription',
    'name',
    'description',
    'buttonText',
    'companyName',
    'companyTel',
    'companyAddress',
];

interface RichText {
    type: string;
    children: RichText[];
    text?: string;
    fontFamily?: string;
}

export function getRichText(richText: RichText[]) {
    let text = '';
    richText.forEach((val) => {
        if (val.children) {
            const res = getRichText(val.children);
            text += res;
        }
        if (val.text) {
            text += val.text;
        }
    });
    return text;
}

function getRichFamily(richText: RichText[], fontMap: { [key: string]: string }) {
    richText.forEach((val) => {
        if (val.fontFamily) {
            if (!fontMap[val.fontFamily]) {
                fontMap[val.fontFamily] = '';
            }
            fontMap[val.fontFamily] += val.text ?? '';
        }
        if (val.children) {
            getRichFamily(val.children, fontMap);
        }
    });
    return fontMap;
}
export function setFontToComponents(
    components: ComponentJson[],
    preview: boolean,
    accountId?: string,
) {
    const fontMap = {} as { [key: string]: string };
    componentsForEach(components, (com) => {
        const { props } = com;
        if (props?.richText) {
            getRichFamily(props.richText, fontMap);
        }
        for (const key in props) {
            if (/style/gi.test(key)) {
                const fontFamily = props[key]?.fontFamily;

                if (fontFamily) {
                    for (const itemKey in props) {
                        if (fontText.some((item) => itemKey.includes(item))) {
                            const text = props[itemKey];
                            if (!fontMap[fontFamily]) {
                                fontMap[fontFamily] = '';
                            }
                            if (itemKey === 'richText') {
                                fontMap[fontFamily] = getRichText(text);
                            } else {
                                (fontMap[fontFamily] as string) += (text as string) || '';
                            }
                        }
                    }
                }
            }
            // 处理表单title字体
            if (key === 'mainTitleRich') {
                let mainTitleList = props[key] || [];
                mainTitleList.forEach((item) => {
                    let children = item.children;
                    children.forEach((child) => {
                        let fontFamily = child?.fontFamily;
                        if (fontFamily) {
                            fontMap[fontFamily] += (child.text as string) || '';
                        }
                    });
                });
            }
        }
    });
    console.log('[最终字体]', fontMap);
    const fontList: any[] = [];
    // eslint-disable-next-line guard-for-in
    for (const key in fontMap) {
        const fontItem = FontFamilyUtil.fontToBase64({ name: key, cdn: '' }, preview, accountId);
        fontItem && fontList.push(fontItem);
    }

    if (preview) {
        const [first, ...other] = components;
        if (first) {
            const result = [
                {
                    ...first,
                    magic: {
                        ...first.magic,
                        fontList,
                    },
                },
                ...other,
            ] as EditorComponentJson[];
            return result;
        }
    } else {
        // 第一个组件的magic加载字体
        if (!components[0].magic) {
            components[0].magic = {};
        }

        components[0].magic.fontList = fontList;
    }
}

export default FontFamilyUtil;
