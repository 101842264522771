import {
    Drawer,
    message,
    Table,
    Input,
    Select,
    Button,
    Popconfirm,
    Modal,
    Tooltip,
    Checkbox,
    Dropdown,
    Menu,
} from 'm-ui';
import { useCallback, useEffect, useState } from 'react';
import { auditStatusOptions } from './ListTable';
import { ColumnsType } from 'm-ui/lib/table';
import { throttle } from 'lodash';

import {
    CreateWrapper,
    FilterSearchWrapper,
    PageCover,
    PageCoverWrapper,
    PageDetailRight,
    PageDetailWrapper,
    PageId,
    PageName,
    QualityFooterWrapper,
    RightWrapper,
    StatusFilterWrapper,
} from './style';
import moment from 'moment';
import { listPageUsingPOST } from 'src/services-new/page-controller';

const MAX_SELECTION = 20;
export enum QualityCheckStatus {
    CREATE = 0,
    CHECKING = 1,
    STOP = 2,
    SUCCESS = 3,
    FAIL = 4,
}
// import { debounce } from 'lodash';
import { submitPageQualityUsingPOST } from 'src/services-new/page-quality-check-controller';
import { IPageView } from 'src/services-new/models';
import { SystemArrowLargeDownLine, SystemArrowLargeUpLine } from '@m-ui/icons';
import { CreateThirdDrawer } from './CreateThirdDrawer';
import { webLogger } from 'src/utils/radar';
import { useLpUserInfo } from 'src/utils/useLpUserInfo';

const { Search } = Input;

interface IProps {
    visible?: boolean;
    onClose?: (refresh?: boolean) => void;
    open?: () => void;
    style?: React.CSSProperties;
}

enum CreateType {
    MAGIC = 1,
    THIRD = 2,
}

export function CreateNewDrawer(props: IProps) {
    const [waitToTest, setWaitToTest] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IPageView[]>([]);
    const [auditStatus, setAuditStatus] = useState(-1);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [createType, setCreateType] = useState(CreateType.MAGIC);
    const submit = throttle(() => {
        setSubmitLoading(true);
        submitPageQualityUsingPOST({
            req: {
                // pageIds: waitToTest,
                pageList: waitToTest.map((item) => {
                    return {
                        outPageId: item,
                    };
                }),
                pageType: 'magicSite',
            },
        })
            .then((res) => {
                if (res.result === 1) {
                    message.success(`${waitToTest.length}个落地页提交检测成功`);
                    setWaitToTest([]);
                } else {
                    message.error(res.msg || '提交失败');
                }
            })
            .catch((err) => {
                message.error(err.msg || '提交失败');
                console.error(err);
            })
            .finally(() => {
                setSubmitLoading(false);
                props?.onClose?.(true);
                setWaitToTest([]);
            });
    }, 1000);
    const [searchValue, setSearchValue] = useState('');

    const [splitInfo, setSplitInfo] = useState({
        pageNum: 1,
        pageSize: 10,
        totalNum: 10,
    });
    // const [testingList, setTestingList] = useState<string[]>([]);
    useEffect(() => {
        if (props.visible) {
            fetchData().then((list) => {
                setData(list);
            });
        }
    }, [props.visible]);

    const fetchData = useCallback(async () => {
        setLoading(true);

        try {
            const response = await listPageUsingPOST({
                req: {
                    pageSize: splitInfo.pageSize,
                    pageNum: splitInfo.pageNum,
                    // @ts-expect-error 历史代码，涉及swagger的改动，需推进后端改动
                    auditStatus,
                    filterDirectCall: true,
                    select: searchValue,
                },
            });
            setSplitInfo((splitInfo) => {
                splitInfo.totalNum = response?.data?.splitInfo?.totalNum;
                return splitInfo;
            });
            setLoading(false);
            console.log('[response]', response);

            if (response.data && response.data.list) {
                return response.data.list;
            }
            return [];
        } catch (error) {
            message.error('请求出错');
            setLoading(false);
            return [];
        }
    }, [splitInfo.pageNum, searchValue, auditStatus, splitInfo.pageSize]);
    useEffect(() => {
        fetchData().then((list) => {
            setData(list);
        });
    }, [fetchData]);
    const columns: ColumnsType<IPageView> = [
        {
            title: '落地页信息',
            dataIndex: 'coverImgUrl',
            key: 'id',
            width: 323,
            render: (text, record) => {
                return (
                    <PageDetailWrapper>
                        <PageCoverWrapper>
                            <PageCover src={text}></PageCover>
                        </PageCoverWrapper>
                        <PageDetailRight>
                            <Tooltip title={record.name}>
                                <PageName>{record.name}</PageName>
                            </Tooltip>
                            <PageId>ID：{record.outPageId}</PageId>
                        </PageDetailRight>
                    </PageDetailWrapper>
                );
            },
        },
        {
            title: '审核状态',
            dataIndex: 'auditStatus',
            key: 'auditStatus',
            width: 126,
            render: (text) => {
                return <span>{auditStatusOptions.find((item) => item.value === text)?.label}</span>;
            },
        },
        {
            title: '创建时间',
            dataIndex: 'submitTime',
            key: 'submitTime',
            render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>,
            width: 197,
            sorter: true,
        },
    ];
    const [searchTemp, setSearchTemp] = useState('');
    const accountId = useLpUserInfo('accountId');
    const userId = useLpUserInfo('ksUserId');
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const onDropdownVisibleChange = (visible: boolean) => {
        setDropDownVisible(visible);
    };
    const closeDrawer = (refresh?: boolean | any) => {
        const close = () => {
            props?.onClose?.(refresh);
            setSplitInfo({
                pageNum: 1,
                pageSize: 10,
                totalNum: 10,
            });
            setWaitToTest([]);
        };
        if (waitToTest.length > 0) {
            Modal.confirm({
                title: '当前已选内容还未提交检测, 确认退出吗？',
                onOk: close,
                okText: '确认',
            });
        } else {
            close();
        }
    };
    return (
        <>
            {/* <Button
                type="primary"
                // icon={<SystemAddLine />}
                onClick={() => {
                    // setShowModal(true);
                    props?.open?.();
                }}
            >
                新建检测
            </Button> */}
            <Dropdown
                onVisibleChange={onDropdownVisibleChange}
                overlay={
                    <Menu>
                        <Menu.Item
                            key="1"
                            onClick={() => {
                                setCreateType(CreateType.MAGIC);
                                props?.open?.();
                                webLogger.sendImmediately('CLICK', {
                                    action: 'SITE_QUALITY_CHECK_LIST_NEW_CREATE_BUTTON_CLICK',
                                    params: {
                                        account_id: accountId,
                                        userid: userId,
                                    },
                                    type: 'USER_OPERATION',
                                });
                            }}
                        >
                            磁力建站落地页
                        </Menu.Item>
                        <Menu.Item
                            key="2"
                            onClick={() => {
                                setCreateType(CreateType.THIRD);
                                props?.open?.();
                                webLogger.sendImmediately('CLICK', {
                                    action: 'SITE_QUALITY_CHECK_LIST_NEW_CREATE_BUTTON_CLICK',
                                    params: {
                                        account_id: accountId,
                                        userid: userId,
                                    },
                                    type: 'USER_OPERATION',
                                });
                            }}
                        >
                            第三方落地页
                        </Menu.Item>
                    </Menu>
                }
            >
                <Button type="primary" style={props.style}>
                    新建检测
                    {dropDownVisible ? (
                        <SystemArrowLargeUpLine
                            style={{
                                fontSize: '10px',
                            }}
                        />
                    ) : (
                        <SystemArrowLargeDownLine />
                    )}
                </Button>
            </Dropdown>
            <Drawer
                title={`添加磁力建站落地页检测页面`}
                placement="right"
                size={'large'}
                className="quality-create-drawer"
                width={794}
                onClose={closeDrawer}
                visible={props.visible && createType === CreateType.MAGIC}
                footer={
                    <QualityFooterWrapper>
                        <div
                            className="left"
                            style={{
                                // textAlign: 'left',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div className="total">
                                已选择{waitToTest.length}/{MAX_SELECTION}
                            </div>
                        </div>
                        <div className="right">
                            <Popconfirm
                                title="当前已选内容还未提交检测, 确认退出吗？"
                                disabled={waitToTest.length === 0}
                                okText="确认"
                                onConfirm={() => {
                                    props?.onClose?.();
                                    setWaitToTest([]);
                                }}
                                // onVisibleChange={() => console.log('visible change')}
                            >
                                <Button
                                    onClick={() => {
                                        if (waitToTest.length) return;
                                        props?.onClose?.();
                                        setWaitToTest([]);
                                    }}
                                >
                                    取消
                                </Button>
                            </Popconfirm>
                            <Button
                                type="primary"
                                disabled={waitToTest.length === 0}
                                loading={submitLoading}
                                onClick={submit}
                                style={{
                                    marginLeft: '8px',
                                }}
                            >
                                提交检测
                            </Button>
                        </div>
                    </QualityFooterWrapper>
                }
            >
                <CreateWrapper>
                    <FilterSearchWrapper>
                        <RightWrapper>
                            <StatusFilterWrapper style={{ marginRight: '16px' }}>
                                <span style={{ marginRight: '12px' }}>落地页</span>
                                <Search
                                    allowClear
                                    style={{ marginRight: '16px', width: 256 }}
                                    placeholder="输入页面名称或id"
                                    value={searchTemp}
                                    onChange={(e) => {
                                        console.log('onChange', e.target.value);
                                        setSearchTemp(e.target.value);
                                    }}
                                    onSearch={(value) => {
                                        console.log('onSearch', value);
                                        setSplitInfo((splitInfo) => {
                                            splitInfo.pageNum = 1;
                                            return splitInfo;
                                        });
                                        setSearchValue(value);
                                    }}
                                    // onBlur={(e) => {
                                    //     console.log('onBlur', e.target.value);
                                    //     setUrl(e.target.value);
                                    // }}
                                />
                                <div style={{ marginRight: '12px' }}> 审核状态</div>
                                <Select
                                    value={auditStatus}
                                    style={{ width: 256 }}
                                    onChange={(e) => {
                                        setAuditStatus(e);
                                    }}
                                >
                                    {/* <Option value="jack">Jack</Option> */}
                                    {auditStatusOptions.map((item) => {
                                        return (
                                            <Select.Option value={item.value} key={item.value}>
                                                {item.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </StatusFilterWrapper>
                        </RightWrapper>
                    </FilterSearchWrapper>
                    <Table
                        // style={{
                        //     maxHeight: 'calc(100vh - 500px)',
                        // }}
                        scroll={{ y: 'calc(100vh - 346px)' }} // 注意这里的scroll属性
                        rowSelection={{
                            selectedRowKeys: waitToTest,
                            preserveSelectedRowKeys: true,
                            getCheckboxProps: (record) => ({
                                // 当选择项超过可选范围时，将其他选项设为不可选，或者正在检测中的也不可选
                                disabled:
                                    (waitToTest.length >= MAX_SELECTION &&
                                        !waitToTest.includes(record.outPageId)) ||
                                    record.qualityCheckStatus === QualityCheckStatus.CHECKING,
                                // title: <span>最多选择{MAX_SELECTION}个</span>,
                            }),
                            onChange: (rowKeys, rows) => {
                                // console.log('[onChange]', v, k);
                                setWaitToTest(
                                    rows
                                        .filter((item) => {
                                            return (
                                                item.qualityCheckStatus !==
                                                QualityCheckStatus.CHECKING
                                            );
                                        })
                                        .slice(0, MAX_SELECTION)
                                        .map((value) => value.outPageId),
                                );
                            },
                            renderCell: (checked, record) => (
                                <Tooltip
                                    title={
                                        record.qualityCheckStatus === QualityCheckStatus.CHECKING
                                            ? '检测中'
                                            : waitToTest.length >= MAX_SELECTION &&
                                                !waitToTest.includes(record.outPageId)
                                              ? `最多选择${MAX_SELECTION}个`
                                              : ''
                                    }
                                >
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => {
                                            const { checked } = event.target;
                                            if (checked) {
                                                if (waitToTest.length < MAX_SELECTION) {
                                                    setWaitToTest([
                                                        ...waitToTest,
                                                        record.outPageId,
                                                    ]);
                                                }
                                            } else {
                                                setWaitToTest(
                                                    waitToTest.filter(
                                                        (key) => key !== record.outPageId,
                                                    ),
                                                );
                                            }
                                        }}
                                        disabled={
                                            // 当选择项超过可选范围时，将其他选项设为不可选，或者正在检测中的也不可选
                                            (waitToTest.length >= MAX_SELECTION &&
                                                !waitToTest.includes(record.outPageId)) ||
                                            record.qualityCheckStatus ===
                                                QualityCheckStatus.CHECKING
                                        }
                                    />
                                </Tooltip>
                            ),
                        }}
                        bordered
                        // scroll={{ x: 1328 } as any}
                        loading={loading}
                        dataSource={data}
                        columns={columns}
                        // dataSource={[]}
                        style={{ background: 'rgba(255,255,255,0)' }}
                        rowKey="outPageId"
                        onChange={(pagination, filter, sorter) => {
                            console.log('[onChange]', pagination, filter, sorter);
                            setSplitInfo((splitInfo) => ({
                                ...splitInfo,
                                pageNum: pagination.current || 1,
                                pageSize: pagination?.pageSize || 10,
                            }));
                            // onSortChange(sorter);
                        }}
                        pagination={{
                            // current: splitInfo.pageNum,
                            pageSize: splitInfo.pageSize,
                            total: splitInfo.totalNum,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50'],
                            // showQuickJumper: true,
                        }}
                    ></Table>
                </CreateWrapper>
            </Drawer>
            <CreateThirdDrawer
                visible={props.visible && createType === CreateType.THIRD}
                onClose={closeDrawer}
            ></CreateThirdDrawer>
        </>
    );
}
