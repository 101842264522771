import { RootState } from '@/store';
import { createModel } from 'rt-model';

export interface TelEditState {
    tel: {
        [key in string]: {
            isEdit: Boolean;
        };
    };
}

const getDefaultState = () => {
    return { tel: {} };
};

export const telEditModal = createModel({
    namespace: 'telEditModal',
    defaultState: getDefaultState(),
    reducers: {
        init() {
            return getDefaultState();
        },
    },
    effects: {
        editTelStatus(payload) {
            return async (dispatch, getState) => {
                const state: RootState = getState();
                dispatch(
                    telEditModal.actions.setModelState({
                        tel: {
                            ...state.telEditModal.tel,
                            ...payload,
                        },
                    }),
                );
            };
        },
    },
});
