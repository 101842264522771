import { AfterSuccessDisplayType } from 'omega-shared/types';
import { PageEditState } from 'omega-editor';
import {
    PageMetaJson,
    stayStatusNum,
    WeChatType,
    ComponentDynamicJson,
    componentsForEach,
    GameInfo,
} from 'omega-render';

/** 从外面传入pageJson，直接从pageJson中取components，pageMeta等字段 */
export const validatePageMeta = (pageJson: any) => {
    const pageMeta: PageMetaJson = pageJson?.pageMeta;
    const { anchorForm, wechatStay, pageModal, appletsModal, showAppletsModal } = pageMeta;
    let hasError = false;
    const error: { [key in string]: string } = {};
    if (anchorForm?.checked && !anchorForm?.formIndex) {
        hasError = true;
        error.formIndex = '请选择锚定表单';
    }
    if (wechatStay?.stayStatus === stayStatusNum.SHOW_STAY) {
        const { funcId, applets, wechatType, info } = wechatStay;
        if (!info?.length) {
            hasError = true;
            error.wechatStay = '请输入弹窗文案';
        }
        if (info && info?.length > 15) {
            hasError = true;
            error.wechatStay = '弹窗文案要在15个字以内';
        }
        if (wechatType === WeChatType.MINIAPP && !applets?.appletsId) {
            hasError = true;
            error.wechatStay = '请选择小程序';
        }
        if (wechatType !== WeChatType.MINIAPP && !funcId) {
            hasError = true;
            error.wechatStay = '请选择要绑定的微信号码';
        }
    }
    if (wechatStay?.stayStatus === stayStatusNum.SHOW_WECHAT_GAME_STAY) {
        if (!wechatStay?.gameInfo?.wechatGameId?.length) {
            hasError = true;
            error.wechatStay = '请选择微信小游戏';
        }
        let wechatGameInfo: GameInfo = {} as GameInfo;
        componentsForEach(pageJson.components, (com) => {
            if (com.type === 'WECHAT_GAME') {
                wechatGameInfo = com.props?.gameInfo;
            }
        });
        if (
            wechatGameInfo?.wechatGameId?.length &&
            wechatGameInfo?.wechatGameId !== wechatStay?.gameInfo?.wechatGameId
        ) {
            hasError = true;
            error.wechatStay = '落地页仅支持配置同一个小游戏';
        }
    }
    if (pageModal && pageModal.isShowModal && !pageModal.modalImg) {
        hasError = true;
        error.pageModal = '请填写弹窗图片链接';
    }
    if (showAppletsModal) {
        if (!appletsModal?.modalText?.length) {
            hasError = true;
            error.appletsModal = '请填写弹窗文案';
        }
        if ((appletsModal?.modalText?.length || 0) > 1000) {
            hasError = true;
            error.appletsModal = '弹窗文案不能超过1000';
        }
        if (!appletsModal?.cancelText?.length) {
            hasError = true;
            error.appletsModal = '请填写取消按钮文案';
        }
        if (!appletsModal?.confirmText?.length) {
            hasError = true;
            error.appletsModal = '请填写跳转按钮文案';
        }
    }
    return hasError ? error : undefined;
};

const comTypeMap = {
    DRAG_IMAGE: '图片组件',
    DRAG_TEXT: '文本组件',
    XIANSUO_FORM_DRAG: '表单组件',
};

// 动态组件校验  由于历史原因，动态组件的数据全在在外层的store中，所以没办法在组件的config的自定义校验中进行校验，暂时放在这里校验

export const validateDynamicsElements = (
    componentDynamicElements: ComponentDynamicJson[],
): { flag: boolean; msg: string; errorId?: string } => {
    const ret: { flag: boolean; msg: string; errorId?: string } = { flag: true, msg: '' };
    if (new Set(componentDynamicElements.map((v) => v.compId)).size > 3) {
        ret.flag = false;
        ret.msg = '同一个落地页上最多支持3组优选';
        return ret;
    }
    componentDynamicElements?.forEach((dynamicElement: ComponentDynamicJson) => {
        if (
            dynamicElement.dynamicElementType !== 'IMAGE_CONTENT' &&
            validateDynamicsElementComplete(dynamicElement)
        ) {
            ret.flag = false;
            ret.msg = `【${comTypeMap[dynamicElement.compType]}】配置存在空项，请完善`;
            ret.errorId = dynamicElement.compId;
            return;
        }
        if (
            dynamicElement.dynamicElementType === 'IMAGE_CONTENT' &&
            validateDragImgComplete(dynamicElement)
        ) {
            ret.flag = false;
            ret.msg = `【${comTypeMap[dynamicElement.compType]}】组件至少配置两张，请完善`;
            ret.errorId = dynamicElement.compId;
            return;
        }
        if (validateDynamicsElementRepeat(dynamicElement)) {
            ret.flag = false;
            ret.msg = `【${comTypeMap[dynamicElement.compType]}】配置存在重复，请修改`;
            ret.errorId = dynamicElement.compId;
        }
    });
    return ret;
};

export const validateDynamicsElementComplete = (componentDynamicElement: ComponentDynamicJson) => {
    // 是否有空项 true -是
    return componentDynamicElement.elementValues.filter((value) => !value).length > 0;
};
export const validateDragImgComplete = (componentDynamicElement: ComponentDynamicJson) => {
    // 验证图片是否上传完整，最多三个，最少两张 true - 没有满足两张
    return componentDynamicElement.elementValues.length < 2;
};
export const validateDynamicsElementRepeat = (componentDynamicElement: ComponentDynamicJson) => {
    // 是否重复
    let ret = false;
    const hash: any = {};
    componentDynamicElement.elementValues.forEach((value) => {
        if (hash[value]) {
            ret = true;
        } else {
            hash[value] = true;
        }
    });
    return ret;
};

//  一个页面只能有一个加粉方案
export const validateOnlyOnePlanId = (json: PageEditState): boolean => {
    const planIds: string[] = [];
    // 微信挽留
    if (
        json.pageMeta?.wechatStay?.wechatType === WeChatType.KEFU &&
        json.pageMeta?.wechatStay?.opt?.planeId
    ) {
        planIds.push(json.pageMeta?.wechatStay?.opt?.planeId);
    }
    componentsForEach(json.components, (com) => {
        if (
            com.type === 'WEI_XIN_DRAG' &&
            com?.props?.wechatType === WeChatType.KEFU &&
            com?.props?.funcId
        ) {
            planIds.push(com?.props?.funcId);
        }
        //表单组件，开启弹窗，且选择的是添加微信, 且选择的是微信客服
        if (
            com.type === 'XIANSUO_FORM_DRAG' &&
            com?.props?.formSetting?.submitTurn?.switch &&
            com?.props?.formSetting?.submitTurn?.itemConfig?.afterSuccessDisplayType ===
                AfterSuccessDisplayType.WE_CHAT &&
            com?.props?.formSetting?.submitTurn?.itemConfig?.weChatOpt?.wechatType ===
                WeChatType.KEFU
        ) {
            planIds.push(com?.props?.formSetting?.submitTurn?.itemConfig?.weChatOpt?.opt.planeId);
        }
        // 对话表单&分步表单
        if (
            com.type === 'XIANSUO_FORM' &&
            com?.props?.wechatType === WeChatType.KEFU &&
            com?.props?.wechatFuncId
        ) {
            planIds.push(com?.props?.wechatFuncId);
        }
    });
    //  如果有多种，校验不通过
    if (new Set(planIds).size >= 2) {
        return false;
    }
    return true;
};

//  一个页面只能有一个获客链接
export const validateOnlyOneAssistant = (json: PageEditState, flag?: WeChatType): boolean => {
    const curFlag = flag || WeChatType.ASSISTANT;
    const ids: string[] = [];
    // 微信挽留
    if (json.pageMeta?.wechatStay?.wechatType === curFlag && json.pageMeta?.wechatStay?.opt?.id) {
        ids.push(json.pageMeta?.wechatStay?.opt?.id);
    }
    componentsForEach(json.components, (com) => {
        if (
            com.type === 'WEI_XIN_DRAG' &&
            com?.props?.wechatType === curFlag &&
            com?.props?.funcId
        ) {
            ids.push(com?.props?.funcId);
        }
        //表单组件，开启弹窗，且选择的是添加微信, 且选择的是微信客服
        if (
            com.type === 'XIANSUO_FORM_DRAG' &&
            com?.props?.formSetting?.submitTurn?.switch &&
            com?.props?.formSetting?.submitTurn?.itemConfig?.afterSuccessDisplayType ===
                AfterSuccessDisplayType.WE_CHAT &&
            com?.props?.formSetting?.submitTurn?.itemConfig?.weChatOpt?.wechatType === curFlag
        ) {
            ids.push(com?.props?.formSetting?.submitTurn?.itemConfig?.weChatOpt?.opt.id);
        }
        // 对话表单&分步表单
        if (
            com.type === 'XIANSUO_FORM' &&
            com?.props?.wechatType === curFlag &&
            com?.props?.wechatFuncId
        ) {
            ids.push(com?.props?.wechatFuncId);
        }
    });
    //  如果有多种，校验不通过
    if (new Set(ids).size >= 2) {
        return false;
    }
    return true;
};
// 一个页面仅能包含一个app  包括预约下载
export const validatOnlyOneApp = (json) => {
    let appInfos: any[] = [];
    let ret = true;
    componentsForEach(json?.components, (component) => {
        if (['APP_DOWNLOAD', 'APP_DOWNLOAD_DRAG'].includes(component.type)) {
            appInfos.push({
                appType: component?.props?.appType,
                appInfo: component?.props?.appInfo,
            });
        }
        if ('XIANSUO_FORM_DRAG' === component.type && component?.props?.isAppointment) {
            appInfos.push({
                appType:
                    component?.props?.formSetting?.submitTurn?.itemConfig?.appointmentInfo?.appType,
                appInfo:
                    component?.props?.formSetting?.submitTurn?.itemConfig?.appointmentInfo?.appInfo,
            });
        }
    });
    for (let index = 0; index < appInfos.length - 1; index++) {
        if (appInfos[index + 1]?.appType !== appInfos[index]?.appType) {
            ret = false;
        }
        if (appInfos[index + 1]?.appInfo?.appId !== appInfos[index]?.appInfo?.appId) {
            ret = false;
        }
        if (appInfos[index + 1]?.appInfo?.packageId !== appInfos[index]?.appInfo?.packageId) {
            ret = false;
        }
    }
    return ret;
};
