import { Button, Checkbox, Drawer, message, Modal, Tooltip } from 'm-ui';
import cs from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSetState } from 'react-use';
import { ComponentJson, PageMetaJson } from 'omega-render';
import PageItemTitle from 'src/components/PageItemTitle';
import PageJsonPreview from 'src/components/PageJsonPreview';
// import { BizId, BizIdConfigMap } from 'src/landingPage/common/enums';
import { basePath } from 'src/landingPage/common/utils/env';
import { usePlatformActions, usePlatformState } from 'src/store/hooks';
import { Alert } from 'src/components/PageItemTitle/style';
import { history } from 'src/routes/config';
// import { pushTemplateUsingPOST } from 'src/services/apis';
// import { ILpViewCompView } from 'src/services/models';
import { trackSaveError, trackPageCopy } from 'src/utils/adMonitor';
import { copyToClipboard } from 'src/utils/copyText';
import { isBeta } from 'omega-shared/utils';

import { pushTemplateUsingPOST } from 'src/services/template-controller';
import { IViewCompParam } from 'src/services/models';
import PreviewPage from '../../../components/PreviewPage/index';
import { pageListModel } from '../redux/pageListModel';
import CopyBox from './CopyBox/index';
import { ReactComponent as Copy } from './static/NormalCopyLine.svg';
import styles from './style.module.less';
import { Status } from './SubComponents/AuditStatus';
// import { STATUS as QUALITY_STATUS } from '../../quality/ListTable';
import { AuditStatus } from './SubComponents/config';
import { DynamicReport } from './SubComponents/DynamicReport';
import { getUserId } from 'src/hooks/useUserId';
import { IPageQualityCheckListResult } from 'src/services-new/models';
import { getTag } from 'src/pages/quality/History';
import dayjs from 'dayjs';
import { SystemArrowLargeRightLine, LoadingOutlined, SystemAttentionCircleFill } from '@m-ui/icons';
import qs from 'qs';
import UserGuide from './utils/UserGuide';
import { useLpUserInfo } from 'src/utils/useLpUserInfo';
import { webLogger } from 'src/utils/radar';
import { usePollingQualityDetail } from 'src/pages/quality/utils';

const PublishQualityGuideKey = 'PublishQualityGuideKey';

const pageStatus = {
    0: '已保存',
    1: '已发布',
    2: '已删除',
};

export enum pageType {
    '门店落地页' = 3,
    '优选' = 4,
    '一跳咨询页' = 8,
}

export interface PageItermProps {
    id: string;
    name: string;
    title?: string;
    descForCantEdit?: string;
    latestAuditPassedPage?: any;
    version?: string;
    outPageId?: string;
    rejectReason?: string;
    createTime: number;
    updateTime: number;
    auditStatus: AuditStatus;
    publishStatus: keyof typeof pageStatus;
    pv: number;
    coverImgUrl: string;
    conversion: number;
    page: string;
    components: ComponentJson[];
    conversionRate: string;
    searchValue?: string;
    reload: () => void;
    editName: (name: string, id: string) => void;
    templateUser?: boolean;
    // bizId?: BizId;
    // 是否是半屏落地页
    isHalfPage?: boolean;
    // 是否动态落地页
    isDynamic?: boolean;
    url?: string;
    // onGive: () => {};
    type: number;
    /** 落地页是否可编辑 */
    edit?: boolean;
    pageMeta?: PageMetaJson;
    componentDynamicElements: [];
    /** 质检数据 */
    pageQualityCheckResult?: IPageQualityCheckListResult;
}

export const timeFormat = (time: number) => {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
};
// export const useBizName = (bizId?: BizId) => {
//     return BizIdConfigMap[bizId || BizId.xinxiliu]?.name;
// };
export const useTwoTitle = () => {
    // return usePlatformState((state) => {
    //     return state.adUser.lpUser?.twoTitlesUser;
    // });
    return true;
};
export const usePageTitle = (props: { name: string; title?: string }) => {
    const twoTitlesUser = useTwoTitle();
    if (twoTitlesUser) {
        return props.title;
    }
    return props.name;
};

export const PageIterm: React.FC<PageItermProps> = (props) => {
    const {
        searchValue,
        id,
        name,
        pv,
        conversion,
        rejectReason,
        auditStatus,
        edit,
        outPageId,
        publishStatus,
        descForCantEdit,
        updateTime,
        createTime,
        components,
        editName,
        templateUser,
        // bizId,
        latestAuditPassedPage,
        title,
        coverImgUrl,
        type,
        conversionRate,
        isHalfPage,
        isDynamic,
        componentDynamicElements,
        url,
        pageMeta,
        pageQualityCheckResult,
    } = props;
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { reload: _n1, editName: _n2, ...rest } = props;
    const ReAuditRejected = auditStatus === AuditStatus.ReAuditRejected;
    // 重申拒绝，无过审版本，使用id
    const previewID = ReAuditRejected && latestAuditPassedPage ? latestAuditPassedPage.id : id;
    const qualityUser = useLpUserInfo('qualityUser');
    const accountId = useLpUserInfo('accountId');
    const userId = useLpUserInfo('ksUserId');

    const actions = usePlatformActions(pageListModel.actions);

    let newUrl =
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url ?? `${window.location.origin}${basePath}/rest/n/lp/page/getHtml?pageId=${outPageId}`;
    if (isBeta) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        newUrl = `${window.location.origin}${basePath}/rest/n/lp/page/getHtml?pageId=${outPageId}`;
    }
    const [state, setState] = useSetState({
        showPreview: false,
    });
    interface FormattedQuality {
        status: IPageQualityCheckListResult['status'];
        reportScore: string;
        submitTime: number;
        id: number;
    }
    const [qualityDetailFormatted, setQualityDetailFormatted] = useState<FormattedQuality>();
    useEffect(() => {
        setQualityDetailFormatted(pageQualityCheckResult);
    }, [pageQualityCheckResult]);
    const { qualityDetail, startPolling } = usePollingQualityDetail(
        outPageId ?? '',
        pageQualityCheckResult?.status !== 'CHECKING' ? true : false,
    );

    useEffect(() => {
        if (qualityDetail) {
            setQualityDetailFormatted(qualityDetail);
        }
    }, [qualityDetail]);
    const { enableReEdit, batchOperationList, showEditState } = usePlatformState((state) => {
        return {
            enableReEdit: state.adUser.lpUser?.enableReEdit,
            batchOperationList: state.pageList?.batchOperationList,
            showEditState: state.pageList?.showEditState,
        };
    });
    // 第一次创建、编辑页面不展示编辑时间  这里用创建和编辑页面时间差来判断，小于1秒表示第一次创建
    const showUpdateTime = Math.abs(createTime - updateTime) > 1000;
    // const bizName = useBizName(bizId);

    if (auditStatus === AuditStatus.NotAudited && publishStatus !== 0) {
        trackSaveError(auditStatus, publishStatus);
    }
    const goToQuality = (outPageId?: string) => {
        setTimeout(() => {
            // window.location.reload();
            startPolling(outPageId ?? '');
        }, 2000);
        setTimeout(() => {
            startPolling(outPageId ?? '');
        }, 5000);
        window.window.open(`/quality/list?checkPageId=${outPageId}`, '_blank');
    };

    // const previewComp = cloneDeep(components) as any;
    //
    // componentsForEach(previewComp, com => {
    //     //小窗预览时，全局悬浮组件定位上漂问题解决
    //     if (com.type === 'TEL_DRAG' || com.type === 'WEI_XIN_DRAG' || com.type === 'CUSTOMER_SERVICE_DRAG') {
    //         if (com.props?.position === 'rightBottom' && com.magic!.globalDragFree) {
    //             if (com.magic!.globalDragFree.bottom && com.magic!.globalDragFree.bottom >= 104) {
    //                 com.magic!.globalDragFree.bottom = com.magic!.globalDragFree.bottom - 104;
    //             }
    //         }
    //     }
    // });pi

    const itemSelect = React.useMemo(() => {
        return batchOperationList?.indexOf(outPageId ?? '') !== -1;
    }, [batchOperationList, outPageId]);

    const [showReport, setShowReport] = useState(false);

    const [wechatGameDirectCallUser] = usePlatformState((state) => {
        return [state.adUser.lpUser?.wechatGameDirectCallUser];
    });
    const [visible, setVisible] = useState(false); // 控制模态框的显示
    const [doNotShowAgain, setDoNotShowAgain] = useState(false); // 控制 "不再提示" 复选框的状态

    const handleOk = () => {
        if (doNotShowAgain) {
            // 如果用户选择了 "不再提示"，则可以在此处保存该设置，例如保存到 localStorage
            localStorage.setItem('doNotShowWarning', 'true');
        }
        goToQuality(outPageId);
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const onChangeMultiCheckList = React.useCallback(
        (e) => {
            if (!showEditState) {
                actions.editShowState(true);
            }
            if ((batchOperationList || []).length >= 50 && e.target.checked) {
                message.info('最多可选择50个落地页');
            } else {
                actions.editBatchOperateList({
                    operateAdd: e.target.checked,
                    pageId: outPageId,
                    pageName: name,
                    pageComp: {
                        // bizId: bizId || 0,
                        name,
                        title: title ?? '',
                        type,
                        id,
                        coverImgUrl,
                        components: components as IViewCompParam[],
                        componentDynamicElements,
                        isHalfPage,
                        pageMeta,
                    } as any,
                    wechatGameDirectCallUser,
                });
            }
        },
        [
            actions,
            isHalfPage,
            batchOperationList?.length,
            // bizId,
            name,
            title,
            type,
            coverImgUrl,
            components,
            outPageId,
            id,
            componentDynamicElements,
            pageMeta,
        ],
    );

    const renderTitle = React.useMemo(() => {
        /** 此处半屏，包括native半屏和H5半屏 */
        // eslint-disable-next-line  @typescript-eslint/prefer-nullish-coalescing
        if (isHalfPage || pageMeta?.halfScreen) {
            return <Alert>半屏</Alert>;
        }
        if (type === pageType.门店落地页) {
            return <Alert>门店</Alert>;
        }
        if (type === pageType.优选) {
            return <Alert>优选</Alert>;
        }
        if (type === pageType.一跳咨询页) {
            return <Alert>咨询页</Alert>;
        }
    }, [isHalfPage, type, pageMeta?.halfScreen]);

    useEffect(() => {
        if (!qualityUser) return;
        const parsedUrl = new URL(window.location.href);
        const parsedQuery = qs.parse(parsedUrl.search, { ignoreQueryPrefix: true });

        const fromPageId = parsedQuery['fromPageId']; // 获取参数的值
        const PublishQualityGuide = localStorage.getItem(PublishQualityGuideKey);
        console.log('fromPageId', fromPageId, outPageId, PublishQualityGuide);
        if (fromPageId && fromPageId === outPageId && !PublishQualityGuide) {
            // if)
            if (!pageQualityCheckResult || pageQualityCheckResult?.status !== 'SUCCESS') {
                // User
                UserGuide.show({
                    target: buttonRef.current,
                    opacity: 0,
                    useCoolDown: false,
                    showLater: false,
                });
                localStorage.setItem(PublishQualityGuideKey, 'true');
                delete parsedQuery['fromPageId'];
                const newQueryString = qs.stringify(parsedQuery);
                const newUrl =
                    parsedUrl.origin +
                    parsedUrl.pathname +
                    (newQueryString ? '?' + newQueryString : '');
                window.history.replaceState({}, '', newUrl);
            }
        }
    }, []);
    enum QUALITY_CLICK_TYPE {
        FIRST = 0,
        AGAIN = 1,
        DETAIL = 2,
    }
    const trackQualityClick = (type: QUALITY_CLICK_TYPE) => {
        webLogger.sendImmediately('CLICK', {
            action: 'MY_PAGE_LIST_QUALITY_CHECK_BUTTON',
            params: {
                button_click_type: type,
                account_id: accountId,
                user_id: userId,
            },
            type: 'USER_OPERATION',
        });
    };
    return (
        <li className={cs({ [styles.showBkg]: itemSelect })}>
            <div className={styles.page}>
                <Checkbox
                    className={cs(styles.checkBox, {
                        [styles.resident]: batchOperationList?.length,
                    })}
                    checked={itemSelect}
                    onChange={(e) => {
                        e.stopPropagation();
                        onChangeMultiCheckList(e);
                    }}
                />
                <div className={styles['preview-wrap']}>
                    <div className={styles['QR-code']} />
                    <PageJsonPreview
                        cover={coverImgUrl}
                        pageId={id}
                        pageType={type}
                        components={components}
                        title={title}
                        width={136}
                        height={181}
                    />
                </div>
                <div className={styles.info}>
                    <div>
                        <PageItemTitle
                            className={styles.title}
                            disable={isHalfPage}
                            renderTitle={renderTitle}
                            value={name}
                            onChange={(name) => {
                                editName((name || '').slice(0, 40), id);
                            }}
                            renderText={(name) => (
                                <RenderText
                                    text={getPageName({ name, createTime })}
                                    colorText={searchValue}
                                />
                            )}
                        />
                        <p>
                            创建日期：
                            {timeFormat(createTime)}
                        </p>
                        {showUpdateTime && (
                            <p>
                                修改日期：
                                {timeFormat(updateTime)}
                            </p>
                        )}
                        <p
                            className={styles.copyText}
                            onClick={() => {
                                const result = copyToClipboard(outPageId ?? '');
                                result
                                    ? message.success('已复制')
                                    : message.error('复制失败，请重试！');
                            }}
                        >
                            落地页ID：
                            {outPageId}
                            <Copy />
                        </p>
                        {qualityDetailFormatted?.status === 'SUCCESS' && (
                            <div className={styles.qualityDetail}>
                                <span>质量检测：</span>
                                {getTag(+qualityDetailFormatted?.reportScore)}
                                <span className={styles.score}>
                                    {(+qualityDetailFormatted?.reportScore).toFixed(1) + '分'}
                                </span>
                                <span className={styles.submitTime}>
                                    {dayjs(qualityDetailFormatted?.submitTime).format(
                                        'YYYY-MM-DD HH:mm:ss',
                                    )}
                                </span>
                                <span
                                    className={styles.reportLink}
                                    onClick={() => {
                                        // setShowReport(true);
                                        trackQualityClick(QUALITY_CLICK_TYPE.DETAIL);
                                        window.open(
                                            `/quality/detail?id=${qualityDetailFormatted.id}`,
                                            '_blank',
                                        );
                                    }}
                                >
                                    查看报告
                                    <SystemArrowLargeRightLine className={styles.arrow} />
                                </span>
                            </div>
                        )}
                        {/* <p>
                            投放流量：
                            {bizName}
                        </p> */}
                    </div>
                    {/* eslint-disable eqeqeq */}
                    {/* <Button onClick={showGuideForButton}>lala</Button> */}
                    <Status
                        status={auditStatus}
                        reason={rejectReason}
                        isWhiteList={Boolean(enableReEdit)}
                        disableEdit={edit === false}
                        onEdit={() => {
                            const { name, components, latestAuditPassedPage, ...state } = rest;
                            history.push({
                                pathname: '/page/edit',
                                search: `pageId=${id}`,
                                state: {
                                    canSave: auditStatus === AuditStatus.NotAudited,
                                    auditPassed:
                                        auditStatus === AuditStatus.AuditPassed ||
                                        auditStatus === AuditStatus.ReAuditPassed,
                                    components,
                                    pageId: id,
                                    name,
                                    // bizId,
                                    title,
                                    ...state,
                                },
                            });
                        }}
                    />
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.data}>
                    {isDynamic && (
                        <div>
                            <p className={styles.view} onClick={() => setShowReport(true)}>
                                查看
                            </p>
                            <p>组件优选数据</p>
                        </div>
                    )}
                    <div>
                        <p className={styles.value}>{pv || '-'}</p>
                        <p>昨日pv</p>
                    </div>
                    <div>
                        <p className={styles.value}>{conversion || '-'}</p>
                        <p>昨日转化数</p>
                    </div>
                    <div>
                        <Tooltip placement="topLeft" title="转化数/行为数">
                            <p className={styles.value}>
                                {conversionRate ? `${conversionRate}%` : '-'}
                            </p>
                            <p>昨日转化率</p>
                        </Tooltip>
                    </div>
                </div>
                {!isHalfPage && (
                    <div className={styles.edit}>
                        {qualityUser && type !== pageType.一跳咨询页 && (
                            <Button
                                data-page-id={outPageId}
                                ref={buttonRef}
                                style={{
                                    marginLeft: '8px',
                                }}
                                onClick={() => {
                                    if (qualityDetailFormatted?.status === 'CHECKING') {
                                        return;
                                    }
                                    if (qualityDetailFormatted?.status === 'SUCCESS') {
                                        trackQualityClick(QUALITY_CLICK_TYPE.AGAIN);
                                    } else {
                                        trackQualityClick(QUALITY_CLICK_TYPE.FIRST);
                                    }
                                    const doNotShowWarning =
                                        localStorage.getItem('doNotShowWarning');
                                    if (doNotShowWarning === 'true') {
                                        goToQuality(outPageId);
                                        return;
                                    }
                                    setVisible(true);
                                }}
                            >
                                {qualityDetailFormatted?.status === 'SUCCESS' ? (
                                    '再次检测'
                                ) : qualityDetailFormatted?.status === 'CHECKING' ? (
                                    <span>
                                        <LoadingOutlined
                                            style={{
                                                marginRight: '4px',
                                            }}
                                        />
                                        检测中
                                    </span>
                                ) : (
                                    '质量检测'
                                )}
                            </Button>
                        )}

                        {templateUser && !isDynamic && type !== pageType.门店落地页 && (
                            <Button
                                className={cs(styles.btn, styles['btn-primary'])}
                                onClick={() => {
                                    pushTemplateUsingPOST({ pageId: id })
                                        .then(() => {
                                            message.success('模版已经提交');
                                        })
                                        .catch((e) => {
                                            message.error(e.msg || '提交失败');
                                        });
                                }}
                            >
                                提交模板
                            </Button>
                        )}

                        <div>
                            {visible && !localStorage.getItem('doNotShowWarning') && (
                                <Modal
                                    title={
                                        <span>
                                            <SystemAttentionCircleFill
                                                style={{
                                                    marginRight: '10px',
                                                    color: '#FBBD1B',
                                                }}
                                            />
                                            提交质量检测
                                        </span>
                                    }
                                    visible={visible}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                    footer={[
                                        <Checkbox
                                            key="doNotShowAgain"
                                            style={{ float: 'left' }}
                                            onChange={(e) => setDoNotShowAgain(e.target.checked)}
                                        >
                                            不再提示
                                        </Checkbox>,
                                        <Button key="back" onClick={handleCancel}>
                                            取消
                                        </Button>,
                                        <Button key="submit" type="primary" onClick={handleOk}>
                                            确定
                                        </Button>,
                                    ]}
                                >
                                    <div
                                        style={{
                                            marginLeft: '26px',
                                        }}
                                    >
                                        点击确认后，将提交当前落地页进行质量检测，并跳转至质量检测页面，确认提交吗？
                                    </div>
                                </Modal>
                            )}
                        </div>
                        {auditStatus !== AuditStatus.NotAudited && (
                            <>
                                <Button
                                    className={cs(styles.btn, styles['btn-small'])}
                                    onClick={() => {
                                        setState({ showPreview: true });
                                    }}
                                >
                                    预览
                                </Button>
                                {state.showPreview && (
                                    <PreviewPage
                                        title={title}
                                        id={previewID}
                                        onClosePreview={() => setState({ showPreview: false })}
                                        pageMeta={pageMeta}
                                        components={
                                            ReAuditRejected && latestAuditPassedPage
                                                ? latestAuditPassedPage
                                                : rest
                                        }
                                        hideUseBtn={true}
                                    />
                                )}
                            </>
                        )}
                        {(auditStatus === AuditStatus.AuditPassed ||
                            auditStatus === AuditStatus.ReAuditPassed ||
                            auditStatus === AuditStatus.ReAuditRejected) &&
                            type !== pageType.一跳咨询页 && (
                                <>
                                    <Button className={cs(styles.btn, styles['btn-small'])}>
                                        <CopyBox
                                            className={styles.btn}
                                            url={newUrl}
                                            text="成功复制地址"
                                        >
                                            复制地址
                                        </CopyBox>
                                    </Button>
                                </>
                            )}
                        <Button
                            className={cs(styles.btn, styles['btn-small'])}
                            onClick={() => {
                                const ReAuditRejected = auditStatus === AuditStatus.ReAuditRejected;
                                trackPageCopy();
                                // const { reload, editName, ...rest } = props;
                                if (type === pageType.一跳咨询页) {
                                    history.push({
                                        pathname: '/page/imPage',
                                        state: {
                                            ...(ReAuditRejected && latestAuditPassedPage
                                                ? latestAuditPassedPage
                                                : rest),
                                            copyId:
                                                ReAuditRejected && latestAuditPassedPage
                                                    ? latestAuditPassedPage.id
                                                    : id,
                                        },
                                    });
                                    return;
                                }
                                if (type === pageType.门店落地页) {
                                    history.push({
                                        pathname: '/page/list/createNewPage/storePage',
                                        state: {
                                            ...(ReAuditRejected && latestAuditPassedPage
                                                ? latestAuditPassedPage
                                                : rest),
                                            copyId:
                                                ReAuditRejected && latestAuditPassedPage
                                                    ? latestAuditPassedPage.id
                                                    : id,
                                        },
                                    });
                                } else {
                                    history.push({
                                        pathname: '/page/edit',
                                        state: {
                                            ...(ReAuditRejected && latestAuditPassedPage
                                                ? latestAuditPassedPage
                                                : rest),
                                            copyId:
                                                ReAuditRejected && latestAuditPassedPage
                                                    ? latestAuditPassedPage.id
                                                    : id,
                                        },
                                    });
                                }
                            }}
                        >
                            复制页面
                        </Button>
                        {/* //白名单内的用户：新版的编辑逻辑 */}
                        {/* // 白名单外的用户：遵循线上的编辑逻辑，即只有草稿下才有编辑入口，其他状态下都没有编辑入口，在落地页编辑态下也都没有任何提示 */}
                        {(!!enableReEdit || auditStatus === AuditStatus.NotAudited) && (
                            // eslint-disable-next-line eqeqeq
                            <Tooltip title={edit === false ? descForCantEdit : undefined}>
                                {/* eslint-disable-next-line eqeqeq */}
                                <Button
                                    disabled={edit === false}
                                    className={cs(styles.btn, styles['btn-small'])}
                                    onClick={() => {
                                        if (type === pageType.一跳咨询页) {
                                            history.push({
                                                pathname: `/page/imPage`,
                                                state: {
                                                    // 除去草稿状态之外，都不可保存，只能直接编辑发布 https://wiki.corp.kuaishou.com/pages/viewpage.action?pageId=714366149
                                                    canSave: auditStatus === AuditStatus.NotAudited,
                                                    auditPassed:
                                                        auditStatus === AuditStatus.AuditPassed ||
                                                        auditStatus === AuditStatus.ReAuditPassed,
                                                    pageId:
                                                        ReAuditRejected && latestAuditPassedPage
                                                            ? latestAuditPassedPage.id
                                                            : id,
                                                    ...(ReAuditRejected && latestAuditPassedPage
                                                        ? latestAuditPassedPage
                                                        : rest),
                                                },
                                            });
                                            return;
                                        }
                                        if (type === pageType.门店落地页) {
                                            history.push({
                                                pathname: '/page/list/createNewPage/storePage',
                                                search: ``,
                                                state: {
                                                    ...(ReAuditRejected && latestAuditPassedPage
                                                        ? latestAuditPassedPage
                                                        : rest),
                                                },
                                            });
                                        } else {
                                            //再次编辑时清空localStorage数据
                                            let pageId = id;
                                            let userId = getUserId();
                                            window.localStorage.removeItem(
                                                (pageId || userId) + '_save_data',
                                            );
                                            history.push(`/page/edit?pageId=${id}`, {
                                                // 除去草稿状态之外，都不可保存，只能直接编辑发布 https://wiki.corp.kuaishou.com/pages/viewpage.action?pageId=714366149
                                                canSave: auditStatus === AuditStatus.NotAudited,
                                                auditPassed:
                                                    auditStatus === AuditStatus.AuditPassed ||
                                                    auditStatus === AuditStatus.ReAuditPassed,
                                                pageId:
                                                    ReAuditRejected && latestAuditPassedPage
                                                        ? latestAuditPassedPage.id
                                                        : id,
                                                ...(ReAuditRejected && latestAuditPassedPage
                                                    ? latestAuditPassedPage
                                                    : rest),
                                            });
                                        }
                                    }}
                                >
                                    编辑
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
            <div className={styles['bottom-line']} />
            <Drawer
                title="组件优选数据"
                width={560}
                visible={showReport}
                onClose={() => {
                    setShowReport(false);
                }}
            >
                <DynamicReport outPageId={outPageId ?? ''} />
            </Drawer>
        </li>
    );
};
const RenderText: React.FC<{ text: string; colorText?: string }> = (props) => {
    const { colorText, text } = props;
    if (colorText) {
        const start = text.indexOf(colorText);
        if (colorText && start > -1 && colorText) {
            return (
                <>
                    {text.substring(0, start)}
                    <span style={{ color: '#3E7CF7' }}>{colorText}</span>
                    {text.substring(start + colorText.length)}
                </>
            );
        }
    }
    return <>{text}</>;
};

/**
 * 获取落地页名称。 用户没有填写title的落地页，使用落地页+创建时间命名
 * @param data
 */
export const getPageName = (data: { name?: string; createTime: number }) => {
    const { name, createTime } = data;
    return name ?? `落地页${moment(createTime).format('MM-DD HH:mm')}`;
};
