import "../chunk-SP5VRKU3.js";

// upgrade/upgrade.ts
import { cloneDeep as cloneDeep2 } from "lodash";

// upgrade/base.ts
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
var getBlankWithHeightAndImage = (options = {}) => {
  const { tempHeight, height, imgUrl, bottomBlank } = options;
  let { activeOption = "color", backgroundColor = "rgba(255, 255, 255, 1)" } = options;
  if (imgUrl) {
    activeOption = "img";
  }
  console.log("backgournd");
  if (backgroundColor.startsWith("rgba") && !backgroundColor.endsWith(")")) {
    backgroundColor = backgroundColor.concat(")");
  }
  return {
    id: uuidv4(),
    type: "BLANK",
    version: "1.0.0",
    props: {
      style: {
        tempHeight,
        height,
        backgroundColor,
        backgroundImage: imgUrl ? `url(${imgUrl})` : ""
      },
      activeOption
    },
    magic: {
      editHeight: height,
      fontList: [],
      bottomBlank
    },
    components: []
  };
};
var getXiansuoFormJson = (id, options, opt) => {
  const { formOpt, andromedaFormId } = options;
  console.log("-----opt", opt);
  return {
    id,
    type: "XIANSUO_FORM_DRAG",
    version: "1.0.0",
    props: {
      newUserAgreement: true,
      showLabel: "show",
      theme: "#3089FF",
      titleStyle: {
        color: "#595C69"
      },
      formStyle: {
        backgroundColor: "#fff"
      },
      buttonSizeType: "default",
      inputStyle: {
        backgroundColor: "#fff",
        borderColor: "#E0E0E0"
      },
      formItemWrapperStyle: {
        marginTop: 15
      },
      submitStyle: {
        width: 325,
        height: 44,
        fontSize: 16,
        borderRadius: 24
      },
      submitWrapperStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: 28
      },
      placeholderColor: "#C6C6C6",
      newAgreement: true,
      funcId: andromedaFormId,
      formOpt,
      formSetting: {
        count: {
          switch: false,
          itemConfig: {}
        },
        apply: {
          switch: false,
          itemConfig: {}
        }
      }
    },
    magic: {
      dragFree: {
        x: 0,
        y: 8,
        width: 375,
        includeAnchor: ["left", "right"]
      }
    }
  };
};
function imageUpgrade(json, options, child) {
  var _a;
  if (child) {
    json.type = "DRAG_IMAGE";
    return json;
  }
  const height = (_a = json.magic) == null ? void 0 : _a.editHeight;
  const blank = getBlankWithHeightAndImage({ height });
  json.type = "DRAG_IMAGE";
  json.magic = {
    editHeight: height,
    dragFree: {
      height,
      holdRatio: true,
      width: 375,
      x: 0,
      y: 0
    }
  };
  if (json.components) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blank.components = [json, ...anotherChild];
  } else {
    blank.components = [json];
  }
  return blank;
}
function formUpgrade(json, options) {
  var _a, _b, _c, _d, _e;
  const blankJson = getBlankWithHeightAndImage({ tempHeight: 400 });
  const option = (_a = options == null ? void 0 : options.formInfoList) == null ? void 0 : _a.find((item) => {
    var _a2;
    return item.formId === ((_a2 = json.props) == null ? void 0 : _a2.formId);
  });
  const hasUserCount = (_c = (_b = json.props) == null ? void 0 : _b.components) == null ? void 0 : _c.some((com) => com.type === "USER_COUNT");
  if (!option) {
    throw Error(`\u672A\u627E\u5230${(_e = (_d = json.props) == null ? void 0 : _d.formId) != null ? _e : "\u7A7A"}\u7684\u8868\u5355\u914D\u7F6E`);
  }
  const formJson = getXiansuoFormJson(json.id, option, { hasUserCount });
  if (json.components) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [formJson, ...anotherChild];
  } else {
    blankJson.components = [formJson];
  }
  return blankJson;
}
function appDownloadUpgrade(json) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
  const blankBackgroundColor = (_a = json.props) == null ? void 0 : _a.backgroundColor;
  const buttonWidth = (_c = (_b = json.props) == null ? void 0 : _b.buttonStyle) == null ? void 0 : _c.width;
  const buttonHeight = (_e = (_d = json.props) == null ? void 0 : _d.buttonStyle) == null ? void 0 : _e.height;
  let width = typeof buttonWidth === "number" ? buttonWidth : +((_g = (_f = buttonWidth == null ? void 0 : buttonWidth.split("px")) == null ? void 0 : _f[0]) != null ? _g : "");
  const height = typeof buttonHeight === "number" ? buttonHeight : +((_i = (_h = buttonHeight == null ? void 0 : buttonHeight.split("px")) == null ? void 0 : _h[0]) != null ? _i : "");
  const bottomBlank = (_j = json.props) == null ? void 0 : _j.bottom;
  const blankJson = getBlankWithHeightAndImage({
    height: ((_k = json.props) == null ? void 0 : _k.index) === 5 ? 72 : height + 20,
    backgroundColor: blankBackgroundColor,
    bottomBlank
  });
  json.type = "APP_DOWNLOAD_DRAG";
  let x = 24;
  if (width > 375 - 24 * 2) {
    width = 375 - 24 * 2;
  } else {
    x = (375 - width) / 2;
  }
  if (((_l = json.props) == null ? void 0 : _l.index) === 5) {
    json.magic = {
      dragFree: {
        x: 0,
        y: 0,
        width: 375,
        height: 72
      }
    };
  } else {
    json.magic = {
      dragFree: {
        x,
        y: 10,
        width,
        height
      }
    };
  }
  ((_m = json == null ? void 0 : json.props) == null ? void 0 : _m.buttonStyle) && delete json.props.buttonStyle.width;
  ((_n = json == null ? void 0 : json.props) == null ? void 0 : _n.buttonStyle) && delete json.props.buttonStyle.height;
  ((_p = (_o = json.props) == null ? void 0 : _o.buttonStyle) == null ? void 0 : _p.margin) && delete json.props.buttonStyle.margin;
  ((_r = (_q = json.props) == null ? void 0 : _q.buttonStyle) == null ? void 0 : _r.padding) && delete json.props.buttonStyle.padding;
  if ((_s = json == null ? void 0 : json.magic) == null ? void 0 : _s.isFullScreen) {
    return json;
  }
  if (json.components && !bottomBlank) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    json.components = [];
    blankJson.components = [json];
  }
  return blankJson;
}
function buttonUpgrade(json) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  const blankBackgroundColor = (_a = json.props) == null ? void 0 : _a.backgroundColor;
  const buttonWidth = (_c = (_b = json.props) == null ? void 0 : _b.buttonStyle) == null ? void 0 : _c.width;
  const buttonHeight = (_e = (_d = json.props) == null ? void 0 : _d.buttonStyle) == null ? void 0 : _e.height;
  const bottomBlank = (_f = json.props) == null ? void 0 : _f.bottom;
  let width = typeof buttonWidth === "number" ? buttonWidth : +((_h = (_g = buttonWidth == null ? void 0 : buttonWidth.split("px")) == null ? void 0 : _g[0]) != null ? _h : "");
  const height = typeof buttonHeight === "number" ? buttonHeight : +((_j = (_i = buttonHeight == null ? void 0 : buttonHeight.split("px")) == null ? void 0 : _i[0]) != null ? _j : "");
  const blankJson = getBlankWithHeightAndImage({
    height: height + 20,
    backgroundColor: blankBackgroundColor,
    bottomBlank
  });
  json.type = "BUTTON_DRAG";
  let x = 24;
  if (width > 375 - 24 * 2) {
    width = 375 - 24 * 2;
  } else {
    x = (375 - width) / 2;
  }
  json.magic = {
    dragFree: {
      x,
      y: 10,
      width,
      height
    }
  };
  ((_k = json == null ? void 0 : json.props) == null ? void 0 : _k.buttonStyle) && delete json.props.buttonStyle.width;
  ((_l = json == null ? void 0 : json.props) == null ? void 0 : _l.buttonStyle) && delete json.props.buttonStyle.height;
  if (json.components && !bottomBlank) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    json.components = [];
    blankJson.components = [json];
  }
  return blankJson;
}
function mapUpgrade(json) {
  const blankJson = getBlankWithHeightAndImage({
    height: 82
  });
  json.type = "MAP_DRAG";
  json.magic = {
    dragFree: {
      x: 0,
      y: 0,
      width: 375,
      height: 82
    }
  };
  if (json.components) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    blankJson.components = [json];
  }
  return blankJson;
}
function weixinUpgrade(json) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const blankBackgroundColor = (_a = json.props) == null ? void 0 : _a.backgroundColor;
  const buttonWidth = (_c = (_b = json.props) == null ? void 0 : _b.buttonStyle) == null ? void 0 : _c.width;
  const buttonHeight = (_e = (_d = json.props) == null ? void 0 : _d.buttonStyle) == null ? void 0 : _e.height;
  const bottomBlank = ((_f = json.props) == null ? void 0 : _f.position) === "bottom";
  if (bottomBlank) {
    json.props.position = "bottomBlank";
  }
  let width = typeof buttonWidth === "number" ? buttonWidth : +((_h = (_g = buttonWidth == null ? void 0 : buttonWidth.split("px")) == null ? void 0 : _g[0]) != null ? _h : "");
  const height = typeof buttonHeight === "number" ? buttonHeight : +((_j = (_i = buttonHeight == null ? void 0 : buttonHeight.split("px")) == null ? void 0 : _i[0]) != null ? _j : "");
  const blankJson = getBlankWithHeightAndImage({
    height: ((_k = json.props) == null ? void 0 : _k.position) === "rightBottom" ? 0.1 : height + 20,
    backgroundColor: blankBackgroundColor,
    bottomBlank
  });
  json.type = "WEI_XIN_DRAG";
  let x = 24;
  if (width > 375 - 24 * 2) {
    width = 375 - 24 * 2;
  } else {
    x = (375 - width) / 2;
  }
  json.props && (json.props.iconStyle = { fill: "white" });
  json.magic = {
    dragFree: {
      x,
      y: 10,
      width,
      height
    },
    globalDragFree: {
      right: 16,
      top: 504,
      width: 64,
      height: 96,
      excludeAnchor: ["left", "right", "top", "bottom"],
      holdRatio: true
    }
  };
  ((_l = json == null ? void 0 : json.props) == null ? void 0 : _l.buttonStyle) && delete json.props.buttonStyle.width;
  ((_m = json == null ? void 0 : json.props) == null ? void 0 : _m.buttonStyle) && delete json.props.buttonStyle.height;
  if (json.components && !bottomBlank) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    json.components = [];
    blankJson.components = [json];
  }
  return blankJson;
}
function customerServiceUpgrade(json) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const blankBackgroundColor = (_a = json.props) == null ? void 0 : _a.backgroundColor;
  const buttonWidth = (_c = (_b = json.props) == null ? void 0 : _b.buttonStyle) == null ? void 0 : _c.width;
  const buttonHeight = (_e = (_d = json.props) == null ? void 0 : _d.buttonStyle) == null ? void 0 : _e.height;
  const bottomBlank = ((_f = json.props) == null ? void 0 : _f.position) === "bottom";
  if (bottomBlank) {
    json.props.position = "bottomBlank";
  }
  let width = typeof buttonWidth === "number" ? buttonWidth : +((_h = (_g = buttonWidth == null ? void 0 : buttonWidth.split("px")) == null ? void 0 : _g[0]) != null ? _h : "");
  const height = typeof buttonHeight === "number" ? buttonHeight : +((_j = (_i = buttonHeight == null ? void 0 : buttonHeight.split("px")) == null ? void 0 : _i[0]) != null ? _j : "");
  const blankJson = getBlankWithHeightAndImage({
    height: ((_k = json.props) == null ? void 0 : _k.position) === "rightBottom" ? 0.1 : height + 20,
    backgroundColor: blankBackgroundColor,
    bottomBlank
  });
  json.type = "CUSTOMER_SERVICE_DRAG";
  let x = 24;
  if (width > 375 - 24 * 2) {
    width = 375 - 24 * 2;
  } else {
    x = (375 - width) / 2;
  }
  json.magic = {
    dragFree: {
      x,
      y: 10,
      width,
      height
    },
    globalDragFree: {
      right: 16,
      top: 504,
      width: 64,
      height: 96,
      excludeAnchor: ["left", "right", "top", "bottom"],
      holdRatio: true
    }
  };
  ((_l = json == null ? void 0 : json.props) == null ? void 0 : _l.buttonStyle) && delete json.props.buttonStyle.width;
  ((_m = json == null ? void 0 : json.props) == null ? void 0 : _m.buttonStyle) && delete json.props.buttonStyle.height;
  if (json.components && !bottomBlank) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    json.components = [];
    blankJson.components = [json];
  }
  return blankJson;
}
function shufflingImageUpgrade(json) {
  var _a, _b, _c, _d, _e;
  const ratio = (_b = (_a = json.props) == null ? void 0 : _a.ratio) != null ? _b : 1;
  const type3 = ((_c = json.props) == null ? void 0 : _c.renderType) === 3;
  const type2 = ((_d = json.props) == null ? void 0 : _d.renderType) === 2;
  let imgWidth = 375;
  if (type3) {
    imgWidth = 273;
  }
  if (type2) {
    imgWidth = 312;
  }
  const ratioHeight = imgWidth / ratio;
  const editHeight = (_e = json.magic) == null ? void 0 : _e.editHeight;
  const height = ratioHeight != null ? ratioHeight : editHeight;
  const blankJson = getBlankWithHeightAndImage({ height: type3 ? +height + 32 : height });
  json.type = "SHUFFLING_IMAGE_DRAG";
  json.magic = {
    dragFree: {
      x: 0,
      y: 0,
      width: 375,
      height: type3 ? +height + 32 : height,
      excludeAnchor: ["left", "right", "top", "bottom"],
      holdRatio: true
    }
  };
  if (json.components) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    blankJson.components = [json];
  }
  return blankJson;
}
function videoUpgrade(json) {
  var _a;
  const height = (_a = json.magic) == null ? void 0 : _a.editHeight;
  const blankJson = getBlankWithHeightAndImage({ height });
  json.type = "VIDEO_DRAG";
  json.magic = {
    dragFree: {
      x: 0,
      y: 0,
      width: 375,
      height,
      holdRatio: true,
      excludeAnchor: ["left", "right", "bottom", "top"]
    }
  };
  if (json.components) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    blankJson.components = [json];
  }
  return blankJson;
}
function textUpgrade(json) {
  var _a, _b, _c, _d;
  const backgroundColor = (_b = (_a = json.props) == null ? void 0 : _a.style) == null ? void 0 : _b.backgroundColor;
  console.log();
  if ((_d = (_c = json.props) == null ? void 0 : _c.style) == null ? void 0 : _d.backgroundColor) {
    json.props.style.backgroundColor = "";
  }
  const blankJson = getBlankWithHeightAndImage({ backgroundColor });
  json.type = "DRAG_TEXT";
  json.magic = {
    dragFree: {
      x: 16,
      y: 10,
      width: 343
    }
  };
  if (json.components) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    blankJson.components = [json];
  }
  return blankJson;
}
function telUpgrade(json) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const blankBackgroundColor = (_b = (_a = json.props) == null ? void 0 : _a.buttonStyle) == null ? void 0 : _b.wrapBackground;
  const buttonWidth = (_d = (_c = json.props) == null ? void 0 : _c.size) == null ? void 0 : _d.width;
  const buttonHeight = (_f = (_e = json.props) == null ? void 0 : _e.size) == null ? void 0 : _f.height;
  const bottomBlank = ((_g = json.props) == null ? void 0 : _g.position) === "bottom";
  if (bottomBlank) {
    json.props.position = "bottomBlank";
  }
  let width = typeof buttonWidth === "number" ? buttonWidth : +((_i = (_h = buttonWidth == null ? void 0 : buttonWidth.split("px")) == null ? void 0 : _h[0]) != null ? _i : "");
  const height = typeof buttonHeight === "number" ? buttonHeight : +((_k = (_j = buttonHeight == null ? void 0 : buttonHeight.split("px")) == null ? void 0 : _j[0]) != null ? _k : "");
  const blankJson = getBlankWithHeightAndImage({
    height: ((_l = json.props) == null ? void 0 : _l.position) === "right-bottom" ? 0.1 : height + 20,
    backgroundColor: blankBackgroundColor,
    bottomBlank
  });
  json.type = "TEL_DRAG";
  if (((_m = json.props) == null ? void 0 : _m.position) === "right-bottom") {
    json.props.position = "rightBottom";
  }
  let x = 24;
  if (width > 375 - 24 * 2) {
    width = 375 - 24 * 2;
  } else {
    x = (375 - width) / 2;
  }
  json.magic = {
    dragFree: {
      x,
      y: 10,
      width,
      height
    },
    globalDragFree: {
      right: 16,
      top: 504,
      width: 64,
      height: 96,
      excludeAnchor: ["left", "right", "top", "bottom"],
      holdRatio: true
    }
  };
  if (json.components && !bottomBlank) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blankJson.components = [json, ...anotherChild];
  } else {
    json.components = [];
    blankJson.components = [json];
  }
  return blankJson;
}
function multiConsultUpgrade(json) {
  console.log("multiConsultUpgrade");
  const height = 64;
  const blankJson = getBlankWithHeightAndImage({ height });
  blankJson.magic = {
    bottomBlank: true,
    hideHeightHandle: true,
    editHeight: height
  };
  json.type = "MULTI_CONSULT_DRAG";
  json.magic = {
    dragFree: {
      x: 0,
      y: 8,
      width: "100%",
      height,
      holdRatio: true,
      disableMove: true,
      includeAnchor: []
    }
  };
  json.components = [];
  blankJson.components = [json];
  return blankJson;
}
var blankUpdate = (json) => {
  if (json.components) {
    console.log(
      "json?.components.some(isBlockComponent",
      json == null ? void 0 : json.components.some(isBlockComponent)
    );
    if (json == null ? void 0 : json.components.some(isBlockComponent)) {
      const blockComponents = json.components.filter(isBlockComponent);
      const dragFreeComs = json.components.filter((com) => !isBlockComponent(com));
      const newBlockComponents = blockComponents.map((com) => {
        var _a;
        if ((_a = com.magic) == null ? void 0 : _a.dragFree) {
          com.type = ComNameUpgradeMap[com.type];
        }
        return com;
      });
      json.components = [...newBlockComponents, ...dragFreeComs];
    }
    json.components = json.components.map((com) => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
      if (com.type === "BLANK" && ((_a = com.props) == null ? void 0 : _a.activeOption) === "img" && ((_c = (_b = com.props) == null ? void 0 : _b.style) == null ? void 0 : _c.backgroundImage)) {
        const height = (_g = (_d = com.magic) == null ? void 0 : _d.editHeight) != null ? _g : ((_f = (_e = com.props) == null ? void 0 : _e.style) == null ? void 0 : _f.height) || 280;
        const img = (_k = (_j = (((_i = (_h = com.props) == null ? void 0 : _h.style) == null ? void 0 : _i.backgroundImage) || "").match(
          /url\((?<url>.+)\)/
        )) == null ? void 0 : _j.groups) == null ? void 0 : _k.url;
        return {
          id: com.id,
          encryptedId: "",
          type: "DRAG_IMAGE",
          version: "1.0.0",
          props: {
            linkType: 1,
            className: "select",
            editType: "normal",
            imgUrl: img
          },
          magic: {
            dragFree: {
              x: 0,
              y: 0,
              width: 375,
              height,
              excludeAnchor: ["left", "right", "top", "bottom"],
              holdRatio: true,
              ratio: 375 / +height
            }
          },
          components: []
        };
      }
      if (com.type === "BLANK" && ((_l = com.components) == null ? void 0 : _l.length) === 0) {
        return void 0;
      }
      return com;
    }).filter(Boolean) || [];
    const child = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    json.components = [...child];
  }
  return json;
};
var xiansuoUpgrade = (json, options, child) => {
  var _a, _b, _c, _d, _e;
  const height = (_c = (_a = json.magic) == null ? void 0 : _a.editHeight) != null ? _c : (_b = json == null ? void 0 : json.props) == null ? void 0 : _b.editHeight;
  if (((_e = (_d = json == null ? void 0 : json.props) == null ? void 0 : _d.formOpt) == null ? void 0 : _e.tag) === "STEP_FORM") {
    return json;
  }
  const blank = getBlankWithHeightAndImage({ tempHeight: height });
  json.type = "XIANSUO_FORM_DRAG";
  json.props && (json.props.newUserAgreement = true);
  json.magic = {
    dragFree: {
      x: 0,
      y: 0,
      width: 375,
      includeAnchor: ["left", "right"]
    }
  };
  if (child)
    return json;
  if (json.components) {
    const anotherChild = upgradeComponentsChild(json.components, void 0, true);
    json.components = [];
    blank.components = [json, ...anotherChild];
  } else {
    blank.components = [json];
  }
  return blank;
};
var componentUpgradeMap = {
  IMAGE: imageUpgrade,
  FORM: formUpgrade,
  APP_DOWNLOAD: appDownloadUpgrade,
  BUTTON: buttonUpgrade,
  MAP: mapUpgrade,
  WEI_XIN: weixinUpgrade,
  CUSTOMER_SERVICE: customerServiceUpgrade,
  SHUFFLING_IMAGE: shufflingImageUpgrade,
  TEXT: textUpgrade,
  VIDEO: videoUpgrade,
  TEL: telUpgrade,
  MULTI_CONSULT: multiConsultUpgrade,
  BLANK: blankUpdate,
  XIANSUO_FORM: xiansuoUpgrade
};
var ComNameUpgradeMap = {
  IMAGE: "DRAG_IMAGE",
  FORM: "XIANSUO_FORM_DRAG",
  APP_DOWNLOAD: "APP_DOWNLOAD_DRAG",
  BUTTON: "BUTTON_DRAG",
  MAP: "MAP_DRAG",
  WEI_XIN: "WEI_XIN_DRAG",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE_DRAG",
  SHUFFLING_IMAGE: "SHUFFLING_IMAGE_DRAG",
  TEXT: "DRAG_TEXT",
  VIDEO: "VIDEO_DRAG",
  TEL: "TEL_DRAG",
  MULTI_CONSULT: "MULTI_CONSULT_DRAG",
  XIANSUO_FORM: "XIANSUO_FORM_DRAG"
};
function upgradeComponent(json, options, child) {
  const { blackList = [] } = options != null ? options : {};
  const { type } = json;
  let result = json;
  if (!blackList.includes(type) && componentUpgradeMap[type]) {
    result = componentUpgradeMap[type](json, options, child);
  }
  return result;
}
function upgradeComponentsChild(json, options, child) {
  json = cloneDeep(json);
  return json.map((com) => upgradeComponent(com, options, child));
}
function isBlockComponent(com) {
  var _a, _b;
  return [
    ...Object.keys(componentUpgradeMap),
    "STORE_PAGE",
    "COLLAPSE_TEXT",
    "CUSTOMER_SERVICE_PAGE"
  ].includes(com.type) || ((_a = com == null ? void 0 : com.magic) == null ? void 0 : _a.isFullScreen) || com.type === "XIANSUO_FORM_DRAG" && ((_b = com.props) == null ? void 0 : _b.displayType) === "autoSingle";
}
function findScope(scope, y) {
  let res = -1;
  if (y <= 0)
    return 0;
  for (let i = 0; i < scope.length; i++) {
    if (y >= scope[i] && y < scope[i + 1]) {
      res = i;
      break;
    }
  }
  return res;
}

// upgrade/upgrade.ts
function upgradeComponents(json, options, child) {
  var _a, _b;
  json = cloneDeep2(json);
  const fontList = (_b = (_a = json[0]) == null ? void 0 : _a.magic) == null ? void 0 : _b.fontList;
  if (!child) {
    json = transformInvalidDragComponentNormal(json);
  }
  const res = json.map((com) => upgradeComponent(com, options, child));
  if (fontList == null ? void 0 : fontList.length) {
    if (res[0].magic) {
      res[0].magic.fontList = fontList;
    } else {
      res[0].magic = {
        fontList
      };
    }
  }
  return res;
}
function transformInvalidDragComponentNormal(json) {
  const blockComponents = json.filter((com) => isBlockComponent(com));
  const dragFreeComs = json.filter((com) => !isBlockComponent(com));
  if (!dragFreeComs.length) {
    return json;
  }
  const blockHeightArray = [0];
  const blockHeight = blockComponents.reduce((acc, current) => {
    var _a, _b, _c;
    let height = (_c = (_a = current == null ? void 0 : current.magic) == null ? void 0 : _a.editHeight) != null ? _c : (_b = current == null ? void 0 : current.props) == null ? void 0 : _b.editHeight;
    if (!height) {
      console.error(cloneDeep2(current));
      height = 260;
      if (current.type === "IMAGE") {
      }
      if (current.type === "FORM") {
        height = 320;
      }
    }
    console.log("[height],", current.type, height);
    blockHeightArray.push(acc + height);
    return acc + height;
  }, 0);
  const backCom = [];
  const corporateCom = [];
  dragFreeComs.forEach((com) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    com = cloneDeep2(com);
    if (com.type === "CORPORATE_INFO") {
      corporateCom.push(com);
      return;
    }
    if (!((_b = (_a = com.magic) == null ? void 0 : _a.dragFree) == null ? void 0 : _b.y)) {
      backCom.push(com);
      return;
    }
    const scopeIndex = findScope(blockHeightArray, (_c = com.magic) == null ? void 0 : _c.dragFree.y);
    if (scopeIndex === -1) {
      backCom.push(com);
      return;
    }
    if (blockComponents[scopeIndex].type !== "FORM") {
      console.log("[dragComs] pre", com.magic.dragFree.y, blockHeightArray[scopeIndex]);
      com.magic.dragFree.y = com.magic.dragFree.y - blockHeightArray[scopeIndex];
      console.log("[dragComs]", com.type, com.id, scopeIndex, (_d = com.magic) == null ? void 0 : _d.dragFree.y);
      (_e = blockComponents[scopeIndex].components) == null ? void 0 : _e.push(com);
    } else {
      const comHeight = (_i = (_h = (_g = com.magic.editHeight) != null ? _g : (_f = com == null ? void 0 : com.props) == null ? void 0 : _f.editHeight) != null ? _h : com.magic.dragFree.height) != null ? _i : 20;
      console.log(
        "FormInside",
        com,
        comHeight,
        com.magic.dragFree.y + comHeight,
        blockHeightArray[scopeIndex + 1]
      );
      if (com.magic.dragFree.y + comHeight > blockHeightArray[scopeIndex + 1]) {
        if (blockComponents[scopeIndex + 1]) {
          (_j = blockComponents[scopeIndex + 1].components) == null ? void 0 : _j.push(com);
        } else {
          backCom.push(com);
        }
      }
    }
  });
  if (backCom.length) {
    const maxHeight = backCom.reduce((acc, current) => {
      var _a, _b, _c, _d, _e, _f;
      return ((_c = (_b = (_a = current.magic) == null ? void 0 : _a.dragFree) == null ? void 0 : _b.y) != null ? _c : 0) > acc ? (_f = (_e = (_d = current.magic) == null ? void 0 : _d.dragFree) == null ? void 0 : _e.y) != null ? _f : 0 : acc;
    }, 0);
    console.log("[maxHeight]", maxHeight);
    const blankHeight = maxHeight - blockHeightArray[blockHeightArray.length - 1];
    const blank = getBlankWithHeightAndImage({ height: blankHeight });
    backCom.forEach((com) => {
      var _a, _b;
      if (!((_b = (_a = com.magic) == null ? void 0 : _a.dragFree) == null ? void 0 : _b.y))
        return;
      com.magic.dragFree.y = com.magic.dragFree.y - blockHeightArray[blockHeightArray.length - 1];
    });
    blank.components = backCom;
    blockComponents.push(blank);
  }
  if (corporateCom.length) {
    blockComponents.push(corporateCom[0]);
  }
  console.log("blockHeight", blockHeightArray, blockHeight);
  return blockComponents;
}

// upgrade/giveClean.ts
import { cloneDeep as cloneDeep3 } from "lodash";
var AppNameOptionList = [
  "\u7ACB\u5373\u4E0B\u8F7D",
  "\u5B89\u88C5\u4F53\u9A8C",
  "\u7ACB\u5373\u5B89\u88C5",
  "\u514D\u8D39\u5B89\u88C5",
  "\u514D\u8D39\u4E0B\u8F7D",
  "\u53BB\u4E0B\u8F7D",
  "\u4E0B\u8F7D\u53C2\u4E0E",
  "\u4E0B\u8F7D\u9886\u94B1",
  "\u4E0B\u8F7D\u9886\u53D6",
  "\u4E0B\u8F7D\u62A2\u8D2D",
  "\u4E0B\u8F7D\u8BD5\u73A9",
  "\u4E0B\u8F7D\u4F53\u9A8C",
  "\u4E00\u952E\u4E0B\u8F7D",
  "\u7ACB\u523B\u4E0B\u8F7D",
  "\u7ACB\u523B\u9884\u7EA6",
  "\u9884\u7EA6"
];
var rule = {
  XIANSUO_FORM: (props) => {
    const {
      formOpt,
      formSetting,
      funcId,
      applets,
      wechatFuncId,
      wechatInfo,
      wechatOpt,
      wechatType,
      ...rest
    } = props;
    const { id, name, ...formOptRest } = formOpt || {};
    return {
      formOpt: formOptRest,
      ...formSetting ? {
        formSetting: {
          count: formSetting.count,
          apply: formSetting.apply
        }
      } : {},
      ...rest
    };
  },
  XIANSUO_FORM_PAY: (props) => {
    const {
      formOpt,
      formSetting,
      funcId,
      activityId,
      activityName,
      itemId,
      itemName,
      formId,
      ...rest
    } = props;
    const { id, name, ...formOptRest } = formOpt || {};
    return {
      formOpt: formOptRest,
      ...rest
    };
  },
  CUSTOMER_SERVICE: (props) => {
    const { url, funcId, appId, ...otherProps } = props;
    return {
      ...otherProps
    };
  },
  TEL: (props) => {
    const { iccId, ...otherProps } = props;
    return {
      ...otherProps
    };
  },
  WEI_XIN: (props) => {
    const { opt, funcId, applets, standbyMode, dialogStyleType, ...otherProps } = props;
    const handleDialogStyleType = () => {
      if (dialogStyleType === 6 /* DIRECT_ATTENTION */) {
        return 0 /* DEFAULT */;
      }
      return dialogStyleType;
    };
    return {
      dialogStyleType: handleDialogStyleType(),
      ...otherProps
    };
  },
  MULTI_CONSULT: (props) => {
    const { telOpt, customerServiceOpt, ...options } = props;
    return {
      ...telOpt ? { telOpt: rule.TEL(telOpt) } : {},
      ...customerServiceOpt ? { customerServiceOpt: rule.CUSTOMER_SERVICE(customerServiceOpt) } : {},
      ...options
    };
  },
  APP_DOWNLOAD: ({ appInfo, iOSLink, quickAppLink, text, buttonInfo, ...options }) => {
    const formatButtonText = (option) => AppNameOptionList.includes(option) ? option : AppNameOptionList[0];
    return {
      ...options,
      text: formatButtonText(text),
      buttonInfo: formatButtonText(buttonInfo || "")
    };
  },
  STORE: (props) => {
    const { funcId, packageId, storeId, clueAccountId, ...options } = props;
    return {
      ...options
    };
  },
  BUTTON: (props) => {
    const { formProps, targetUrl, fallbackH5, ...rest } = props;
    return {
      ...rest
    };
  },
  COUPON_CARD: (props) => {
    const { couponId, couponTitle, ...rest } = props;
    return {
      couponTitle: "\u5361\u5238\u6807\u9898",
      ...rest
    };
  },
  WECHAT_GAME: (props) => {
    const { name, gameInfo, description, ...rest } = props;
    return {
      name: "\u5C0F\u6E38\u620F\u540D\u79F0",
      description: "\u8BF7\u8F93\u5165\u5C0F\u6E38\u620F\u7B80\u4ECB",
      ...rest
    };
  },
  ACTIVITY_CODE: (props) => {
    const { activityCode, ...rest } = props;
    return {
      ...rest
    };
  }
};
function cleanComponent(components) {
  const newComponents = componentsMap(components, (component) => {
    var _a;
    let transformRule = rule[component == null ? void 0 : component.type];
    if ((_a = component == null ? void 0 : component.type) == null ? void 0 : _a.includes("_DRAG")) {
      const DRAG = component.type.substr(0, component.type.length - 5);
      transformRule = rule[DRAG];
    }
    const newComponent = { ...component };
    if (transformRule) {
      newComponent.props = transformRule(newComponent.props);
    }
    if (["XIANSUO_FORM", "XIANSUO_FORM_DRAG"].includes(component == null ? void 0 : component.type)) {
      component.components = [];
      component.props && (component.props.isAppointment = false);
      newComponent.components = [];
      newComponent.props && (newComponent.props.isAppointment = false);
    }
    return newComponent;
  });
  return newComponents;
}
function cleanPageMeta(params) {
  const { pageMeta, wechatGameDirectCallUser } = params;
  const { wechatStay, quickAppDirectCall, appletsModal, wechatGameDirectCall, ...rest } = cloneDeep3(pageMeta || {});
  let newWechatStay = wechatStay;
  if (wechatStay) {
    const { opt, funcId, applets, dialogStyleType, gameInfo, ...otherProps } = wechatStay;
    const handleDialogStyleType = () => {
      if (dialogStyleType === 6 /* DIRECT_ATTENTION */) {
        return 0 /* DEFAULT */;
      }
      return dialogStyleType;
    };
    newWechatStay = {
      dialogStyleType: handleDialogStyleType(),
      ...otherProps
    };
  }
  let newAppletsModal = {};
  if (appletsModal) {
    newAppletsModal = {
      ...defaultAppletsModal
    };
  }
  return {
    wechatStay: newWechatStay,
    appletsModal: newAppletsModal,
    wechatGameDirectCall: wechatGameDirectCallUser ? wechatGameDirectCall : Number(false),
    ...rest
  };
}
var componentsMap = (components, fun) => {
  return components.map((com, index) => {
    const res = fun(com, index);
    if (res.components && com.components) {
      res.components = componentsMap(com.components, fun);
    }
    return res;
  });
};
var defaultAppletsModal = {
  modalText: "",
  modalTextStyle: {
    color: "#666"
  },
  cancelText: "\u53D6\u6D88",
  cancelTextStyle: {
    color: "#FE3666",
    borderColor: "#FE3666",
    borderRadius: 100
  },
  confirmText: "\u8DF3\u8F6C",
  confirmTextStyle: {
    color: "#fff",
    backgroundColor: "#FE3666",
    borderRadius: 100
  }
};
export {
  AppNameOptionList,
  cleanComponent,
  cleanPageMeta,
  componentUpgradeMap,
  componentsMap,
  defaultAppletsModal,
  rule,
  upgradeComponents
};
