import { message } from 'm-ui';
import { PageEditState } from 'omega-editor';

export function validateDirectCall(json: PageEditState) {
    const { pageMeta } = json;
    const arr: string[] = [];
    if (pageMeta?.quickAppDirectCall) {
        arr.push('下载(快应用直跳)');
    }
    if (pageMeta?.wechatGameDirectCall) {
        arr.push('微信小游戏(直跳)');
    }
    if (pageMeta?.qywxDirectCall) {
        arr.push('微信(企微加粉直跳)');
    }
    if (pageMeta?.qywxCustomerAcqDirectCall) {
        arr.push('微信(获客链接直跳)');
    }
    if (arr.length > 1) {
        message.error(`当前同时开启了${arr.join('、')}，请保留一种`);
        return false;
    }
    return true;
}
