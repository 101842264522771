import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsMapObject, bindActionCreators } from 'redux';

import { RootState } from '../index';

export function usePlatformState<T>(selector: (state: RootState) => T): T {
    return useSelector(selector, shallowEqual);
}
type FunMap = {
    [key in string]: (...args: any) => any;
};
type ActionFun<T extends FunMap> = {
    [key in keyof T]: (
        ...args: Parameters<T[key]>
    ) => ReturnType<T[key]> extends (...args: any) => infer A ? A : ReturnType<T[key]>;
};
export function usePlatformActions<T extends ActionCreatorsMapObject>(actions: T) {
    const dispatch = useDispatch();
    return useMemo(() => {
        const res = bindActionCreators(actions, dispatch);
        return res as ActionFun<T>;
    }, [actions, dispatch]);
}
