import { history } from 'src/routes/config';

import {
    Wrapper,
    Page,
    Title,
    TitleWrapper,
    Tips,
    TipsContent,
    TipsIconWrapper,
    FlowchartWrapper,
    StepWrapper,
    StepImage,
    StepTitle,
    StepDesc,
    GapIcon,
    NewButtonWrapper,
    AddTitle,
    EmptyWrapper,
    GlobalStyle,
} from './style';
import { ReactComponent as TipsIcon } from './assets/TipIcon.svg';
import { ListTable } from './ListTable';
// import { CreateNewButton } from './CreateNewButton';
import { CreateNewDrawer } from './CreateNewDrawer';
import { useEffect, useState } from 'react';
import { useLpUserInfo } from 'src/utils/useLpUserInfo';
// import { useLpUserInfo } from 'src/utils/useLpUserInfo';
// import { useEffect } from 'react';

const list = [
    {
        image: 'https://h2.static.yximgs.com/kos/nlav10749/moli-module/performance-select.png',
        title: '1、选择要检测落地页',
        desc: '支持磁力建站落地页检测',
    },
    {
        image: 'https://h2.static.yximgs.com/kos/nlav10749/moli-module/performance-test.png',
        title: '2、通过质量检测工具测试',
        desc: '提交检测，等待平台检测结果',
    },
    {
        image: 'https://h2.static.yximgs.com/kos/nlav10749/moli-module/performance-detail.png',
        title: '3、查看检测结果，优化问题',
        desc: '查看检测报告，了解问题与优化建议',
    },
];

export default function List() {
    const isWhite = useLpUserInfo('qualityUser');
    const accountId = useLpUserInfo('accountId');
    useEffect(() => {
        if (accountId && !isWhite) {
            history.push('/mainGuide');
        }
    }, []);
    return (
        <Page>
            <GlobalStyle />
            <Wrapper>
                <TitleWrapper>
                    <Title>质量检测</Title>
                </TitleWrapper>
                {<ListTable />}
            </Wrapper>
        </Page>
    );
}

export const Empty = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    return (
        <EmptyWrapper>
            <Tips>
                <TipsIconWrapper>
                    <TipsIcon />
                </TipsIconWrapper>
                <TipsContent>
                    <div>
                        落地页质量检测工具帮您全方位检测定位影响投放效果的质量问题并给出优化建议，补齐质量短板，优化页面质量，从而提高投放效果。
                    </div>
                    <ul>
                        <li>
                            根据落地页质检的检测总分结果，得出营销吸引力、体验优化度、页面性能流畅度等级，最终综合得出页面整体等级优秀、良好、合格、较差4类评级。
                        </li>
                        <li>
                            较低分值时，您可以根据页面给出的改善建议，优化您的页面以便提高分值。
                        </li>
                    </ul>
                </TipsContent>
            </Tips>
            <FlowchartWrapper>
                {list.map((item, index) => (
                    <>
                        <StepWrapper key={index}>
                            <StepImage src={item.image} />
                            <StepTitle> {item.title} </StepTitle>
                            <StepDesc> {item.desc} </StepDesc>
                        </StepWrapper>
                        {index !== list.length - 1 && (
                            <GapIcon
                                src="https://h2.static.yximgs.com/kos/nlav10749/magic-site/connect-icon3.png"
                                alt=""
                            />
                        )}
                    </>
                ))}
            </FlowchartWrapper>
            <NewButtonWrapper>
                <AddTitle> 还未创建过检测项目，快来试试吧~ </AddTitle>
                <CreateNewDrawer
                    visible={showDrawer}
                    open={() => {
                        setShowDrawer(true);
                    }}
                    onClose={(refresh) => {
                        if (refresh) {
                            //  getData();
                            // window.location.reload();
                        }
                        setShowDrawer(false);
                    }}
                />
            </NewButtonWrapper>
        </EmptyWrapper>
    );
};
