import React from 'react';
/**
 * 缩放某个元素
 * @param opt 缩放后的宽、高和原本的宽度
 */
export const useScale = (opt: { width: number; height: number; rawWidth: number }) => {
    const { width, height, rawWidth } = opt;
    return React.useMemo(() => {
        const rawHeight = (height / width) * rawWidth;
        const scale = width / rawWidth;
        const left = ((scale - 1) / 2) * rawWidth;
        const top = ((scale - 1) / 2) * rawHeight;
        return {
            style: {
                width: rawWidth,
                height: rawHeight,
                transform: `scale(${scale})`,
                left,
                top,
            },
        };
    }, [height, width, rawWidth]);
};
