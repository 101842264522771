import { SystemQuestionmarkCircleLine } from '@m-ui/icons';
import { LINK_TYPE } from 'landingPage/components/AppDownloadDrag/redux';
import { Button, Modal, Radio } from 'm-ui';
import { PageEditState, useEditorSelector, useSDK } from 'omega-editor';
import { ComponentJson, findComponent } from 'omega-render';
import { useCallback, useState } from 'react';
import { PropsGroup } from 'src/components/PropsEditor/PropsGroup';
import { useLpUserInfo } from 'src/utils/useLpUserInfo';
import { RadioGroup } from '../setting/TitleEditor/style';

export const halfForbiddenCom = ['MAP_DRAG', 'VIDEO_DRAG'];

/** 是否有半屏不能使用的组件 */
export function ifHasBanCom(components: ComponentJson[]) {
    return !!findComponent(components, (com) => {
        if (halfForbiddenCom.includes(com.type)) {
            return true;
        }
        if (com.type === 'APP_DOWNLOAD_DRAG' && com?.props?.linkType === LINK_TYPE.IOS) {
            return true;
        }
        return false;
    });
}

export function HalfScreenSetting() {
    const [halfScreenValue, components] = useEditorSelector((state) => {
        return [state?.pageMeta?.halfScreen, state.components];
    });
    const pageHalfScreenWhiteList = useLpUserInfo('pageHalfScreen');
    const sdk = useSDK();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const setHalfScreen = useCallback((val: boolean) => {
        const hasBanCom = ifHasBanCom(components);
        if (val && hasBanCom) {
            setShowErrorModal(true);
            return;
        }
        sdk.setPageMeta({
            halfScreen: val,
        });
    }, []);
    if (!pageHalfScreenWhiteList) return <></>;
    return (
        <PropsGroup
            title="落地页样式"
            icon={<SystemQuestionmarkCircleLine style={{ color: '#8c8c8c' }} />}
            tooltip={
                <div style={{ width: 310, color: '#BFBFBF' }}>
                    <div>
                        <span style={{ color: 'white' }}>全屏：</span>
                        落地页会全屏打开，首屏展示更多内容
                    </div>
                    <div>
                        <span style={{ color: 'white' }}>半屏：</span>
                        落地页会在半屏样式下打开，且不影响广告视频的播放，同时当前页面将无法在联盟投放；
                    </div>
                </div>
            }
        >
            <RadioGroup
                style={{ marginBottom: 0 }}
                onChange={(e) => setHalfScreen(e.target.value)}
                value={!!halfScreenValue}
            >
                <Radio.Button value={false} style={{ width: '179px' }}>
                    全屏
                </Radio.Button>
                <Radio.Button value style={{ width: '179px' }}>
                    半屏
                </Radio.Button>
            </RadioGroup>
            <Modal
                title="不能使用半屏样式"
                visible={showErrorModal}
                onOk={() => setShowErrorModal(false)}
                onCancel={() => setShowErrorModal(false)}
                footer={[
                    <Button
                        key="0"
                        type="primary"
                        size="large"
                        onClick={() => setShowErrorModal(false)}
                    >
                        知道了
                    </Button>,
                ]}
            >
                <p>画板上存在视频/地图组件/iOS应用下载，请手动删除后再使用半屏落地页样式</p>
            </Modal>
        </PropsGroup>
    );
}

export function validateHalfScreen(json: PageEditState, scene: '保存' | '发布' | '预览') {
    const { pageMeta, components } = json;
    const { halfScreen } = pageMeta ?? {};
    if (halfScreen) {
        const hasBanCom = ifHasBanCom(components);
        if (hasBanCom) {
            Modal.error({
                title: `落地页${scene}失败`,
                width: 480,
                content: (
                    <>
                        <p>
                            半屏落地页样式不支持视频、地图组件以及iOS应用下载，请手动删除后再进行
                            {scene}
                        </p>
                    </>
                ),
            });
            return false;
        }
    }
    return true;
}
