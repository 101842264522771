import React from 'react';
import ReactDOM from 'react-dom';
// import './styles/nomalize.css';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './store';
// import 'm-ui/dist/m-ui.css'; // or 'm-ui/dist/m-ui.less'
import './style/index.less';

ReactDOM.render(
    <Provider store={store}>
        {/* <BrowserRouter> */}
        <App />
        {/* </BrowserRouter> */}
    </Provider>,
    document.getElementById('app'),
);
