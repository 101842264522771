import { NormalWorkbenchLine } from '@m-ui/icons';
import { Button, Popover } from 'm-ui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ReadStatus, STATUS } from 'src/pages/quality/ListTable';
import { history } from 'src/routes/config';
import { pageQualityCheckListUsingPOST } from 'src/services-new/page-quality-check-controller';
import { useLpUserInfo } from 'src/utils/useLpUserInfo';
import styles from './styles.module.less';

const BAD_STORAGE_KEY = 'qualityPopBad';
const NEW_STORAGE_KEY = 'qualityPopNew';

/**
 * 判断给定的日期是否是今天。
 * @param date - 需要判断的日期，可以是字符串或 null。
 * @returns 如果给定的日期是今天，则返回 true；否则返回 false。
 */
const isToday = (date?: string | null) => {
    if (!date) return false;
    return moment(+date).isSame(moment(), 'day');
};
export function QualityPop() {
    const [badSum, setBadSum] = useState(0);
    const [newSum, setNewSum] = useState(0);
    const qualityUser = useLpUserInfo('qualityUser');

    const getUnreadBad = async () => {
        const res = await pageQualityCheckListUsingPOST({
            req: {
                splitInfo: {
                    pageNum: 1,
                    pageSize: 50,
                },
                readStatus: ReadStatus.Unread,

                pageType: '',
                scoreLevel: ['不合格'],
            } as any,
        });
        if (res.data?.list?.length > 0) {
            setBadSum(res.data.list.length);
            return res.data.list.length;
        }
    };
    const getNew = async () => {
        const res = await pageQualityCheckListUsingPOST({
            req: {
                startTime: moment().subtract(24, 'hours').valueOf(),
                endTime: moment().valueOf(),
                status: STATUS.SUCCESS,
                readStatus: ReadStatus.Unread,
                splitInfo: {
                    pageNum: 1,
                    pageSize: 50,
                },

                pageType: '',
            } as any,
        });
        if (res.data?.list?.length > 0) {
            setNewSum(res.data.list.length);
            return res.data.list.length;
        }
    };
    const close = () => {
        setBadSum(0);
        setNewSum(0);
    };

    useEffect(() => {
        async function init() {
            if (!qualityUser) return;
            const lastBadTime = localStorage.getItem(BAD_STORAGE_KEY);
            console.log('lastBadTime', lastBadTime, 'isToday(lastBadTime)', isToday(lastBadTime));
            if (!isToday(lastBadTime)) {
                const badSum = await getUnreadBad();
                if (badSum) return;
            }
            const lastNewStorage = localStorage.getItem(NEW_STORAGE_KEY);
            if (!isToday(lastNewStorage)) {
                await getNew();
            }
        }
        init();
    }, [qualityUser]);
    const laterBad = () => {
        localStorage.setItem(BAD_STORAGE_KEY, moment().valueOf().toString());
        setBadSum(0);
    };
    const laterNew = () => {
        localStorage.setItem(NEW_STORAGE_KEY, moment().valueOf().toString());
        setNewSum(0);
    };
    return (
        <Popover
            visible={badSum || newSum ? true : false}
            overlayClassName={styles.qualityPop}
            content={
                <div
                    className={styles.popoverContent}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {!!badSum && (
                        <>
                            <div className={styles.popoverTitle}>
                                您有{badSum}个落地页质量可优化
                            </div>
                            <div className={styles.popoverContent}>
                                {badSum}个落地页质量监测结果
                                <span className={styles.badText}>【较差】</span>
                                ，可根据建议继续提升优化
                            </div>
                            <div className={styles.btnGroup}>
                                <span
                                    className={styles.later}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        laterBad();
                                    }}
                                >
                                    我知道了
                                </span>

                                <Button
                                    type="primary"
                                    style={{ marginLeft: '12px' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(
                                            `/quality/list?scoreLevel=不合格&readStatus=1`,
                                        );
                                        close();
                                    }}
                                >
                                    立即查看
                                </Button>
                            </div>
                        </>
                    )}
                    {!!newSum && (
                        <>
                            <div className={styles.popoverTitle}>
                                您有{newSum}条新增检测结果，快来查看吧～
                            </div>
                            <div className={styles.popoverContent}>
                                使用质量检测查看落地页评分，帮您提升转化率！
                            </div>
                            <div className={styles.btnGroup}>
                                <span
                                    className={styles.later}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        laterNew();
                                    }}
                                    // onClick=
                                >
                                    我知道了
                                </span>

                                <Button
                                    type="primary"
                                    style={{ marginLeft: '12px' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(
                                            `/quality/list?&readStatus=1&startTime=${moment()
                                                .subtract(1, 'days')
                                                .startOf('day')
                                                .valueOf()}&endTime=${moment()
                                                .endOf('day')
                                                .valueOf()}&status=${STATUS.SUCCESS}`,
                                        );
                                        // laterNew();
                                        close();
                                    }}
                                >
                                    立即查看
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            }
            placement="right"
        >
            <NormalWorkbenchLine
                style={{
                    fontSize: '20px',
                    marginRight: '8px',
                }}
            />
        </Popover>
    );
}
