import styled, { css } from 'styled-components';
import arrow from './to.svg';
import { AuditStatus, ColorsMap } from './config';

export const Wrapper = styled.div<{ status: AuditStatus }>`
    display: flex;
    align-items: center;
`;
export const Center = styled.div`
    display: flex;
    align-items: center;
`;

export const Arrow = styled.div`
    width: 20px;
    height: 20px;
    background-image: url('${arrow}');
    background-size: 100%;
`;

export const Dot = styled.div<{ status: AuditStatus }>`
    width: 6px;
    margin: 0 4px;
    height: 6px;
    background-color: ${(p) => ColorsMap[p.status]};
    border-radius: 50%;
    ${(p) =>
        // eslint-disable-next-line eqeqeq
        p.status == AuditStatus.NotAudited
            ? css`
                  opacity: 0.45;
              `
            : ''}
`;

export const StatusLabel = styled.div<{ status: AuditStatus }>`
    display: inline-block;
    margin-right: 8px;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: ${(p) => ColorsMap[p.status]};
    line-height: 20px;
`;

export const ReAuditLabel = styled.div`
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 5, 25, 0.85);
    line-height: 20px;
`;
export const TotalWrap = styled.div`
    .ant-tooltip {
        max-width: 600px !important;
    }
`;
export const EditBtn = styled.div<{ disable?: boolean }>`
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: ${(p) => (p.disable ? 'rgba(0,5,25,0.45)' : '#3e7cf7')};
    line-height: 20px;
    cursor: pointer;
    ${(p) =>
        p.disable
            ? css`
                  cursor: not-allowed;
              `
            : ''};
`;

export const Reason = styled.div`
    max-width: calc(100% - 20px);
    height: 32px;
    background: rgba(0, 5, 25, 0.04);
    border-radius: 2px;
    font-size: 12px;
    text-align: left;
    color: rgba(0, 5, 25, 0.45);
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
    display: table;
    table-layout: fixed;
    width: calc(100% - 20px);
    .bolder {
        font-weight: 500;
    }
`;

export const Ellipsis = styled.div`
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
