import cs from 'classnames';
import * as React from 'react';

import { IconType } from './type.gen';

interface IconFontProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    /**
     * icon的类型,对应每个icon的class。通过定义执行 `npm run updateIconType` 生成
     */
    type: IconType;
}

const IconFont: React.FunctionComponent<IconFontProps> = (props) => {
    const { className, type, ...rest } = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <span className={cs('iconfont', type, className)} {...rest} />;
};

export default IconFont;
