/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import QRCode from 'qrcode.react';
import React from 'react';
import { ComponentJson, AnyObj, PageMetaJson } from 'omega-render';
import { useMount } from 'react-use';
// import { createPreviewUsingPOST } from 'src/services/apis';
// import { IPagePreviewParam } from '../../services/models';
// import { setFontToComponents } from 'src/pages/pageEdit/redux/thunk';
import message from 'm-ui/es/message';
import { createPreviewUsingPOST } from 'src/services/page-controller';
import { IPagePreviewReq } from 'src/services/models';
import { setFontToComponents } from 'src/utils/fontFamily';
import { SystemAttentionCircleLine } from '@m-ui/icons';
import { basePath } from '../../landingPage/common/utils/env';
import { AutoSizeIframe } from '../AutoSizeIframe';
import * as S from './style';
import GlobalStyle from './styleGlobal';
import { Radio, Button } from 'm-ui';
import Reload from './reload.png';
import { history } from 'src/routes/config';

export interface PreviewProps {
    id?: string;
    title?: string;
    modalTitle?: string;
    components?: ComponentJson[];
    onClosePreview: () => void;
    /** 有页面的url，说明可以直接预览，不需要请求。否则要请求 */
    url?: string;
    pageMeta?: PageMetaJson;
    type?: 'page' | 'template' | 'keywordMidPage';
    useTemplateBtn?: () => JSX.Element | false | undefined;
    coverImgUrl?: string;
    hideUseBtn?: boolean;
    onClickUseTemp?: () => void;
}

enum ScreenStatusEnum {
    NORMAL = 1,
    FULL_SCREEN = 2,
    HALF_SCREEN = 3,
}
const ScreenPage = {
    [ScreenStatusEnum.NORMAL]: {
        width: 280,
        height: 498,
    },
    [ScreenStatusEnum.FULL_SCREEN]: {
        width: 280,
        height: 606,
    },
    [ScreenStatusEnum.HALF_SCREEN]: {
        width: 375,
        height: 666,
    },
};
// const delay = async (ms: number) => new Promise((re) => setTimeout(re, ms));
export const PreviewPage: React.SFC<PreviewProps> = (props) => {
    const {
        type = 'page',
        id,
        title,
        components,
        url: urlProps,
        modalTitle: modalTitleProps,
        onClosePreview,
        pageMeta,
        useTemplateBtn,
        coverImgUrl,
        hideUseBtn,
        onClickUseTemp,
    } = props;
    const { halfScreen } = pageMeta ?? {};
    const [url, setUrl] = React.useState(urlProps ?? '');
    const [screenStatus, setScreenStatus] = React.useState(
        halfScreen ? ScreenStatusEnum.HALF_SCREEN : ScreenStatusEnum.NORMAL,
    );
    const [showReload, setShowReload] = React.useState(false);
    const [countdownNum, setCountdownNum] = React.useState(600);
    const [needCountdown, setNeedCountdown] = React.useState(false);

    const modalTitle = modalTitleProps ?? '预览';

    useMount(() => {
        if (url) {
            return;
        }
        let params: IPagePreviewReq;
        if (id) {
            params = {
                pageId: id,
            } as IPagePreviewReq;
        } else {
            params = {
                title,
                pageInfo: {
                    name: title,
                    components:
                        setFontToComponents(components as ComponentJson<AnyObj>[], true) ||
                        components,
                    pageMeta,
                },
            } as IPagePreviewReq;
        }
        const domain = window.location.origin + (basePath?.length ? basePath : '');
        if (type === 'template') {
            setTimeout(() => {
                setUrl(`${domain}/rest/lp/template/getPreview?templateId=${id}&anchorClear=1`);
            }, 200);
        } else {
            createPreviewUsingPOST({ req: params })
                .then((res) => {
                    setTimeout(() => {
                        setUrl(`${domain}/rest/lp/page/getPreview?key=${res.data}`);
                        setNeedCountdown(true);
                        setCountdownNum(600);
                    }, 200);
                })
                .catch((err) => {
                    console.error(err);
                    message.error(err.msg);
                });
        }
    });

    React.useEffect(() => {
        if (needCountdown) {
            let timer;
            if (countdownNum > 0) {
                timer = window.setTimeout(() => {
                    setCountdownNum(countdownNum - 1);
                }, 1000);
            } else {
                clearTimeout(timer);
                setShowReload(true);
                setNeedCountdown(false);
            }
        }
    }, [countdownNum, needCountdown]);

    const changeScreenStatus = (e) => {
        setScreenStatus(e.target.value);
    };

    const reloadPageUrl = () => {
        let params: IPagePreviewReq;
        if (id) {
            params = {
                pageId: id,
            } as IPagePreviewReq;
        } else {
            params = {
                title,
                pageInfo: {
                    name: title,
                    components:
                        setFontToComponents(components as ComponentJson<AnyObj>[], true) ||
                        components,
                    pageMeta,
                },
            } as IPagePreviewReq;
        }
        const domain = window.location.origin + (basePath?.length ? basePath : '');
        createPreviewUsingPOST({ req: params })
            .then((res) => {
                setTimeout(() => {
                    setUrl(`${domain}/rest/lp/page/getPreview?key=${res.data}`);
                    setShowReload(false);
                    setCountdownNum(600);
                    setNeedCountdown(true);
                }, 200);
            })
            .catch((err) => {
                console.error(err);
                message.error(err.msg);
            });
    };
    return (
        <S.ModalWrapper
            visible
            onCancel={onClosePreview}
            title={modalTitle}
            footer={null}
            width={681}
        >
            <GlobalStyle />
            {/* <div className="previewPage-container"> */}
            <S.Wrapper>
                {/* <div className="mobile-model"> */}
                <S.PageContainer
                    style={{
                        width: ScreenPage[screenStatus].width,
                        height: ScreenPage[screenStatus].height,
                        overflowY: coverImgUrl ? 'scroll' : 'hidden',
                    }}
                >
                    {/* <h4 className="title">{title}</h4> */}
                    {type === 'page' ? (
                        halfScreen ? (
                            <HalfPreview url={url} />
                        ) : (
                            <AutoSizeIframe
                                className="page"
                                noBorder
                                width={ScreenPage[screenStatus].width}
                                height={ScreenPage[screenStatus].height}
                                url={url}
                            />
                        )
                    ) : coverImgUrl ? (
                        <img src={coverImgUrl} style={{ width: '100%' }} alt="模板封面" />
                    ) : (
                        <AutoSizeIframe
                            className="page"
                            noBorder
                            width={ScreenPage[screenStatus].width}
                            height={ScreenPage[screenStatus].height}
                            url={url}
                        />
                    )}
                </S.PageContainer>
                <S.QrCodeContainer>
                    <S.PageTitle>落地页预览</S.PageTitle>
                    <Radio.Group
                        onChange={changeScreenStatus}
                        value={screenStatus}
                        style={{ display: 'flex' }}
                    >
                        <Radio
                            value={ScreenStatusEnum.NORMAL}
                            style={{
                                fontSize: '14px',
                                lineHeight: '22px',
                                color: '#434343',
                            }}
                        >
                            主流机型<span style={{ color: '#8C8C8C' }}>{' (375x667)'}</span>
                        </Radio>
                        <Radio
                            value={ScreenStatusEnum.FULL_SCREEN}
                            style={{
                                fontSize: '14px',
                                lineHeight: '22px',
                                color: '#434343',
                            }}
                        >
                            全面屏<span style={{ color: '#8C8C8C' }}>{' (375x812)'}</span>
                        </Radio>
                    </Radio.Group>
                    <S.QrTitle>手机端预览</S.QrTitle>
                    <S.QrTips>打开快手app扫一扫,扫码预览效果</S.QrTips>
                    <S.QrCodeWrapper>
                        {!urlProps && showReload && (
                            <S.ReloadQrCode>
                                <S.ReloadIcon src={Reload} onClick={reloadPageUrl} />
                            </S.ReloadQrCode>
                        )}
                        <QRCode value={url} style={{ height: 138, width: 138 }} />
                    </S.QrCodeWrapper>
                    {!urlProps && (
                        <S.QrTipsWarning>
                            <SystemAttentionCircleLine style={{ marginRight: 5, fontSize: 14 }} />
                            {showReload
                                ? '二维码已失效，请手动刷新'
                                : '链接10分钟后过期，仅做样式展示，请勿用于投放！'}
                        </S.QrTipsWarning>
                    )}
                    {useTemplateBtn?.()}
                    {!urlProps && !hideUseBtn && (
                        <Button
                            type="primary"
                            style={{ width: 88, marginTop: 24 }}
                            onClick={() => {
                                if (onClickUseTemp) {
                                    onClickUseTemp();
                                    return;
                                }
                                const state = {
                                    components,
                                    pageId: id || '',
                                };

                                history.push({
                                    pathname: '/page/edit',
                                    search: `pageId=${id as string}`,
                                    state,
                                });
                            }}
                        >
                            使用
                        </Button>
                    )}
                </S.QrCodeContainer>
            </S.Wrapper>
        </S.ModalWrapper>
    );
};
export default PreviewPage;

/** 半屏落地页预览 */
export function HalfPreview(props: { url: string }) {
    const { url } = props;
    return (
        <S.HalfScreenPreview>
            <S.HalfScreenWrapper>
                <AutoSizeIframe className="page" noBorder width={375} height={534} url={url} />
            </S.HalfScreenWrapper>
        </S.HalfScreenPreview>
    );
}
