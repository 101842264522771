import React from 'react';
import { usePlatformActions, usePlatformState } from 'src/store/hooks';
import { userModel } from 'src/store/userModel';
import * as sso from '@ks/sso';
import { basePath } from 'src/landingPage/common/utils/env';
import { RootState } from 'src/store';
import { NormalCopyLine, NormalExchangeLine } from '@m-ui/icons';
import styles from './styles.module.less';
import { message } from 'm-ui';
import { chooseAccount } from '@ad/uc';

async function logout() {
    await sso.logout();
    const followUrl = `${encodeURIComponent(
        window.location.origin,
    )}${basePath}/rest/lp/agent/logout`;
    window.location.href = `/rest/infra/logout?sid=kuaishou.ad.dsp&followUrl=${followUrl}`;
}

const Label = (props: { label: string; value: string | number | undefined }) => {
    const { label, value } = props;

    return (
        <div className={styles.label}>
            <span>{label}</span>
            <span className={styles.value}>{value}</span>
            <span
                className={styles.icon}
                onClick={() => {
                    navigator.clipboard?.writeText(value + '').then(() => {
                        message.success('已复制');
                    });
                }}
            >
                <NormalCopyLine />
            </span>
        </div>
    );
};

export default () => {
    const actions = usePlatformActions(userModel.actions);
    const adUser = usePlatformState((state: RootState) => {
        return state.adUser;
    });

    const { user, lpUser } = adUser;

    const showAgreement = React.useCallback(() => {
        actions.setModelState({
            agreement: { show: true },
        });
    }, [actions]);

    return (
        <>
            <div className={styles.userInfo}>
                <div className={styles.block}>
                    <div className={styles.title}>登录用户信息</div>
                    <Label label="快手ID" value={user?.userId} />
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        <div>当前操作的广告账号信息</div>
                        <div
                            className={styles.switchAccount}
                            onClick={() => {
                                chooseAccount();
                            }}
                        >
                            <NormalExchangeLine style={{ marginRight: 4, fontSize: 12 }} />
                            切换账号
                        </div>
                    </div>
                    <Label label="账户名" value={user?.userName} />
                    <Label label="账户ID" value={lpUser?.accountId} />
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>当前广告账号绑定的快手ID</div>
                    <Label label="快手ID" value={lpUser?.ksUserId} />
                </div>
                <div className={styles.operation}>
                    <div className={styles.aggrement} onClick={() => showAgreement()}>
                        查看用户协议
                    </div>
                    <div className={styles.logout} onClick={() => logout()}>
                        退出登录
                    </div>
                </div>
            </div>
        </>
    );
};
