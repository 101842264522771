import * as React from 'react';
import { Input, Button } from 'm-ui';
import { history } from 'src/routes/config';
import { usePlatformActions } from 'src/store/hooks';
import styles from './style.module.less';
import { groupListModel } from './redux/programmaticListModel';

interface HeaderProps {
    setSearchResult: (param: boolean) => void;
}

const Header: React.FC<HeaderProps> = (props) => {
    const { setSearchResult } = props;
    const actions = usePlatformActions(groupListModel.actions);

    return (
        <div className={styles.header}>
            <Input
                defaultValue=""
                onPressEnter={(e) => {
                    const inputValue = e.target as EventTarget & HTMLInputElement;
                    // if (inputValue.value?.length) {
                    setSearchResult(true);
                    actions.fetchGroupList({ select: inputValue.value as string, pageNum: 1 });
                    // }
                }}
                onBlur={(e) => {
                    // if (e.target.value?.length) {
                    setSearchResult(true);
                    actions.fetchGroupList({ select: e.target.value as string, pageNum: 1 });
                    // }
                }}
                className={styles.search}
                placeholder="请输入落地页组名称/ID"
            />

            <div>
                <Button
                    onClick={() => {
                        history.push('/programmaticTrash');
                    }}
                >
                    组回收站
                </Button>
                <Button
                    className={styles.createGroup}
                    onClick={() => {
                        history.push('/programmaticMulti/stepOne');
                    }}
                >
                    批量新建
                </Button>
                <Button
                    type="primary"
                    className={styles.createGroup}
                    onClick={() => {
                        history.push('/programmaticGroup');
                    }}
                >
                    新建落地页组
                </Button>
            </div>
        </div>
    );
};

export default Header;
