/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { ComponentJson } from 'omega-render';
import { useEffect } from 'react';
import { throttle } from 'lodash';
import { usePreview } from 'omega-editor';
import { getUrlParams } from '../../landingPage/common/adTrack/util/env';
import { AutoSizeIframe, AutoSizeIframeProps, AutoSizeImage } from '../AutoSizeIframe';

export interface PageJsonPreviewProps extends Omit<AutoSizeIframeProps, 'url'> {
    components?: ComponentJson[];
    pageId?: string;
    templateId?: string;
    keywordMidPageId?: string;
    keywordMidPageName?: string;
    cover?: string;
    isFiction?: boolean;
    onLoad?: () => void;
}

export const PageJsonPreview2: React.FunctionComponent<PageJsonPreviewProps> = (props) => {
    const { components, pageId, onLoad, width, height, ...rest } = props;
    const { ref } = usePreview({
        components,
        onLoad,
    });
    return <div ref={ref} style={{ width, height }} {...rest} />;
};

const PageJsonPreview: React.FunctionComponent<PageJsonPreviewProps> = (props) => {
    const {
        components,
        pageId,
        templateId,
        onLoad,
        isFiction,
        cover,
        keywordMidPageId,
        keywordMidPageName,
        ...rest
    } = props;
    if (cover) {
        return (
            <AutoSizeImage {...rest} url={cover}>
                <img src={cover} style={{ width: '100%' }} alt="模板封面" />
            </AutoSizeImage>
        );
    }
    const iframeUrl = React.useMemo(() => {
        const [domain, accountId, pageType] = [
            window.location.origin,
            getUrlParams('__accountId__'),
            isFiction ? 2 : 0,
        ];
        if (templateId)
            return `${domain}/rest/lp/template/getPreview?templateId=${templateId}&anchorClear=1`;
        if (keywordMidPageId)
            return `${domain}/rest/lp/middle/page/getPreview?id=${keywordMidPageId}&pageName=${keywordMidPageName}`;
        return `${domain}/rest/lp/page/html?pageId=${pageId}&__accountId__=${accountId}&pageType=${pageType}`;
    }, [templateId, pageId, isFiction]);
    return <AutoSizeIframe {...rest} url={iframeUrl} />;
};
export const capScreenHoc =
    (C: typeof PageJsonPreview) => (props: PageJsonPreviewProps & { coverImgUrl?: string }) => {
        const { components, coverImgUrl, pageId, ...rest } = props;
        if (coverImgUrl) {
            return (
                <AutoSizeImage {...rest}>
                    <img src={coverImgUrl} style={{ width: '100%' }} alt="模板封面" />
                </AutoSizeImage>
            );
        }
        return <C {...props} />;
    };

interface UseLazyOpt {
    getScroller?: () => HTMLElement;
}

export const useLazy = <T extends HTMLElement>(opt?: UseLazyOpt) => {
    const getScroller = opt?.getScroller;
    const [load, setLoad] = React.useState(false);

    const ref = React.useRef<T | null>(null);
    const isInView = React.useMemo(() => {
        return throttle(() => {
            const node = ref.current;
            if (!node || load) {
                return;
            }
            const top = node.getBoundingClientRect().top + window.scrollY;
            if (window.scrollY + window.innerHeight > top - 300) {
                setLoad(true);
            }
        }, 100);
    }, [load]);
    useEffect(isInView);
    useEffect(() => {
        const scroller = getScroller?.() ?? window;
        isInView();
        scroller.addEventListener('scroll', isInView);
        return () => {
            scroller.removeEventListener('scroll', isInView);
        };
    }, [getScroller, isInView]);
    return {
        ref,
        load,
    };
};
export const lazyHoc = <T extends { width?: number }>(C: React.ComponentType<T>) => {
    return (props: T & UseLazyOpt) => {
        const { getScroller, width, ...rest } = props;
        const { ref, load } = useLazy<HTMLDivElement>({ getScroller });
        if (!load) {
            return <div ref={ref} style={{ width }} />;
        }
        return <C {...({ ...rest, width } as T)} />;
    };
};
export default lazyHoc(capScreenHoc(PageJsonPreview));
