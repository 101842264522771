import cs from 'classnames';
import * as React from 'react';
import { useSetState } from 'react-use';
import IconFont from 'src/components/IconFont';
import keyboardJS from 'keyboardjs';
import styles from './style.module.less';

const searchKey = 'enter';

interface EditTextProps {
    className?: string;
    value: string;
    onChange: (v?: string) => void;
    titleClass?: string;
    disable?: boolean;
    renderText?: (value: string) => React.ReactNode;
    renderTitle?: React.ReactNode;
}

/**
 * 可编辑标题
 *
 */
const PageItemTitle: React.FunctionComponent<EditTextProps> = (props) => {
    const [state, setState] = useSetState({
        edit: false,
    });
    const ref = React.useRef<HTMLInputElement>(null);
    const { value, onChange, className, titleClass, disable, renderText, renderTitle } = props;
    const { edit } = state;
    // const onChange
    const changeText = React.useCallback(() => {
        onChange(ref.current?.value);
        setState({ edit: false });
    }, [onChange, setState]);
    React.useEffect(() => {
        if (edit) {
            keyboardJS.bind(searchKey, changeText);
        }
        return () => {
            keyboardJS.unbind(searchKey, changeText);
        };
    }, [changeText, edit, setState]);

    return (
        <div className={cs(className, 'text-overflow', styles.root)}>
            <IconFont type="icon-back-24" />
            {edit && <input ref={ref} defaultValue={value} autoFocus onBlur={changeText} />}
            {!edit && (
                <>
                    {renderTitle}
                    <span className={cs('text-overflow', titleClass)}>
                        {renderText ? renderText(value) : value}
                    </span>
                    {!disable && (
                        <IconFont type="icon-edit-24" onClick={() => setState({ edit: true })} />
                    )}
                </>
            )}
        </div>
    );
};

export default PageItemTitle;
