import { isBeta, isStaging, isTest } from './env';

export type Env = 'production' | 'development' | 'staging' | 'test';
// export const isProduction = process.env.REACT_APP_BUILD_ENV === 'production';

export const AD_E_HOST: string = isBeta
    ? '//beta-ad-e.corp.kuaishou.com'
    : isStaging
    ? '//chore-staging--kuaishou-frontend-dsp.jinx.staging.kuaishou.com'
    : isTest
    ? '//master.kuaishou-frontend-dsp.jinx.corp.kuaishou.com'
    : '//ad.e.kuaishou.com';
export const env: Env = isStaging ? 'staging' : isTest ? 'development' : 'production';

export const landingPageHostUrl =
    env === 'production' ? '//chenzhongkj.com' : `//${window.location.host}`;

export const getLandingpageUrl = (pageId: number) => {
    return `${landingPageHostUrl}/rest/n/lp/page/getHtml?hyId=landingPg&pageId=${pageId}`;
};
